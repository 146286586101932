import React = require('react')

import { CommonUtils } from '../common/common-utils'
import { Username } from '../common/data-attribute-defs'
import { ServiceError, UiError } from '../common/data-error-report'
import { getDataService } from '../common/data-service'
import { i18n } from '../common/i18n'
import { Transaction } from '../common/transaction-utils'
import { bindCheckbox } from './bind-utils'
import { Filter } from './filter'
import { LoadingButton } from './loading-button'
import { MainMenu } from './main-menu'
import { ReportsMenu } from './reports-menu'
import { User } from './user'

interface JsonArrayProps {
  array: any[],
  visibleKey?: string,
}

interface JsonObjectProps {
  object: any,
  visibleKey?: string,
}

interface ServiceErrorTableProps {
  entries: any[],
  getUsername: (user: string) => string,
  refreshParent: () => void,
}

interface UiErrorTableProps {
  entries: any[],
  getUsername: (user: string) => string,
  refreshParent: () => void,
}

interface FailedTransactionTableProps {
  transactions: any[],
  getUsername: (user: string) => string,
  refreshParent: () => void,
}

interface ErrorReportState {
  loaded: boolean,
  showNonCritical: boolean,
  showHidden: boolean,
  serviceErrors: ServiceError[],
  uiErrors: UiError[],
  transactions: Transaction[],
  usernames: Username[],
}

const maxJsonLength = 30
const jsonStyle = { fontFamily: 'monospace', fontSize: '0.8em' }

function getValueComponent(keyParam: string | number, value) {
  const key = keyParam.toString()

  function simple() {
    return (
      <div>
        {CommonUtils.mongoUnescapeKey(key)}
        {': '}
        {JSON.stringify(CommonUtils.mongoUnescape(value))}
      </div>
    )
  }

  let json

  if (Array.isArray(value)) {
    json = JSON.stringify(value)

    if (json.length > maxJsonLength) {
      return <JsonArray visibleKey={key} array={value} />
    }
    else {
      return simple()
    }
  }
  else if (typeof value === 'object') {
    if (!value) {
      return (
        <div>
          {CommonUtils.mongoUnescapeKey(key)}
          : null
        </div>
      )
    }
    else {
      json = JSON.stringify(value)

      if (json.length > maxJsonLength) {
        return <JsonObject visibleKey={key} object={value} />
      }
      else {
        return simple()
      }
    }
  }
  else {
    return simple()
  }
}

class JsonArray extends React.Component<JsonArrayProps> {
  render() {
    const elements = this.props.array.map(function(element, index) {
      const elementComponent = getValueComponent(index, element)
      return (
        <div key={index} style={{ marginLeft: '1.5em' }}>
          {elementComponent}
        </div>
      )
    })

    return (
      <div>
        {this.props.visibleKey !== undefined ? CommonUtils.mongoUnescapeKey(this.props.visibleKey) + ': ' : null}
        [
        {elements}
        ]
      </div>
    )
  }
}

class JsonObject extends React.Component<JsonObjectProps> {
  render() {
    const members = Object.keys(this.props.object).map((key) => {
      const member = this.props.object[key]
      const memberComponent = getValueComponent(key, member)
      return (
        <div key={key} style={{ marginLeft: '1.5em' }}>
          {memberComponent}
        </div>
      )
    })

    return (
      <div>
        {this.props.visibleKey !== undefined ? CommonUtils.mongoUnescapeKey(this.props.visibleKey) + ': ' : null}
        {'{'}
        {members}
        {'}'}
      </div>
    )
  }
}

class ServiceErrorTable extends React.Component<ServiceErrorTableProps> {
  render() {
    const filterConf = { time: { type: 'date-range' } }
    const filterManager = Filter.createManager('errors-service', filterConf, this.props.entries)

    const colCount = 6

    const headerRow = (
      <tr>
        <th>
          {i18n.t('reports.date-time')}
          {filterManager.getIcon('time')}
        </th>
        <th>{i18n.t('common.user')}</th>
        <th>Operation</th>
        <th>Parameters</th>
        <th>Error</th>
        <th>Action</th>
      </tr>
    )

    let entryRows = filterManager.getFiltered().map((entry) => {
      const userTime = CommonUtils.toUserTime(User.getUser().country, new Date(entry.time))

      let errorElement = null

      if ('customErrorData' in entry.error) {
        const errorData = entry.error.customErrorData
        const { errorType } = errorData

        if (errorType === 'validation') {
          const { validationErrors } = errorData

          errorElement = (
            <div>
              <div style={{ marginBottom: '0.4em' }}>Validation errors:</div>
              <div style={jsonStyle}>
                {Object.keys(validationErrors).map(function(key) {
                  return <div key={key}>{getValueComponent(key, validationErrors[key])}</div>
                })}
              </div>
            </div>
          )
        }
        else if (errorType === 'custom') {
          errorElement = (
            <div>
              <div style={{ marginBottom: '0.4em' }}>Custom error:</div>
              <div style={jsonStyle}>
                {errorData.errorMessage}
                {Object.keys(errorData).map(function(key) {
                  if (key !== 'errorType' && key !== 'errorMessage') {
                    return <div key={key}>{getValueComponent(key, errorData[key])}</div>
                  }
                })}
              </div>
            </div>
          )
        }
        else if (errorType === 'no-permissions') {
          errorElement = 'No permissions'
        }
      }
      else if ('stack' in entry.error) {
        errorElement = <pre className="stack">{entry.error.stack}</pre>
      }

      if (!errorElement) {
        errorElement = (
          <div style={jsonStyle}>
            <JsonObject object={entry.error} />
          </div>
        )
      }

      let hideButton = null

      if (!entry.hidden) {
        hideButton = (
          <LoadingButton
            getPromise={() => {
              return getDataService().ErrorReport.hideServiceError(entry._id)
              .then(() => {
                // A bit hacky, but ok for non-critical functionality
                entry.hidden = true
                this.props.refreshParent()
              })
            }}
            text="Hide"
          />
        )
      }

      return (
        <tr key={entry._id}>
          <td style={{ whiteSpace: 'nowrap' }}>
            {CommonUtils.utcDateTime(userTime)}
          </td>
          <td>
            {this.props.getUsername(entry.user)}
          </td>
          <td>
            {entry.serviceName}
            .
            {entry.opName}
          </td>
          <td style={jsonStyle}>
            <a
              style={{ cursor: 'pointer', float: 'right' }}
              onClick={function() {
                prompt('Copy the text from this prompt:', JSON.stringify(entry.params, null, '  '))
              }}
            >
              Copy JSON
            </a>
            {Object.keys(entry.params || {}).map(function(key) {
              return (
                <div key={key}>
                  {getValueComponent(key, entry.params[key])}
                </div>
              )
            })}
          </td>
          <td>{errorElement}</td>
          <td>{hideButton}</td>
        </tr>
      )
    })

    if (!entryRows.length) {
      const anyFilters = filterManager.anyFilters()

      entryRows = [
        <tr key="no-items">
          <td colSpan={colCount}>
            {anyFilters ? 'There are no errors that match the current filters' : 'There are no errors'}
          </td>
        </tr>,
      ]
    }

    return (
      <table
        id="tbl-rep-error-service"
        className="table table-bordered table-condensed table-striped"
      >
        <thead>{headerRow}</thead>
        <tbody>{entryRows}</tbody>
      </table>
    )
  }
}

class UiErrorTable extends React.Component<UiErrorTableProps> {
  state = {
    visibleStacks: {},
  }

  render() {
    const filterConf = { time: { type: 'date-range' } }

    const filterManager = Filter.createManager('errors-ui', filterConf, this.props.entries)

    const colCount = 5

    const headerRow = (
      <tr>
        <th>
          {i18n.t('reports.date-time')}
          {filterManager.getIcon('time')}
        </th>
        <th>{i18n.t('common.user')}</th>
        <th>Route</th>
        <th>Error</th>
        <th>Action</th>
      </tr>
    )

    let entryRows = filterManager.getFiltered().map((entry) => {
      const userTime = CommonUtils.toUserTime(User.getUser().country, new Date(entry.time))

      const stackVisible = this.state.visibleStacks[entry._id]

      let stackToggler = null

      if (entry.stack) {
        stackToggler = (
          <a
            style={{
              cursor: 'pointer',
              marginLeft: '0.8em',
              fontSize: '80%',
            }}
            onClick={() => {
              const newStacks = CommonUtils.clone(this.state.visibleStacks)
              newStacks[entry._id] = !stackVisible
              this.setState({ visibleStacks: newStacks })
            }}
          >
            {stackVisible ? '(hide stack)' : '(show stack)'}
          </a>
        )
      }

      let hideButton = null

      if (!entry.hidden) {
        hideButton = (
          <LoadingButton
            getPromise={() => {
              return getDataService().ErrorReport.hideUiError(entry._id)
              .then(() => {
                // A bit hacky, but ok for non-critical functionality
                entry.hidden = true
                this.props.refreshParent()
              })
            }}
            text="Hide"
          />
        )
      }

      let userAgent = null

      if (entry.userAgent) {
        userAgent = (
          <div style={{ fontSize: '70%', color: 'darkgrey' }}>
            {'User agent: '}
            {entry.userAgent}
          </div>
        )
      }

      return (
        <tr key={entry._id}>
          <td style={{ whiteSpace: 'nowrap' }}>
            {CommonUtils.utcDateTime(userTime)}
          </td>
          <td>
            {this.props.getUsername(entry.user)}
          </td>
          <td>
            <a href={'#/' + entry.route}>
              /
              {entry.route}
            </a>
          </td>
          <td>
            <div style={{ marginBottom: '0.5em' }}>
              {entry.message}
              {stackToggler}
            </div>
            {stackVisible && <pre className="stack">{entry.stack}</pre>}
            {userAgent}
          </td>
          <td>
            {hideButton}
          </td>
        </tr>
      )
    })

    if (!entryRows.length) {
      const anyFilters = filterManager.anyFilters()

      entryRows = [
        <tr key="no-items">
          <td colSpan={colCount}>
            {anyFilters ? 'There are no errors that match the current filters' : 'There are no errors'}
          </td>
        </tr>,
      ]
    }

    return (
      <table
        id="tbl-rep-error-ui"
        className="table table-bordered table-condensed table-striped"
      >
        <thead>{headerRow}</thead>
        <tbody>{entryRows}</tbody>
      </table>
    )
  }
}

class FailedTransactionTable extends React.Component<FailedTransactionTableProps> {
  state = {
    visibleSteps: {},
  }

  render() {
    const filterConf = {
      time: {
        type: 'date-range',
        getField: function(transaction) {
          return transaction.logParams.time
        },
      },
    }

    const filterManager = Filter.createManager(
      'errors-transactions', filterConf, this.props.transactions,
    )

    const colCount = 6

    const headerRow = (
      <tr>
        <th>
          {i18n.t('reports.date-time')}
          {filterManager.getIcon('time')}
        </th>
        <th>{i18n.t('common.user')}</th>
        <th>Type</th>
        <th>Parameters</th>
        <th>State</th>
        <th>Action</th>
      </tr>
    )

    let entryRows = filterManager.getFiltered().map((transaction) => {
      const { logParams } = transaction
      const userTime = CommonUtils.toUserTime(User.getUser().country, new Date(logParams.time))

      const stepsVisible = this.state.visibleSteps[transaction._id]

      const stepsToggler = (
        <a
          style={{ cursor: 'pointer', fontSize: '80%' }}
          onClick={() => {
            const newSteps = CommonUtils.clone(this.state.visibleSteps)
            newSteps[transaction._id] = !stepsVisible
            this.setState({ visibleSteps: newSteps })
          }}
        >
          {stepsVisible ? '(hide steps)' : '(show steps)'}
        </a>
      )

      let steps

      if (stepsVisible) {
        steps = transaction.steps.map(function(step, index) {
          let color = '#e0e0e0' // Grey

          if (step.rollbackError) {
            // Use red for failed rollback as it's the most critical problem
            color = '#ffb0b0'
          }
          else if (step.failed) {
            color = '#fff0b0' // Yellow
          }
          else if (step.rolledBack) {
            color = '#d0f0b0' // Green
          }

          return (
            <div key={index}>
              <div
                style={{
                  border: '1px solid #b0b0b0',
                  borderBottom: 0,
                  backgroundColor: color,
                  margin: '5px 5px 0',
                  padding: '0 0.3em',
                }}
              >
                {index}
                {': '}
                {step.type}
              </div>
              <div
                style={{
                  fontFamily: 'monospace',
                  fontSize: '0.8em',
                  padding: '0.5em',
                  margin: '0 5px',
                  border: '1px solid #b0b0b0',
                }}
              >
                {Object.keys(step).map(function(key) {
                  if (key !== 'type') {
                    return (
                      <div key={key}>
                        {getValueComponent(key, step[key])}
                      </div>
                    )
                  }
                })}
              </div>
            </div>
          )
        })
      }

      let hideButton = null

      if (!transaction.hidden) {
        hideButton = (
          <LoadingButton
            getPromise={() => {
              return getDataService().ErrorReport.hideFailedTransaction(transaction._id)
              .then(() => {
                // A bit hacky, but ok for non-critical functionality
                transaction.hidden = true
                this.props.refreshParent()
              })
            }}
            text="Hide"
          />
        )
      }

      let color = 'black'

      if (transaction.state === 'ROLLBACK-FAILED') {
        color = 'red'
      }
      else if (transaction.state === 'ROLLED-BACK') {
        color = 'green'
      }

      return (
        <tr key={transaction._id}>
          <td style={{ whiteSpace: 'nowrap' }}>
            {CommonUtils.utcDateTime(userTime)}
          </td>
          <td>
            {this.props.getUsername(logParams.user)}
          </td>
          <td>
            {logParams.type}
          </td>
          <td style={jsonStyle}>
            <a
              style={{ cursor: 'pointer', float: 'right' }}
              onClick={function() {
                prompt('Copy the text from this prompt:', JSON.stringify(logParams.params, null, '  '))
              }}
            >
              Copy JSON
            </a>
            {Object.keys(logParams.params).map(function(key) {
              return (
                <div key={key}>
                  {getValueComponent(key, logParams.params[key])}
                </div>
              )
            })}
          </td>
          <td>
            <span style={{ color, fontWeight: 'bold' }}>
              {transaction.state}
            </span>
            <div>{stepsToggler}</div>
            {steps}
          </td>
          <td>{hideButton}</td>
        </tr>
      )
    })

    if (!entryRows.length) {
      const anyFilters = filterManager.anyFilters()

      const message = (anyFilters ?
        'There are no failed transactions that match the current filters' :
        'There are no failed transactions'
      )

      entryRows = [
        <tr key="no-items">
          <td colSpan={colCount}>{message}</td>
        </tr>,
      ]
    }

    return (
      <table
        id="tbl-rep-error-transactions"
        className="table table-bordered table-condensed table-striped"
      >
        <thead>{headerRow}</thead>
        <tbody>{entryRows}</tbody>
      </table>
    )
  }
}

export class ErrorReport extends React.Component<Record<string, never>, ErrorReportState> {
  state = {
    loaded: false,
    showNonCritical: false,
    showHidden: false,
    serviceErrors: undefined,
    uiErrors: undefined,
    transactions: undefined,
    usernames: undefined,
  }

  _isMounted = false

  componentDidMount() {
    this._isMounted = true
    Filter.registerEvent(this)
    const DataService = getDataService()

    Promise.all([
      // TODO: server side filtering
      DataService.ErrorReport.getServiceErrors(),
      DataService.ErrorReport.getUiErrors(),
      DataService.ErrorReport.getFailedTransactions(),
      DataService.Users.getUsernames(),
    ])
    .then(([serviceErrors, uiErrors, transactions, usernames]) => {
      if (!this._isMounted) {
        return
      }

      const filteredTransactions: Transaction[] = transactions.filter(function(transaction) {
        if (transaction.state === 'EXECUTING') {
          // There may be successful transactions that are executing at the moment.
          // However, if it's been executing for a long time, it's most likely stuck.
          // The report will include any that are older than a minute.
          const ageInMilliseconds = Date.now() - new Date(transaction.logParams.time).getTime()
          return ageInMilliseconds > 60000
        }
        else {
          return true
        }
      })

      this.setState({
        loaded: true,
        serviceErrors,
        uiErrors,
        transactions: filteredTransactions,
        usernames,
      })
    })
  }

  componentWillUnmount() {
    this._isMounted = false
    Filter.unregisterEvent(this)
  }

  getUsername = (userId) => {
    const obj = CommonUtils.findById<any>(this.state.usernames, userId)
    return obj ? obj.username : '(not logged in)'
  }

  isNotHidden = (error) => {
    return !error.hidden
  }

  render() {
    const menus = [
      <MainMenu key="main" activeTab="reports" />,
      <ReportsMenu key="reports" activeTab="errors" />,
    ]

    if (!this.state.loaded) {
      return (
        <div>
          {menus}
          {i18n.t('common.loading')}
        </div>
      )
    }

    let { serviceErrors, uiErrors, transactions } = this.state

    if (!this.state.showHidden) {
      serviceErrors = serviceErrors.filter(this.isNotHidden)
      uiErrors = uiErrors.filter(this.isNotHidden)
      transactions = transactions.filter(this.isNotHidden)
    }

    if (!this.state.showNonCritical) {
      serviceErrors = serviceErrors.filter(CommonUtils.isCriticalError)
    }

    const refreshThis = () => {
      // Make sure no parameters are passed to forceUpdate
      this.forceUpdate()
    }

    return (
      <div>
        {menus}
        <div>
          {bindCheckbox(this, ['showNonCritical'])}
          {' Show non-critical errors'}
        </div>
        <div>
          {bindCheckbox(this, ['showHidden'])}
          {' Show hidden errors'}
        </div>
        <h3 style={{ fontWeight: 'bold' }}>
          Service errors
        </h3>
        <ServiceErrorTable
          entries={serviceErrors}
          getUsername={this.getUsername}
          refreshParent={refreshThis}
        />
        <h3 style={{ fontWeight: 'bold' }}>UI errors</h3>
        <UiErrorTable
          entries={uiErrors}
          getUsername={this.getUsername}
          refreshParent={refreshThis}
        />
        <h3 style={{ fontWeight: 'bold' }}>Failed transactions</h3>
        <FailedTransactionTable
          transactions={transactions}
          getUsername={this.getUsername}
          refreshParent={refreshThis}
        />
      </div>
    )
  }
}
