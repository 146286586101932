import { ReactNode, useState, useEffect, useRef } from 'react'
import React = require('react')

import { DetailedEntry } from '../common/common-utils'
import { getDataService } from '../common/data-service'
import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { getDefaultCancelButton, Modal, ModalButton } from './modal'
import { useOpenModalEvent } from './use-open-modal-event'
import { Utils } from './utils'
import { ValidationErrors, ValidationUtils } from './validation-utils'

interface EditAmountModalProps {
  entry: DetailedEntry,
  modalId: string,
  afterSave: () => void,
}

export const EditAmountModal = (props: EditAmountModalProps) => {
  const [isVisible, close] = useOpenModalEvent(props.modalId)
  const inputRef = useRef<HTMLInputElement>(null)
  const [amount, setAmount] = useState('')
  const [errors, setErrors] = useState<ValidationErrors>(null)

  const { entryAmount } = props.entry

  useEffect(() => {
    if (isVisible) {
      // On open
      setAmount(entryAmount)
      inputRef.current?.focus()
    } else {
      // On close
      setAmount('')
    }
  }, [isVisible, entryAmount])

  if (!isVisible) {
    return <div />
  }

  const buttons: ModalButton[] = [
    {
      id: 'btn-save',
      title: i18n.t('action.save'),
      onClick: async () => {
        setErrors(null)

        try {
          await getDataService().Carts.editAmount(
            props.entry.productId, amount,
          )
          EventBus.fire('cart-updated')
          await close()
          await props.afterSave()
        } catch (error) {
          const newErrors = ValidationUtils.getErrors(error)

          if (newErrors) {
            setErrors(newErrors)
            EventBus.fire('validation-errors-rendered')
          }
        }
      },
    },
    getDefaultCancelButton(),
  ]

  const row = (fieldName: string, field: ReactNode) => {
    return (
      <div className="row" style={{ margin: '0.5em 0' }}>
        <div className="col-xs-7" style={{ fontWeight: 'bold', paddingLeft: 0 }}>
          {fieldName}
        </div>
        <div className="col-xs-5">
          {field}
        </div>
      </div>
    )
  }

  const form = (
    <div>
      {row(
        i18n.t('products.title'),
        <span id="lbl-name">
          {props.entry.name}
        </span>,
      )}
      {row(
        i18n.t('cart.amount-in-inv'),
        <span id="lbl-amount-in-inv">
          {props.entry.inventoryAmount}
        </span>,
      )}
      {row(
        i18n.t('cart.amount-in-cart'),
        <span id="lbl-amount-in-cart">
          {props.entry.entryAmount}
        </span>,
      )}
      {row(
        i18n.t('cart.new-amount-in-cart'),
        <input
          ref={inputRef}
          value={amount}
          onChange={(evt) => {
            const { value } = evt.currentTarget
            if (Utils.nonNegativeIntegerValidator(value)) {
              setAmount(value)
            }
          }}
          id="inp-amount"
          style={{ width: '4em' }}
        />,
      )}
      {ValidationUtils.render(errors, 'amount')}
    </div>
  )

  return (
    <div>
      <Modal
        title={i18n.t('cart.edit-amount')}
        closeModal={close}
        dialogClassName="edit-amount"
        buttons={buttons}
      >
        {form}
      </Modal>
    </div>
  )
}
