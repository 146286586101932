import { Language } from './enums'

let currentLanguage: Language = 'en'

const strings = {
  'default': {
    'common.amount': 'Amount',
    'common.amount-threshold': 'Amount threshold',
    'common.category': 'Category',
    'common.currency': 'Currency',
    'common.custom': 'Custom',
    'common.date': 'Date',
    'common.discontinued': 'Discontinued',
    'common.fabricUsage': 'Fabric Usage',
    'common.loading': 'Loading...',
    'common.location': 'Location',
    'common.name': 'Name',
    'common.note': 'Note',
    'common.cost': 'Cost',
    'common.price': 'Price',
    'common.type': 'Type',
    'common.prices': 'Prices',
    'common.print': 'Print',
    'common.leave-print-mode': 'Back to normal view',
    'common.total': 'Total',
    'common.unit-price': 'Unit price',
    'common.user': 'User',
    'common.value': 'Value',
    'common.or': 'or',
    'common.yes': 'Yes',
    'common.no': 'No',
    'common.singular': 'singular',
    'common.plural': 'plural',
    'common.optional': 'optional',
    'common.description': 'Description',
    'common.period': 'Period',
    'common.in': 'In',
    'common.out': 'Out',
    'common.additional-expenses': 'Additional expenses',
    'common.total-cost': 'Total cost',

    'common.more-matches.singular': '1 more match',
    'common.more-matches.plural': '{0} more matches',

    'action.activate': 'Activate',
    'action.archive': 'Archive',
    'action.deactivate': 'Deactivate',
    'action.edit': 'Edit',
    'action.showInvoice': 'Show Invoice',
    'action.delete': 'Delete',
    'action.remove': 'Remove',
    'action.save': 'Save',
    'action.cancel': 'Cancel',
    'action.add': 'Add',
    'action.add-to-stock': 'Add to stock',
    'action.add-new': 'Add new',
    'action.filter': 'Filter',
    'action.apply': 'Apply',
    'action.clear-filter': 'Clear filter',
    'action.override': 'Override',
    'action.transform': 'Transform',
    'action.action': 'Action',
    'action.actions': 'Actions',
    'action.send': 'Send',
    'action.accept': 'Accept',
    'action.back-to-list': 'Back to list',
    'action.undo': 'Undo',
    'action.select': 'Select',
    'action.show-less': 'show less',
    'action.show-more': 'show more',
    'action.move-up': 'Move up',
    'action.move-down': 'Move down',
    'action.material-in-out': 'In/Out',

    'menu.main.raw-materials': 'Materials',
    'menu.main.products': 'Products',
    'menu.main.inventory': 'Inventory',
    'menu.main.cart': 'Cart',
    'menu.main.transfers': 'Transfers',
    'menu.main.reports': 'Reports',
    'menu.main.customers': 'Customers',
    'menu.main.users': 'Users',
    'menu.main.data': 'Data',
    'menu.main.accounting': 'Accounting',
    'menu.main.material-orders': 'Material orders',

    'menu.definitions.products': 'Product definitions',
    'menu.definitions.templates': 'Template definitions',

    'menu.inventory.production': 'Production',
    'menu.inventory.sales-points': 'Sales Points',

    'menu.reports.sales': 'Sales',
    'menu.reports.production': 'Production',
    'menu.reports.inventory': 'Inventory',
    'menu.reports.material-consumption': 'Material consumption',
    'menu.reports.correction': 'Manual correction',
    'menu.reports.invoice-archive': 'Invoice archive',
    'menu.reports.errors': 'Errors',

    'menu.data.attributes': 'Edit dynamic attributes',

    'menu.accounting.expenses': 'Expenses',
    'menu.accounting.expenses-summary': 'Expenses summary',
    'menu.accounting.earnings': 'Earnings',
    'menu.accounting.earnings-summary': 'Earnings summary',
    'menu.accounting.expense-categories': 'Expense categories',
    'menu.accounting.earning-categories': 'Earning categories',

    'table.no-items': 'No items to show',
    'table.no-filtered-items': 'No items to show that match the current filters',

    'raw-materials.material': 'Material',
    'raw-materials.name': 'Material Name',
    'raw-materials.reference': 'Reference',
    'raw-materials.width': 'Width',
    'raw-materials.producer': 'Producer',
    'raw-materials.amount-cut': 'Amount cut',
    'raw-materials.unit': 'Unit',
    'raw-materials.added': 'Added',
    'raw-materials.cut': 'Cut',
    'raw-materials.history-of-added': 'History of added amounts',
    'raw-materials.add-new': 'Add new material',
    'raw-materials.add-to-amount': 'Add to amount',
    'raw-materials.current-amount': 'Current amount',
    'raw-materials.amount-to-add': 'Amount to add',
    'raw-materials.new-amount': 'New amount',
    'raw-materials.cut-material': 'Cut material',
    'raw-materials.price-per-unit': 'Price per unit',
    'raw-materials.amount-to-cut': 'Amount to cut',
    'raw-materials.no-items': 'There are no materials',
    'raw-materials.no-filtered-items': 'There are no materials that match the current filters',
    'raw-materials.expected-one-location': 'Expected exactly one material location',
    'raw-materials.photo': 'Photo',
    'raw-materials.amount-below-threshold': 'There is only a small amount of this material left',
    'raw-materials.include-discontinued': 'Include discontinued',
    'raw-materials.in-out': 'In/Out',

    'material-orders.id': 'Reference',
    'material-orders.producer': 'Producer',
    'material-orders.material-reference': 'Material reference',
    'material-orders.material-quantity': 'Quantity',
    'material-orders.time-created': 'Date',
    'material-orders.invoice': 'Invoice',
    'material-orders.payment-type': 'Payment type',
    'material-orders.time-of-payment': 'Payment date',
    'material-orders.delivery-note': 'Delivery note',
    'material-orders.verification-of-conformity': 'Verification of conformity',
    'material-orders.note': 'Remarks (notes)',
    'material-orders.status': 'Order status',
    'material-orders.expense': 'Expense',
    'material-orders.no-materials': 'No material available',
    'material-orders.add-material': 'Add material',
    'material-orders.add-new': 'Add new order',
    'material-orders.add-archive': 'Add to archive',
    'material-orders.include-archived': 'Include archived',

    'material-orders.incorrect-status': 'Order has invalid status',
    'material-orders.missing-amount-and-prices': 'Order has unfilled material amount and/or costs',
    'material-orders.stock-already-updated': 'Order was already used in stock update',

    'material-orders.select-one-item': 'Please select one item',

    'material-orders.show-invoice.name': 'Material / Tissue',
    'material-orders.show-invoice.quantity': 'Quantity in meters',
    'material-orders.show-invoice.prix-euro': 'Price EUR',
    'material-orders.show-invoice.prix-usd': 'Price USD',
    'material-orders.show-invoice.prix-mad': 'Price MAD',
    'material-orders.show-invoice.total-euro': 'Total EURO',
    'material-orders.show-invoice.total-usd': 'Total USD',
    'material-orders.show-invoice.total-mad': 'Total MAD',
    'material-orders.show-invoice.total-cost': 'Total cost',
    'material-orders.show-invoice.total-unitare': 'Unit cost',

    'tailor-order.not-found': 'Order not found',
    'tailor-order.comment-not-found': 'Comment not found',

    'image-upload.view-larger': 'Click to view larger version',
    'image-upload.change': 'Change image',
    'image-upload.remove': 'Remove image',
    'image-upload.expected-format': 'The image must be a JPG or PNG file',
    'image-upload.failed': 'Failed to process image',

    'upload.file-too-large': 'File must not be larger than {0}',

    'definition.no-items': 'There are no definitions',
    'definition.no-filtered-items': 'There are no definitions that match the current filters',
    'definition.discontinued-field-note': 'If checked, this definition cannot be used in production',
    'definition.is-discontinued': 'Definition is discontinued',
    'definition.cannot-remove-combo-in-use': 'Can not remove attribute value combinations that are still in use',

    'tpl-def.title': 'Template definition',
    'tpl-def.define-new': 'Define new template',
    'tpl-def.product-links': 'Product links',
    'tpl-def.no-product-links': 'There are no product definitions linked to this template definition yet',
    'tpl-def.tailor-cost': 'Tailor cost',
    'tpl-def.fabric-cost': 'Fabric cost',

    'prod-def.title': 'Product definition',
    'prod-def.define-new': 'Define new product',
    'prod-def.template': 'Template',
    'prod-def.invalid-tpl-def': 'Invalid template definition ID',
    'prod-def.category-mismatch': 'Category does not match template',
    'prod-def.editing-custom-directly': 'Editing custom product definitions directly is not allowed',

    'inventory.order-id': 'Order ID',
    'inventory.template-unit-cost': 'Template unit cost',
    'inventory.template-total-cost': 'Template total cost',
    'inventory.product-unit-price': 'Product unit price',
    'inventory.product-value': 'Product value',
    'inventory.no-items': 'There are no items in the inventory of this location',
    'inventory.no-filtered-items': 'There are no inventory items that match the current filters',
    'inventory.product': 'Product',
    'inventory.in-cart': '{0} in cart',
    'inventory.add-to-cart': 'Add to cart',
    'inventory.add-amount': 'Add amount',
    'inventory.from-template': 'From template',
    'inventory.original-location': 'Original location',
    'inventory.not-enough-items': 'Not enough items in inventory',
    'inventory.not-enough-templates': 'Not enough templates in inventory',
    'inventory.can-only-add-standalone': 'Can only directly add products that don\'t have source templates',
    'inventory.can-only-add-amount-standalone': 'Can only add to amount to products without templates',
    'inventory.duplicates-found': 'Duplicate inventory entries detected',
    'inventory.rows-omitted': 'There are more rows in the table that were omitted to load the page faster.',
    'inventory.load-remaining-rows': 'Load remaining items',
    'inventory.convert-to-custom': 'Convert to custom product',
    'inventory.cannot-convert-unsaved': 'You cannot convert the product when there are unsaved changes on the form',
    'inventory.convert-prompt': 'Warning: You will not be able to convert the custom product back into a regular product. Are you sure you want to continue?',
    'inventory.product-already-custom': 'This is already a custom product',

    'inventory.type.template': 'Template',
    'inventory.type.product': 'Product',

    'products.title': 'Product',
    'products.invalid-id': 'Invalid product id',
    'products.expected-one-location': 'Expected exactly one storage location',

    'templates.title': 'Template',
    'templates.add-to-amount': 'Add to amount',
    'templates.current-amount': 'Current amount',
    'templates.amount-to-add': 'Amount to add',
    'templates.new-amount': 'New amount',
    'templates.transform': 'Transform template',
    'templates.transform-into': 'Transform into',
    'templates.amount-below-threshold': 'There is only a small amount of these templates left',
    'template.no-products-for-transform': 'Unable to transform - there are no product definitions that match this template with this set of attributes.',
    'template.defs-do-not-match': 'Template definitions do not match',

    'attrs.in-use': 'in use',

    'price-ovr.title': 'Price overrides',
    'price-ovr.ovr-based': 'Override the price based on the following attributes:',
    'price-ovr.use-base-price': 'Use base price',
    'price-ovr.confirm-remove-attr': 'Removing attributes will reset all price overrides. Are you sure you want to continue?',

    'currency.per-unit': '{0} per unit',

    'cart.amount-in-inv': 'Amount in inventory',
    'cart.amount-in-cart': 'Amount in cart',
    'cart.new-amount-in-cart': 'New amount in cart',
    'cart.edit-amount': 'Edit amount',
    'cart.no-items': 'There are no items from {0} in the cart',
    'cart.add-more-items': 'Add more items',
    'cart.sell-items': 'Sell the items',
    'cart.transfer-items': 'Transfer the items',
    'cart.transfer-to': 'to',
    'cart.not-enough-in-inv': 'Error: there are not enough items in the inventory. Please edit the amount.',
    'cart.no-location-selected': 'No location selected',
    'cart.amount-over-max': 'Amount in cart can not exceed amount in inventory',
    'cart.entry-not-found': 'Cart entry not found for this product',
    'cart.create-empty-invoice': 'Create empty invoice',

    'invoice.invoice': 'Invoice',
    'invoice.invoices': 'Invoices',
    'invoice.new-invoice': 'New invoice',
    'invoice.invoice-number': 'Invoice #{0}',
    'invoice.correction-title': 'Correction of invoice #{0}',
    'invoice.quantity': 'Quantity',
    'invoice.item': 'Item',
    'invoice.default-unit-price': 'Default unit price',
    'invoice.original-total': 'Original total',
    'invoice.overridden-from': 'Overridden from {0} {1}',
    'invoice.recipient-name': 'Customer name',
    'invoice.contact-info': 'Contact information',
    'invoice.notes': 'Notes',
    'invoice.shipping-fee': 'Shipping fee',
    'invoice.droits-de-timbre': 'Droits de timbre',
    'invoice.droits-de-timbre-paid': 'Droits de timbre ({0} {1}) has been paid',
    'invoice.droits-de-timbre-needed': 'Droits de timbre must be marked as paid',
    'invoice.export-invoice': 'Export invoice (no VAT)',
    'invoice.marra-cash-card-discount': 'MarraCashCard discount: {0} {1}',
    'invoice.subtotal': 'Subtotal',
    'invoice.vat': 'VAT',
    'invoice.guide-commission': 'Guide\'s commission',
    'invoice.card-commission': 'Card commission',
    'invoice.actual-payments': 'Actual payments',
    'invoice.actual-payment-hint': 'Click on one of the links to pay the entire invoice in that currency and method or enter partial amounts in different fields.',
    'invoice.confirm-sale': 'Confirm sale',
    'invoice.print': 'Print invoice',
    'invoice.correct': 'Correct',
    'invoice.corrected': 'Corrected',
    'invoice.prices-missing': 'All items need to have a price (or price override) in {0}.',
    'invoice.uncovered-warning': 'There is still {0} {1} that isn\'t covered by any actual payments. Are you sure you want to confirm the invoice?',
    'invoice.no-actual-payments': 'Actual payment information must be entered before confirming the invoice.',
    'invoice.change-attributes': 'Change attributes',
    'invoice.choose-item': 'Choose item',
    'invoice.totals-mismatch-error': 'The new total {0} must match the original total {1}',
    'invoice.totals-mismatch-warning': 'The new total {0} does not match the original total {1}. Are you sure you want to change the total amount?',
    'invoice.totals-mismatch-server': 'The new total {0} does not match the original total {1}',
    'invoice.undo-confirm': 'Are you sure you want to undo this invoice? This will return the sold items to inventory and delete the invoice.',
    'invoice.cannot-correct-more': 'Cannot correct an invoice more than once',
    'invoice.book': 'Book',
    'invoice.book-name': 'Book "Colors in Marrakech" by Ronnie Niedermeyer, 2015',
    'invoice.archive-by-year': 'Archive invoices by year',
    'invoice.dearchive-by-year': 'Dearchive invoices by year',
    'invoice.search-by-number': 'Search invoice by number',

    'invoice.select-variation.1': 'Select variation of',
    'invoice.select-variation.2': 'at',

    'invoice.select-different-variation.1': 'Select different variation of',
    'invoice.select-different-variation.2': 'at',

    'invoice.correct-warning.1': 'Warning',
    'invoice.correct-warning.2': 'an invoice can only be corrected once.',
    'invoice.correct-warning.3': 'After this, it will remain read-only.',

    'invoice.actual.in-cash': 'in cash',
    'invoice.actual.by-card': 'by card',
    'invoice.actual.cheque': '(cheque)',
    'invoice.actual.transfer': '(transfer)',

    'invoice.set-raw-material': 'Set raw material',

    'transfers.new-transfer': 'New transfer',
    'transfers.status': 'Status',
    'transfers.from': 'From',
    'transfers.to': 'To',
    'transfers.pieces': 'Pieces',
    'transfers.no-transfers': 'There are no transfers',
    'transfers.no-filtered-transfers': 'There are no transfers that match the current filters',
    'transfers.amount-too-high': 'Cannot transfer more items than there are in the cart',
    'transfers.amount-too-low': 'Cannot transfer a negative amount of items',
    'transfers.correct-amounts': 'Correct amounts',
    'transfers.accept-with-corrections': 'Accept with corrections',
    'transfers.actual-amount': 'Actual amount',
    'transfers.correction-reason': 'Correction reason',
    'transfers.return-transfer-info': 'This is a return transfer of',
    'transfers.corrected-from': 'corrected from {0}',
    'transfers.not-enough-in-inv': 'Not enough in inventory',
    'transfers.no-items': 'There are no transfer items',
    'transfers.no-filtered-items': 'There are no transfer items that match the current filters',
    'transfers.same-location': 'From and to locations must be different',
    'transfers.can-only-edit-own': 'Can only edit own transfers',
    'transfers.can-only-edit-draft': 'Can only edit transfer drafts',
    'transfers.can-only-send-own': 'Can only send own transfers',
    'transfers.can-only-send-draft': 'Can only send transfer drafts',
    'transfers.can-only-delete-own': 'Can only delete own transfers',
    'transfers.can-only-delete-draft': 'Can only delete transfer drafts',
    'transfers.can-only-accept-sent': 'Can only accept sent transfers',
    'transfers.cannot-correct-return': 'Cannot correct a return transfer',

    'customer.search-by-name': 'Search by customer name',

    'user.username': 'Username',
    'user.given-name': 'Given name',
    'user.family-name': 'Family name',
    'user.email': 'Email',
    'user.role': 'Role',
    'user.country': 'Country',
    'user.language': 'Language',
    'user.allowed-locations': 'Allowed locations',
    'user.password': 'Password',
    'user.password-leave-empty': 'Leave the password fields empty to keep the existing one.',
    'user.old-password': 'Old password',
    'user.new-password': 'New password',
    'user.repeat-new-password': 'Repeat new password',
    'user.repeat-password': 'Repeat password',
    'user.is-admin': 'is admin',
    'user.add-user': 'Add user',
    'user.log-in': 'Log in',
    'user.log-out': 'Log out',
    'user.failed-log-in': 'Failed to log in',
    'user.passwords-no-match': 'The passwords do not match',
    'user.old-password-required': 'Old password is required when changing it',
    'user.old-password-mismatch': 'Old password does not match',
    'user.cannot-delete-self': 'Cannot delete own user',
    'user.cannot-deactivate-self': 'Cannot deactivate own user',
    'user.cannot-activate-self': 'Cannot activate own user',

    'reports.date-time': 'Date / time',
    'reports.no-items': 'There are no entries in the report',
    'reports.invoices-loaded': 'invoices loaded',
    'reports.load-next-invoices': 'Load next invoices',
    'reports.no-filtered-items': 'There are no entries in the report that match the current filters',
    'reports.no-invoices': 'There are no invoices',
    'reports.no-filtered-invoices': 'There are no invoices that match the current filters',
    'reports.clear-invoice-filters': 'Clear invoice filters',
    'reports.invoice-filters': 'Invoice filters',
    'reports.entry-filters': 'Entry filters',
    'reports.totals': 'Totals',
    'reports.edit-entry': 'Edit report entry',
    'reports.cash-from-sales': 'Cash from sales',
    'reports.cash-to-guides': 'Cash to guides',
    'reports.cash-inflow': 'Cash inflow',
    'reports.card-payments-from-sales': 'Card payments from sales',
    'reports.card-commissions': 'Card commissions',
    'reports.card-payment-inflow': 'Card payment inflow',
    'reports.total-by-cheque': 'Total by cheque',
    'reports.total-by-transfer': 'Total by transfer',
    'reports.total-inflow': 'Total inflow',
    'reports.total-subtotal': 'Total minus VAT',
    'reports.total-vat': 'Total VAT',
    'reports.total-total': 'Total revenue',
    'reports.type': 'Type',
    'reports.from-amount': 'From amount',
    'reports.to-amount': 'To amount',
    'reports.download-excel-file': 'Download Excel file',
    'reports.invalid-date-time': 'Invalid date and/or time',
    'reports.cannot-edit-transform': 'Cannot edit attributes of template transformation',

    'data.back-to-index': 'Back to index',
    'data.sort-key': 'Sort key',
    'data.attributes.add-new': 'Add new attribute',
    'data.attributes.back': 'Back to attributes',
    'data.attributes.save-note.note': 'Note',
    'data.attributes.save-note': 'Unlike in other data management sections, here you need to press the Save button at the bottom of the page to save any of your changes.',
    'data.attributes.contexts': 'Contexts',
    'data.attributes.is-optional': 'Is optional',
    'data.attributes.freeform-for-custom': 'Use freeform text for custom products',
    'data.attributes.value-group': 'Value group',
    'data.attributes.value-group.delete': 'Delete value group',
    'data.attributes.value-group.add': 'Add value group',
    'data.attributes.not-limited': 'Not limited by category',

    'api.not-found': 'Not found',
    'api.no-permissions': 'No permissions',
    'api.unexpected-validation-error': 'Unexpected validation error. Please notify developer.',
    'api.validation-errors': 'There are validation errors',
    'api.duplicate-key-error': 'A value that you entered already exists, but needed to be unique. The system was unable to automatically detect which value it was.',
    'api.error': 'Error',

    'references.cannot-remove.singular': 'Cannot remove this {0} - there is 1 {1} linked to it',
    'references.cannot-remove.plural': 'Cannot remove this {0} - there are {1} {2} linked to it',

    'validation.required': 'Required',
    'validation.invalid': 'Invalid value',
    'validation.decimal': 'Must be a decimal number',
    'validation.integer': 'Must be a whole number',
    'validation.integer-string': 'Must be a whole number',
    'validation.non-empty': 'Must not be empty',
    'validation.unique': 'Must be unique. You entered a value that was already in use.',
    'validation.over-max': 'The value is too high',
    'validation.under-min': 'The value is too low',
    'validation.date-ymd': 'Must be a YYYY-MM-DD date',
    'validation.unexpected': 'Unexpected validation error',
    'validation.year-four-digit': 'Must be a four digit year',
    'validation.year-out-of-range': 'The specified year is out of range',
    'validation.month-two-digit': 'Must be a two digit month',
    'validation.month-out-of-range': 'The specified month is out of range',

    'filters': 'Filters',
    'filters.predefined.desc': 'Only show rows where the value matches one of the following:',
    'filters.predefined-combo.desc': 'Only show rows where the combination matches one of the following:',
    'filters.predefined-combo.mode-button.partial': 'Include partial matches',
    'filters.predefined-combo.mode-button.strict': 'Only show strict matches',
    'filters.predefined-combo.summary-mode.partial': 'partial',
    'filters.predefined-combo.summary-mode.strict': 'strict',
    'filters.number-range.desc': 'Only show rows where the value is between',
    'filters.number-range.and': 'and',
    'filters.number-range.between': 'Between',
    'filters.number-range.at-least': 'At least',
    'filters.number-range.at-most': 'At most',
    'filters.number-range.may-leave-empty': 'You may leave one of the fields empty for an unbounded range.',
    'filters.date-range.from': 'From',
    'filters.date-range.to': 'To',
    'filters.date-range.shortcuts': 'Shortcuts',
    'filters.date-range.all-of': 'All of',
    'filters.clear-all': 'Clear all',
    'filters.dropped': 'Some filters were dropped',

    'months.1': 'January',
    'months.2': 'February',
    'months.3': 'March',
    'months.4': 'April',
    'months.5': 'May',
    'months.6': 'June',
    'months.7': 'July',
    'months.8': 'August',
    'months.9': 'September',
    'months.10': 'October',
    'months.11': 'November',
    'months.12': 'December',

    'short-months.1': 'Jan',
    'short-months.2': 'Feb',
    'short-months.3': 'Mar',
    'short-months.4': 'Apr',
    'short-months.5': 'May',
    'short-months.6': 'Jun',
    'short-months.7': 'Jul',
    'short-months.8': 'Aug',
    'short-months.9': 'Sep',
    'short-months.10': 'Oct',
    'short-months.11': 'Nov',
    'short-months.12': 'Dec',

    'confirm.archive.item': 'Are you sure you want to archive this item?',
    'confirm.delete.material': 'Are you sure you want to delete this material?',
    'confirm.delete.definition': 'Are you sure you want to delete this definition?',
    'confirm.delete.item': 'Are you sure you want to delete this item?',
    'confirm.delete.transfer': 'Are you sure you want to delete this transfer?',
    'confirm.delete.customer': 'Are you sure you want to delete this customer?',
    'confirm.delete.user': 'Are you sure you want to delete this user?',
    'confirm.delete.expense': 'Are you sure you want to delete this expense?',
    'confirm.delete.earning': 'Are you sure you want to delete this earning?',
    'confirm.undo.entry': 'Are you sure you want to undo this entry?',

    'enum.languages.en': 'English',
    'enum.languages.fr': 'French',

    'enum.attribute-contexts.materials': 'Raw materials',
    'enum.attribute-contexts.products': 'Products',
    'enum.attribute-contexts.templates': 'Templates',

    'enum.transfer-statuses.draft': 'Draft',
    'enum.transfer-statuses.sent': 'Sent',
    'enum.transfer-statuses.accepted': 'Accepted',
    'enum.transfer-statuses.corrected': 'Corrected',

    'enum.material-units.metres': 'metres',
    'enum.material-units.pieces': 'pieces',

    'enum.roles.admin': 'Administrator',
    'enum.roles.powerUser': 'Power user',
    'enum.roles.assistant': 'Assistant',
    'enum.roles.rawMaterials': 'Raw materials stock manager',
    'enum.roles.secretary': 'Secretary',
    'enum.roles.production': 'Production manager',
    'enum.roles.sales': 'Sales staff',
    'enum.roles.shopManager': 'Shop manager',
    'enum.roles.accountant': 'Accountant',
    'enum.roles.tailor': 'Tailor',

    'enum.material-orders-statuses.created': 'Created',
    'enum.material-orders-statuses.confirmed': 'Confirmed',
    'enum.material-orders-statuses.sent': 'Sent',
    'enum.material-orders-statuses.received': 'Received',

    'enum.payment-types.cash': 'Cash',
    'enum.payment-types.card': 'Card',
    'enum.payment-types.bankWire': 'Bank wire',

    'enum.additional-expenses.shipping': 'Shipping',
    'enum.additional-expenses.analysis': 'Analysis',
    'enum.additional-expenses.storage': 'Storage',
    'enum.additional-expenses.customs': 'Customs',
    'enum.additional-expenses.delivery': 'Delivery',
    'enum.additional-expenses.other': 'Other',

    'accounting.expenses.no-expense-category-error': 'No expense category with id "{0}"',
    'accounting.expenses.expense': 'Expense',
    'accounting.expenses.add-expense': 'Add expense',
    'accounting.earnings.no-earning-category-error': 'No earning category with id "{0}"',
    'accounting.earnings.earning': 'Earning',
    'accounting.earnings.add-earning': 'Add earning',
  },
  'fr': {
    'common.amount': 'Quantité',
    'common.amount-threshold': 'Valeur limite',
    'common.category': 'Catégorie',
    'common.currency': 'Monnaie',
    'common.custom': 'Commande',
    'common.date': 'Date',
    'common.discontinued': 'Pas maintenue',
    'common.fabricUsage': 'Fabric Usage',
    'common.loading': 'Chargement...',
    'common.location': 'Lieu',
    'common.name': 'Nom',
    'common.note': 'Remarque',
    'common.cost': 'Coût',
    'common.price': 'Prix',
    'common.type': 'Type',
    'common.prices': 'Prix',
    'common.print': 'Imprimer',
    'common.leave-print-mode': 'Retourner à l\'affichage normale',
    'common.total': 'Total',
    'common.unit-price': 'Prix unité',
    'common.user': 'Utilisateur',
    'common.value': 'Valeur',
    'common.or': 'ou',
    'common.yes': 'Oui',
    'common.no': 'Non',
    'common.singular': 'singulier',
    'common.plural': 'pluriel',
    'common.optional': 'optionnel',
    'common.description': 'Description',
    'common.period': 'Période',
    'common.in': 'Entrée',
    'common.out': 'Sortie',
    'common.additional-expenses': 'Dépense supplémentaire',
    'common.total-cost': 'Couts total',

    'common.more-matches.singular': 'une occurrence de plus',
    'common.more-matches.plural': '{0} occurrences de plus',

    'action.activate': 'Activer',
    'action.archive': 'Archiver',
    'action.deactivate': 'Desactiver',
    'action.edit': 'Editer',
    'action.delete': 'Supprimer',
    'action.remove': 'Enlever',
    'action.save': 'Enregistrer',
    'action.cancel': 'Annuler',
    'action.add': 'Ajouter',
    'action.add-to-stock': 'Ajout au stock',
    'action.add-new': 'Ajouter nouveau',
    'action.filter': 'Filtre',
    'action.apply': 'Appliquer',
    'action.clear-filter': 'Enlever filtre',
    'action.override': 'Changer prix',
    'action.transform': 'Transformer',
    'action.action': 'Opération',
    'action.actions': 'Opérations',
    'action.send': 'Envoyer',
    'action.accept': 'Accepter',
    'action.back-to-list': 'Retourner à liste',
    'action.undo': 'Annuler',
    'action.select': 'Selectionner',
    'action.show-less': 'afficher moins',
    'action.show-more': 'afficher plus',
    'action.move-up': 'Monter',
    'action.move-down': 'Descendre',
    'action.material-in-out': 'Entrée/Sortie',

    'menu.main.raw-materials': 'Tissus',
    'menu.main.products': 'Produits',
    'menu.main.inventory': 'Inventaire',
    'menu.main.cart': 'Chariot',
    'menu.main.transfers': 'Transferts',
    'menu.main.reports': 'Rapports',
    'menu.main.customers': 'Clients',
    'menu.main.users': 'Utilisateurs',
    'menu.main.data': 'Données',
    'menu.main.accounting': 'Comptabilité',
    'menu.main.material-orders': 'Commandes de tissu',

    'menu.definitions.products': 'Définitions du produit',
    'menu.definitions.templates': 'Définitions du modèle par défaut',

    'menu.inventory.production': 'Production',
    'menu.inventory.sales-points': 'Points de vente',

    'menu.reports.sales': 'Ventes',
    'menu.reports.production': 'Production',
    'menu.reports.inventory': 'Inventaire',
    'menu.reports.material-consumption': 'Consommation des tissus',
    'menu.reports.correction': 'Correction manuelle',
    'menu.reports.invoice-archive': 'Archive des factures',
    'menu.reports.errors': 'Erreurs',

    'menu.data.attributes': 'Modifier des attributs dynamiques',

    'menu.accounting.expenses': 'Depénses',
    'menu.accounting.expenses-summary': 'Sommaire de dépenses',
    'menu.accounting.earnings': 'Revenues',
    'menu.accounting.earnings-summary': 'Sommaire des revenues',
    'menu.accounting.expense-categories': 'Categorie de dépenses',
    'menu.accounting.earning-categories': 'Categorie de revenues',

    'table.no-items': 'Aucun élemént disponible',
    'table.no-filtered-items': 'Aucun élemént de la sélection disponible',

    'raw-materials.material': 'Tissu',
    'raw-materials.name': 'Nom du tissu',
    'raw-materials.reference': 'Référence',
    'raw-materials.width': 'Largeur',
    'raw-materials.producer': 'Fabricant',
    'raw-materials.amount-cut': 'Quantité coupée',
    'raw-materials.unit': 'Unité de mesure',
    'raw-materials.added': 'Ajouté',
    'raw-materials.cut': 'Couper',
    'raw-materials.history-of-added': 'Rapport de quantité ajoutée',
    'raw-materials.add-new': 'Ajouter nouveau tissu',
    'raw-materials.add-to-amount': 'Ajouter en quantité',
    'raw-materials.current-amount': 'Quantité actuelle',
    'raw-materials.amount-to-add': 'Quantité à ajouter',
    'raw-materials.new-amount': 'Nouvelle quantité',
    'raw-materials.cut-material': 'Couper quantité',
    'raw-materials.price-per-unit': 'Prix par mètre',
    'raw-materials.amount-to-cut': 'Quantité à couper',
    'raw-materials.no-items': 'Aucun tissu disponible',
    'raw-materials.no-filtered-items': 'Aucun tissu conformant avec filtre disponible',
    'raw-materials.expected-one-location': 'Prévu exactement une place du stock des tissus',
    'raw-materials.photo': 'Photo',
    'raw-materials.amount-below-threshold': 'Il reste seulement une petite quantité',
    'raw-materials.include-discontinued': 'Afficher les abandonnés',
    'raw-materials.in-out': 'Entrée/Sortie',

    'material-orders.id': 'Référence',
    'material-orders.producer': 'Fournisseur',
    'material-orders.material-reference': 'Référence tissu',
    'material-orders.material-quantity': 'Quantité',
    'material-orders.time-created': 'Date',
    'material-orders.invoice': 'Facture',
    'material-orders.payment-type': 'Type de payement',
    'material-orders.time-of-payment': 'Date de payement',
    'material-orders.delivery-note': 'Note de livraison',
    'material-orders.verification-of-conformity': 'Vérification de conformité',
    'material-orders.note': 'Remarque',
    'material-orders.status': 'Statuts de commande',
    'material-orders.expense': 'Dépense',
    'material-orders.no-materials': 'Pas de tissu choisis',
    'material-orders.add-material': 'Ajout de tissu',
    'material-orders.add-new': 'Ajout de nouvelle commande',
    'material-orders.add-archive': 'Ajout a l\'archive',
    'material-orders.include-archived': 'Archivé',

    'material-orders.incorrect-status': 'Order has invalid status',
    'material-orders.missing-amount-and-prices': 'Order has unfilled material amount and/or costs',
    'material-orders.stock-already-updated': 'Order was already used in stock update',

    'material-orders.show-invoice.name': 'Matériel / Tissu',
    'material-orders.show-invoice.quantity': 'QNT de mèters',
    'material-orders.show-invoice.prix-euro': 'Prix Unt EURO',
    'material-orders.show-invoice.prix-usd': 'Prix Unt USD',
    'material-orders.show-invoice.prix-mad': 'Prix Unt MAD',
    'material-orders.show-invoice.total-euro': 'Totale EUR',
    'material-orders.show-invoice.total-usd': 'Totale USD',
    'material-orders.show-invoice.total-mad': 'Totale MAD',
    'material-orders.show-invoice.total-cost': 'Coût total',
    'material-orders.show-invoice.total-unitare': 'Cout unitaire MAD',

    'tailor-order.not-found': 'Commande introuvable',
    'tailor-order.comment-not-found': 'Commentaire introuvable',

    'image-upload.view-larger': 'Cliquer ici pour regarder en format large',
    'image-upload.change': 'Changer image',
    'image-upload.remove': 'Enlever image',
    'image-upload.expected-format': 'L\'image doit être en format JPG ou PNG',
    'image-upload.failed': 'Une erreur s\'est produit',

    'upload.file-too-large': 'Le fichier ne doit pas excéder {0}',

    'definition.no-items': 'Aucune définition',
    'definition.no-filtered-items': 'Aucune définition conformant avec filtre',
    'definition.discontinued-field-note': 'Si activé, on peut pas utiliser la définition pour la production',
    'definition.is-discontinued': 'Définition n\'est pas maintenue',
    'definition.cannot-remove-combo-in-use': 'Ce n\'est pas possible à supprimer les combinations des valeurs d\'attributs utilisées',

    'tpl-def.title': 'Définition du modèle par défaut',
    'tpl-def.define-new': 'Créer nouveau modèle par défaut',
    'tpl-def.product-links': 'Lien du produit',
    'tpl-def.no-product-links': 'Encore aucune définition des produits reliés avec définition',
    'tpl-def.tailor-cost': 'Coût Couture',
    'tpl-def.fabric-cost': 'Coût Tissu',

    'prod-def.title': 'Définition du produit',
    'prod-def.define-new': 'Créer nouveau produit',
    'prod-def.template': 'Modèle par défaut',
    'prod-def.invalid-tpl-def': 'ID pour modèle par défaut expiré',
    'prod-def.category-mismatch': 'Catégorie pas conforme avec modèle par défaut',
    'prod-def.editing-custom-directly': 'Editer directement la définition du produit sur mesure, c\'est interdit',

    'inventory.order-id': 'Commande No.',
    'inventory.template-unit-cost': 'Coût du modèle par défaut',
    'inventory.template-total-cost': 'Coût total du modèle par défaut',
    'inventory.product-unit-price': 'Produit prix unité',
    'inventory.product-value': 'Valeur du produit',
    'inventory.no-items': 'Aucun produit au stock local',
    'inventory.no-filtered-items': 'Aucun produit du stock conforme avec filtre',
    'inventory.product': 'Produit',
    'inventory.in-cart': '{0} au chariot',
    'inventory.add-to-cart': 'Ajouter au chariot',
    'inventory.add-amount': 'Augmenter nombre',
    'inventory.from-template': 'Du modèle',
    'inventory.original-location': 'Origine',
    'inventory.not-enough-items': 'Nombre des produits dans le stock pas suffisant',
    'inventory.not-enough-templates': 'Nombre des définitions dans le stock pas suffisant',
    'inventory.can-only-add-standalone': 'Ajoute juste des produits sans sources des modèles par défaut',
    'inventory.can-only-add-amount-standalone': 'Ajoute juste au nombre des produits sans modèles par défaut',
    'inventory.duplicates-found': 'Doublons en stock constaté',
    'inventory.rows-omitted': 'Des autres lignes sont cachés pour une plus vite chargement de page.',
    'inventory.load-remaining-rows': 'Charger produits restants',
    'inventory.convert-to-custom': 'Transformer en commande',
    'inventory.cannot-convert-unsaved': 'On ne peut pas transformer le produit si la fiche n\'est pas complété',
    'inventory.convert-prompt': 'Attention: On ne peut pas inverser la transformation de commande au produit normal. Est-ce que vous souhaitez poursuivre?',
    'inventory.product-already-custom': 'C\'est déjà une commande',

    'inventory.type.template': 'Définition',
    'inventory.type.product': 'Produit',

    'products.title': 'Produit',
    'products.invalid-id': 'No du produit incorrect',
    'products.expected-one-location': 'Prévu exactement une place du stock',

    'templates.title': 'Définition',
    'templates.add-to-amount': 'Ajouter en quantité',
    'templates.current-amount': 'Quantité actuelle',
    'templates.amount-to-add': 'Quantité à ajouter',
    'templates.new-amount': 'Nouvelle quantité',
    'templates.transform': 'Transformer définition',
    'templates.transform-into': 'Transformer à',
    'templates.amount-below-threshold': 'Il reste une petite quantité des définitions',
    'template.no-products-for-transform': 'Incapable à transformer - aucuns définition du produit conformant avec modèle par défaut avec ces attributs.',
    'template.defs-do-not-match': 'Définition pas conforme',

    'attrs.in-use': 'en cours',

    'price-ovr.title': 'Changements des prix',
    'price-ovr.ovr-based': 'Changer le prix en base des attributs suivants:',
    'price-ovr.use-base-price': 'Utiliser prix de base',
    'price-ovr.confirm-remove-attr': 'Annuler les attributs va réinitialiser tous les changements des prix. Est-ce que vous voulez vraiment continuer?',

    'currency.per-unit': '{0} par unité',

    'cart.amount-in-inv': 'Quantité dans le stock',
    'cart.amount-in-cart': 'Quantité dans le chariot',
    'cart.new-amount-in-cart': 'Nouvelle quantité dans le chariot',
    'cart.edit-amount': 'Editer quantité',
    'cart.no-items': 'Aucuns produits de {0} dans le chariot',
    'cart.add-more-items': 'Ajouter produits',
    'cart.sell-items': 'Vendre produits',
    'cart.transfer-items': 'Transférer produits',
    'cart.transfer-to': 'à',
    'cart.not-enough-in-inv': 'Erreur: Il n\'y a pas produits suffisants dans le stock. SVP editez la quantité.',
    'cart.no-location-selected': 'Aucun lieu selectionné',
    'cart.amount-over-max': 'Nombre dans le chariot ne peut pas dépasser nombre dans le stock',
    'cart.entry-not-found': 'Enregistrement du produit n\'est pas trouvé dans le chariot',
    'cart.create-empty-invoice': 'Créer facture vide',

    'invoice.invoice': 'Facture',
    'invoice.invoices': 'Factures',
    'invoice.new-invoice': 'Nouvelle facture',
    'invoice.invoice-number': 'Facture #{0}',
    'invoice.correction-title': 'Corriger facture #{0}',
    'invoice.quantity': 'Quantité',
    'invoice.item': 'Produit',
    'invoice.default-unit-price': 'Prix unité par défaut',
    'invoice.original-total': 'Total original',
    'invoice.overridden-from': 'Changé de {0} {1}',
    'invoice.recipient-name': 'Nom du client',
    'invoice.contact-info': 'Coordonnées',
    'invoice.notes': 'Remarques',
    'invoice.shipping-fee': 'Frais d\'envoi',
    'invoice.droits-de-timbre': 'Droits de timbre',
    'invoice.droits-de-timbre-paid': 'Droits de timbre ({0} {1}) a été payé',
    'invoice.droits-de-timbre-needed': 'Droits de timbre doit été marquer "payé"',
    'invoice.export-invoice': 'Facture d\'exportation (sans TVA)',
    'invoice.marra-cash-card-discount': 'MarraCashCard remise: {0} {1}',
    'invoice.subtotal': 'Sous-total',
    'invoice.vat': 'TVA',
    'invoice.guide-commission': 'Commission du guide',
    'invoice.card-commission': 'Commission du carte',
    'invoice.actual-payments': 'Paiement actuel',
    'invoice.actual-payment-hint': 'Cliquez sur un des liens pour regler la facture dans cette devise et mode de paiement. Ou enregistrez une partie de montant dans les autres champs.',
    'invoice.confirm-sale': 'Confirmer vente',
    'invoice.print': 'Imprimer facture',
    'invoice.correct': 'Corriger',
    'invoice.corrected': 'Corrigé',
    'invoice.prices-missing': 'Chaque produit a besoin un prix (ou changement du prix) en {0}.',
    'invoice.uncovered-warning': 'Pas encore {0} {1} réglé par paiement actuel. Est-ce que vous voulez vraiment confirmer la facture?',
    'invoice.no-actual-payments': 'Paiement actuel à enregistrer avant confirmer la facture.',
    'invoice.change-attributes': 'Changer attributs',
    'invoice.choose-item': 'Choisir produit',
    'invoice.totals-mismatch-error': 'Le nouveau total {0} doit correspondre au total original {1}',
    'invoice.totals-mismatch-warning': 'Le nouveau total {0} ne correspond pas au total original {1}. Est-ce que vous voulez vraiment changer le montant total?',
    'invoice.totals-mismatch-server': 'Le nouveau total {0} ne correspond pas au total original {1}',
    'invoice.undo-confirm': 'Est-ce que vous voulez vraiment annuler la facture? Ca va supprimer la facture et retourner les produits vendus au stock.',
    'invoice.cannot-correct-more': 'Ne peut pas corriger une facture plus qu\'une fois',
    'invoice.book': 'Livre',
    'invoice.book-name': 'Livre "Couleurs à Marrakech" de Ronnie Niedermeyer, 2015',
    'invoice.archive-by-year': 'Archiver les factures par année',
    'invoice.dearchive-by-year': 'Désarchiver les facture par année',
    'invoice.search-by-number': 'Rechercher une facture par numéro',

    'invoice.select-variation.1': 'Selectionner variation de',
    'invoice.select-variation.2': 'à',

    'invoice.select-different-variation.1': 'Selectionner une autre variation de',
    'invoice.select-different-variation.2': 'à',

    'invoice.correct-warning.1': 'Avertissement',
    'invoice.correct-warning.2': 'on peut corriger une facture qu\'une fois.',
    'invoice.correct-warning.3': 'Ensuite, il reste en lecture seule.',

    'invoice.actual.in-cash': 'en espèces',
    'invoice.actual.by-card': 'par carte de crédit',
    'invoice.actual.cheque': '(chèque)',
    'invoice.actual.transfer': '(virement)',

    'transfers.new-transfer': 'Nouveau transfert',
    'transfers.status': 'Statut',
    'transfers.from': 'De',
    'transfers.to': 'À',
    'transfers.pieces': 'Pièces',
    'transfers.no-transfers': 'Aucuns transferts',
    'transfers.no-filtered-transfers': 'Aucuns transferts correspondent avec filtre',
    'transfers.amount-too-high': 'Ne peut pas transférer produits plus qu\'il y a dans le chariot',
    'transfers.amount-too-low': 'Ne peut pas transférer une quantité negative des produits',
    'transfers.correct-amounts': 'Quantité exacte',
    'transfers.accept-with-corrections': 'Accepter avec corrections',
    'transfers.actual-amount': 'Quantité actuelle',
    'transfers.correction-reason': 'Raison de modification',
    'transfers.return-transfer-info': 'C\'est un retour de',
    'transfers.corrected-from': 'corrigé de {0}',
    'transfers.not-enough-in-inv': 'Pas suffisant dans le stock',
    'transfers.no-items': 'Aucuns produits à transférer',
    'transfers.no-filtered-items': 'Aucuns produits du transfert qui correspond avec filtre actuel',
    'transfers.same-location': 'Du lieu au lieu doit être different',
    'transfers.can-only-edit-own': 'Peut éditer que les propres transferts',
    'transfers.can-only-edit-draft': 'Peut éditer que les projets des transferts',
    'transfers.can-only-send-own': 'Peut éditer que les propres transferts',
    'transfers.can-only-send-draft': 'Peut envoyer que les projets du transfert',
    'transfers.can-only-delete-own': 'Peut supprimer que les propres transferts',
    'transfers.can-only-delete-draft': 'Peut supprimer que les projets du transfert',
    'transfers.can-only-accept-sent': 'Peut accepter que les transferts envoyés',
    'transfers.cannot-correct-return': 'Ne peut pas corriger un transfert du retour',

    'customer.search-by-name': 'Recherche par nom du client',

    'user.username': 'Utilisateur',
    'user.given-name': 'Prénom',
    'user.family-name': 'Nom de famille',
    'user.role': 'Rôle',
    'user.country': 'Pays',
    'user.language': 'Langue',
    'user.allowed-locations': 'Lieus permets',
    'user.password': 'Mot de passe',
    'user.password-leave-empty': 'Pour conserver l\'ancienc mot de passe n\'ajoutez pas un nouveau mot de passe au champ.',
    'user.old-password': 'Ancien mot de passe',
    'user.new-password': 'Nouveau mot de passe',
    'user.repeat-new-password': 'Répétez nouveau mot de passe',
    'user.repeat-password': 'Répétez mot de passe',
    'user.is-admin': 'administrateur',
    'user.add-user': 'Ajouter utilisateur',
    'user.log-in': 'Connexion',
    'user.log-out': 'Deconnexion',
    'user.failed-log-in': 'Connexion échoué',
    'user.passwords-no-match': 'Mot de passe n\'est pas correct',
    'user.old-password-required': 'Ancien mot de passe necessaire pour changer le mot de passe',
    'user.old-password-mismatch': 'Ancien mot de passe n\'est pas correct',
    'user.cannot-delete-self': 'Ne peut pas supprimer propre utilisateur',
    'user.cannot-deactivate-self': 'L\'utilisateur ne peut pas se désactiver soi-même',
    'user.cannot-activate-self': 'L\'utilisateur ne peut pas s\'activer soi-même',

    'reports.date-time': 'Date / heure',
    'reports.no-items': 'Aucuns inscriptions au rapport',
    'reports.invoices-loaded': 'factures chargées',
    'reports.load-next-invoices': 'chargez les factures suivantes',
    'reports.no-filtered-items': 'Aucuns inscriptions au rapport qui correspond avec filtre actuel',
    'reports.no-invoices': 'Aucunes factures',
    'reports.no-filtered-invoices': 'Aucunes factures qui correspond avec filtre actuel',
    'reports.clear-invoice-filters': 'Annuler filtre de facture',
    'reports.invoice-filters': 'Filtre des factures',
    'reports.entry-filters': 'Filtre d\'entrée',
    'reports.totals': 'Total',
    'reports.edit-entry': 'Editer enregistrement au rapport',
    'reports.cash-from-sales': 'Espèces de vente',
    'reports.cash-to-guides': 'Espèces aux guides',
    'reports.cash-inflow': 'Revenu par espèces',
    'reports.card-payments-from-sales': 'Paiement par carte de vente',
    'reports.card-commissions': 'Commission de carte de crédit',
    'reports.card-payment-inflow': 'Revenus par carte de crédit',
    'reports.total-by-cheque': 'Total par chèque',
    'reports.total-by-transfer': 'Total par virement',
    'reports.total-inflow': 'Revenu en total',
    'reports.total-subtotal': 'Total hors TVA',
    'reports.total-vat': 'Totale TVA',
    'reports.total-total': 'Total des recettes',
    'reports.type': 'Sorte',
    'reports.from-amount': 'Du montant',
    'reports.to-amount': 'Au montant',
    'reports.download-excel-file': 'Chargement fiche excel',
    'reports.invalid-date-time': 'Date et/ou heure pas valable',
    'reports.cannot-edit-transform': 'Ne peut pas éditer les attributs de transformation du modèle par défaut',

    'data.back-to-index': 'Retourner à l\'index',
    'data.sort-key': 'Critère de tri',
    'data.attributes.add-new': 'Ajouter nouvel attribut',
    'data.attributes.back': 'Retourner à l\'attribut',
    'data.attributes.save-note.note': 'Attention',
    'data.attributes.save-note': 'Cliquez le button "enregistrer" en bas de la page pour enregister des modifications!',
    'data.attributes.contexts': 'Contextes',
    'data.attributes.is-optional': 'Facultatif',
    'data.attributes.freeform-for-custom': 'Utilisez le texte de la forme libre pour des produits sur mesure',
    'data.attributes.value-group': 'Groupe de valeur',
    'data.attributes.value-group.delete': 'Supprimer groupe de valeur',
    'data.attributes.value-group.add': 'Ajouter groupe de valeur',
    'data.attributes.not-limited': 'Pas limité par catégorie',

    'api.not-found': 'Pas trouvé',
    'api.no-permissions': 'Aucun accès',
    'api.unexpected-validation-error': 'Imprévu erreurs de validation. S\'il vous plait notifiez le développeur.',
    'api.validation-errors': 'Erreurs de validation',
    'api.duplicate-key-error': 'Le valeur enregisté déjà existe, mais doit être unique. Le systeme n\'est pas capable à detecter automatiquement la valeur.',
    'api.error': 'Erreur',

    'references.cannot-remove.singular': 'Ne peut pas l\'enlever {0} - il y a 1 {1} connecté à lui',
    'references.cannot-remove.plural': 'Ne peut pas l\'enlever {0} - il y a {1} {2} connecté à lui',

    'validation.required': 'Necessaire',
    'validation.invalid': 'Valeur pas valable',
    'validation.decimal': 'Doit être un nombre décimal',
    'validation.integer': 'Doit être un nombre entier',
    'validation.integer-string': 'Doit être un nombre entier',
    'validation.non-empty': 'Doit être complété',
    'validation.unique': 'Doit être unique. Entrée déjà existe.',
    'validation.over-max': 'Valeur trop élévée',
    'validation.under-min': 'Valeur trop bas',
    'validation.date-ymd': 'Doit être une date YYYY-MM-DD',
    'validation.unexpected': 'Imprévu erreur de validation',
    'validation.year-four-digit': 'Année avec 4 chiffres demandée.',
    'validation.year-out-of-range': 'L\'année est dehors de la zone.',
    'validation.month-two-digit': 'Mois avec 2 chiffres demandé.',
    'validation.month-out-of-range': 'Le mois specifié est dehors de la zone.',

    'filters': 'Filtres',
    'filters.predefined.desc': 'Montre les séries seules avec valeur correspond avec les suivantes',
    'filters.predefined-combo.desc': 'Montre les séries seules combination correspond avec les suivantes',
    'filters.predefined-combo.mode-button.partial': 'Similarités partielles comprises',
    'filters.predefined-combo.mode-button.strict': 'Afficher seulement similarités exactes',
    'filters.predefined-combo.summary-mode.partial': 'partiel',
    'filters.predefined-combo.summary-mode.strict': 'exact',
    'filters.number-range.desc': 'Montre les séries seules avec valeur entre celles-ci',
    'filters.number-range.and': 'et',
    'filters.number-range.between': 'Entre',
    'filters.number-range.at-least': 'Au moins',
    'filters.number-range.at-most': 'Pas plus de',
    'filters.number-range.may-leave-empty': 'Ne remplissez pas le champ si vous préférez une sélection illimitée.',
    'filters.date-range.from': 'De',
    'filters.date-range.to': 'À',
    'filters.date-range.shortcuts': 'Raccourci',
    'filters.date-range.all-of': 'Tous de',
    'filters.clear-all': 'Annuler les filtres',
    'filters.dropped': 'Quelques filtres sont désactivés',

    'months.1': 'Janvier',
    'months.2': 'Février',
    'months.3': 'Mars',
    'months.4': 'Avril',
    'months.5': 'Mai',
    'months.6': 'Juin',
    'months.7': 'Juillet',
    'months.8': 'Août',
    'months.9': 'Septembre',
    'months.10': 'Octobre',
    'months.11': 'Novembre',
    'months.12': 'Décembre',

    'short-months.1': 'Jan',
    'short-months.2': 'Fév',
    'short-months.3': 'Mar',
    'short-months.4': 'Avr',
    'short-months.5': 'Mai',
    'short-months.6': 'Juin',
    'short-months.7': 'Juil',
    'short-months.8': 'Août',
    'short-months.9': 'Sep',
    'short-months.10': 'Oct',
    'short-months.11': 'Nov',
    'short-months.12': 'Déc',

    'confirm.archive.item': 'Voulez-vous vraiment archiver ce produit?',
    'confirm.delete.material': 'Est-ce que vous voulez vraiment supprimer ce tissu?',
    'confirm.delete.definition': 'Est-ce que vous voulez vraiment supprimer cette définition?',
    'confirm.delete.item': 'Est-ce que vous voulez vraiment supprimer ce produit?',
    'confirm.delete.transfer': 'Est-ce que vous voulez vraiment supprimer le transfert?',
    'confirm.delete.customer': 'Êtes vous sûr de vouloir supprimer ce client?',
    'confirm.delete.user': 'Est-ce que vous voulez vraiment supprimer cet utilisateur?',
    'confirm.delete.expense': 'Est-ce que vous voulez vraiment supprimer cette dépense?',
    'confirm.delete.earning': 'Est-ce que vous voulez vraiment supprimer cette revenue?',
    'confirm.undo.entry': 'Est-ce que vous voulez vraiment annuler l\'entrée?',

    'enum.languages.en': 'Anglais',
    'enum.languages.fr': 'Français',

    'enum.attribute-contexts.materials': 'Tissus',
    'enum.attribute-contexts.products': 'Produits',
    'enum.attribute-contexts.templates': 'Modèles',

    'enum.transfer-statuses.draft': 'Projet',
    'enum.transfer-statuses.sent': 'Envoyé',
    'enum.transfer-statuses.accepted': 'Accepté',
    'enum.transfer-statuses.corrected': 'Corrigé',

    'enum.material-units.metres': 'mètres',
    'enum.material-units.pieces': 'pièces',

    'enum.roles.admin': 'Administrateur',
    'enum.roles.powerUser': 'Power User',
    'enum.roles.assistant': 'Assistante',
    'enum.roles.rawMaterials': 'Magasinière',
    'enum.roles.secretary': 'Sécretaire',
    'enum.roles.production': 'Chef d\'Atelier',
    'enum.roles.sales': 'Vendeuse',
    'enum.roles.shopManager': 'Chef de boutique',
    'enum.roles.accountant': 'Comptable',
    'enum.roles.tailor': 'Tailleur',

    'enum.material-orders-statuses.created': 'Créé',
    'enum.material-orders-statuses.confirmed': 'Confirmé',
    'enum.material-orders-statuses.sent': 'Envoyé',
    'enum.material-orders-statuses.received': 'Reçu',

    'enum.payment-types.cash': 'Espèce',
    'enum.payment-types.card': 'Cate',
    'enum.payment-types.bankWire': 'Virement bancaire',

    'enum.additional-expenses.shipping': 'Transport',
    'enum.additional-expenses.analysis': 'Analyses',
    'enum.additional-expenses.storage': 'Magasinage',
    'enum.additional-expenses.customs': 'Dédouanement',
    'enum.additional-expenses.delivery': 'Livraison',
    'enum.additional-expenses.other': 'Autre',

    'accounting.expenses.no-expense-category-error': 'Aucun catégorie de dépense avec valeur "{0}"',
    'accounting.expenses.expense': 'Dépense',
    'accounting.expenses.add-expense': 'Ajouter dépense',
    'accounting.earnings.no-earning-category-error': 'Aucun catégorie de revenues avec valeur "{0}"',
    'accounting.earnings.earning': 'Revenu',
    'accounting.earnings.add-earning': 'Ajouter revenue',
  },
}

function getFormat(language: Language, key: string): string {
  if (language in strings && key in strings[language]) {
    return strings[language][key]
  }
  else if (key in strings['default']) {
    return strings['default'][key]
  }
  else {
    console.warn('i18n key not found:', key)
    return '[' + key + ']'
  }
}

function getString(language: Language, key: string, args: string[]): string {
  let result = getFormat(language, key)

  // Replace {n} placeholders
  for (const [index, arg] of Object.entries(args)) {
    result = result.replace(new RegExp('\\{' + index + '\\}', 'g'), arg)
  }

  return result
}

export const i18n = {
  setLanguage: function (language: Language): void {
    currentLanguage = language
  },

  t: function (key: string, ...args: string[]): string {
    return getString(currentLanguage, key, args)
  },

  lt: function (language: Language, key: string, ...args: string[]): string {
    return getString(language, key, args)
  },
}
