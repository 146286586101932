// TODO: reusable generic searchable component
import React = require('react')

import { Customer } from '../common/data-customers'
import { Enums } from '../common/enums'
import { i18n } from '../common/i18n'
import { Comp } from './comp'
import { UiUtils } from './ui-utils'

interface SearchableCustomerProps {
  customers: Customer[],
  nameValue: string,
  onChange: (customerName: string) => void,
  onSelect: (customer: Customer) => void,
}

const { Dropdown, DropdownElement } = Comp
const { keyCodes } = Enums

const numShownCandidates = 15

export class SearchableCustomer extends React.Component<SearchableCustomerProps> {
  state = {
    idxActiveCandidate: 0,
    shouldRenderCandidates: false,
  }

  onChange = (evt) => {
    this.props.onChange(evt.target.value)
    this.setState({ shouldRenderCandidates: true })
  }

  hideCandidates = () => {
    this.setState({ shouldRenderCandidates: false })
  }

  onSelect = (candidate) => {
    this.props.onSelect(candidate)
    this.hideCandidates()
  }

  setActiveCandidate = (idx) => {
    this.setState({ idxActiveCandidate: idx })
  }

  searchCustomers = () => {
    const { props } = this

    if (!props.nameValue) {
      return { candidates: [], numHidden: 0 }
    }

    const allCandidates = props.customers.filter(function(customer) {
      return customer.name.toLowerCase().indexOf(props.nameValue.toLowerCase()) !== -1
    })

    const hasMore = allCandidates.length > numShownCandidates
    const shownCandidates = hasMore ? allCandidates.slice(0, numShownCandidates) : allCandidates
    const numHidden = allCandidates.length - shownCandidates.length

    return { candidates: shownCandidates, numHidden }
  }

  activatePreviousCandidate = () => {
    const { candidates } = this.searchCustomers()

    if (!candidates.length) {
      return
    }

    const { idxActiveCandidate } = this.state

    if (idxActiveCandidate === null) {
      this.setActiveCandidate(0)

      return
    }

    const idxPrev = idxActiveCandidate === 0 ? candidates.length - 1 : idxActiveCandidate - 1
    this.setActiveCandidate(idxPrev)
  }

  activateNextCandidate = () => {
    const { candidates } = this.searchCustomers()

    if (!candidates.length) {
      return
    }

    const { idxActiveCandidate } = this.state

    if (idxActiveCandidate === null) {
      this.setActiveCandidate(0)

      return
    }

    const idxNext = idxActiveCandidate === candidates.length - 1 ? 0 : idxActiveCandidate + 1
    this.setActiveCandidate(idxNext)
  }

  selectActiveCandidate = () => {
    const { idxActiveCandidate } = this.state

    if (idxActiveCandidate === null) {
      return
    }

    const { candidates } = this.searchCustomers()
    const activeCandidate = candidates[idxActiveCandidate]

    if (!activeCandidate) {
      return
    }

    this.onSelect(activeCandidate)
  }

  onKeyDown = (evt) => {
    const { keyCode } = evt

    if (keyCode === keyCodes.arrowUp) {
      this.activatePreviousCandidate()
    }

    if (keyCode === keyCodes.arrowDown) {
      this.activateNextCandidate()
    }

    if (keyCode === keyCodes.enter) {
      this.selectActiveCandidate()
    }

    if (keyCode === keyCodes.escape) {
      this.hideCandidates()
    }
  }

  renderNameInput = () => {
    return (
      <div>
        <div style={{ fontWeight: 'bold' }}>
          {i18n.t('invoice.recipient-name')}
          :
        </div>
        <input
          id="inp-customer-name"
          style={{ width: '100%' }}
          value={this.props.nameValue}
          onKeyDown={this.onKeyDown}
          onChange={this.onChange}
        />
      </div>
    )
  }

  renderCandidate = (candidate, idx) => {
    return (
      <DropdownElement
        key={candidate._id}
        isActive={idx === this.state.idxActiveCandidate}
        onActivate={() => this.setActiveCandidate(idx)}
        onDeactivate={() => this.setActiveCandidate(null)}
        onSelect={() => this.onSelect(candidate)}
      >
        {UiUtils.highlight(candidate.name, this.props.nameValue)}
      </DropdownElement>
    )
  }

  renderEllipsis = (numHidden) => {
    if (numHidden < 1) {
      return null
    }

    const translationKeySuffix = numHidden === 1 ? 'singular' : 'plural'
    const translationKey = 'common.more-matches.' + translationKeySuffix
    const str = i18n.t(translationKey, numHidden)

    return <div className="ellipsis">({str})</div>
  }

  renderCandidates = () => {
    if (!this.state.shouldRenderCandidates) {
      return null
    }

    const searchResult = this.searchCustomers()
    const { candidates } = searchResult

    if (!candidates.length) {
      return null
    }

    return (
      <Dropdown>
        {candidates.map(this.renderCandidate)}
        {this.renderEllipsis(searchResult.numHidden)}
      </Dropdown>
    )
  }

  render() {
    return (
      <div>
        {this.renderNameInput()}
        {this.renderCandidates()}
      </div>
    )
  }
}
