import moment from 'moment'
import React, { HTMLAttributes } from 'react'

import toastr from 'toastr/toastr'

import { CommonUtils } from '../../../common/common-utils'
import { MaterialOrder } from '../../../common/data-material-order'
import { MaterialProducer } from '../../../common/data-misc'
import { RawMaterial } from '../../../common/data-raw-materials'
import { getDataService } from '../../../common/data-service'
import { Enums, MaterialOrderStatus, PaymentType } from '../../../common/enums'
import { EventBus } from '../../../common/event-bus'
import { i18n } from '../../../common/i18n'
import { AddMaterialOrderModal } from '../../add-material-order-modal'
import { Comp } from '../../comp'
import { Filter, FilterManager } from '../../filter'
import { MainMenu } from '../../main-menu'
import { ReportUtils } from '../../report-utils'
import { Column, UiUtils } from '../../ui-utils'
import { User } from '../../user'
import { Utils } from '../../utils'
import { EditMaterialOrderModal } from './edit-material-order-modal'
import { ShowMaterialOrdersInvoice } from './show-meterial-order-invoice'

interface SubRow {
  reference: string,
  amount: {
    value: number,
    unit: string,
  },
}

interface Row {
  id: string,
  producer: string,
  materials: SubRow[],
  timeCreated: Date,
  invoice?: number,
  paymentType?: PaymentType,
  timeOfPayment?: Date,
  status: MaterialOrderStatus,
  expense?: number,
  deliveryNote?: string,
  verificationOfConformity?: string,
  note?: string,
}

const { Checkbox } = Comp

class List extends React.Component {
  state = {
    loaded: false,
    producers: undefined as MaterialProducer[] | undefined,
    materials: undefined as RawMaterial[] | undefined,
    orders: undefined as MaterialOrder[] | undefined,
    printView: false,
    usdRate: 0,
    euroRate: 0,
    selectedRowId: undefined,
    includeArchived: false,
  }

  _isMounted = false

  async componentDidMount() {
    this._isMounted = true

    const DataService = getDataService()
    await DataService.Settings.get().then((res) => {
      this.setState({
        usdRate: res.usdRate,
        euroRate: res.eurRate,
      })
    })
    Filter.registerEvent(this)
    const [orders, materials, producers] = await Promise.all([
      DataService.MaterialOrders.getAll(),
      DataService.RawMaterials.getAll(true),
      DataService.MaterialProducers.getAll(),
    ])

    this.setState(
      {
        loaded: true,
        producers,
        materials,
        orders,
      },
      EventBus.fireFunc('material-orders-rendered'),
    )
  }

  componentWillUnmount() {
    this._isMounted = false
    Filter.unregisterEvent(this)
  }

  print = () => {
    this.setState({ printView: true }, Utils.print)
  }

  leavePrintMode = () => {
    this.setState({ printView: false })
  }

  handleSelectRow = (id: string) => {
    this.setState({ selectedRowId: id })
  }

  reloadOrders = () => {
    const scrollPosition = window.scrollY
    this.setState({ loaded: false })

    getDataService()
      .MaterialOrders.getAll()
      .then((orders) => {
        if (this._isMounted) {
          this.setState({ loaded: true, orders }, function () {
            window.scrollTo(0, scrollPosition)
            EventBus.fire('material-orders-rendered')
          })
        }
      })

    return null
  }

  reloadOrder = (orderId: string) => {
    getDataService()
      .MaterialOrders.getById(orderId)
      .then((order) => {
        if (this._isMounted) {
          const allOrders = [...this.state.orders]
          const orderIndex = allOrders.findIndex((o) => o._id === orderId)

          if (orderIndex !== -1) {
            allOrders[orderIndex] = order
            this.setState({ orders: allOrders, loaded: true })
          }
        }
      })
  }

  getColumnConf = (options = { excludeActions: false }) => {
    // const language = User.getLanguage()

    const { printView } = this.state

    const getNoWrap = function (): HTMLAttributes<HTMLTableCellElement> {
      return { style: { whiteSpace: 'nowrap' } }
    }

    const columns: Column<Row>[] = [
      {
        id: 'id',
        header: i18n.t('material-orders.id'),
        excelWidth: 25,
        includeIf: (view) => view === 'table',
      },
      {
        id: 'producer',
        header: i18n.t('material-orders.producer'),
        excelWidth: 20,
        getExcelValue: function (rowData) {
          return rowData.producer
        },
      },
      {
        id: 'material-reference',
        header: i18n.t('material-orders.material-reference'),
        getCellContents: function (rowData) {
          return (
            <div>
              {rowData.materials.map((material, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      paddingBottom:
                        index === rowData.materials.length - 1 ? 0 : 10,
                    }}
                  >
                    {material.reference}
                  </div>
                )
              })}
            </div>
          )
        },
        excelWidth: 20,
        getExcelValue: function (rowData) {
          return rowData.materials.map((m) => m.reference).join('\n')
        },
      },
      {
        id: 'material-quantity',
        header: i18n.t('material-orders.material-quantity'),
        getCellContents: function (rowData) {
          return (
            <div>
              {rowData.materials.map((material, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      paddingBottom:
                        index === rowData.materials.length - 1 ? 0 : 10,
                    }}
                  >{`${material.amount.value} ${material.amount.unit}`}</div>
                )
              })}
            </div>
          )
        },
        excelWidth: 15,
        getExcelValue: function (rowData) {
          return rowData.materials
            .map((m) => `${m.amount.value} ${m.amount.unit}`)
            .join('\n')
        },
      },
      {
        id: 'timeCreated',
        header: i18n.t('material-orders.time-created'),
        getCellContents: function (rowData) {
          return <span>{moment(rowData.timeCreated).format('L')}</span>
        },
        excelWidth: 15,
        includeIf: (view) => view === 'table',
        getExcelValue: function (rowData) {
          return moment(rowData.timeCreated).format('L')
        },
      },
      {
        id: 'invoice',
        header: i18n.t('material-orders.invoice'),
        getCellContents: function (rowData) {
          return <span>{rowData.invoice ? `#${rowData.invoice}` : '-'}</span>
        },
        excelWidth: 20,
        includeIf: (view) => view === 'table',
        getExcelValue: function (rowData) {
          return rowData.invoice ? `#${rowData.invoice}` : '-'
        },
      },
      {
        id: 'paymentType',
        header: i18n.t('material-orders.payment-type'),
        getCellContents: function (rowData) {
          return (
            <span>
              {rowData.paymentType ?
                i18n.t('enum.payment-types.' + rowData.paymentType) :
                '-'}
            </span>
          )
        },
        excelWidth: 20,
        includeIf: (view) => view === 'table',
        getExcelValue: function (rowData) {
          return rowData.paymentType ?
            i18n.t('enum.payment-types.' + rowData.paymentType) :
            '-'
        },
      },
      {
        id: 'timeOfPayment',
        header: i18n.t('material-orders.time-of-payment'),
        getCellContents: function (rowData) {
          return (
            <span>{`${rowData.timeOfPayment ?
              moment(rowData.timeOfPayment).format('L') :
              '-'
              }`}</span>
          )
        },
        excelWidth: 20,
        includeIf: (view) => view === 'table',
        getExcelValue: function (rowData) {
          return rowData.timeOfPayment ?
            moment(rowData.timeOfPayment).format('L') :
            '-'
        },
      },
      {
        id: 'deliveryNote',
        header: i18n.t('material-orders.delivery-note'),
        excelWidth: 30,
        includeIf: (view) => view === 'table',
        getExcelValue: function (rowData) {
          return rowData.deliveryNote
        },
      },
      {
        id: 'verificationOfConformity',
        header: i18n.t('material-orders.verification-of-conformity'),
        excelWidth: 30,
        includeIf: (view) => view === 'table',
        getExcelValue: function (rowData) {
          return rowData.verificationOfConformity
        },
      },
      {
        id: 'note',
        header: i18n.t('material-orders.note'),
        excelWidth: 30,
        includeIf: (view) => view === 'table',
        getExcelValue: function (rowData) {
          return rowData.note
        },
      },
      {
        id: 'status',
        header: i18n.t('material-orders.status'),
        getCellContents: function (rowData) {
          return (
            <span>
              {i18n.t('enum.material-orders-statuses.' + rowData.status)}
            </span>
          )
        },
        filterFieldName: 'status',
        excelWidth: 20,
        includeIf: (view) => view === 'table',
        getExcelValue: function (rowData) {
          return i18n.t('enum.material-orders-statuses.' + rowData.status)
        },
      },
      {
        id: 'expense',
        header: i18n.t('material-orders.expense'),
        getCellContents: function (rowData) {
          return <span>{rowData.expense ? `#${rowData.expense}` : '-'}</span>
        },
        excelWidth: 20,
        includeIf: (view) => view === 'table',
        getExcelValue: function (rowData) {
          return rowData.expense ? `#${rowData.expense}` : '-'
        },
      },
    ]

    if (!printView && !options.excludeActions) {
      columns.unshift({
        id: 'checkbox',
        header: i18n.t('action.select'),
        getCellProperties: getNoWrap,
        getCellContents: this.renderCheckbox,
      })
      columns.push({
        id: 'action',
        header: i18n.t('action.action'),
        getCellProperties: getNoWrap,
        getCellContents: this.renderActions,
      })
    }

    return columns
  }

  getFilterConf() {
    const filterConfig = {
      status: {
        type: 'predefined',
        labelKey: 'material-orders.status',
        options: Enums.orderedMaterialOrderStatuses.map((status) => {
          return {
            value: status,
            label: i18n.t('enum.material-orders-statuses.' + status),
          }
        }),
      },
    }

    return filterConfig
  }

  getFilteredMaterials(filterManager: FilterManager<MaterialOrder>) {
    let filteredOrders = filterManager.getFiltered()

    if (!this.state.includeArchived) {
      filteredOrders = filteredOrders.filter(function (material) {
        return !material.archived
      })
    }

    return filteredOrders
  }

  getRowsData(filterManager: FilterManager<MaterialOrder>, context) {
    const language = User.getLanguage()

    const filteredOrders = this.getFilteredMaterials(filterManager)

    return filteredOrders.map((order): Row => {
      const producer = CommonUtils.findById<MaterialProducer>(
        this.state.producers,
        order.producer,
      )

      return {
        id: order._id,
        producer: producer.labels[language],
        timeCreated: order.timeCreated,
        invoice: order.invoice,
        paymentType: order.paymentType,
        status: order.status,
        expense: order.expense,
        timeOfPayment: order.timeOfPayment,
        deliveryNote: order.deliveryNote,
        verificationOfConformity: order.verificationOfConformity,
        note: order.note,
        materials: order.materials.map((orderMaterial): SubRow => {
          const material = CommonUtils.findById<RawMaterial>(
            this.state.materials,
            orderMaterial.material,
          )

          return {
            reference: material.name,
            amount: {
              value: orderMaterial.amount,
              unit: material.amount.unit,
            },
          }
        }),
      }
    })
  }

  renderMenu = () => {
    if (!this.state.printView) {
      return <MainMenu activeTab="material-orders" />
    }
  }

  renderPrintButton = () => {
    return (
      <button onClick={this.print} style={{ float: 'right' }}>
        {i18n.t('common.print')}
      </button>
    )
  }

  renderButtons = () => {
    if (this.state.printView) {
      return (
        <button
          id="btn-leave-print"
          className="no-print"
          onClick={this.leavePrintMode}
          style={{ marginBottom: '0.5em' }}
        >
          {i18n.t('common.leave-print-mode')}
        </button>
      )
    }

    return (
      <div style={{ marginBottom: '0.5em' }}>
        <button id="btn-excel" onClick={() => this.downloadExcelFile()}>
          {i18n.t('reports.download-excel-file')}
        </button>
        {this.renderPrintButton()}
        {this.renderAddNewButton()}
        {this.renderDiscontinuedFilter()}
      </div>
    )
  }

  renderPrintHeader = () => {
    return (
      <h3 style={{ fontWeight: 'bold' }}>
        {i18n.t('menu.main.material-orders')}
      </h3>
    )
  }

  renderDiscontinuedFilter = () => {
    return (
      <Comp.Checkbox
        checked={this.state.includeArchived}
        label={i18n.t('material-orders.include-archived')}
        onChange={(evt) => {
          this.setState({ includeArchived: evt.target.checked })
        }}
      />
    )
  }

  renderAddNewButton = () => {
    return (
      <div>
        <button
          id="btn-add-new"
          onClick={function () {
            EventBus.fire('open-modal', { modalId: 'add-material-order' })
          }}
        >
          {i18n.t('material-orders.add-new')}
        </button>
        <AddMaterialOrderModal
          modalId="add-material-order"
          producers={this.state.producers}
          materials={this.state.materials}
          afterSave={() => this.reloadOrders()}
        />
      </div>
    )
  }

  renderActions = (rowData: Row) => {
    const userRole = User.getUser().role
    const isManager = userRole === Enums.roles.production || userRole === Enums.roles.admin
    const editModalId = 'edit-modal-' + rowData.id
    const showInvoice = `show-invoice-${rowData.id}`

    const order = this.state.orders.find((o) => o._id === rowData.id)
    const producer = this.state.producers.find((p) => p._id === order.producer)
    const materials = this.state.materials.filter((m) =>
      order.materials.map((c) => c.material).includes(m._id),
    )
    const availableMaterials = this.state.materials.filter(
      (m) =>
        m.producer === producer._id &&
        !materials.map((material) => material._id).includes(m._id),
    )

    const editButton = (
      <div>
        <img
          className="table-btn btn-edit"
          title={i18n.t('action.edit')}
          src="img/edit.png"
          onClick={function () {
            EventBus.fire('open-modal', { modalId: editModalId })
          }}
        />
        <EditMaterialOrderModal
          modalId={editModalId}
          order={order}
          producer={producer}
          materials={materials}
          afterSave={() => this.reloadOrders()}
        ></EditMaterialOrderModal>
      </div>
    )
    const showInvoiceButton = (
      <div>
        <img
          className="table-btn btn-edit"
          title={i18n.t('action.showInvoice')}
          src="img/sheets.png"
          onClick={function () {
            EventBus.fire('open-modal', { modalId: showInvoice })
          }}
        />
        <ShowMaterialOrdersInvoice
          modalId={showInvoice}
          materials={materials}
          availableMaterials={availableMaterials}
          order={order}
          producer={producer}
          isManager={isManager}
          currencyData={{
            usdRate: this.state.usdRate,
            euroRate: this.state.euroRate,
          }}
          afterSave={() => this.reloadOrders()}
          onMaterialAdd={(orderId: string) => this.reloadOrder(orderId)}
        />
      </div>
    )
    const deleteButton = (
      <img
        className="table-btn btn-delete"
        title={i18n.t('action.delete')}
        src="img/delete.png"
        onClick={() => {
          Utils.confirmTr('confirm.delete.material-order').then((confirmed) => {
            if (confirmed) {
              return getDataService()
                .MaterialOrders.delete(rowData.id)
                .then(() => this.reloadOrders())
            }
          })
        }}
      />
    )

    return (
      <div style={{ display: 'flex', gap: '5px' }}>
        {editButton}
        {showInvoiceButton}
        {/* {deleteButton} */}
      </div>
    )
  }

  renderCheckbox = (rowData: Row) => {
    const selected = rowData.id === this.state.selectedRowId

    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Checkbox checked={selected} onChange={() => this.handleSelectRow(rowData.id)} style={{ marginTop: 0 }} />
      </div>
    )
  }

  renderTable = (filterManager: FilterManager<MaterialOrder>) => {
    const context = {}
    const rowsData = this.getRowsData(filterManager, context)

    return UiUtils.getTable(this.getColumnConf(), rowsData, {
      tableId: 'tbl-mat',
      tableClassName: 'table table-bordered table-condensed table-striped',
      rowClassName: 'row-mat',
      filterManager,
      context,
      noItemsText: i18n.t('material-orders.no-items'),
      noFilteredItemsText: i18n.t('material-orders.no-filtered-items'),
      printView: this.state.printView,
    })
  }

  getFilterManager = (): FilterManager<MaterialOrder> => {
    const filterConf = this.getFilterConf()
    return Filter.createManager(
      'material-orders',
      filterConf,
      this.state.orders,
    )
  }

  downloadExcelFile = () => {
    if (!this.state.selectedRowId) {
      toastr.warning(i18n.t('material-orders.select-one-item'), '', {
        positionClass: 'toast-top-left',
        closeButton: true,
      })
      return
    }
    const userTime = CommonUtils.toUserTime(
      User.getUser().country,
      CommonUtils.getNow(),
    )
    const fileName =
      'material-orders-' + CommonUtils.utcDateYMD(userTime) + '.xlsx'

    const filterManager = this.getFilterManager()
    const context = {}
    let rowsData = this.getRowsData(filterManager, context)
    const columnConf = this.getColumnConf({ excludeActions: true })

    if (this.state.selectedRowId) {
      rowsData = [rowsData.find((el) => el.id === this.state.selectedRowId)]
      // transform rowsData for excel
      rowsData = rowsData.map((el) => {
        return el.materials.map((material) => ({
          ...el,
          materials: [material], // make 1 material per row
        }))
      }).flat() // transform to unidimensional array

    }
    const workbook = ReportUtils.createExcelReport(
      rowsData,
      columnConf,
      context,
    )
    ReportUtils.downloadExcelFile(fileName, workbook)
  }

  render() {
    if (!this.state.loaded) {
      return (
        <div>
          {this.renderMenu()}
          {i18n.t('common.loading')}
        </div>
      )
    }

    const filterManager = Filter.createManager(
      'material-orders',
      this.getFilterConf(),
      this.state.orders,
    )

    return (
      <div>
        {this.renderMenu()}
        {this.renderButtons()}
        {this.renderPrintHeader()}
        {this.renderTable(filterManager)}
      </div>
    )
  }
}

export const MaterialOrders = { List }
