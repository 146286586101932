import { CSSProperties, ReactNode } from 'react'
import React = require('react')

export interface GridSelectionProps {
  value: string,
  onChange: (value: string) => void,
  type: string,
  className: string,
  style?: CSSProperties,
  label?: ReactNode,
  children?: ReactNode[],
}

export class GridSelection extends React.Component<GridSelectionProps> {

  static defaultProps = {
    type: 'default',
    style: {},
  }

  getClassName = () => {
    let className = 'grid-selection panel panel-' + this.props.type
    if (this.props.className) {
      className += ' ' + this.props.className
    }
    return className
  }

  getOptionClassName = (option) => {
    let className = 'grid-selection-option'
    if (option.props.value === this.props.value) {
      className += ' active'
    }
    if (option.props.disabled) {
      className += ' disabled'
    }
    return className
  }

  renderHead = () => {
    if (this.props.label) {
      return (
        <div className="panel-heading">
          <h3 className="panel-title">
            {this.props.label}
          </h3>
        </div>
      )
    }
  }

  renderOption = (option) => {
    const liProps = {
      key: option.key,
      onClick: () => {
        this.props.onChange(option.props.value)
      },
      className: this.getOptionClassName(option),
    }
    const aProps = {
      onClick: () => {
        this.props.onChange(option.props.value)
      },
    }
    return (
      <li {...liProps}>
        <a {...aProps}>{option}</a>
      </li>
    )
  }

  renderBody = () => {
    return (
      <div className="panel-body" style={this.props.style}>
        <ul className="nav nav-pills">
          {this.props.children.map(this.renderOption)}
        </ul>
      </div>
    )
  }

  render() {
    return (
      <div className={this.getClassName()}>
        {this.renderHead()}
        {this.renderBody()}
      </div>
    )
  }
}
