import React = require('react')

import { CommonUtils } from '../common/common-utils'
import { AttributeDefinition } from '../common/data-attribute-defs'
import { Location } from '../common/data-locations'
import { Category } from '../common/data-misc'
import { ProductDefinition } from '../common/data-product-defs'
import { Product } from '../common/data-products'
import { getDataService } from '../common/data-service'
import { TemplateDefinition } from '../common/data-template-defs'
import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { Filter } from './filter'
import { InventoryTable } from './inventory-table'
import { MainMenu } from './main-menu'
import { ReportsMenu } from './reports-menu'
import { User } from './user'
import { Utils } from './utils'

interface State {
    loaded: boolean,
    printView: boolean,
    attributeDefinitions: AttributeDefinition[],
    categories: Category[],
    locations: Location[],
    productDefinitions: ProductDefinition[],
    products: Product[],
    templateDefinitions: TemplateDefinition[],
}

export class InventoryReport extends React.Component<Record<string, never>> {
  state: State = {
    loaded: false,
    printView: false,
    attributeDefinitions: undefined,
    categories: undefined,
    locations: undefined,
    productDefinitions: undefined,
    products: undefined,
    templateDefinitions: undefined,
  }

  tableRef = React.createRef<InventoryTable>()

  _isMounted = false

  componentDidMount() {
    this._isMounted = true
    Filter.registerEvent(this)
    const DataService = getDataService()

    Promise.all([
      DataService.Products.getAll(),
      DataService.Locations.getAll(),
      DataService.Categories.getAll(),
      DataService.AttributeDefinitions.getAll(),
      DataService.ProductDefinitions.getAll(),
      DataService.TemplateDefinitions.getAll(),
      DataService.Users.getUsernames(),
    ])
    .then(([
      products,
      locations,
      categories,
      attributeDefinitions,
      productDefinitions,
      templateDefinitions,
      usernames,
    ]) => {
      if (!this._isMounted) {
        return
      }

      const filteredAttrDefs = attributeDefinitions.filter(
        CommonUtils.attributeContextFilters.products,
      )

      const filteredProducts = products.filter(function(product) {
        return product.amount > 0
      })

      const sortedProducts = Utils.sortInventory(
        filteredProducts, User.getLanguage(), categories, filteredAttrDefs,
        productDefinitions, templateDefinitions, locations,
      )

      this.setState(
        {
          loaded: true,
          products: sortedProducts,
          locations,
          categories,
          attributeDefinitions: filteredAttrDefs,
          productDefinitions,
          templateDefinitions,
          usernames,
        },
        EventBus.fireFunc('inventory-report-rendered'),
      )
    })
  }

  componentWillUnmount() {
    this._isMounted = false
    Filter.unregisterEvent(this)
  }

  print = () => {
    this.setState({ printView: true }, Utils.print)
  }

  leavePrintMode = () => {
    this.setState({ printView: false })
  }

  renderMenus = () => {
    if (!this.state.printView) {
      return [
        <MainMenu key="main" activeTab="reports" />,
        <ReportsMenu key="reports" activeTab="inventory" />,
      ]
    }
  }

  renderButtons = () => {
    if (this.state.printView) {
      return (
        <button
          id="btn-leave-print"
          className="no-print"
          onClick={this.leavePrintMode}
          style={{ marginBottom: '0.5em' }}
        >
          {i18n.t('common.leave-print-mode')}
        </button>
      )
    }

    const onClickExcel = () => {
      const userTime = CommonUtils.toUserTime(User.getUser().country, CommonUtils.getNow())
      const fileName = 'inventory-' + CommonUtils.utcDateYMD(userTime) + '.xlsx'
      this.tableRef.current.downloadExcelFile(fileName)
    }

    return (
      <div style={{ margin: '0.5em 0' }}>
        <button id="btn-excel" onClick={onClickExcel}>
          {i18n.t('reports.download-excel-file')}
        </button>
        {' '}
        <button id="btn-print" onClick={this.print}>
          {i18n.t('common.print')}
        </button>
      </div>
    )
  }

  renderPrintHeader = () => {
    if (this.state.printView) {
      return (
        <h3 style={{ fontWeight: 'bold' }}>
          {i18n.t('menu.main.reports')}
          {' - '}
          {i18n.t('menu.reports.inventory')}
        </h3>
      )
    }
  }

  renderTable = () => {
    const language = User.getLanguage()

    const locationOptions = this.state.locations.filter(CommonUtils.isSalesPoint)
    .map(function(loc) {
      return { value: loc._id, label: loc.names[language] }
    })

    locationOptions.sort(function(loc1, loc2) {
      return loc1.label.localeCompare(loc2.label)
    })

    return (
      <InventoryTable
        ref={this.tableRef}
        id="tbl-rep-inv"
        rowClassName="row-item"
        attributeDefinitions={this.state.attributeDefinitions}
        locations={this.state.locations}
        currency="MAD"
        categories={this.state.categories}
        definitions={this.state.productDefinitions}
        items={this.state.products}
        templateDefinitions={this.state.templateDefinitions}
        modifyColumns={function(columnConf) {
          const locColumn = {
            id: 'locationName',
            header: i18n.t('common.location'),
            excelWidth: 25,
            getTotalRowContents: function() {
              return i18n.t('common.total')
            },
            filterFieldName: 'location',
          }

          const unitPriceColumn = {
            id: 'unit-price',
            header: i18n.t('common.unit-price'),
            excelWidth: 10,
            getCellContents: function(rowData) {
              const price = rowData.prices[rowData.currency]
              return price ? CommonUtils.formatDecimal(price, true) + ' ' + rowData.currency : ''
            },
            getExcelValue: function(rowData) {
              return rowData.prices[rowData.currency]
            },
          }

          const valueColumn = {
            id: 'value',
            header: i18n.t('common.value'),
            excelWidth: 15,
            getCellContents: function(rowData) {
              const price = rowData.values[rowData.currency]
              return price ? CommonUtils.formatDecimal(price, true) + ' ' + rowData.currency : ''
            },
            getExcelValue: function(rowData) {
              return rowData.values[rowData.currency]
            },
            getTotalRowContents: function(context) {
              return (
                <div>
                  <div>
                    {CommonUtils.formatDecimal(context.totalValues['MAD'], true)}
                    {' MAD'}
                  </div>
                  <div>
                    {CommonUtils.formatDecimal(context.totalValues['EUR'], true)}
                    {' EUR'}
                  </div>
                </div>
              )
            },
            getExcelTotalValue: function(context) {
              return (
                CommonUtils.formatDecimal(context.totalValues['MAD'], true) + ' MAD\n' +
                CommonUtils.formatDecimal(context.totalValues['EUR'], true) + ' EUR'
              )
            },
          }

          const currencyColumn = {
            id: 'currency',
            header: i18n.t('common.currency'),
            includeIf: function(view) {
              return view === 'excel'
            },
            excelWidth: 10,
          }

          // Add location column to beginning
          columnConf.splice(0, 0, locColumn)

          // Replace price column with unit price and value (and currency for Excel)
          const priceIndex: number = Utils.findIndexByField<any, 'id'>(columnConf, 'id', 'price')
          columnConf.splice(priceIndex, 1, unitPriceColumn, valueColumn, currencyColumn)
        }}
        modifyFilterConf={function(filterConf) {
          filterConf['location'] = {
            type: 'predefined',
            labelKey: 'common.location',
            options: locationOptions,
          }
        }}
        filterSetKey="inventory-report"
        printView={this.state.printView}
        limitRows={true}
        isProduction={false}
      />
    )
  }

  render() {
    if (!this.state.loaded) {
      return (
        <div>
          {this.renderMenus()}
          {i18n.t('common.loading')}
        </div>
      )
    }

    return (
      <div>
        {this.renderMenus()}
        {this.renderButtons()}
        {this.renderPrintHeader()}
        {this.renderTable()}
      </div>
    )
  }
}
