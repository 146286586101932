import update from 'immutability-helper'
import { useRef } from 'react'
import React = require('react')

import { Access } from '../common/access'
import { CommonUtils } from '../common/common-utils'
import { AttributeDefinition } from '../common/data-attribute-defs'
import { Location } from '../common/data-locations'
import { SimpleAttr } from '../common/data-misc'
import { ProductDefinition } from '../common/data-product-defs'
import { RawMaterial } from '../common/data-raw-materials'
import { getDataService, ProductOps, TemplateOps } from '../common/data-service'
import { TemplateDefinition } from '../common/data-template-defs'
import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { InventoryItem } from '../common/types'
import { AttributeCombination } from './attribute-combination'
import { AttributeCombinationSelector } from './attribute-combination-selector'
import { AutoComplete } from './auto-complete'
import { bindInput, bindProps } from './bind-utils'
import { Comp } from './comp'
import { LoadingButton } from './loading-button'
import { getDefaultCancelButton, Modal, ModalButton } from './modal'
import { SearchableMaterial } from './searchable-material'
import { useOpenModalEvent } from './use-open-modal-event'
import { User } from './user'
import { Utils } from './utils'
import { ValidationErrors, ValidationUtils } from './validation-utils'

export interface EditInventoryModalProps {
  modalId: string,
  isProduction: boolean,
  definition?: TemplateDefinition | ProductDefinition, // Taken from inventory.tsx
  isCustom?: boolean,
  item?: InventoryItem, // Missing if isNew
  isNew?: boolean,
  itemService?: TemplateOps | ProductOps, // Taken from inventory.tsx
  currencies: string[],
  categories: SimpleAttr[],
  attributeDefinitions: AttributeDefinition[],
  templateDefinitions?: TemplateDefinition[],
  location?: Location,
  allProductNames?: string[],
  materials?: RawMaterial[],
  reloadParent: () => void,
}

const { ValueLabel } = Comp

interface FormProps {
  isProduction: boolean,
  definition?: any, // TemplateDefinition | ProductDefinition, give error in renderCosts()
  isCustom?: boolean,
  item?: InventoryItem,
  isNew?: boolean, // Missing if isNew
  itemService: any, // TemplateOps | ProductOps give error in save()
  currencies: string[],
  categories: SimpleAttr[],
  attributeDefinitions: AttributeDefinition[],
  templateDefinitions?: TemplateDefinition[],
  location?: Location,
  allProductNames?: string[],
  materials?: RawMaterial[], // TODO: required?
  closeAndReload: () => void,
}

interface FormState {
  item: any,
  definition: any,
  changed: boolean,
  materialNameValue: string,
  validationErrors?: ValidationErrors,
}

class Form extends React.Component<FormProps, FormState> {
  constructor(props) {
    super(props)
    let item, definition

    if (props.isNew) {
      const attributes = {}

      if (props.isCustom) {
        definition = {
          isCustom: true,
          name: '',
          orderId: '',
          attributeCombinations: {},
          prices: {},
        }

        props.attributeDefinitions.forEach(function(attribute) {
          if (!attribute.textForCustom) {
            // Custom products have exactly one combo for each attribute
            definition.attributeCombinations[attribute._id] = []
          }
        })
      }
      else {
        definition = props.definition // TODO: clone?

        // Auto-select attribute combinations where there's only one choice
        Object.keys(definition.attributeCombinations).forEach((attrId) => {
          const combinations = definition.attributeCombinations[attrId]
          attributes[attrId] = null

          if (combinations.length === 1) {
            const attributeDefinition = CommonUtils.findById(this.props.attributeDefinitions, attrId)

            // TODO: allow deselecting button for optional attributes
            if (!attributeDefinition.optional) {
              const [combo] = combinations
              attributes[attrId] = typeof combo === 'number' ? combo : combo.id
            }
          }
        })
      }

      item = {
        location: props.location._id,
        attributes,
        amount: '',
        amountThreshold: '',
        note: null,
      }

      if (definition._id) {
        item.definition = definition._id
      }
    }
    else {
      item = CommonUtils.clone(props.item)
      definition = props.definition // TODO: clone?
    }

    this.state = { item, definition, changed: false, materialNameValue: '' }
  }

  _isMounted = false

  componentDidMount() {
    this._isMounted = true
    EventBus.fire('edit-inventory-modal-opened')
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  canEditFull = () => {
    return Access.editFullInventory(User.getUser(), this.props.location)
  }

  canEditAmountThreshold = () => {
    return Access.editTemplateAmountThreshold(User.getUser())
  }

  canEditNote = () => {
    return Access.editInventoryNote(User.getUser(), this.props.location)
  }

  save = () => {
    return ValidationUtils.clear(this)
    .then(() => {
      let definition = CommonUtils.clone(this.state.definition)

      const item: any = {
        note: this.state.item.note,
      }

      if (this.props.isProduction && this.canEditAmountThreshold()) {
        if (this.state.item.amountThreshold) {
          item.amountThreshold = this.state.item.amountThreshold.toString() || '0'
        } else {
          item.amountThreshold = '0'
        }
      }

      if (this.props.isNew) {
        item.location = this.state.item.location
        item.attributes = this.state.item.attributes

        if (this.props.isCustom) {
          item.attributes = {}

          this.props.attributeDefinitions.forEach(function(attribute) {
            if (!attribute.textForCustom) {
              if (definition.attributeCombinations[attribute._id].length) {
                // TODO: could also avoid saving this in the database,
                // though it's simpler at first to keep it this way.
                item.attributes[attribute._id] = 1
              }
            }
          })
        }
        else {
          item.definition = this.state.item.definition
        }
      }

      if (this.props.isNew || this.canEditFull()) {
        item.amount = this.state.item.amount.toString()

        if (this.state.item.material) {
          item.material = this.state.item.material
        }
      }

      if (this.props.isCustom) {
        if (this.props.isNew) {
          const cleanDefinition = CommonUtils.clone(definition)
          delete cleanDefinition.isCustom
          return getDataService().Products.createCustom(cleanDefinition, item)
        }
        else {
          if (this.canEditFull()) {
            definition = {
              orderId: definition.orderId,
              prices: {
                MAD: definition.prices.MAD.toString(),

                // TODO: test case that fails without the falsy check
                EUR: (definition.prices.EUR || '').toString(),
              },
            }
          }
          else {
            definition = null
          }

          return this.props.itemService.update(this.state.item._id, item, definition)
        }
      }
      else {
        if (this.props.isNew) {
          return this.props.itemService.create(item)
        }
        else {
          return this.props.itemService.update(this.state.item._id, item)
        }
      }
    })
    .catch((error) => ValidationUtils.check(this, error))
  }

  convertToCustom = async () => {
    if (await Utils.confirmTr('inventory.convert-prompt')) {
      await getDataService().Products.convertToCustom(this.state.item._id)
      this.props.closeAndReload()
    }
  }

  markChanged = () => {
    // Currently only used in situations where changes may affect the
    // "Convert to custom product" button, other changes are not marked.
    this.setState({ changed: true })
  }

  renderCombinationDefiner = (attribute) => {
    const combinations = this.state.definition.attributeCombinations[attribute._id]
    const [combination] = combinations

    // Wrap in similar divs as on definition form so we can use the same test utils
    return (
      <div id={'attr-combo-set-' + attribute._id}>
        <div className="attr-combo">
          <AttributeCombination
            ref={'combo-' + attribute._id}
            attribute={attribute}
            values={combination ? combination.values : []}
            onChange={(newValues) => {
              if (newValues.length) {
                if (!combinations.length) {
                  combinations.push({ id: 1, values: newValues })
                }
                else {
                  combinations[0].values = newValues
                }
              }
              else {
                combinations.length = 0
              }

              this.forceUpdate() // TODO: use setState?
            }}
          />
        </div>
      </div>
    )
  }

  renderCombinationForNew = (attribute) => {
    const { definition } = this.state

    if (this.props.isCustom) {
      if (attribute.textForCustom) {
        return bindInput(this,
          ['definition', 'attributeCombinations', attribute._id],
          { id: 'inp-attr-' + attribute._id, style: { width: '20em' } },
        )
      }
      else {
        // Custom definition and non-text attribute
        return this.renderCombinationDefiner(attribute)
      }
    }
    else if (attribute._id in definition.attributeCombinations || !attribute.optional) {
      const { item } = this.state

      return (
        <AttributeCombinationSelector
          attribute={attribute}
          definition={definition}
          templateDefinitions={this.props.templateDefinitions}
          selectedComboId={item.attributes[attribute._id]}
          onSelect={(comboId) => {
            item.attributes[attribute._id] = comboId
            this.forceUpdate()
          }}
        />
      )
    }
    else {
      return '-'
    }
  }

  renderRow = (key, fieldName, field, validationField?) => {
    return (
      <div key={key} className="row row-inventory">
        <div className="col-xs-3 col-left">
          {fieldName}
        </div>
        <div className="col-xs-9">
          {field}
          {ValidationUtils.render(this.state.validationErrors, validationField)}
        </div>
      </div>
    )
  }

  renderName = () => {
    if (this.props.isCustom && this.props.isNew) {
      return (
        <AutoComplete
          value={this.state.definition.name}
          onChange={(newName) => {
            this.setState({
              definition: update(this.state.definition, {
                name: { $set: newName },
              }),
            })
          }}
          fieldProps={{ id: 'inp-name', style: { width: '20em' } }}
          allValues={this.props.allProductNames}
        />
      )
    }
    else {
      return (
        <div id="lbl-name">
          {this.state.definition.name}
        </div>
      )
    }
  }

  renderOrderIdRow = () => {
    if (this.props.isCustom) {
      if (this.props.isNew || this.canEditFull()) {
        return this.renderRow(
          undefined,
          i18n.t('inventory.order-id'),
          bindInput(this,
            ['definition', 'orderId'],
            { id: 'inp-order-id', style: { width: '20em' } },
          ),
          'orderId',
        )
      }
      else {
        return this.renderRow(
          undefined,
          i18n.t('inventory.order-id'),
          <span id="lbl-order-id">
            {this.state.definition.orderId}
          </span>,
        )
      }
    }
  }

  renderAttributes = () => {
    const language = User.getLanguage()
    let attributeDetails = null

    if (!this.props.isNew) {
      attributeDetails = CommonUtils.getAttributeDetails(
        this.state.item, this.props.attributeDefinitions, this.state.definition,
        this.props.templateDefinitions, language,
      )
    }

    return this.props.attributeDefinitions.map((attribute) => {
      let combinationElement

      if (this.props.isNew) {
        combinationElement = this.renderCombinationForNew(attribute)
      }
      else {
        const { label } = attributeDetails[attribute._id]

        combinationElement = (
          <div id={'lbl-combo-' + attribute._id}>
            {label}
          </div>
        )
      }

      let validationFieldName = 'attributes.' + attribute._id

      if (this.props.isCustom) {
        validationFieldName = 'attributeCombinations.' + attribute._id
      }

      return this.renderRow(
        attribute._id,
        this.props.isCustom ? attribute.names[language] : attribute.pluralNames[language],
        combinationElement,
        validationFieldName,
      )
    })
  }

  renderCosts = () => {
    const currency = 'MAD'
    const { definition } = this.props

    const tailorCost = definition.tailorCosts[currency]
    const strTailorCost = CommonUtils.formatDecimal(tailorCost, true) + ' ' + currency

    const divTailorCost = (
      <div id="lbl-tailor-cost">
        {strTailorCost}
      </div>
    )

    const fabricCost = definition.fabricCosts[currency]
    const strFabricCost = CommonUtils.formatDecimal(fabricCost, true) + ' ' + currency

    const divFabricCost = (
      <div id="lbl-fabric-cost">
        {strFabricCost}
      </div>
    )

    return [
      this.renderRow('tailor-cost', i18n.t('tpl-def.tailor-cost'), divTailorCost),
      this.renderRow('fabric-cost', i18n.t('tpl-def.fabric-cost'), divFabricCost),
    ]
  }

  renderPrices = () => {
    if (this.props.isCustom && (this.props.isNew || this.canEditFull())) {
      return this.props.currencies.map((currency) => {
        return (
          <div key={currency} style={{ marginBottom: '0.3em' }}>
            {bindInput(this, ['definition', 'prices', currency], {
              id: 'inp-prices-' + currency,
              style: { width: '4em' },
              validator: Utils.nonNegativeDecimalValidator,
            })}
            {' '}
            {i18n.t('currency.per-unit', currency)}
            {ValidationUtils.render(this.state.validationErrors, 'prices.' + currency)}
          </div>
        )
      })
    }
    else {
      const prices = CommonUtils.getPrices(this.state.item, this.state.definition)

      return this.props.currencies.map(function(currency) {
        const value = prices[currency]

        if (!value && value !== '0' && value !== 0) {
          // Value missing and not zero - show nothing
          return null
        }

        return (
          <div key={currency} id={'lbl-price-' + currency}>
            {CommonUtils.formatDecimal(value, true)}
            {' '}
            {currency}
          </div>
        )
      })
    }
  }

  renderCostsOrPrices = () => {
    if (this.props.isProduction) {
      return this.renderCosts()
    }
    else {
      return this.renderRow(
        undefined,
        i18n.t(this.props.currencies.length === 1 ? 'common.price' : 'common.prices'),
        this.renderPrices(),
      )
    }
  }

  renderAmount = () => {
    if (this.props.isNew || this.canEditFull()) {
      return bindInput(this, ['item', 'amount'], {
        id: 'inp-amount',
        style: { width: '4em' },
        validator: Utils.nonNegativeIntegerValidator,
        afterChange: this.markChanged,
      })
    }
    else {
      return (
        <div id="lbl-amount">
          {this.state.item.amount}
        </div>
      )
    }
  }

  renderAmountWarning = () => {
    const { item } = this.state
    if (!this.props.isNew && item.amountThreshold) {
      if (item.amount < item.amountThreshold) {
        return (
          <div className="row" style={{ margin: '0.5em 0' }}>
            <div className="col-xs-3" />
            <div className="col-xs-9">
              <p id="below-threshold-warning" className="text-red">
                {i18n.t('templates.amount-below-threshold')}
              </p>
            </div>
          </div>
        )
      }
    }
  }

  renderAmountThreshold = () => {
    return bindInput(this, ['item', 'amountThreshold'], {
      id: 'inp-amount-threshold',
      style: { width: '4em' },
      validator: Utils.nonNegativeIntegerValidator,
    })
  }

  renderAmountThresholdRow = () => {
    if (this.canEditAmountThreshold() && this.props.isProduction) {
      return this.renderRow(undefined, i18n.t('common.amount-threshold'), this.renderAmountThreshold(), 'amount-threshold')
    }
  }

  renderNote = () => {
    let note
    if (this.canEditNote()) {
      note = (
        <textarea
          {...bindProps(this, ['item', 'note'], {
            id: 'inp-note',
            style: { width: 300, height: 100 },
            afterChange: this.markChanged,
          })}
        />
      )
    } else {
      note = <p>{this.state.item.note}</p>
    }

    return note
  }

  renderSearchableMaterial = () => {
    return (
      <SearchableMaterial
        materials={this.props.materials}
        nameValue={this.state.materialNameValue}
        onChange={(val) => {
          this.setState({ materialNameValue: val })
        }}
        onSelect={(mat) => {
          const item = CommonUtils.clone(this.state.item)
          item.material = mat._id
          this.setState({ item, materialNameValue: '' })
        }}
      />
    )
  }

  renderMaterialValueLabel = () => {
    const material = CommonUtils.findById(this.props.materials, this.state.item.material)

    return (
      <ValueLabel
        onRemove={() => {
          const item = CommonUtils.clone(this.state.item)
          delete item.material
          this.setState({ item })
        }}
      >
        {material.name}
      </ValueLabel>
    )
  }

  renderMaterialInput = () => {
    if (this.state.item.material) {
      return this.renderMaterialValueLabel()
    }

    return this.renderSearchableMaterial()
  }

  renderMaterial = () => {
    if (this.props.isNew || this.canEditFull) {
      return this.renderMaterialInput()
    }

    return this.state.item.material
  }

  renderMaterialRow = () => {
    if (this.props.isProduction) {
      return null
    }

    return this.renderRow(
      undefined,
      i18n.t('raw-materials.material'),
      this.renderMaterial(),
      'material',
    )
  }

  renderNoteRow = () => {
    return this.renderRow(
      undefined,
      i18n.t('common.note'),
      this.renderNote(),
      'note',
    )
  }

  renderConvertRow = () => {
    if (
      !this.props.isNew &&
      !this.props.isCustom &&
      this.props.location.type === 'storage' &&
      Access.convertProducts(User.getUser())
    ) {
      const button = (
        <LoadingButton
          id="btn-convert"
          text={i18n.t('inventory.convert-to-custom')}
          getPromise={this.convertToCustom}
          disabled={this.state.changed}
          className={this.state.changed ? 'disabled' : ''}
          disabledTitle={i18n.t('inventory.cannot-convert-unsaved')}
        />
      )

      return this.renderRow(undefined, '', button)
    }
  }

  render() {
    const language = User.getLanguage()

    const categoryName = CommonUtils.getCategoryName(
      this.state.definition, this.props.categories, language,
    )

    const locationName = this.props.location.names[language]

    return (
      <div id="form-inv">
        {this.renderRow(
          undefined,
          i18n.t('common.location'),
          <div id="lbl-location">{locationName}</div>,
        )}
        {this.renderRow(
          undefined,
          i18n.t('common.category'),
          <div id="lbl-category">{categoryName}</div>,
        )}
        {this.renderRow(
          undefined,
          i18n.t('common.name'),
          this.renderName(),
          'name',
        )}
        {this.renderOrderIdRow()}
        {this.renderAttributes()}
        {this.renderCostsOrPrices()}
        {this.renderRow(undefined, i18n.t('common.amount'), this.renderAmount(), 'amount')}
        {this.renderAmountWarning()}
        {this.renderAmountThresholdRow()}
        {this.renderMaterialRow()}
        {this.renderNoteRow()}
        {this.renderConvertRow()}
      </div>
    )
  }
}

export const EditInventoryModal = (props: EditInventoryModalProps) => {
  const [isVisible, close] = useOpenModalEvent(props.modalId)
  const formRef = useRef<Form>(null)

  if (!isVisible) {
    return <div />
  }

  const closeAndReload = () => {
    close()
    return props.reloadParent()
  }

  const buttons: ModalButton[] = [
    {
      id: 'btn-save',
      title: i18n.t('action.save'),
      onClick: async () => {
        await formRef.current.save()
        await props.reloadParent()
        close()
      },
    },
    getDefaultCancelButton(),
  ]

  return (
    <div>
      <Modal
        title={i18n.t(props.isProduction ? 'templates.title' : 'products.title')}
        closeModal={close}
        buttons={buttons}
      >
        <Form
          ref={formRef}
          isProduction={props.isProduction}
          definition={props.definition}
          isCustom={props.isCustom}
          item={props.item}
          isNew={props.isNew}
          // TODO: pass a callback instead of service?
          itemService={props.itemService}
          currencies={props.currencies}
          categories={props.categories}
          attributeDefinitions={props.attributeDefinitions}
          templateDefinitions={props.templateDefinitions}
          location={props.location}
          allProductNames={props.allProductNames}
          materials={props.materials}
          closeAndReload={closeAndReload}
        />
      </Modal>
    </div>
  )
}
