import { DetailedHTMLProps, InputHTMLAttributes } from 'react'
import React = require('react')

import { UiUtils } from './ui-utils'

// TODO: unduplicate style values with AttributeCombination

interface AutoCompleteProps {
  value?: string,
  allValues: string[],
  onChange: (value: string) => void,
  fieldProps?: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, // TODO: required?
}

export class AutoComplete extends React.Component<AutoCompleteProps> {
  state = {
    dropdownActive: false,
    selectedIndex: 0,
  }

  handleKey = (evt) => {
    const { keyCode } = evt

    if (keyCode === 13) { // Enter
      if (this.state.dropdownActive) {
        const value = this.getMatches()[this.state.selectedIndex]

        if (value) {
          this.props.onChange(value)
          this.setState({ dropdownActive: false })
        }

        evt.preventDefault()
      }
    }
    else if (keyCode === 38) { // Up
      this.moveSelection(-1)
    }
    else if (keyCode === 40) { // Down
      this.moveSelection(+1)
    }
  }

  moveSelection = (diff) => {
    // diff must be either -1 or 1
    const maxIndex = this.getMatches().length - 1
    const newIndex = Math.max(0, Math.min(maxIndex, this.state.selectedIndex + diff))
    this.setState({ selectedIndex: newIndex })
  }

  getMatches = () => {
    if (!this.props.value) {
      return []
    }

    const lower = this.props.value.toLowerCase()

    return this.props.allValues.filter(function(value) {
      return value.toLowerCase().indexOf(lower) !== -1
    }).slice(0, 25)
  }

  selectValue = (value) => {
    this.props.onChange(value)
    this.setState({ dropdownActive: false })
  }

  getDropdown = () => {
    if (!this.state.dropdownActive) {
      return null
    }

    const matches = this.getMatches()

    if (matches.length < 1) {
      return null
    }

    let index = -1

    const dropdownElements = matches.map((value) => {
      index += 1

      // Copy to local variable so we can use this value in callbacks
      const localIndex = index

      const bgColor = index === this.state.selectedIndex ? 'rgb(48, 108, 192)' : 'transparent'
      const textColor = index === this.state.selectedIndex ? 'white' : 'black'

      return (
        <div
          key={index}
          className="suggestion"
          style={{
            backgroundColor: bgColor,
            color: textColor,
            padding: '0.15em 0.3em',
            cursor: 'pointer',
          }}
          onMouseDown={function(evt) {
            // Prevent click on dropdown from triggering blur on input
            evt.preventDefault()
          }}
          onClick={() => {
            this.selectValue(value)
          }}
          onMouseOver={() => {
            if (this.state.selectedIndex !== localIndex) {
              this.setState({ selectedIndex: localIndex })
            }
          }}
        >
          {UiUtils.highlight(value, this.props.value)}
        </div>
      )
    })

    return (
      <div
        style={{
          position: 'absolute',
          top: '1.6em',
          width: '15em',
          backgroundColor: 'white',
          border: '1px solid #ccc',
          boxShadow: '0 0.3em 0.6em rgba(0, 0, 0, 0.2)',
          zIndex: 100,
        }}
      >
        {dropdownElements}
      </div>
    )
  }

  render() {
    const dropdown = this.getDropdown()

    return (
      <span style={{ position: 'relative' }}>
        <input
          value={this.props.value}
          onKeyDown={this.handleKey}
          onBlur={() => {
            this.setState({ dropdownActive: false })
          }}
          onFocus={() => {
            this.setState({ dropdownActive: true })
          }}
          onChange={(evt) => {
            this.setState({ dropdownActive: true, selectedIndex: 0 })
            this.props.onChange(evt.target.value)
          }}
          {...this.props.fieldProps}
        />
        {dropdown}
      </span>
    )
  }
}
