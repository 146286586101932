import { useRef } from 'react'
import React = require('react')
import toastr from 'toastr/toastr'

import { CommonUtils } from '../common/common-utils'
import { AttributeDefinition } from '../common/data-attribute-defs'
import { MaterialConsumption } from '../common/data-material-consumption-report'
import { SimpleAttr } from '../common/data-misc'
import { RawMaterial } from '../common/data-raw-materials'
import { getDataService } from '../common/data-service'
import { i18n } from '../common/i18n'
import { bindInput, bindProps } from './bind-utils'
import { getDefaultCancelButton, Modal, ModalButton } from './modal'
import { useOpenModalEvent } from './use-open-modal-event'
import { User } from './user'
import { Utils } from './utils'
import { ValidationErrors, ValidationUtils } from './validation-utils'

interface EditMaterialReportModalProps {
  modalId: string,
  entry: MaterialConsumption,
  material: RawMaterial,
  categories: SimpleAttr[],
  producers: SimpleAttr[],
  widths: SimpleAttr[],
  attributeDefinitions: AttributeDefinition[],
  getUsername: (userId: string) => string,
  afterSave?: () => void,
}

interface FormProps {
  entry: MaterialConsumption,
  material: RawMaterial,
  categories: SimpleAttr[],
  producers: SimpleAttr[],
  widths: SimpleAttr[],
  attributeDefinitions: AttributeDefinition[],
  getUsername: (user: string) => string,
}

interface FormState {
  entry: MaterialConsumption,
  date: string,
  time: string,
  validationErrors?: ValidationErrors,
}

class Form extends React.Component<FormProps, FormState> {
  constructor(props) {
    super(props)
    const userTime = this.getUserTime()

    this.state = {
      entry: CommonUtils.clone(props.entry),
      date: CommonUtils.utcDateYMD(userTime),
      time: CommonUtils.utcTime(userTime),
    }
  }

  dateInputRef = React.createRef<HTMLInputElement>()

  _isMounted = false

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getUserTime = () => {
    return CommonUtils.toUserTime(User.getUser().country, new Date(this.props.entry.time))
  }

  onSave = () => {
    return ValidationUtils.clear(this)
    .then(() => {
      const entry = {
        amount: this.state.entry.amount.toString(),
        note: this.state.entry.note,
        time: this.state.entry.time,
      }

      const userTime = this.getUserTime()

      // Update datetime only if the user has changed it, otherwise we'll
      // lose precision beyond minutes for no reason.
      if (
        this.state.date !== CommonUtils.utcDateYMD(userTime) ||
        this.state.time !== CommonUtils.utcTime(userTime)
      ) {
        this.validateDateTime(this.state.date, this.state.time)

        const utcTime = CommonUtils.userDateToUTC(
          User.getUser().country, this.state.date, this.state.time,
        )

        entry.time = utcTime.toISOString()
      }

      // TODO: prompt user about updating storage if amount changed?

      return getDataService().MaterialConsumptionReport.update(this.state.entry._id, entry)
    })
    .catch((error) => ValidationUtils.check(this, error))
  }

  validateDateTime = (date, time) => {
    const dateStr = date + ' ' + time

    // Append Z to parse as a UTC date
    const dateObj = new Date(dateStr + 'Z')
    const processedDateStr = CommonUtils.utcDateTime(dateObj)

    if (processedDateStr !== dateStr) {
      // TODO: log warning through API?
      console.error(dateStr + ' does not match ' + processedDateStr)
      toastr.error(i18n.t('reports.invalid-date-time'))

      this.dateInputRef.current.focus()

      // Skip the rest of the promise chain
      throw Utils.getAlreadyHandledError()
    }
  }

  render() {
    const { material } = this.props

    const language = User.getLanguage()

    const row = (key, fieldName, field, validationField?) => {
      return (
        <div key={key} className="row" style={{ margin: '0.5em 0' }}>
          <div className="col-xs-4" style={{ fontWeight: 'bold', paddingLeft: 0 }}>
            {fieldName}
          </div>
          <div className="col-xs-8">
            {field}
            {ValidationUtils.render(this.state.validationErrors, validationField)}
          </div>
        </div>
      )
    }

    const category = CommonUtils.findById(this.props.categories, material.category)
    const producer = CommonUtils.findById(this.props.producers, material.producer)
    const width = CommonUtils.findById(this.props.widths, material.width)

    return (
      <div>
        <div id="form-entry">
          {row(undefined,
            i18n.t('raw-materials.material'),
            <div id="lbl-name">
              {material.name}
            </div>,
          )}
          {row(undefined,
            i18n.t('common.category'),
            <div id="lbl-category">
              {category.labels[language]}
            </div>,
          )}
          {row(undefined,
            i18n.t('raw-materials.producer'),
            <div id="lbl-producer">
              {producer.labels[language]}
            </div>,
          )}
          {row(undefined,
            i18n.t('raw-materials.width'),
            <div id="lbl-width">
              {width.labels[language]}
            </div>,
          )}
          {this.props.attributeDefinitions.map(function(attribute) {
            const values = material.attributes[attribute._id]

            return row(
              attribute._id,
              attribute.names[language],
              CommonUtils.getAttributeComboLabel(
                attribute, values, material.category, language,
              ),
            )
          })}
          {row(undefined,
            i18n.t('common.location'),
            <div id="lbl-location">Al Nour</div>, // TODO: un-hardcode
          )}
          {row(undefined,
            i18n.t('common.amount'),
            <div>
              {bindInput(this,
                ['entry', 'amount'],
                { id: 'inp-amount', style: { width: '4em' }, validator: Utils.nonNegativeDecimalValidator },
              )}
              {' '}
              {i18n.t('enum.material-units.' + material.amount.unit)}
              {ValidationUtils.render(this.state.validationErrors, 'amount')}
            </div>,
          )}
          {row(undefined,
            i18n.t('common.note'),
            <textarea
              {...bindProps(this, ['entry', 'note'], {
                id: 'inp-note',
                style: { width: '20em', height: '7em' },
              })}
            />,
            'note',
          )}
          {row(undefined,
            i18n.t('common.user'),
            <div id="lbl-user">
              {this.props.getUsername(this.state.entry.user)}
            </div>,
          )}
          {row(undefined,
            i18n.t('reports.date-time'),
            // TODO: date picker?
            <div>
              {bindInput(this, ['date'], { ref: this.dateInputRef, style: { width: '6.2em' } })}
              {' '}
              {bindInput(this, ['time'], { style: { width: '3.5em' } })}
            </div>,
          )}
        </div>
      </div>
    )
  }
}

export const EditMaterialReportModal = (props: EditMaterialReportModalProps) => {
  const [isVisible, close] = useOpenModalEvent(props.modalId)
  const formRef = useRef<Form>(null)

  if (!isVisible) {
    return <div />
  }

  const buttons: ModalButton[] = [
    {
      id: 'btn-save',
      title: i18n.t('action.save'),
      onClick: async () => {
        await formRef.current.onSave()
        await props.afterSave?.()
        close()
      },
    },
    getDefaultCancelButton(),
  ]

  return (
    <div>
      <Modal
        title={i18n.t('reports.edit-entry')}
        closeModal={close}
        dialogClassName="edit-material-report"
        buttons={buttons}
      >
        <Form
          ref={formRef}
          entry={props.entry}
          material={props.material}
          categories={props.categories}
          producers={props.producers}
          widths={props.widths}
          attributeDefinitions={props.attributeDefinitions}
          getUsername={props.getUsername}
        />
      </Modal>
    </div>
  )
}
