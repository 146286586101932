import React = require('react')

import { AccountingUtils } from '../common/accounting-utils'
import { CommonUtils } from '../common/common-utils'
import { Enums } from '../common/enums'
import { AugmentedCategory } from '../common/expense-category-path-builder'
import { i18n } from '../common/i18n'
import { LangStr } from '../common/types'
import { Comp } from './comp'
import { LoadingIcon } from './loading-icon'
import { TableButton } from './table-button'

interface TreeProps {
  tree: AugmentedCategory[],
  onChange: (categoryId: string, labels: LangStr) => void,
  onSave: (categoryId: string) => Promise<void>,
  onDelete: (categoryId: string) => void,
  onAdd: (parentId: string) => void,
}

interface ExpenseCategoriesForestProps {
  expenseCategories: AugmentedCategory[],
  onChange: (categoryId: string, labels: LangStr) => void,
  onSave: (categoryId: string) => Promise<void>,
  onDelete: (categoryId: string) => void,
  onAdd: (parentId: string) => void,
}

const { languages } = Enums
const { stubId, idSeparator } = AccountingUtils

const { AddButton, SaveButton, DeleteButton } = TableButton
const { LabelInput } = Comp

class Tree extends React.Component<TreeProps> {
  state = {
    changed: false,
    loading: false,
  }

  getRoot = () => {
    return this.props.tree[0]
  }

  hasChildren = () => {
    return this.props.tree.length > 1
  }

  shouldRenderDeleteButton = () => {
    const expenseCategory = this.getRoot()
    const isInvoiceExpenseCategory = AccountingUtils.isInvoiceExpenseCategory(expenseCategory._id)
    return !(this.hasChildren() || isInvoiceExpenseCategory)
  }

  onLabelChange = (lang, val) => {
    this.setState({ changed: true })
    const expenseCategory = this.getRoot()
    const labels = CommonUtils.clone(expenseCategory.labels)
    labels[lang] = val
    this.props.onChange(expenseCategory._id, labels)
  }

  isStub = () => {
    const root = this.getRoot()
    return AccountingUtils.isStubId(root._id)
  }

  hasStubChild = () => {
    const root = this.getRoot()
    const stubChildId = root._id + idSeparator + stubId
    const stubChild = CommonUtils.findById(this.props.tree, stubChildId)
    return stubChild !== null
  }

  onSave = () => {
    this.setState({ loading: true })
    const expenseCategory = this.getRoot()
    this.props.onSave(expenseCategory._id)
    .then(() => {
      if (!this.isStub()) {
        this.setState({
          changed: false,
          loading: false,
        })
      }
    })
  }

  onDelete = () => {
    this.setState({ loading: true })
    const expenseCategory = this.getRoot()
    this.props.onDelete(expenseCategory._id)
  }

  onAdd = () => {
    const root = this.getRoot()
    this.props.onAdd(root._id)
  }

  renderLabelInput = (lang) => {
    const expenseCategory = this.getRoot()
    const props = {
      className: 'col-xs-5',
      label: i18n.t('enum.languages.' + lang),
      value: expenseCategory.labels[lang],
      onChange: (val) => {
        this.onLabelChange(lang, val)
      },
    }
    return <LabelInput {...props} />
  }

  renderSaveButton = () => {
    const expenseCategory = this.getRoot()
    const { labels } = expenseCategory
    const disabled = !this.state.changed || labels.en === '' || labels.fr === ''
    return <SaveButton onClick={this.onSave} disabled={disabled} />
  }

  renderDeleteButton = () => {
    if (this.shouldRenderDeleteButton()) {
      return <DeleteButton onClick={this.onDelete} />
    }
  }

  renderAddButton = () => {
    if (!this.isStub()) {
      const disabled = this.hasStubChild()
      return <AddButton onClick={this.onAdd} disabled={disabled} />
    }
  }

  renderButtons = () => {
    if (this.state.loading) {
      return (
        <div className="col-xs-2">
          <LoadingIcon />
        </div>
      )
    } else {
      return (
        <div className="col-xs-2">
          {this.renderSaveButton()}
          {this.renderDeleteButton()}
          {this.renderAddButton()}
        </div>
      )
    }
  }

  renderRoot = () => {
    return (
      <div className="row expense-category-node">
        {this.renderLabelInput(languages.en)}
        {this.renderLabelInput(languages.fr)}
        {this.renderButtons()}
      </div>
    )
  }

  renderDescendants = () => {
    if (this.hasChildren()) {
      const descendants = CommonUtils.clone(this.props.tree)
      descendants.shift()

      return (
        <ExpenseCategoriesForest
          expenseCategories={descendants}
          onChange={this.props.onChange}
          onSave={this.props.onSave}
          onDelete={this.props.onDelete}
          onAdd={this.props.onAdd}
        />
      )
    }
  }

  render() {
    let className = 'list-group-item'
    if (this.state.changed || this.isStub()) {
      className += ' list-group-item-info'
    }
    return (
      <li className={className}>
        {this.renderRoot()}
        {this.renderDescendants()}
      </li>
    )
  }
}

export class ExpenseCategoriesForest extends React.Component<ExpenseCategoriesForestProps> {
  renderRow = (tree: AugmentedCategory[]) => {
    return (
      <Tree
        key={tree[0]._id}
        tree={tree}
        onChange={this.props.onChange}
        onSave={this.props.onSave}
        onDelete={this.props.onDelete}
        onAdd={this.props.onAdd}
      />
    )
  }

  render() {
    const trees = AccountingUtils.extractExpenseCategoryTrees(this.props.expenseCategories)

    return (
      <ul className="list-group tree-list-group">
        {trees.map(this.renderRow)}
      </ul>
    )
  }
}
