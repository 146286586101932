import classNames from 'classnames'
import React = require('react')

import { CommonUtils } from '../common/common-utils'
import { UserCountry } from '../common/data-misc'
import { getDataService } from '../common/data-service'
import { UiUser } from '../common/data-users'
import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { EditUserModal } from './edit-user-modal'
import { EmptyObject } from './empty-object'
import { FloatingHeader } from './floating-header'
import { MainMenu } from './main-menu'
import { User } from './user'
import { Utils } from './utils'

interface State {
  loaded: boolean,
  users: UiUser[],
  countries?: UserCountry[],
}

class List extends React.Component<EmptyObject, State> {
  state: State = { loaded: false, users: [] }

  _isMounted = false

  componentDidMount() {
    this._isMounted = true
    const DataService = getDataService()

    Promise.all([
      DataService.Users.getAll(),
      DataService.UserCountries.getAll(),
    ])
    .then(([users, countries]) => {
      if (this._isMounted) {
        this.setState(
          { loaded: true, users, countries },
          EventBus.fireFunc('users-rendered'),
        )
      }
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  reloadUsers = () => {
    getDataService().Users.getAll()
    .then((users) => {
      if (this._isMounted) {
        this.setState({ users }, EventBus.fireFunc('users-rendered'))
      }
    })

    return null
  }

  renderToggleActiveButton = (user: UiUser) => {
    let toggleActiveServiceName: 'activate' | 'deactivate'
    let titleTranslationKey
    let iconSrc

    if (user.deactivated) {
      toggleActiveServiceName = 'activate'
      titleTranslationKey = 'action.activate'
      iconSrc = 'img/check.png'
    }
    else {
      toggleActiveServiceName = 'deactivate'
      titleTranslationKey = 'action.deactivate'
      iconSrc = 'img/deactivate.png'
    }

    return (
      <img
        className="table-btn"
        title={i18n.t(titleTranslationKey)}
        src={iconSrc}
        onClick={() => {
          getDataService().Users[toggleActiveServiceName](user._id).then(this.reloadUsers)
        }}
      />
    )
  }

  render() {
    const mainMenu = <MainMenu activeTab="users" />

    if (!this.state.loaded) {
      return (
        <div>
          {mainMenu}
          {i18n.t('common.loading')}
        </div>
      )
    }

    const headerRow = (
      <tr>
        <th>
          {i18n.t('user.username')}
        </th>
        <th>
          {i18n.t('common.name')}
        </th>
          <th>
              {i18n.t('user.email')}
          </th>
        <th>
          {i18n.t('user.role')}
        </th>
        <th>
          {i18n.t('user.country')}
        </th>
        <th>
          {i18n.t('action.action')}
        </th>
      </tr>
    )

    const userRows = this.state.users.map((user) => {
      const country = CommonUtils.findById(this.state.countries, user.country)

      const editModalId = 'edit-modal-' + user._id

      let deleteButton = null
      let toggleActiveButton = null

      if (user._id !== User.getUser()._id) {
        deleteButton = (
          <img
            className="table-btn btn-delete"
            title={i18n.t('action.delete')}
            src="img/delete.png"
            onClick={() => {
              Utils.confirmTr('confirm.delete.user')
                .then(Utils.runIfTrue(() => {
                  getDataService().Users.delete(user._id).then(this.reloadUsers)
                  return null
                }))
            }}
          />
        )

        toggleActiveButton = this.renderToggleActiveButton(user)
      }

      const className = classNames('row-user', { deactivated: user.deactivated })

      return (
        <tr key={user._id} className={className}>
          <td>{user.username}</td>
          <td>
            {user.givenName}
            {' '}
            {user.familyName}
          </td>
            <td>{user.email || '--'} </td>
          <td>{i18n.t('enum.roles.' + user.role)}</td>
          <td>{country.names[User.getLanguage()]}</td>
          <td>
            <img
              className="table-btn btn-edit"
              title={i18n.t('action.edit')}
              src="img/edit.png"
              onClick={() => {
                EventBus.fire('open-modal', { modalId: editModalId })
              }}
            />
            {deleteButton}
            {toggleActiveButton}
            <EditUserModal
              modalId={editModalId}
              user={user}
              countries={this.state.countries}
              afterSave={this.reloadUsers}
            />
          </td>
        </tr>
      )
    })

    return (
      <div>
        {mainMenu}
        <div style={{ marginBottom: '0.5em' }}>
          <button
            id="btn-add-user"
            onClick={() => {
              EventBus.fire('open-modal', { modalId: 'add-modal' })
            }}
          >
            {i18n.t('user.add-user')}
          </button>
          <EditUserModal
            modalId='add-modal'
            isNew={true}
            countries={this.state.countries}
            afterSave={this.reloadUsers}
          />
        </div>
        <table
          id="tbl-users"
          className="table table-bordered table-condensed table-striped"
          style={{ maxWidth: '60em' }}
        >
          <FloatingHeader headerRow={headerRow} />
          <tbody>{userRows}</tbody>
        </table>
      </div>
    )
  }
}

export const Users = { List }
