import React = require('react')

import { CommonUtils } from '../common/common-utils'
import { AttributeDefinition } from '../common/data-attribute-defs'
import { DetailedInvoice } from '../common/data-invoices'
import { Location } from '../common/data-locations'
import { getDataService } from '../common/data-service'
import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { InvoiceSettings, InvoiceUtils } from '../common/invoice-utils'
import { InvoiceUiUtils, Rates } from './invoice-ui-utils'
import { User } from './user'
import { Utils } from './utils'

interface InvoicePrintProps {
  invoiceId: string,
}

interface State {
  loaded: boolean,
  footerTop: number,
  attributeDefinitions: AttributeDefinition[],
  invoice: DetailedInvoice | null,
  location: Location | null,
  invoiceSettings?: InvoiceSettings,
  rates?: Rates,
}

export class InvoicePrint extends React.Component<InvoicePrintProps, State> {
  state: State = {
    loaded: false,
    footerTop: 23,
    attributeDefinitions: [],
    invoice: null as DetailedInvoice | null,
    location: null,
  }

  summaryRef = React.createRef<HTMLDivElement>()
  footerRef = React.createRef<HTMLTableElement>()

  componentDidMount() {
    const DataService = getDataService()
    let invoice

    // Start requests
    const attrDefPromise = DataService.AttributeDefinitions.getAll()
    const ratesPromise = InvoiceUiUtils.getExchangeRates()
    const invoiceSettingsPromise = InvoiceUiUtils.getSettings()

    DataService.Invoices.getDetailed(this.props.invoiceId)
    .then(function(invoiceParam) {
      invoice = invoiceParam

      return Promise.all([
        attrDefPromise,
        ratesPromise,
        invoiceSettingsPromise,
        DataService.Locations.getById(invoice.location),
      ])
    })
    .then(([attributeDefinitions, rates, invoiceSettings, loc]) => {
      const filteredAttrDefs = attributeDefinitions.filter(
        CommonUtils.attributeContextFilters.products,
      )

      this.setState(
        {
          loaded: true,
          attributeDefinitions: filteredAttrDefs,
          rates,
          invoiceSettings,
          invoice,
          location: loc,
        },
        this.adjustFooter,
      )
    })
  }

  adjustFooter = () => {
    // Centimeters per page
    const INCREMENT = 27.6

    // Check if summary overlaps footer
    const summaryNode = this.summaryRef.current
    const footerNode = this.footerRef.current

    const summaryBottom = summaryNode.getBoundingClientRect().bottom
    const footerTop = footerNode.getBoundingClientRect().top

    if (summaryBottom > footerTop) {
      // Move footer to next page
      this.setState({ footerTop: this.state.footerTop + INCREMENT }, () => {
        // Ensure that footer has moved to avoid accidental infinite recursion
        const newFooterNode = this.footerRef.current
        const newFooterTop = newFooterNode.getBoundingClientRect().top

        if (newFooterTop > footerTop) {
          this.adjustFooter()
        }
        else {
          EventBus.fire('invoice-print-rendered')
        }
      })
    }
    else {
      EventBus.fire('invoice-print-rendered')
    }
  }

  onLogoLoaded = () => {
    // Activate browser's print feature after the logo is loaded
    Utils.print()
  }

  getTotal = () => {
    const invoiceValues = InvoiceUtils.getValues(this.state.invoice, this.state.location.currency)
    return InvoiceUtils.getTotal(this.state.invoiceSettings, invoiceValues, false)
  }

  getDroitsDeTimbre = () => {
    return InvoiceUtils.getDroitsDeTimbre(
      this.state.invoiceSettings,
      this.state.rates,
      this.state.location.currency,
      this.state.invoice.actualPayments,
    )
  }

  renderHeader = () => {
    return (
      <div className="pull-right text-right" style={{ fontSize: '0.3cm' }}>
        <div style={{ fontSize: '0.45cm' }}>Société Tarzajmal s.a.r.l. bank details:</div>
        <table id="bank-details" className="pull-right">
          <thead>
            <tr>
              <th>Code Banque</th>
              <th>Code Ville</th>
              <th>No Compte</th>
              <th>Clé RIB</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>007</td>
              <td>450</td>
              <td>0000 327000050770</td>
              <td>36</td>
            </tr>
          </tbody>
        </table>
        <div style={{ clear: 'both' }}>
          Code Swift BCMAMAMC(IBAN): MA64 007 450 0000327000050770 36 ; AttijariWafa bank
        </div>
        <div>
          Agence Marrakech BAB FTOUH, 40 Bab Ftouh, Marrakech Maroc
        </div>
      </div>
    )
  }

  renderLogo = () => {
    return (
      <div style={{ position: 'absolute', paddingBottom: '2em' }}>
        <img
          id="logo"
          src="/img/logo.png"
          style={{ width: '4.6cm' }}
          onLoad={this.onLogoLoaded}
        />
        <div
          style={{
            position: 'absolute',
            left: '2.4cm',
            fontSize: '0.3cm',
            fontFamily: 'Courier New',
            color: '#c00',
          }}
        >
          MARRAKECH
        </div>
      </div>
    )
  }

  renderCustomerName = () => {
    if (this.state.invoice.customerName) {
      return (
        <div style={{ fontSize: '90%', marginBottom: '0.4cm' }}>
          <div style={{ fontWeight: 'bold' }}>Customer name:</div>
          <div style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.invoice.customerName}
          </div>
        </div>
      )
    }
  }

  renderCustomerContact = () => {
    if (this.state.invoice.customerContact) {
      return (
        <div style={{ fontSize: '90%', marginBottom: '0.4cm' }}>
          <div style={{ fontWeight: 'bold' }}>Recipient address:</div>
          <div style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.invoice.customerContact}
          </div>
        </div>
      )
    }
  }

  renderTitle = () => {
    const { invoice } = this.state
    const userTime = CommonUtils.toUserTime(User.getUser().country, new Date(invoice.time))

    return (
      <div style={{ fontSize: '0.5cm' }}>
        <div id="invoice-date" className="pull-right">
          {CommonUtils.utcDateDMY(userTime)}
        </div>
        <div id="invoice-no">
          {'Invoice No.: '}
          {invoice._id}
        </div>
      </div>
    )
  }

  renderItems = () => {
    const { invoice, location: { currency } } = this.state

    const headerRow = (
      <tr className="active">
        <th className="text-center">Quantity</th>
        <th className="text-center">Item</th>
        <th className="text-center" style={{ whiteSpace: 'nowrap' }}>Unit price</th>
        <th className="text-center">Total</th>
      </tr>
    )

    const entryRows = invoice.entries.map((entry) => {
      const attributeLabels = this.state.attributeDefinitions.map(function(attribute) {
        return entry.attributes[attribute._id].label
      })
      .filter(function(label) {
        return label !== ''
      })

      let description = entry.name + ' (' + attributeLabels.join('; ') + ')'

      if (entry.materialName) {
        description += ' (' + entry.materialName + ')'
      }

      const price = Number(InvoiceUtils.getEntryUnitPriceWithoutVat(entry))

      return (
        <tr key={entry.productId} className="row-invoice-print">
          <td>{entry.entryAmount}</td>
          <td>{description}</td>
          <td className="text-right" style={{ whiteSpace: 'nowrap' }}>
            <div>
              {CommonUtils.formatDecimal(price)}
              {' '}
              {currency}
            </div>
          </td>
          <td className="text-right" style={{ whiteSpace: 'nowrap' }}>
            {CommonUtils.formatDecimal(InvoiceUtils.getEntryTotalWithoutVat(entry))}
            {' '}
            {currency}
          </td>
        </tr>
      )
    })

    const { book } = invoice

    if (book) {
      entryRows.push(<tr key="$book" className="row-book">
        <td>
          {book.quantity}
        </td>
        <td>
          {InvoiceUiUtils.getBookDesc(true)}
        </td>
        <td className="text-right" style={{ whiteSpace: 'nowrap' }}>
          <div>
            {CommonUtils.formatDecimal(book.price)}
            {' '}
            {currency}
          </div>
        </td>
        <td className="text-right" style={{ whiteSpace: 'nowrap' }}>
          {CommonUtils.formatDecimal(book.quantity * book.price)}
          {' '}
          {currency}
        </td>
      </tr>)
    }

    if (entryRows.length) {
      return (
        <table
          id="tbl-invoice-print"
          className="table table-bordered table-condensed"
        >
          <thead>{headerRow}</thead>
          <tbody>{entryRows}</tbody>
        </table>
      )
    }
  }

  renderShippingFee = () => {
    if (this.state.invoice.shipping) {
      return (
        <div className="text-right">
          <b>Shipping fee:</b>
          {' '}
          {CommonUtils.formatDecimal(this.state.invoice.shipping)}
          {' '}
          {this.state.location.currency}
        </div>
      )
    }
  }

  renderMarraCashCard = () => {
    if (this.state.invoice.marraCashCard) {
      const { currency } = this.state.location
      const settings = this.state.invoiceSettings

      const invoiceValues = InvoiceUtils.getValues(this.state.invoice, currency)
      const discount = InvoiceUtils.getMarraCashCardDiscount(settings, invoiceValues, false)
      const text = i18n.lt('en', 'invoice.marra-cash-card-discount', String(CommonUtils.round(discount)), currency)

      return <div id="lbl-marra-cash-card" className="text-right">{text}</div>
    }
  }

  renderTotals = () => {
    const { invoice, invoiceSettings, location: { currency } } = this.state
    const total = this.getTotal()

    const totals = InvoiceUtils.calculateTotals(invoiceSettings, total, invoice.export)

    return (
      <table
        id="invoice-print-totals"
        className="table table-bordered table-condensed table-striped"
        style={{ width: '7cm', marginTop: '0.5cm', marginLeft: 'auto' }}
      >
        <tbody>
          <tr>
            <td>Subtotal:</td>
            <td>
              {CommonUtils.formatDecimal(totals.subtotal)}
              {' '}
              {currency}
            </td>
          </tr>
          <tr>
            <td>VAT:</td>
            <td>
              {CommonUtils.formatDecimal(totals.vat)}
              {' '}
              {currency}
            </td>
          </tr>
          <tr>
            <td>Balance Due:</td>
            <td>
              {CommonUtils.formatDecimal(totals.total)}
              {' '}
              {currency}
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  renderActualPayments = () => {
    const { currency } = this.state.location

    const actualPaymentsString = InvoiceUtils.getSortedActualPayments(
      this.state.invoice.actualPayments, this.state.rates, currency, true,
    )
    .map(function(payment) {
      return payment.str
    })
    .join(', ')

    return (
      <div id="lbl-actual-payments" className="text-right">
        <b>Actual payments:</b>
        {' '}
        {actualPaymentsString}
      </div>
    )
  }

  renderDroitsDeTimbre = () => {
    if (this.state.invoice.droitsDeTimbre) {
      const ddt = this.getDroitsDeTimbre()

      if (ddt > 0) {
        const { currency } = this.state.location

        return (
          <div id="lbl-droits-de-timbre" className="text-right">
            <b>Droits de timbre:</b>
            {' '}
            {CommonUtils.round(ddt)}
            {' '}
            {currency}
          </div>
        )
      }
    }
  }

  renderFooter = () => {
    return (
      <div style={{ position: 'absolute', top: this.state.footerTop + 'cm', width: '100%' }}>
        <table id="invoice-print-footer" ref={this.footerRef}>
          <thead>
            <tr>
              <th>ADDRESS</th>
              <th>PHONE</th>
              <th>WEB</th>
              <th>IDENTIFIANTS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Derb Moulay El Ghali 19<br />
                Marrakech-Medina<br />
                Maroc 40000
              </td>
              <td>
                +212 524 390 323<br />
                +212 524 391 323
              </td>
              <td>
                www.alnour‐textiles.org<br />
                alnour.marrakech@gmail.com
              </td>
              <td>
                Patente: 45790343<br />
                D.A. No.: MA044324<br />
                Registre du commerce: 55861<br />
                Identifiant fiscal: 06527689<br />
                Affiliation a la CNSS: 9426546<br />
                ICE: 001530774000026
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  render() {
    if (!this.state.loaded) {
      return (
        <div>
          {i18n.t('common.loading')}
        </div>
      )
    }

    return (
      <div style={{ width: '18cm', height: '26cm', position: 'relative', fontSize: '0.35cm' }}>
        {this.renderHeader()}
        {this.renderLogo()}
        <div style={{ paddingTop: '3cm', paddingBottom: '0.8cm' }}>
          {this.renderCustomerName()}
          {this.renderCustomerContact()}
          {this.renderTitle()}
        </div>
        {this.renderItems()}
        {this.renderShippingFee()}
        {this.renderMarraCashCard()}
        <div ref={this.summaryRef} className="pull-right">
          {this.renderTotals()}
          {this.renderActualPayments()}
          {this.renderDroitsDeTimbre()}
        </div>
        {this.renderFooter()}
      </div>
    )
  }
}
