import { ComponentType, useRef } from 'react'
import React = require('react')

import { AttributeDefinition } from '../common/data-attribute-defs'
import { Category } from '../common/data-misc'
import { ProductDefinition } from '../common/data-product-defs'
import { TemplateDefinition } from '../common/data-template-defs'

import { i18n } from '../common/i18n'
import { getDefaultCancelButton, Modal, ModalButton } from './modal'
import { useOpenModalEvent } from './use-open-modal-event'

export interface EditModalProps {
  modalId: string,
  definition?: ProductDefinition | TemplateDefinition | AttributeDefinition,
  isNew?: boolean,
  template?: string,
  categories: Category[],
  attributeDefinitions: AttributeDefinition[],
  afterSave: () => void,
}

interface DefinitionEditModalProps extends EditModalProps {
  isProduction: boolean,
  editForm: ComponentType<any>,
}

export const DefinitionEditModal = (props: DefinitionEditModalProps) => {
  const [isVisible, close] = useOpenModalEvent(props.modalId)
  const formRef = useRef(null)

  if (!isVisible) {
    return <div />
  }

  const buttons: ModalButton[] = [
    {
      id: 'btn-save',
      title: i18n.t('action.save'),
      onClick: async () => {
        await formRef.current.onSave()
        await props.afterSave()
        close()
      },
    },
    getDefaultCancelButton(),
  ]

  const dialogClassName = props.isProduction ? 'edit-template-definition' : 'edit-product-definition'
  const EditForm = props.editForm

  return (
    <div>
      <Modal
        title={i18n.t(props.isProduction ? 'tpl-def.title' : 'prod-def.title')}
        closeModal={close}
        dialogClassName={dialogClassName}
        buttons={buttons}
      >
        <EditForm
          ref={formRef}
          definition={props.definition}
          isNew={props.isNew}
          template={props.template}
          categories={props.categories}
          attributeDefinitions={props.attributeDefinitions}
        />
      </Modal>
    </div>
  )
}
