import { ReactNode } from 'react'
import React = require('react')
import toastr from 'toastr/toastr'

import { Access } from '../common/access'
import { CommonUtils } from '../common/common-utils'
import { AttributeDefinition } from '../common/data-attribute-defs'
import { CartEntry } from '../common/data-carts'
import { Location } from '../common/data-locations'
import { Category } from '../common/data-misc'
import { ProductDefinition } from '../common/data-product-defs'
import { RawMaterial } from '../common/data-raw-materials'
import { getDataService, TemplateOps } from '../common/data-service'
import { TemplateDefinition } from '../common/data-template-defs'
import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { InventoryItem } from '../common/types'
import { AddAmountModal } from './add-amount-modal'
import { bindInput, bindProps } from './bind-utils'
import { EditInventoryModal } from './edit-inventory-modal'
import { InventoryTable } from './inventory-table'
import { MainMenu } from './main-menu'
import { TabMenu } from './tab-menu'
import { TransformModal } from './transform-modal'
import { User } from './user'
import { Utils } from './utils'
import { ValidationUtils } from './validation-utils'

type Mode = 'prod' | 'sales'

interface LocationTypeMenuProps {
  activeTab: string,
}

interface LocationMenuProps {
  activeTab: string,
  locations: Location[],
  locationType: string,
}

interface ListProps {
  locationType: string,
  locationId?: string,
}

interface ListState {
  loadedFor: 'none' | Mode,
  printView: boolean,
  definitionId: string | null,
  categories: Category[],
  unfilteredAttributeDefinitions: AttributeDefinition[],
  attributeDefinitions: AttributeDefinition[],
  locations: Location[],
  location: Location,
  definitions: (TemplateDefinition | ProductDefinition)[],
  templateDefinitions: TemplateDefinition[],
  items: InventoryItem[],
  processingCart: Record<string, boolean>,
  materials: RawMaterial[],
  addAmounts: Record<string, string>,
  visibleDefinitions: (TemplateDefinition | ProductDefinition)[],
  allProductNames: string[],
  cartEntries: CartEntry[],
}

const LocationTypeMenu = ({ activeTab }: LocationTypeMenuProps) => (
  <TabMenu
    menuId="inventory"
    pills={true}
    tabs={[
      { id: 'production', route: '/inventory/production' },
      { id: 'sales-points', route: '/inventory/sales-points' },
    ]}
    activeTab={activeTab}
  />
)

const LocationMenu = (props: LocationMenuProps) => (
  <TabMenu
    menuId="location"
    pills={true}
    tabs={props.locations.map((loc) => {
      return {
        id: loc._id,
        route: '/inventory/' + props.locationType + '/' + loc._id,
        name: loc.names[User.getLanguage()],
      }
    })}
    activeTab={props.activeTab}
  />
)

class List extends React.Component<ListProps, ListState> {
  state: ListState = {
    loadedFor: 'none',
    printView: false,
    definitionId: null,
    categories: [],
    unfilteredAttributeDefinitions: [],
    attributeDefinitions: [],
    locations: [],
    location: null,
    definitions: [],
    templateDefinitions: [],
    items: [],
    processingCart: {},
    materials: [],
    addAmounts: undefined,
    visibleDefinitions: undefined,
    allProductNames: undefined,
    cartEntries: undefined,
  }

  _isMounted = false

  componentDidMount() {
    this._isMounted = true
    this.loadEverything(this.props)
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidUpdate(prevProps: ListProps) {
    if (
      this.props.locationType !== prevProps.locationType ||
      this.props.locationId !== prevProps.locationId
    ) {
      this.loadEverything(this.props)
    }
  }

  isProduction = (props?) => {
    return (props || this.props).locationType === 'production'
  }

  getCurrencies = (props?) => {
    return this.isProduction(props) ? ['MAD'] : ['MAD', 'EUR']
  }

  getItemService = (props?) => {
    const DataService = getDataService()
    return this.isProduction(props) ? DataService.Templates : DataService.Products
  }

  loadEverythingNoArgs = () => {
    // A wrapper that ensures no arguments are passed to loadEverything.
    // May otherwise happen when used as a callback to a DataService operation.
    this.loadEverything()
    return null
  }

  // TODO: optimize - split items that need to be initialised once vs ones that need reloading
  loadEverything = (propsParam?) => {
    if (!this._isMounted) {
      return
    }

    const scrollPosition = window.scrollY
    this.setState({ loadedFor: 'none' })

    const props = propsParam || this.props

    const isProduction = this.isProduction(props)
    const DataService = getDataService()

    const definitionService = (isProduction ?
      DataService.TemplateDefinitions : DataService.ProductDefinitions
    )

    // Start requests
    const defPromise = definitionService.getAll() as Promise<(TemplateDefinition | ProductDefinition)[]>
    const catPromise = DataService.Categories.getAll()
    const attrDefPromise = DataService.AttributeDefinitions.getAll()
    const prodNamesPromise = DataService.ProductDefinitions.getNames()
    const materialsPromise = DataService.RawMaterials.getAll()

    DataService.Locations.getByType(props.locationType).then((locations) => {
      if (!this._isMounted) {
        return
      }

      const { locationId } = props
      let loc = null

      if (locationId) {
        loc = CommonUtils.findById(locations, locationId)
      }

      if (!loc) {
        // Select first location
        loc = locations[0]
        User.setLastLocation(this.props.locationType, loc._id)
      }

      // Got enough data to render location menu
      this.setState({ locations, location: loc })

      const itemService = this.getItemService(props)
      const itemPromise = itemService.getByLocation(loc._id)
      const cartPromise = DataService.Carts.getContents(loc._id)
      let tplDefPromise = null

      if (!isProduction) {
        tplDefPromise = DataService.TemplateDefinitions.getAll()
      }

      // Wait for all requests to complete
      return Promise.all([
        defPromise,
        catPromise,
        attrDefPromise,
        itemPromise,
        cartPromise,
        tplDefPromise,
        prodNamesPromise,
        materialsPromise,
      ]).then(
        ([
          definitions,
          categories,
          attributeDefinitions,
          allItems,
          cartEntries,
          templateDefinitions,
          allProductNames,
          materials,
        ]) => {
          if (!this._isMounted) {
            return
          }

          let visibleDefinitions = definitions

          if (!isProduction) {
            visibleDefinitions = definitions.filter(Utils.nonCustomDefinitionFilter)
          }

          const contextFilter = (
            isProduction ?
            CommonUtils.attributeContextFilters.templates :
            CommonUtils.attributeContextFilters.products
          )

          const filteredAttrDefs = attributeDefinitions.filter(contextFilter)

          const items = this.prepareItems(
            isProduction, allItems, categories,
            filteredAttrDefs, definitions, templateDefinitions,
          )

          const addAmounts = this.state.addAmounts || {}

          if (!isProduction) {
            // Note - addAmounts may also still have old values from a previous location.
            // Clean those up?
            items.forEach(function(product) {
              if (!(product._id in addAmounts)) {
                addAmounts[product._id] = '1'
              }
            })
          }

          this.setState(
            {
              loadedFor: isProduction ? 'prod' : 'sales',
              categories,
              unfilteredAttributeDefinitions: attributeDefinitions,
              attributeDefinitions: filteredAttrDefs,
              definitions,
              visibleDefinitions,
              templateDefinitions,
              items,
              cartEntries,
              addAmounts,
              allProductNames,
              materials,
            },
            function() {
              window.scrollTo(0, scrollPosition)
              EventBus.fire('inventory-rendered')
            },
          )
        },
      )
    })

    return null
  }

  reloadCart = () => {
    // TODO: test case that fails if this.props.locationId is used here
    const locationId = this.state.location._id

    const itemService = this.getItemService()
    const itemPromise = itemService.getByLocation(locationId)
    const cartPromise = getDataService().Carts.getContents(locationId)

    return Promise.all([itemPromise, cartPromise])
    .then(([allItems, cartEntries]) => {
      if (!this._isMounted) {
        return
      }

      const items = this.prepareItems(
        this.isProduction(),
        allItems,
        this.state.categories,
        this.state.attributeDefinitions,
        this.state.definitions,
        this.state.templateDefinitions,
      )

      this.setState(
        { items, cartEntries },
        EventBus.fireFunc('inventory-cart-reloaded'),
      )
    })
  }

  prepareItems = (
    isProduction,
    allItems,
    categories,
    filteredAttrDefs,
    definitions,
    templateDefinitions,
  ) => {
    const items = allItems.filter(function(item) {
      if (item.amount > 0) {
        return true
      }
      else if (isProduction) {
        // Show templates with amount 0 as long as they're not discontinued.
        const definition = CommonUtils.findById<(TemplateDefinition | ProductDefinition)>(definitions, item.definition)
        return !definition.discontinued
      }
    })

    return Utils.sortInventory(
      items, User.getLanguage(), categories, filteredAttrDefs,
      definitions, templateDefinitions, null,
    )
  }

  getDefinitionDropdown = () => {
    let customOption = null
    const isProduction = this.isProduction()

    if (!isProduction) {
      customOption = (
        <option value="custom">
          {'(' + i18n.t('common.custom').toLowerCase() + ')'}
        </option>
      )
    }

    const definitions = this.state.visibleDefinitions.filter((definition) => {
      return !(definition as ProductDefinition).template && !definition.discontinued
    })

    definitions.sort(function(def1, def2) {
      return def1.name.localeCompare(def2.name)
    })

    return (
      <select
        {...// TODO: remove?
        bindProps(this, ['definitionId'], {
          id: 'inp-definition',
          style: { verticalAlign: 'top' },
        })}
      >
        <option value="" />
        {definitions.map(function(definition) {
          return (
            <option key={definition._id} value={definition._id}>
              {definition.name}
            </option>
          )
        })}
        {customOption}
      </select>
    )
  }

  addToCart = async (item) => {
    try {
      try {
        const newProcessing = CommonUtils.clone(this.state.processingCart)
        newProcessing[item._id] = true
        this.setState({ processingCart: newProcessing })

        await getDataService().Carts.addAmount(item._id, this.state.addAmounts[item._id])
      }
      catch (error) {
        const validationErrors = ValidationUtils.getErrors(error)

        if (validationErrors) {
          Object.keys(validationErrors).forEach(function(fieldName) {
            const validationError = validationErrors[fieldName]

            if (fieldName === 'amountToAdd' && validationError.type === 'over-max') {
              toastr.error(i18n.t('cart.amount-over-max'))
            }
            else {
              toastr.error(i18n.t('validation.unexpected'))
            }
          })
        }

        throw error
      }

      EventBus.fire('cart-updated')
      return await this.reloadCart()
    }
    finally {
      const newProcessing = CommonUtils.clone(this.state.processingCart)
      delete newProcessing[item._id]
      this.setState({ processingCart: newProcessing })
    }
  }

  getActionCellContents = (
    definition: TemplateDefinition | ProductDefinition,
    item: InventoryItem,
    canAddToCart: boolean,
    canAdd: boolean,
    canTransform: boolean,
    canEdit: boolean,
    canDelete: boolean,
  ) => {
    let addToCartElements = null
    let addAmountButton = null
    let addAmountModal = null
    let transformButton = null
    let transformModal = null
    let editButton = null
    let editModal = null
    let deleteButton = null
    let archiveButton = null

    if (canAddToCart) {
      addToCartElements = [
        bindInput(this, ['addAmounts', item._id], {
          key: 'input',
          className: 'inp-add-to-cart',
          style: { width: '2em' },
          validator: Utils.nonNegativeIntegerValidator,
        }),
      ]

      if (item._id in this.state.processingCart) {
        addToCartElements.push(i18n.t('common.loading'))
      }
      else {
        addToCartElements.push(
          <img
            key="button"
            className="table-btn btn-add-to-cart"
            title={i18n.t('inventory.add-to-cart')}
            src="img/cart.png"
            onClick={() => {
              this.addToCart(item)
            }}
          />,
        )
      }
    }

    if (canAdd) {
      if (this.isProduction() || !(definition as ProductDefinition).template) {
        const addAmountModalId = 'add-amount-modal-' + item._id

        addAmountButton = (
          <img
            className="table-btn btn-add-amount"
            title={i18n.t('inventory.add-amount')}
            src="img/add.png"
            onClick={function() {
              EventBus.fire('open-modal', { modalId: addAmountModalId })
            }}
          />
        )

        const DataService = getDataService()

        addAmountModal = (
          <AddAmountModal
            modalId={addAmountModalId}
            definition={definition}
            item={item}
            itemService={this.isProduction() ? DataService.Templates : DataService.Products}
            afterSave={this.loadEverythingNoArgs}
          />
        )
      }
    }

    if (canTransform) {
      const transformModalId = 'transform-modal-' + item._id

      transformButton = (
        <img
          className="table-btn btn-transform"
          title={i18n.t('action.transform')}
          src="img/transform.png"
          onClick={function() {
            EventBus.fire('open-modal', { modalId: transformModalId })
          }}
        />
      )

      const filteredAttributes = this.state.unfilteredAttributeDefinitions.filter(
        CommonUtils.attributeContextFilters.templateProductDiff,
      )

      transformModal = (
        <TransformModal
          modalId={transformModalId}
          templateDefinition={definition as TemplateDefinition}
          attributeDefinitions={filteredAttributes}
          template={item}
          afterSave={this.loadEverythingNoArgs}
        />
      )
    }

    if (canEdit) {
      const editModalId = 'edit-modal-' + item._id

      editButton = (
        <img
          className="table-btn btn-edit"
          title={i18n.t('action.edit')}
          src="img/edit.png"
          onClick={function() {
            EventBus.fire('open-modal', { modalId: editModalId })
          }}
        />
      )

      editModal = (
        <EditInventoryModal
          modalId={editModalId}
          isProduction={this.isProduction()}
          definition={definition}
          isCustom={(definition as ProductDefinition).isCustom}
          item={item}
          itemService={this.getItemService()}
          currencies={this.getCurrencies()}
          categories={this.state.categories}
          attributeDefinitions={this.state.attributeDefinitions}
          templateDefinitions={this.state.templateDefinitions}
          location={this.state.location}
          allProductNames={this.state.allProductNames}
          materials={this.state.materials}
          // TODO: possible to optimize by just adding the
          // new object from the edit form's state
          reloadParent={this.loadEverythingNoArgs}
        />
      )
    }

    if (canDelete) {
      deleteButton = (
        <img
          className="table-btn btn-delete"
          title={i18n.t('action.delete')}
          src="img/delete.png"
          onClick={() => {
            Utils.confirmTr('confirm.delete.item').then((confirmed) => {
              if (confirmed) {
                const templateService = this.getItemService() as TemplateOps

                return templateService.delete(item._id)
                // TODO: just remove it from the loaded list?
                .then(this.loadEverythingNoArgs)
              }
            })
          }}
        />
      )

      archiveButton = this.renderArchiveButton(item._id)
    }

    return (
      <div>
        {addToCartElements}
        {addAmountButton}
        {transformButton}
        {editButton}
        {deleteButton}
        {archiveButton}
        {addAmountModal}
        {transformModal}
        {editModal}
      </div>
    )
  }

  getAddNewElement = () => {
    if (!this.canAdd()) {
      return null
    }

    let addModal = null
    let definition = null
    let isCustom = false

    if (this.state.definitionId === 'custom') {
      isCustom = true
    }
    else {
      definition = CommonUtils.findById(this.state.definitions, this.state.definitionId)
    }

    const canAdd = isCustom || definition

    if (canAdd) {
      addModal = (
        <EditInventoryModal
          modalId="add-modal"
          isProduction={this.isProduction()}
          definition={definition}
          isCustom={isCustom}
          isNew={true}
          itemService={this.getItemService()}
          currencies={this.getCurrencies()}
          categories={this.state.categories}
          attributeDefinitions={this.state.attributeDefinitions}
          templateDefinitions={this.state.templateDefinitions}
          location={this.state.location}
          allProductNames={this.state.allProductNames}
          materials={this.state.materials}
          // TODO: only load the added item?
          reloadParent={this.loadEverythingNoArgs}
        />
      )
    }

    return (
      <div>
        <button
          id="btn-add-new"
          style={{ marginBottom: 5 }}
          className={canAdd ? '' : 'disabled'}
          disabled={!canAdd}
          onClick={function() {
            EventBus.fire('open-modal', { modalId: 'add-modal' })
          }}
        >
          {i18n.t('action.add-new')}
        </button>
        {' '}
        {this.getDefinitionDropdown()}
        {addModal}
      </div>
    )
  }

  getCartInfo = (itemId) => {
    const cartEntry = CommonUtils.findByField(this.state.cartEntries, 'product', itemId)

    if (cartEntry) {
      return [
        ' ',
        <a
          key="link"
          className="lnk-in-cart"
          href={'#/cart/' + this.state.location._id}
          // Using inline-block as a text wrapping guide
          style={{ cursor: 'pointer', display: 'inline-block' }}
        >
          (
          {i18n.t('inventory.in-cart', cartEntry.amount.toString())}
          )
        </a>,
      ]
    }

    return null
  }

  canAdd = () => {
    const isProduction = this.isProduction(this.props)
    const loc = this.state.location

    if (isProduction) {
      return Access.addTemplates(User.getUser(), loc)
    }
    else {
      return Access.addProducts(User.getUser(), loc)
    }
  }

  print = () => {
    this.setState({ printView: true }, Utils.print)
  }

  leavePrintMode = () => {
    this.setState({ printView: false })
  }

  renderArchiveButton = (itemId) => {
    return (
      <img
        className="table-btn btn-archive"
        title={i18n.t('action.archive')}
        src="img/archive.png"
        onClick={() => {
          Utils.confirmTr('confirm.archive.item').then((confirmed) => {
            if (confirmed) {
              const templateService = this.getItemService() as TemplateOps

              return templateService.archive(itemId)
              .then(this.loadEverythingNoArgs)
            }
          })
        }}
      />
    )
  }

  renderMenus = () => {
    if (this.state.printView) {
      return null
    }

    const menus: ReactNode[] = [
      <MainMenu key="main" activeTab="inventory" />,
      <LocationTypeMenu key="locType" activeTab={this.props.locationType} />,
    ]

    if (this.state.location) {
      const activeLocations = this.state.locations.filter(function(loc) {
        return !loc.deactivated
      })

      activeLocations.sort(function(loc1, loc2) {
        // If storage and shop locations are mixed, show storage locations first.
        let result = (loc1.type === 'storage' ? 0 : 1) - (loc2.type === 'storage' ? 0 : 1)

        if (result === 0) {
          result = loc1.names[User.getLanguage()].localeCompare(loc2.names[User.getLanguage()])
        }

        return result
      })

      menus.push(
        <LocationMenu
          key="loc"
          activeTab={this.state.location._id}
          locations={activeLocations}
          locationType={this.props.locationType}
        />,
      )
    }

    return menus
  }

  renderButtons = () => {
    if (this.state.printView) {
      return (
        <button
          id="btn-leave-print"
          className="no-print"
          onClick={this.leavePrintMode}
          style={{ marginBottom: '0.5em' }}
        >
          {i18n.t('common.leave-print-mode')}
        </button>
      )
    }

    const printButton = (
      <button onClick={this.print}>
        {i18n.t('common.print')}
      </button>
    )

    return (
      <div>
        <div style={{ float: 'right', marginBottom: 5 }}>
          {printButton}
        </div>
        {this.getAddNewElement()}
      </div>
    )
  }

  renderPrintHeader = () => {
    if (this.state.printView) {
      const language = User.getLanguage()
      const locationName = this.state.location.names[language]

      return (
        <h3 style={{ fontWeight: 'bold' }}>
          {i18n.t('menu.main.inventory')}
          {' - '}
          {locationName}
        </h3>
      )
    }
  }

  render() {
    const isProduction = this.isProduction(this.props)
    const expectedLoadedFor: Mode = isProduction ? 'prod' : 'sales'

    if (this.state.loadedFor !== expectedLoadedFor) {
      return (
        <div>
          {this.renderMenus()}
          {i18n.t('common.loading')}
        </div>
      )
    }

    const loc = this.state.location
    const user = User.getUser()

    const canSell = Access.sales(user, loc)
    const canSendTransfer = Access.transfer(user, loc)

    const canEditNote = Access.editInventoryNote(user, loc)
    const canEditFull = Access.editFullInventory(user, loc)
    const canEditAmountThreshold = Access.editTemplateAmountThreshold(user) && isProduction

    const canAddToCart = canSell || canSendTransfer
    const canAdd = this.canAdd()

    const canTransform = Access.transform(user, loc)
    const canEdit = canEditNote || canEditFull || canEditAmountThreshold
    const canDelete = Access.deleteInventory(user, loc)

    const hasPermissions = canAddToCart || canAdd || canTransform || canEdit || canDelete

    return (
      <div>
        {this.renderMenus()}
        {this.renderButtons()}
        {this.renderPrintHeader()}
        <InventoryTable
          id="tbl-inv"
          rowClassName="row-inv"
          hasPermissions={hasPermissions}
          attributeDefinitions={this.state.attributeDefinitions}
          locations={[loc]}
          currency={isProduction ? 'MAD' : loc.currency}
          categories={this.state.categories}
          definitions={this.state.definitions}
          items={this.state.items}
          templateDefinitions={this.state.templateDefinitions}
          getActionCellContents={(definition, item) => this.getActionCellContents(
            definition,
            item,
            canAddToCart,
            canAdd,
            canTransform,
            canEdit,
            canDelete,
          )}
          hideCustomCategoryFilter={isProduction}
          filterSetKey="inventory"
          modifyColumns={(columnConf) => {
            if (loc.showOrderId) {
              const index = Utils.findIndexByField(columnConf, 'id', 'category') + 1

              columnConf.splice(index, 0, {
                id: 'orderId',
                header: i18n.t('inventory.order-id'),
                getCellContents: function(rowData) {
                  const prodDef = rowData.definition as ProductDefinition
                  return prodDef.isCustom ? prodDef.orderId : null
                },
              })
            }

            const amountColumn = CommonUtils.findByField(columnConf, 'id', 'amount')

            amountColumn.getCellContents = (rowData) => {
              // TODO: optimize: skip cart info in production
              return (
                <div>
                  {rowData.amount}
                  {this.getCartInfo(rowData.id)}
                </div>
              )
            }
          }}
          printView={this.state.printView}
          limitRows={true}
          isProduction={isProduction}
        />
      </div>
    )
  }
}

export const Inventory = { List }
