import React = require('react')

import { GridSelection } from './grid-selection'

interface YearSelectionProps {
  start?: string,
  end?: string,
  value?: string,
  onChange: (year: string) => void,
}

export class YearSelection extends React.Component<YearSelectionProps> {
  getOptions = () => {
    const opts = []
    const intStart = parseInt(this.props.start)
    const intEnd = parseInt(this.props.end)
    for (let intYear = intEnd; intYear >= intStart; intYear -= 1) {
      const val = intYear.toString()

      opts.push(
        <option key={val} value={val}>
          {val}
        </option>,
      )
    }

    return opts
  }

  render() {
    const props = {
      type: 'primary',
      className: 'grid-selection-year',
      value: this.props.value,
      onChange: this.props.onChange,
    }

    return (
      <GridSelection {...props}>
        {this.getOptions()}
      </GridSelection>
    )
  }
}
