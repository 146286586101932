import { AllHTMLAttributes, Attributes, ChangeEvent, ReactNode } from 'react'
import React = require('react')

import { AccountingUtils } from '../common/accounting-utils'
import { CommonUtils } from '../common/common-utils'
import { getDataService } from '../common/data-service'
import { AugmentedCategory } from '../common/expense-category-path-builder'
import { i18n } from '../common/i18n'
import { CheckboxProps, Comp } from './comp'
import { LoadingIcon } from './loading-icon'
import { TableButton } from './table-button'
import { User } from './user'

interface OptSetProps {
  categories: AugmentedCategory[],
  selectedCategoryIds: Record<string, string>,
  activeCategoryIds: string[],
  onCheck: (value: string) => void,
  onUncheck: (value: string) => void,
}

interface DropdownSetProps {
  categories: AugmentedCategory[],
  onChange: (selectedCategoryIds: Record<string, string>) => void,
  selectedCategoryIds: Record<string, string>,
  activeCategoryIds: string[],
}

interface DropdownSetState {
  orderedSelectedCategoryIds: string[],
}

interface FormProps {
  values: Record<string, string>,
  onChange: (values: Record<string, string>) => void,
  getActiveOptions: () => any,
}

interface FormState {
  categories: AugmentedCategory[],
}

interface SummaryProps {
  values: object,
}

interface SummaryState {
  categories: AugmentedCategory[] | null,
}

const { Checkbox } = Comp
const { DeleteButton } = TableButton

const DROPDOWN_THRESHOLD = 15

const hasSelectedAncestor = (selectedCategoryIds: Record<string, string>, categoryId: string) => {
  return Object.keys(selectedCategoryIds).some((selectedCategoryId) => {
    return AccountingUtils.expenseCategoryIdStartsWith(categoryId, selectedCategoryId) && selectedCategoryId !== categoryId
  })
}

const isActive = (activeCategoryIds: string[], categoryId: string) => {
  return activeCategoryIds.some((activeCategoryId) => {
    return AccountingUtils.expenseCategoryIdStartsWith(activeCategoryId, categoryId)
  })
}

class OptSet extends React.Component<OptSetProps> {
  onChange = (ev: ChangeEvent<HTMLInputElement>) => {
    if (ev.target.checked) {
      this.props.onCheck(ev.target.value)
    } else {
      this.props.onUncheck(ev.target.value)
    }
  }

  isSelected = (categoryId: string) => {
    return this.props.selectedCategoryIds[categoryId] ? true : false
  }

  renderInput = (category: AugmentedCategory) => {
    const lang = User.getLanguage()
    const isSelected = this.isSelected(category._id)
    const hasAncestor = hasSelectedAncestor(this.props.selectedCategoryIds, category._id)
    const props: CheckboxProps & Attributes = {
      key: category._id,
      value: category._id,
      label: category.paths[lang],
      checked: isSelected || hasAncestor,
      disabled: hasAncestor || !isActive(this.props.activeCategoryIds, category._id),
      onChange: this.onChange,
    }
    if (props.disabled) {
      props.className = 'filter-option inactive'
    }
    return <Checkbox {...props} />
  }

  render() {
    return (
      <div className="expense-category-filter-form">
        {this.props.categories.map(this.renderInput)}
      </div>
    )
  }
}

class DropdownSet extends React.Component<DropdownSetProps, DropdownSetState> {
  constructor(props: DropdownSetProps) {
    super(props)
    const selectedCategoryIds = Object.keys(props.selectedCategoryIds)
    if (selectedCategoryIds.length === 0) {
      selectedCategoryIds.push(null)
    }
    this.state = { orderedSelectedCategoryIds: selectedCategoryIds }
  }

  setOrderedSelectedCategoryIds = (ids: string[]) => {
    this.setState({ orderedSelectedCategoryIds: ids })
  }

  cloneSelectedCategoryIds = () => {
    return CommonUtils.clone(this.props.selectedCategoryIds)
  }

  cloneOrderedSelectedCategoryIds = () => {
    return CommonUtils.clone(this.state.orderedSelectedCategoryIds)
  }

  swapSelectedCategoryId = (oldId: string, newId: string) => {
    const selectedCategoryIds = this.cloneSelectedCategoryIds()
    delete selectedCategoryIds[oldId]
    selectedCategoryIds[newId] = newId
    this.props.onChange(selectedCategoryIds)
  }

  unselectCategoryId = (id: string) => {
    const selectedCategoryIds = this.cloneSelectedCategoryIds()
    delete selectedCategoryIds[id]
    this.props.onChange(selectedCategoryIds)
  }

  setOrderedSelectedCategoryId = (idx: number, id: string) => {
    const orderedSelectedCategoryIds = this.cloneOrderedSelectedCategoryIds()
    orderedSelectedCategoryIds[idx] = id
    this.setOrderedSelectedCategoryIds(orderedSelectedCategoryIds)
  }

  removeOrderedSelectedCategoryId = (idx: number) => {
    const orderedSelectedCategoryIds = this.cloneOrderedSelectedCategoryIds()
    orderedSelectedCategoryIds.splice(idx, 1)
    this.setOrderedSelectedCategoryIds(orderedSelectedCategoryIds)
  }

  onDropdownChange = (idx: number, val: string) => {
    const oldId = this.state.orderedSelectedCategoryIds[idx]
    if (val !== oldId) {
      if (val !== '') {
        this.swapSelectedCategoryId(oldId, val)
      } else {
        this.unselectCategoryId(oldId)
      }
      this.setOrderedSelectedCategoryId(idx, val)
    }
  }

  addDropdown = () => {
    const orderedSelectedCategoryIds = this.cloneOrderedSelectedCategoryIds()
    orderedSelectedCategoryIds.push(null)
    this.setOrderedSelectedCategoryIds(orderedSelectedCategoryIds)
  }

  removeDropdown = (idx: number) => {
    const categoryId = this.state.orderedSelectedCategoryIds[idx]
    this.unselectCategoryId(categoryId)
    this.removeOrderedSelectedCategoryId(idx)
  }

  renderOption = (category: AugmentedCategory) => {
    const lang = User.getLanguage()

    const props: Attributes & AllHTMLAttributes<HTMLOptionElement> = {
      key: category._id,
      value: String(category._id),
    }

    if (
      !isActive(this.props.activeCategoryIds, category._id) ||
      hasSelectedAncestor(this.props.selectedCategoryIds, category._id)
    ) {
      props.className = 'inactive'
    }

    return (
      <option {...props}>
        {category.paths[lang]}
      </option>
    )
  }

  renderSelect = (categoryId: string, idx: number) => {
    const props = {
      className: 'filter filter-narrow',
      value: categoryId || '',
      onChange: (ev) => {
        this.onDropdownChange(idx, ev.target.value)
      },
    }
    return (
      <select {...props}>
        <option key={null} value="" />
        {this.props.categories.map(this.renderOption)}
      </select>
    )
  }

  renderRemoveButton = (idx: number) => {
    return (
      <DeleteButton
        onClick={() => {
          this.removeDropdown(idx)
        }}
      />
    )
  }

  renderDropdown = (categoryId: string, idx: number) => {
    return (
      <div key={idx} className="filter-dropdown">
        {this.renderSelect(categoryId, idx)}
        {' '}
        {this.renderRemoveButton(idx)}
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.state.orderedSelectedCategoryIds.map(this.renderDropdown)}
        <a className="add-link" onClick={this.addDropdown}>
          {i18n.t('action.add')}
        </a>
      </div>
    )
  }
}

class Form extends React.Component<FormProps, FormState> {
  state: FormState = { categories: null }

  componentDidMount() {
    getDataService().ExpenseCategories.getAll()
    .then(this.setCategories)
  }

  setCategories = (categories: AugmentedCategory[]) => {
    this.setState({ categories })
  }

  selectCategory = (id: string) => {
    const values = CommonUtils.clone(this.props.values)
    values[id] = id
    this.props.onChange(values)
  }

  deselectCategory = (id: string) => {
    const values = CommonUtils.clone(this.props.values)
    delete values[id]
    this.props.onChange(values)
  }

  getInputComponentProps = () => {
    const activeOptions = this.props.getActiveOptions()
    return {
      categories: this.state.categories,
      selectedCategoryIds: this.props.values,
      activeCategoryIds: activeOptions.toArray(),
    }
  }

  renderOptSet = () => {
    const props: any = this.getInputComponentProps()
    props.onCheck = this.selectCategory
    props.onUncheck = this.deselectCategory
    return <OptSet {...props} />
  }

  renderDropdownSet = () => {
    const props: any = this.getInputComponentProps()
    props.onChange = this.props.onChange
    return <DropdownSet {...props} />
  }

  render() {
    if (this.state.categories) {
      const list = this.state.categories.length < DROPDOWN_THRESHOLD ? this.renderOptSet() : this.renderDropdownSet()
      return (
        <div>
          <div className="filter-intro">
            {i18n.t('filters.predefined.desc')}
          </div>
          {list}
        </div>
      )
    } else {
      return <LoadingIcon />
    }
  }
}

class Summary extends React.Component<SummaryProps, SummaryState> {
  state: SummaryState = { categories: null }

  componentDidMount() {
    getDataService().ExpenseCategories.getAll()
    .then(this.setCategories)
  }

  setCategories = (categories: AugmentedCategory[]) => {
    this.setState({ categories })
  }

  getCategoryPath = (id: string) => {
    const { categories } = this.state
    const category: AugmentedCategory = CommonUtils.findById(categories, id)
    const lang = User.getLanguage()
    return <b key={id}>{category.paths[lang]}</b>
  }

  buildSummary = (parts: ReactNode[], pathComp: ReactNode) => {
    if (parts.length > 0) {
      parts.push(' ' + i18n.t('common.or') + ' ')
    }
    parts.push(pathComp)
    return parts
  }

  render() {
    if (this.state.categories) {
      const selectedCategoryIds = Object.keys(this.props.values)
      return (
        <span>
          {selectedCategoryIds.map(this.getCategoryPath).reduce(this.buildSummary, [])}
        </span>
      )
    } else {
      return <span />
    }
  }
}

export const ExpenseCategoryFilter = {
  Form,
  Summary,
}
