import React = require('react')

import { CommonUtils } from '../common/common-utils'
import { getDataService } from '../common/data-service'
import { Currency } from '../common/invoice-utils'
import { EditFormProps, DefinitionEditForm } from './definition-edit-form'
import { EditModalProps, DefinitionEditModal } from './definition-edit-modal'
import { DefinitionList } from './definition-list'

const currencies: Currency[] = ['MAD', 'EUR']

const EditForm = React.forwardRef((props: EditFormProps, ref: React.ForwardedRef<DefinitionEditForm>) => (
  <DefinitionEditForm
    ref={ref}
    {...props}
    isProduction={false}
    definitionService={getDataService().ProductDefinitions}
    currencies={currencies}
    menuTabId="products"
  />
))

const EditModal = (props: EditModalProps) => {
  return <DefinitionEditModal {...props} isProduction={false} editForm={EditForm} />
}

const List = () => {
  const definitionService = getDataService().ProductDefinitions

  return (
    <DefinitionList
      isProduction={false}
      menuTabId="products"
      editModal={EditModal}
      definitionService={definitionService}
      getAllCommand={definitionService.getNonCustom}
      currencies={currencies}
      attributeContextFilter={CommonUtils.attributeContextFilters.products}
    />
  )
}

export const ProductDefinitions = { List, EditModal }
