import { CommonUtils } from './common-utils'
import { Location } from './data-locations'
import { UiSessionUser } from './data-users'
import { Enums, Role } from './enums'

function hasRole(user: UiSessionUser, role: Role) {
  if (!user) {
    return false
  }

  if (!user.role) {
    throw new Error('User has no role')
  }

  if (!(role in Enums.roles)) {
    throw new Error('Invalid role: ' + role)
  }

  return user.role === role
}

function isAdmin(user: UiSessionUser) {
  return hasRole(user, 'admin')
}

function isPowerUserOrAdmin(user: UiSessionUser) {
  return hasRole(user, 'powerUser') || isAdmin(user)
}

function isAssistant(user: UiSessionUser) {
  return hasRole(user, 'assistant')
}

function isAssistantOrHigher(user: UiSessionUser) {
  return isAssistant(user) || isPowerUserOrAdmin(user)
}

function isRawMaterialManager(user: UiSessionUser) {
  return hasRole(user, 'rawMaterials')
}

function isSecretary(user: UiSessionUser) {
  return hasRole(user, 'secretary')
}

function isProductionManager(user: UiSessionUser) {
  return hasRole(user, 'production')
}

function isProductionManagerOrAdmin(user: UiSessionUser) {
  return isProductionManager(user) || isAdmin(user);
}

function isSalesStaff(user: UiSessionUser) {
  return hasRole(user, 'sales')
}

function isShopManager(user: UiSessionUser) {
  return hasRole(user, 'shopManager')
}

function isAccountant(user: UiSessionUser) {
  return hasRole(user, 'accountant')
}

function isTailor(user: UiSessionUser) {
  return hasRole(user, 'tailor');
}

function locationAllowedForUser(locationId: string, user: UiSessionUser) {
  if (!locationId) {
    throw new Error('Location ID is required')
  }

  let isAllowed = false
  if (user.allowedLocations) {
    /*
    A user's allowed locations can only include sales points i.e
    locations whose type is either 'storage' or 'shop'
    */
    isAllowed = CommonUtils.arrayContains(user.allowedLocations, locationId)
  }

  return isAllowed
}

function canSellAtShop(user: UiSessionUser, loc: Location) {
  if (isPowerUserOrAdmin(user)) {
    return true
  }
  else if (isSalesStaff(user) || isShopManager(user) || isAssistant(user)) {
    return locationAllowedForUser(loc._id, user)
  }
  else {
    return false
  }
}

const manageData = isAdmin

const manageAttributes = function (user: UiSessionUser) {
  return isAdmin(user) || isSecretary(user)
}

export const Access = {
  isLocationBased: function (role) {
    if (!(role in Enums.roles)) {
      throw new Error('Invalid role: ' + role)
    }

    return CommonUtils.arrayContains(Enums.locationBasedRoles, role)
  },

  viewErrors: isAdmin,
  manageUsers: isAdmin,
  manageData,
  manageAttributes,

  seeDataMenu: function (user: UiSessionUser) {
    return manageData(user) || manageAttributes(user)
  },

  manageRawMaterials: function (user: UiSessionUser) {
    return isAssistantOrHigher(user) || isRawMaterialManager(user)
  },

  manageRawMaterialsExtended: isPowerUserOrAdmin,

  editRawMaterialAmountThreshold: function (user: UiSessionUser) {
    return isAssistantOrHigher(user) || isRawMaterialManager(user) || isProductionManager(user)
  },

  manageDefinitions: function (user: UiSessionUser) {
    return isAssistantOrHigher(user) || isSecretary(user)
  },

  manageProductionReport: function (user: UiSessionUser) {
    return isAssistantOrHigher(user) || isProductionManager(user)
  },

  cart: function (user: UiSessionUser) {
    return (
      isAssistantOrHigher(user) || isSecretary(user) ||
      isProductionManager(user) || isSalesStaff(user) || isShopManager(user)
    )
  },

  addTemplates: function (user: UiSessionUser, loc: Location) {
    if (loc.type === 'production') {
      return isAssistantOrHigher(user) || isSecretary(user) || isProductionManager(user)
    }
    else {
      return false
    }
  },

  editTemplateAmountThreshold: function (user: UiSessionUser) {
    return isAssistantOrHigher(user) || isRawMaterialManager(user) || isProductionManager(user)
  },

  addProducts: function (user: UiSessionUser, loc: Location) {
    // Note: caller must also check that the definition is not linked to a template.

    if (loc.type === 'storage') {
      return isAssistantOrHigher(user) || isProductionManager(user)
    }
    else {
      return false
    }
  },

  seeInventory: function (user: UiSessionUser) {
    return !isTailor(user);
  },

  editFullInventory: function (user: UiSessionUser, loc: Location) {
    if (isPowerUserOrAdmin(user)) {
      return true
    } else if (isAssistant(user)) {
      return locationAllowedForUser(loc._id, user)
    } else if (isSecretary(user)) {
      return loc.type === 'production' || loc.type === 'storage'
    } else if (isShopManager(user)) {
      return locationAllowedForUser(loc._id, user)
    } else {
      return false
    }
  },

  setProductRawMaterial: function (user: UiSessionUser) {
    return isPowerUserOrAdmin(user)
  },

  convertProducts: function (user: UiSessionUser) {
    return isPowerUserOrAdmin(user) || isProductionManager(user)
  },

  archiveInvoices: function (user: UiSessionUser) {
    return isAdmin(user)
  },

  editInventoryNote: function (user: UiSessionUser, loc: Location) {
    if (loc.type === 'production') {
      return isAssistantOrHigher(user) || isSecretary(user) || isProductionManager(user) || isShopManager(user)
    } else if (loc.type === 'storage') {
      return isAssistantOrHigher(user) || isProductionManager(user) || isShopManager(user)
    } else if (loc.type === 'shop') {
      return canSellAtShop(user, loc)
    } else {
      return false
    }
  },

  deleteInventory: function (user: UiSessionUser, loc: Location) {
    if (loc.type === 'production') {
      return isAssistantOrHigher(user) || isSecretary(user) || isProductionManager(user)
    } else {
      return false
    }
  },

  transform: function (user: UiSessionUser, loc: Location) {
    if (loc.type === 'production') {
      return isAssistantOrHigher(user) || isSecretary(user) || isProductionManager(user) || isShopManager(user)
    } else {
      return false
    }
  },

  sales: function (user: UiSessionUser, loc: Location) {
    if (loc.type === 'storage') {
      return isAssistantOrHigher(user) || isProductionManager(user) || isShopManager(user)
    } else if (loc.type === 'shop') {
      return canSellAtShop(user, loc)
    } else {
      return false
    }
  },

  editFullInvoice: function (user: UiSessionUser, loc: Location) {
    if (isPowerUserOrAdmin(user)) {
      return true
    } else if (isAssistant(user) || isShopManager(user)) {
      return locationAllowedForUser(loc._id, user)
    } else {
      return false
    }
  },

  transfer: function (user: UiSessionUser, loc: Location) {
    if (loc.type === 'storage') {
      return isAssistantOrHigher(user) || isSecretary(user) || isProductionManager(user) || isShopManager(user)
    } else if (loc.type === 'shop') {
      return canSellAtShop(user, loc)
    } else {
      return false
    }
  },

  seeTransfers: function (user: UiSessionUser) {
    return !isTailor(user);
  },

  manageCustomers: isPowerUserOrAdmin,

  accounting: function (user: UiSessionUser) {
    return isAccountant(user) || isSecretary(user) || isAssistantOrHigher(user)
  },

  updateMaterialStock: function (user: UiSessionUser) {
    return isProductionManagerOrAdmin(user);
  },

  manageMaterialOrders: function (user: UiSessionUser) {
    return !isTailor(user);
  },

  seeProductionReports: function (user: UiSessionUser) {
    return !isTailor(user);
  },

  createTailorOrders: function (user: UiSessionUser) {
    return (isAdmin(user) || isProductionManager(user));
  },

  manageTailorOrders: function (user: UiSessionUser) {
    return (isTailor(user) || isAdmin(user) || isProductionManager(user));
  }

}
