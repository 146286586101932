import { useCallback, useEffect, useRef, useState } from 'react'

import { EventBus } from '../common/event-bus'

const useIsMounted = () => {
  const ref = useRef(false)

  useEffect(() => {
    ref.current = true

    return () => {
      ref.current = false
    }
  }, [])

  return useCallback(() => ref.current, [])
}

export const useOpenModalEvent = (modalId: string) => {
  const [isVisible, setVisible] = useState(false)
  const isMounted = useIsMounted()

  const handleEvent = useCallback((payload) => {
    if (payload.modalId === modalId) {
      setVisible(true)
    }
  }, [modalId])

  useEffect(() => {
    EventBus.on('open-modal', handleEvent)

    return () => EventBus.off('open-modal', handleEvent)
  }, [handleEvent])

  const close = () => {
    if (isMounted()) {
      setVisible(false)
    }
  }

  return [isVisible, close] as const
}
