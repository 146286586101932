import React = require('react')

import { Access } from '../common/access'
import { TabMenu } from './tab-menu'
import { User } from './user'

interface ReportsMenuProps {
  activeTab: string,
}

export class ReportsMenu extends React.Component<ReportsMenuProps> {
  render() {
    const tabs = [
      { id: 'sales', route: '/reports/sales' },
      { id: 'production', route: '/reports/production' },
      { id: 'inventory', route: '/reports/inventory' },
      { id: 'material-consumption', route: '/reports/material-consumption' },
      { id: 'correction', route: '/reports/correction' },
      { id: 'invoice-archive', route: '/reports/invoice-archive' },
    ]

    if (Access.viewErrors(User.getUser())) {
      tabs.push({ id: 'errors', route: '/reports/errors' })
    }

    return (
      <TabMenu
        menuId="reports"
        pills={true}
        tabs={tabs}
        activeTab={this.props.activeTab}
      />
    )
  }
}
