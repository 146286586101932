import { CSSProperties } from 'react'
import React = require('react')
import toastr from 'toastr/toastr'

import { Access } from '../common/access'
import { CommonUtils } from '../common/common-utils'
import { AttributeDefinition } from '../common/data-attribute-defs'
import { Customer } from '../common/data-customers'
import { ActualPaymentKey, ActualPaymentsInput, Invoice } from '../common/data-invoices'
import { Location } from '../common/data-locations'
import { Category } from '../common/data-misc'
import { ProductDefinition } from '../common/data-product-defs'
import { Product } from '../common/data-products'
import { getDataService } from '../common/data-service'
import { TemplateDefinition } from '../common/data-template-defs'
import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'

import {
  Currency,
  InvoiceSettings,
  InvoiceUtils,
  InvoiceValues,
  UiInvoiceBook,
  UiInvoiceEntry,
} from '../common/invoice-utils'

import { bindCheckbox, bindInput, bindProps } from './bind-utils'
import { ChooseItemModal, ChooseItemModalParams } from './choose-item-modal'
import { InvoiceUiUtils, Rates } from './invoice-ui-utils'
import { LoadingButton } from './loading-button'
import { MainMenu } from './main-menu'
import { ReportsMenu } from './reports-menu'
import { router } from './router'
import { SearchableCustomer } from './searchable-customer'
import { Column, UiUtils } from './ui-utils'
import { User } from './user'
import { Utils } from './utils'
import { ValidationErrors, ValidationUtils } from './validation-utils'

interface InvoiceCorrectProps {
  invoiceId: string,
}

interface State {
  loaded: boolean,
  attributeDefinitions: AttributeDefinition[],
  productDefinitions: ProductDefinition[],
  invoice: Invoice | null,
  location: Location | null,
  chooseItemModalParams: ChooseItemModalParams,
  book?: UiInvoiceBook,
  entries?: UiInvoiceEntry[],
  shipping?: number | string,
  marraCashCard?: boolean,
  invoiceSettings?: InvoiceSettings,
  rates?: Rates,
  actualPayments?: Partial<Record<ActualPaymentKey, string | number>>, // TODO string only
  customerName?: string,
  customerContact?: string,
  note?: string,
  export?: boolean,
  guideCommission?: boolean,
  definitionId?: string,
  inventoryProducts?: Product[],
  categories?: Category[],
  templateDefinitions?: TemplateDefinition[],
  validationErrors?: ValidationErrors,
  customers?: Customer[],
}

interface Row {
  id: string,
  index?: number,
  description: string,
  defaultPrice: number,
  price: string | number,
  amount: string | number,
  currency: Currency,
  type?: 'book',
  rowClassName?: string,
}

const paymentsToStrings = (
  payments: Partial<Record<ActualPaymentKey, unknown>>,
): ActualPaymentsInput => {
  const result: ActualPaymentsInput = {}

  for (const [key, value] of Object.entries(payments)) {
    result[key] = String(value)
  }

  return result
}

export class InvoiceCorrect extends React.Component<InvoiceCorrectProps, State> {
  state: State = {
    loaded: false,
    attributeDefinitions: [],
    productDefinitions: [],
    invoice: null,
    location: null,
    chooseItemModalParams: {
      mode: 'add',
      productDefinition: null,
      products: [],
      updateNewEntries: undefined,
    },
  }

  _isMounted = false

  async componentDidMount() {
    this._isMounted = true
    const DataService = getDataService()

    // Start requests
    const attrDefPromise = DataService.AttributeDefinitions.getAll()
    const catPromise = DataService.Categories.getAll()
    const tplDefsPromise = DataService.TemplateDefinitions.getAll() // TODO: get only needed ones?
    const ratesPromise = InvoiceUiUtils.getExchangeRates()
    const invoiceSettingsPromise = InvoiceUiUtils.getSettings()

    const invoice = await DataService.Invoices.getById(this.props.invoiceId)

    const [
      attributeDefinitions,
      categories,
      templateDefinitions,
      loc,
      inventoryProducts,
      productDefinitions,
      rates,
      invoiceSettings,
      customers,
    ] = await Promise.all([
      attrDefPromise,
      catPromise,
      tplDefsPromise,
      DataService.Locations.getById(invoice.location),
      DataService.Products.getByLocation(invoice.location),
      DataService.ProductDefinitions.getByInventoryLocation(invoice.location),
      ratesPromise,
      invoiceSettingsPromise,
      DataService.Customers.getAll(),
    ])

    if (!this._isMounted) {
      return
    }

    const filteredAttrDefs = attributeDefinitions.filter(
      CommonUtils.attributeContextFilters.products,
    )

    const entries = CommonUtils.clone(invoice.entries)
    const actualPayments: ActualPaymentsInput = {}

    Object.keys(invoice.actualPayments).forEach(function(key: ActualPaymentKey) {
      actualPayments[key] = invoice.actualPayments[key].toString()
    })

    let book: UiInvoiceBook | null = null

    if (loc.sellBook) {
      book = invoice.book

      if (book) {
        book.quantity = String(book.quantity)
        book.price = String(book.price)
      }
      else {
        const price = InvoiceUiUtils.getDefaultBookPrice(loc.currency)
        book = { quantity: '', price: String(price) }
      }
    }

    this.setState(
      {
        loaded: true,
        rates,
        invoiceSettings,
        invoice,
        attributeDefinitions: filteredAttrDefs,
        categories,
        templateDefinitions,
        location: loc,
        inventoryProducts,
        productDefinitions,
        entries,
        customerName: invoice.customerName || '',
        customerContact: invoice.customerContact || '',
        note: invoice.note,
        book,
        shipping: invoice.shipping || '',
        export: invoice.export,
        marraCashCard: invoice.marraCashCard || false,
        actualPayments,
        guideCommission: invoice.guideCommission,
        customers,
      },
      EventBus.fireFunc('invoice-correction-rendered'),
    )
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getOriginalInvoiceValues = () => {
    return InvoiceUtils.getValues(this.state.invoice, this.state.location.currency)
  }

  getCurrentInvoiceValues = (): InvoiceValues => {
    const book = CommonUtils.clone(this.state.book)

    // Convert '-' to 0 instead of NaN while negative number is not fully entered
    if (book && book.price === '-') {
      book.price = '0'
    }

    return {
      currency: this.state.location.currency,
      entries: this.state.entries,
      shipping: this.state.shipping,
      marraCashCard: this.state.marraCashCard,
      book,
    }
  }

  getDroitsDeTimbre = (actualPayments) => {
    return InvoiceUtils.getDroitsDeTimbre(
      this.state.invoiceSettings,
      this.state.rates,
      this.state.location.currency,
      actualPayments,
    )
  }

  getOriginalTotal = () => {
    const invoiceValues = this.getOriginalInvoiceValues()
    return InvoiceUtils.getTotal(this.state.invoiceSettings, invoiceValues, false)
  }

  getCurrentTotal = () => {
    const invoiceValues = this.getCurrentInvoiceValues()
    return InvoiceUtils.getTotal(this.state.invoiceSettings, invoiceValues, false)
  }

  getCoveredAmount = () => {
    return InvoiceUtils.getCoveredAmount(
      this.state.actualPayments, this.state.location.currency, this.state.rates,
    )
  }

  canEditFull = () => {
    return Access.editFullInvoice(User.getUser(), this.state.location)
  }

  save = async () => {
    const { currency } = this.state.location

    await ValidationUtils.clear(this)
    const total = this.getCurrentTotal()
    const originalTotal = this.getOriginalTotal()

    if (!CommonUtils.pricesEqual(total, originalTotal)) {
      const totalStr = CommonUtils.formatDecimal(total) + ' ' + currency
      const originalTotalStr = CommonUtils.formatDecimal(originalTotal) + ' ' + currency

      if (this.canEditFull()) {
        const message = i18n.t('invoice.totals-mismatch-warning', totalStr, originalTotalStr)

        if (!await Utils.confirm(message)) {
          return
        }
      }
      else {
        toastr.error(i18n.t('invoice.totals-mismatch-error', totalStr, originalTotalStr))
        return
      }
    }

    const isValid = await InvoiceUiUtils.validateActualPayments(
      this.state.invoiceSettings,
      this.state.rates,
      this.getCurrentInvoiceValues(),
      false,
      this.state.actualPayments,
    )

    if (isValid) {
      await this.performConfirmedSave()
    }
  }

  performConfirmedSave = () => {
    // Format field types for server
    const formattedEntries = this.state.entries.map(function(entry) {
      return {
        product: entry.product,
        amount: Number(entry.amount),
        price: entry.price.toString(),
      }
    })

    let { book } = this.state

    if (book && !book.quantity) {
      book = null
    }

    const canEditFullInvoice = this.canEditFull()

    return getDataService().Invoices.correct(
      this.props.invoiceId,
      formattedEntries,
      this.state.customerName,
      this.state.customerContact,
      this.state.note,
      book ? {
        quantity: String(book.quantity),
        price: String(book.price),
      } : null,
      this.state.shipping.toString(),
      this.state.export,
      paymentsToStrings(this.state.actualPayments),
      canEditFullInvoice ? this.state.guideCommission : undefined,
      canEditFullInvoice ? this.state.marraCashCard : undefined,
    )
    .catch((error) => ValidationUtils.check(this, error))
    .then(() => {
      router.setRoute('/invoice/view/' + this.props.invoiceId)
    })
  }

  cancel = () => {
    router.setRoute('/invoice/view/' + this.props.invoiceId)
  }

  addNew = () => {
    const { currency } = this.state.location
    const definition = CommonUtils.findById(this.state.productDefinitions, this.state.definitionId)

    const updateNewEntries = function(newProduct, newEntries) {
      const prices = CommonUtils.getPrices(newProduct, definition)

      newEntries.push({
        product: newProduct._id,
        price: prices[currency],
        amount: 1,
      })
    }

    this.showChooseItemModal('add', definition, updateNewEntries)
  }

  showChooseItemModal = (mode, definition, updateNewEntries) => {
    const productsOnInvoice = {}

    this.state.entries.forEach(function(entry) {
      productsOnInvoice[entry.product] = true
    })

    let filteredProducts = this.state.inventoryProducts.filter(function(product) {
      return (
        !(product._id in productsOnInvoice) &&
        product.definition === definition._id &&
        product.amount > 0
      )
    })

    filteredProducts = Utils.sortInventory(
      filteredProducts,
      User.getLanguage(),
      this.state.categories,
      this.state.attributeDefinitions,
      this.state.productDefinitions,
      this.state.templateDefinitions,
      null,
    )

    this.setState(
      {
        chooseItemModalParams: {
          mode,
          productDefinition: definition,
          products: filteredProducts,
          updateNewEntries,
        },
      },
      () => {
        EventBus.fire('open-modal', { modalId: 'choose-item-modal' })
      },
    )
  }

  getColumnConf = () => {
    const columnConf: Column<Row>[] = []

    columnConf.push({
      id: 'item',
      header: i18n.t('invoice.item'),
      getCellContents: function(rowData) {
        return rowData.description
      },
    })

    columnConf.push({
      id: 'default-unit-price',
      header: i18n.t('invoice.default-unit-price'),
      getCellContents: function(rowData) {
        if (isNaN(rowData.defaultPrice)) {
          return 'N/A'
        }

        return CommonUtils.formatDecimal(rowData.defaultPrice) + ' ' + rowData.currency
      },
    })

    columnConf.push({
      id: 'unit-price',
      header: i18n.t('common.unit-price'),
      getCellProperties: function() {
        return { style: { whiteSpace: 'nowrap' } }
      },
      getCellContents: this.renderUnitPrice,
    })

    columnConf.push({
      id: 'quantity',
      header: i18n.t('invoice.quantity'),
      getCellContents: this.renderQuantity,
    })

    columnConf.push({
      id: 'total',
      header: i18n.t('common.total'),
      getCellContents: function(rowData) {
        const total = Number(rowData.price) * Number(rowData.amount)
        return CommonUtils.formatDecimal(total) + ' ' + rowData.currency
      },
    })

    columnConf.push({
      id: 'action',
      header: i18n.t('action.action'),
      getCellContents: this.renderActionCell,
    })

    return columnConf
  }

  getAddableDefinitions = () => {
    // Filter out definitions where all products are already on the invoice
    const productsOnInvoice = {}

    this.state.entries.forEach(function(entry) {
      productsOnInvoice[entry.product] = true
    })

    const definitionIds = {}

    this.state.inventoryProducts.forEach(function(product) {
      // TODO: test case that fails if product amount is not checked
      if (product.amount > 0 && !(product._id in productsOnInvoice)) {
        definitionIds[product.definition] = true
      }
    })

    return this.state.productDefinitions.filter(function(definition) {
      return definition._id in definitionIds
    })
  }

  getRowsData = () => {
    const loc = this.state.location

    const rowsData = this.state.entries.map((entry, entryIndex): Row => {
      const product = CommonUtils.findById(this.state.inventoryProducts, entry.product)
      const productDefinition = CommonUtils.findById(this.state.productDefinitions, product.definition)

      const attributes = CommonUtils.getAttributeDetails(
        product,
        this.state.attributeDefinitions,
        productDefinition,
        this.state.templateDefinitions,
        User.getLanguage(),
      )

      const attributeLabels = this.state.attributeDefinitions.map(function(attribute) {
        return attributes[attribute._id].label
      })
      .filter(function(label) {
        return label !== ''
      })

      const description = productDefinition.name + ' (' + attributeLabels.join('; ') + ')'

      const prices = CommonUtils.getPrices(product, productDefinition)

      return {
        id: entry.product,
        index: entryIndex,
        description,
        defaultPrice: prices[loc.currency],
        price: entry.price,
        amount: entry.amount,
        currency: loc.currency,
      }
    })

    if (loc.sellBook) {
      const { book } = this.state

      // Convert '-' to 0 instead of NaN while negative number is not fully entered
      const price = book.price === '-' ? 0 : Number(book.price)

      rowsData.push({
        id: '$book', // Must not conflict with any other row id
        type: 'book',
        rowClassName: 'row-book',
        description: InvoiceUiUtils.getBookDesc(false),
        defaultPrice: InvoiceUiUtils.getDefaultBookPrice(loc.currency),
        price,
        amount: Number(book.quantity),
        currency: loc.currency,
      })
    }

    return rowsData
  }

  updateCustomerName = (customerName) => {
    this.setState({ customerName })
  }

  selectCustomer = (customer) => {
    this.setState({
      customerName: customer.name,
      customerContact: customer.contact,
    })
  }

  renderMenus = () => {
    return [
      <MainMenu key="main" activeTab="reports" />,
      <ReportsMenu key="reports" activeTab="sales" />,
    ]
  }

  renderTitle = () => {
    return (
      <div
        id="invoice-title"
        style={{
          display: 'inline-block', width: '60%', fontSize: '2em', fontWeight: 'bold',
        }}
      >
        {i18n.t('invoice.correction-title', this.props.invoiceId)}
      </div>
    )
  }

  renderItems = () => {
    const columnConf = this.getColumnConf()
    const rowsData = this.getRowsData()

    return UiUtils.getTable(columnConf, rowsData, {
      tableId: 'tbl-invoice-correct', rowClassName: 'row-invoice-correct',
    })
  }

  renderUnitPrice = (rowData) => {
    if (rowData.type === 'book') {
      return (
        <div>
          {bindInput(this, ['book', 'price'], {
            id: 'inp-book-price',
            style: { width: '5em' },
            validator: Utils.decimalValidator,
          })}
          {ValidationUtils.render(this.state.validationErrors, 'book.price')}
        </div>
      )
    }

    return (
      <div>
        <input
          className="inp-unit-price"
          style={{ width: '5em' }}
          value={rowData.price}
          onChange={(evt) => {
            const { value } = evt.currentTarget

            if (Utils.nonNegativeDecimalValidator(value)) {
              const newEntries = CommonUtils.clone(this.state.entries)
              newEntries[rowData.index].price = value
              this.setState({ entries: newEntries })
            }
          }}
        />
        {ValidationUtils.render(this.state.validationErrors, 'entries.' + rowData.index + '.price')}
      </div>
    )
  }

  renderQuantity = (rowData) => {
    if (rowData.type === 'book') {
      return (
        <div>
          {bindInput(this, ['book', 'quantity'], {
            id: 'inp-book-quantity',
            style: { width: '5em' },
            validator: Utils.nonNegativeIntegerValidator,
          })}
          {ValidationUtils.render(this.state.validationErrors, 'book.quantity')}
        </div>
      )
    }

    return (
      <div>
        <input
          className="inp-quantity"
          style={{ width: '5em' }}
          value={rowData.amount}
          onChange={(evt) => {
            const { value } = evt.currentTarget

            if (Utils.nonNegativeIntegerValidator(value)) {
              const newEntries = CommonUtils.clone(this.state.entries)
              newEntries[rowData.index].amount = value
              this.setState({ entries: newEntries })
            }
          }}
        />
        {ValidationUtils.render(this.state.validationErrors, 'entries.' + rowData.index + '.amount')}
      </div>
    )
  }

  renderActionCell = (rowData) => {
    if (rowData.type === 'book') {
      return null
    }

    return (
      <span>
        <img
          className="table-btn btn-change-attr"
          title={i18n.t('invoice.change-attributes')}
          src="img/change.png"
          onClick={() => {
            const entry = this.state.entries[rowData.index]
            const product: Product = CommonUtils.findById(this.state.inventoryProducts, entry.product)

            const definition = CommonUtils.findById(this.state.productDefinitions, product.definition)
            const { currency } = this.state.location

            const updateNewEntries = function(newProduct, newEntries) {
              const prices = CommonUtils.getPrices(newProduct, definition)

              newEntries[rowData.index] = {
                product: newProduct._id,
                price: prices[currency],
                amount: entry.amount,
              }
            }

            this.showChooseItemModal('change', definition, updateNewEntries)
          }}
        />
        <img
          className="table-btn btn-remove"
          title={i18n.t('action.remove')}
          src="img/delete.png"
          onClick={() => {
            const newEntries = CommonUtils.clone(this.state.entries)
            newEntries.splice(rowData.index, 1)
            this.setState({ entries: newEntries })
          }}
        />
      </span>
    )
  }

  renderCustomerName = () => {
    return (
      <SearchableCustomer
        customers={this.state.customers}
        nameValue={this.state.customerName}
        onChange={this.updateCustomerName}
        onSelect={this.selectCustomer}
      />
    )
  }

  renderCustomerContact = () => {
    return (
      <div>
        <div style={{ fontWeight: 'bold' }}>
          {i18n.t('invoice.contact-info')}
          :
        </div>
        <textarea
          {...bindProps(this,
            ['customerContact'],
            { id: 'inp-customer-contact', style: { width: '100%', height: '12em' } },
          )}
        />
      </div>
    )
  }

  renderNotes = () => {
    return (
      <div>
        <div style={{ fontWeight: 'bold' }}>
          {i18n.t('invoice.notes')}
          :
        </div>
        <textarea
          {...bindProps(this,
            ['note'], { id: 'inp-notes', style: { width: '100%', height: '12em' } },
          )}
        />
      </div>
    )
  }

  renderAddNew = () => {
    const addableDefinitions = this.getAddableDefinitions()

    if (addableDefinitions.length) {
      const selectedProductDefinition = CommonUtils.findById(
        this.state.productDefinitions, this.state.definitionId,
      )

      return (
        <div style={{ marginBottom: '1.5em' }}>
          <button
            id="btn-add-new"
            disabled={!selectedProductDefinition}
            className={selectedProductDefinition ? '' : 'disabled'}
            onClick={this.addNew}
          >
            {i18n.t('action.add-new')}
          </button>
          {' '}
          <select {...bindProps(this, ['definitionId'], { id: 'inp-definition' })}>
            <option value="" />
            {addableDefinitions.map(function(definition) {
              return (
                <option key={definition._id} value={definition._id}>
                  {definition.name}
                </option>
              )
            })}
          </select>
        </div>
      )
    }
  }

  renderShippingFee = () => {
    return (
      <div style={{ margin: '0.4em 0' }}>
        {i18n.t('invoice.shipping-fee')}
        :
        {bindInput(this, ['shipping'], {
            id: 'inp-shipping',
            style: { width: '5em', marginLeft: '0.7em' },
            validator: Utils.decimalValidator,
          })}
        {' '}
        {this.state.location.currency}
      </div>
    )
  }

  renderExport = () => {
    return (
      <div>
        {bindCheckbox(this, ['export'], { id: 'chk-export' })}
        {' '}
        {i18n.t('invoice.export-invoice')}
      </div>
    )
  }

  renderMarraCashCard = () => {
    const canEditFull = this.canEditFull()

    if (canEditFull || this.state.invoice.marraCashCard) {
      const { currency } = this.state.location
      const settings = this.state.invoiceSettings

      const invoiceValues = this.getCurrentInvoiceValues()
      const discount = InvoiceUtils.getMarraCashCardDiscount(settings, invoiceValues, false)
      const text = i18n.t('invoice.marra-cash-card-discount', String(CommonUtils.round(discount)), currency)

      if (canEditFull) {
        return (
          <div>
            {bindCheckbox(this, ['marraCashCard'], { id: 'chk-marra-cash-card' })}
            {' '}
            <span id="lbl-marra-cash-card">
              {text}
            </span>
          </div>
        )
      }
      else if (this.state.invoice.marraCashCard) {
        return <div id="lbl-marra-cash-card">{text}</div>
      }
    }
  }

  renderTotals = () => {
    const currentTotal = this.getCurrentTotal()

    const currentTotals = InvoiceUtils.calculateTotals(
      this.state.invoiceSettings, currentTotal, this.state.export,
    )

    const { currency } = this.state.location
    const originalTotal = this.getOriginalTotal()
    const originalTotalStyle: CSSProperties = {}

    if (!CommonUtils.pricesEqual(currentTotal, originalTotal)) {
      originalTotalStyle.color = '#c00'
    }

    // TODO: extract reusable component?
    return (
      <table className="invoice-totals">
        <tbody>
          <tr>
            <td style={{ fontWeight: 'bold' }}>
              {i18n.t('invoice.subtotal')}
              :
            </td>
            <td className="value cell-subtotal">
              {CommonUtils.formatDecimal(currentTotals.subtotal)}
              {' '}
              {currency}
            </td>
          </tr>
          <tr>
            <td style={{ fontWeight: 'bold' }}>
              {i18n.t('invoice.vat')}
              :
            </td>
            <td className="value cell-vat">
              {CommonUtils.formatDecimal(currentTotals.vat)}
              {' '}
              {currency}
            </td>
          </tr>
          <tr>
            <td style={{ fontWeight: 'bold' }}>
              {i18n.t('common.total')}
              :
            </td>
            <td className="value cell-total">
              {CommonUtils.formatDecimal(currentTotals.total)}
              {' '}
              {currency}
            </td>
          </tr>
          <tr>
            <td>
              <b>
                {i18n.t('invoice.original-total')}
                :
              </b>
            </td>
            <td className="value cell-original-total" style={originalTotalStyle}>
              {CommonUtils.formatDecimal(originalTotal)}
              {' '}
              {currency}
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  renderGuideCommission = () => {
    const { currency } = this.state.location

    if (currency === 'MAD' && this.canEditFull()) {
      const totalWithoutDdt = this.getCurrentTotal()
      const shipping = this.state.shipping || 0
      const totalWithoutShipping = totalWithoutDdt - Number(shipping)
      const amount = InvoiceUtils.getGuideCommission(this.state.invoiceSettings, totalWithoutShipping)

      return (
        <div>
          {bindCheckbox(this, ['guideCommission'], { id: 'chk-guide-commission' })}
          {' '}
          {i18n.t('invoice.guide-commission')}
          {' ('}
          <span className="lbl-guide-commission">
            {CommonUtils.formatDecimal(amount)}
            {' '}
            {currency}
          </span>
          )
        </div>
      )
    }
  }

  renderActualPayments = () => {
    const { currency } = this.state.location
    const totalWithoutDdt = this.getCurrentTotal()
    const covered = this.getCoveredAmount()

    const uncovered = totalWithoutDdt - covered

    return (
      <div id="actual-payments" style={{ marginBottom: 10 }}>
        <div style={{ marginTop: '1em', marginBottom: '0.5em', fontWeight: 'bold' }}>
          {i18n.t('invoice.actual-payments')}
          :
        </div>
        {InvoiceUiUtils.getActualPaymentRows(
          this.state.actualPayments,
          uncovered,
          currency,
          this.state.rates,
          totalWithoutDdt,
          (newActualPayments) => {
            this.setState({ actualPayments: newActualPayments })
          },
        )}
      </div>
    )
  }

  renderDroitsDeTimbre = () => {
    if (this.state.invoice.droitsDeTimbre) {
      const ddt = this.getDroitsDeTimbre(this.state.actualPayments)

      if (ddt !== 0) {
        const { currency } = this.state.location

        return (
          <div id="lbl-droits-de-timbre" style={{ marginBottom: '0.5em' }}>
            <b>Droits de timbre:</b>
            {' '}
            {CommonUtils.round(ddt)}
            {' '}
            {currency}
          </div>
        )
      }
    }
  }

  renderSaveButton = () => {
    let disabled = false
    let disabledTitle = null

    const total = this.getCurrentTotal()
    const covered = this.getCoveredAmount()
    const uncovered = total - covered

    if (covered === 0 && uncovered > 0) {
      disabled = true
      disabledTitle = i18n.t('invoice.no-actual-payments')
    }

    return (
      <LoadingButton
        getPromise={this.save}
        text={i18n.t('action.save')}
        disabled={disabled}
        disabledTitle={disabledTitle}
        id="btn-save"
      />
    )
  }

  renderButtons = () => {
    return (
      <div>
        {this.renderSaveButton()}
        {' '}
        <button id="btn-cancel" onClick={this.cancel}>
          {i18n.t('action.cancel')}
        </button>
      </div>
    )
  }

  renderWarning = () => {
    if (!this.canEditFull()) {
      return (
        <div style={{ marginTop: '1em' }}>
          <div>
            <b>
              {i18n.t('invoice.correct-warning.1')}
              {': '}
            </b>
            {i18n.t('invoice.correct-warning.2')}
          </div>
          <div>
            {i18n.t('invoice.correct-warning.3')}
          </div>
        </div>
      )
    }
  }

  renderModal = () => {
    const replaceItem = (newProduct: Product) => {
      return new Promise<void>((resolve) => {
        const newEntries = CommonUtils.clone(this.state.entries)
        this.state.chooseItemModalParams.updateNewEntries(newProduct, newEntries)
        this.setState({ entries: newEntries }, resolve)
      })
    }

    return (
      <ChooseItemModal
        modalId="choose-item-modal"
        params={this.state.chooseItemModalParams}
        attributeDefinitions={this.state.attributeDefinitions}
        templateDefinitions={this.state.templateDefinitions}
        location={this.state.location}
        replaceItem={replaceItem}
      />
    )
  }

  render() {
    if (!this.state.loaded) {
      return (
        <div>
          {this.renderMenus()}
          {i18n.t('common.loading')}
        </div>
      )
    }

    return (
      <div>
        {this.renderMenus()}
        <div id="form-invoice" style={{ maxWidth: 1200 }}>
          {this.renderTitle()}
          {this.renderItems()}
          <div id="notes-parent">
            {this.renderCustomerName()}
            {this.renderCustomerContact()}
            {this.renderNotes()}
          </div>
          {this.renderAddNew()}
          {this.renderShippingFee()}
          {this.renderExport()}
          {this.renderMarraCashCard()}
          {this.renderTotals()}
          {this.renderGuideCommission()}
          {this.renderActualPayments()}
          {this.renderDroitsDeTimbre()}
          {this.renderButtons()}
          {this.renderWarning()}
        </div>
        {this.renderModal()}
      </div>
    )
  }
}
