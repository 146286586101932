import { CSSProperties, ReactNode, useEffect } from 'react'
import React = require('react')
import ReactBootstrapModal from 'react-bootstrap/Modal'

import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { LoadingButton } from './loading-button'
import { Utils } from './utils'

export interface ModalButton {
  id: string,
  title: string,
  onClick?: () => Promise<void> | void,
  className?: string,
  style?: CSSProperties,
  isCancel?: boolean,
  disabled?: boolean,
}

export interface ModalProps {
  children: ReactNode,
  title: string,
  closeModal: () => void,
  dialogClassName?: string,
  buttons: ModalButton[],
  size?: 'sm' | 'lg' | 'xl',
}

let specialEffectsEnabled = true

export const getDefaultCancelButton = (): ModalButton => ({
  id: 'btn-cancel',
  title: i18n.t('action.cancel'),
  isCancel: true,
})

export const disableSpecialEffects = () => {
  specialEffectsEnabled = false
}

export const Modal = (props: ModalProps) => {
  useEffect(() => {
    // Fire on mount
    EventBus.fire('modal-toggled')

    // Fire on dismount
    return () => EventBus.fire('modal-toggled')
  })

  const buttons = (props.buttons || [])
  .map((buttonConf, index) => (
    <LoadingButton
      key={index}
      id={buttonConf.id}
      className={buttonConf.className}
      style={Utils.merge({ marginRight: 5 }, buttonConf.style)}
      getPromise={async () => buttonConf.isCancel ? props.closeModal() : buttonConf.onClick()}
      text={buttonConf.title}
      disabled={buttonConf.disabled}
    />
  ))

  // Managing header directly to avoid the tooltip from the title attribute
  // on the <div> element that comes when using the title property.
  return (
    <ReactBootstrapModal
      show={true}
      dialogClassName={props.dialogClassName}
      onHide={props.closeModal}
      animation={specialEffectsEnabled}
      backdrop={specialEffectsEnabled}
      size={props?.size}
    >
      <div className="modal-header">
        <button
          type="button"
          className="close"
          aria-hidden="true"
          onClick={props.closeModal}
        >
          {'\u00d7'}
        </button>
        <h4 className="modal-title">{props.title}</h4>
      </div>
      <div className="modal-body">{props.children}</div>
      <div className="modal-footer">{buttons}</div>
    </ReactBootstrapModal>
  )
}
