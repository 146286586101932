import { ReactNode, useCallback, useEffect, useRef } from 'react'
import React = require('react')

import { EventBus } from '../common/event-bus'

interface FloatingHeaderProps {
  headerRow: ReactNode,
}

export const FloatingHeader = ({ headerRow }: FloatingHeaderProps) => {
  const floatHeaderRef = useRef<HTMLTableSectionElement>(null)
  const normalHeaderRef = useRef<HTMLTableSectionElement>(null)

  const update = useCallback(() => {
    const normalHeader = normalHeaderRef.current

    if (!normalHeader) {
      // Most likely the table isn't rendered at the moment, so we can skip the updates.
      return
    }

    const floatHeader = floatHeaderRef.current
    const showFloatHeader = normalHeader.getBoundingClientRect().top < 0

    // Keeping the floating header in the DOM and hiding/showing it using
    // transform provides a smoother transition than using 'display: none'
    floatHeader.style.transform = showFloatHeader ? 'scaleX(1)' : 'scaleX(0)'

    if (showFloatHeader) {
      // Synchronize column widths from normal header to floating header
      const normalCells = normalHeader.querySelectorAll('th')
      const floatCells = floatHeader.querySelectorAll('th')

      for (let i = 0; i < normalCells.length; i += 1) {
        const width = normalCells[i].offsetWidth + 'px'
        floatCells[i].style.minWidth = width
        floatCells[i].style.maxWidth = width
      }

      // Align by table bottom if needed
      const headerHeight = floatHeader.getBoundingClientRect().height
      const tableBottom = (floatHeader.parentNode as Element).getBoundingClientRect().bottom

      floatHeader.style.top = Math.min(0, tableBottom - headerHeight) + 'px'
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', update)

    const modal = document.querySelector('.modal')

    if (modal) {
      // Scrolling on a modal does not trigger the scroll event on the window.
      modal.addEventListener('scroll', update)
    }
    else {
      window.addEventListener('scroll', update)
    }

    // Toggling a modal can toggle a scrollbar and affect the body's width
    // without triggering a resize event on the window.
    EventBus.on('modal-toggled', update)

    return () => {
      EventBus.off('modal-toggled', update)

      // No need to remove scroll event from modal as it's dismounted anyway
      window.removeEventListener('scroll', update)

      window.removeEventListener('resize', update)
    }
  }, [update])

  // TODO: equivalent of updateFloatingHeader in componentDidUpdate

  // Render two headers with the same contents into the table.
  // The normal header always stays "visible", even when it's off-screen.
  // The floating header is only visible when the normal header is off-screen
  // and it will try to keep its column widths in sync with the normal header.

  return (
    <>
      <thead ref={floatHeaderRef} className="floating">
        {headerRow}
      </thead>
      <thead ref={normalHeaderRef}>
        {headerRow}
      </thead>
    </>
  )
}
