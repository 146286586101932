import { ReactNode, useEffect, useRef, useState } from 'react'
import React = require('react')

import { ProductDefinition } from '../common/data-product-defs'
import { ProductOps, TemplateOps } from '../common/data-service'
import { TemplateDefinition } from '../common/data-template-defs'
import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { InventoryItem } from '../common/types'
import { getDefaultCancelButton, Modal, ModalButton } from './modal'
import { useOpenModalEvent } from './use-open-modal-event'
import { Utils } from './utils'
import { ValidationErrors, ValidationUtils } from './validation-utils'

interface AddAmountModalProps {
  modalId: string,
  definition: TemplateDefinition | ProductDefinition,
  item: InventoryItem,
  itemService: TemplateOps | ProductOps,
  afterSave: () => void,
}

export const AddAmountModal = (props: AddAmountModalProps) => {
  const [isVisible, close] = useOpenModalEvent(props.modalId)
  const inputRef = useRef<HTMLInputElement>(null)
  const [amountToAdd, setAmountToAdd] = useState('')
  const [errors, setErrors] = useState<ValidationErrors>(null)

  useEffect(() => {
    if (isVisible) {
      // On open
      inputRef.current?.focus()
    } else {
      // On close
      setAmountToAdd('')
    }
  }, [isVisible])

  if (!isVisible) {
    return <div />
  }

  const buttons: ModalButton[] = [
    {
      id: 'btn-save',
      title: i18n.t('action.save'),
      onClick: async () => {
        setErrors(null)

        try {
          await props.itemService.addToAmount(props.item._id, amountToAdd)
          close()
          await props.afterSave()
        } catch (err) {
          const newErrors = ValidationUtils.getErrors(err)

          if (newErrors) {
            setErrors(newErrors)
            EventBus.fire('validation-errors-rendered')
          }
        }
      },
    },
    getDefaultCancelButton(),
  ]

  const row = (fieldName: string, field: ReactNode) => {
    return (
      <div className="row" style={{ margin: '0.5em 0' }}>
        <div className="col-xs-5" style={{ fontWeight: 'bold', paddingLeft: 0 }}>
          {fieldName}
        </div>
        <div className="col-xs-7">
          {field}
        </div>
      </div>
    )
  }

  const form = (
    <div>
      {row(i18n.t('templates.title'), props.definition.name)}
      {row(
        i18n.t('templates.current-amount'),
        <span id="lbl-current-amount">
          {props.item.amount}
        </span>,
      )}
      {row(
        i18n.t('templates.amount-to-add'),
        <input
          ref={inputRef}
          value={amountToAdd}
          onChange={(evt) => {
            const { value } = evt.currentTarget

            if (Utils.nonNegativeIntegerValidator(value)) {
              setAmountToAdd(value)
            }
          }}
          id="inp-amount-to-add"
          style={{ width: '4em' }}
        />,
      )}
      {ValidationUtils.render(errors, 'amountToAdd')}
      {row(
        i18n.t('templates.new-amount'),
        <span id="lbl-new-amount">
          {Number(props.item.amount) + Number(amountToAdd)}
        </span>,
      )}
    </div>
  )

  return (
    <div>
      <Modal
        title={i18n.t('templates.add-to-amount')}
        closeModal={close}
        dialogClassName="add-inventory-amount"
        buttons={buttons}
      >
        {form}
      </Modal>
    </div>
  )
}
