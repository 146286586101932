import {
  Component,
  DetailedHTMLProps,
  InputHTMLAttributes,
  ReactNode,
  SelectHTMLAttributes,
} from 'react'

import React = require('react')

import { CommonUtils } from '../common/common-utils'

type Validator = (value: string) => boolean

interface AdditionalProps {
  validator?: Validator,
  afterChange?: () => void,
}

export const bindProps = (
  component: Component,
  keys: string | string[],
  props: DetailedHTMLProps<any, unknown> & AdditionalProps = {},
  isCheckbox = false,
): typeof props => {
  const { validator, afterChange, ...fullProps } = props

  let value: boolean | string = getValue(component.state, keys, isCheckbox ? false : '')

  if (isCheckbox) {
    if (value === undefined) {
      value = false
    }

    fullProps.checked = value
  }
  else {
    if (value === undefined) {
      value = ''
    }

    fullProps.value = value ?? ''
  }

  if (validator && !validator(value)) {
    throw new Error('Invalid initial value')
  }

  fullProps.onChange = function(evt) {
    const newValue: boolean | string = isCheckbox ? evt.target.checked : evt.target.value

    if (validator) {
      const isValid: boolean = validator(newValue)

      if (!isValid) {
        return
      }
    }

    let stateUpdate

    if (Array.isArray(keys) && keys.length > 1) {
      stateUpdate = CommonUtils.clone(component.state)
      setValue(stateUpdate, keys, newValue)
    }
    else {
      // TODO: handle error if keys is empty array?
      const key = Array.isArray(keys) ? keys[0] : keys

      stateUpdate = {}
      stateUpdate[key] = newValue
    }

    component.setState(stateUpdate, afterChange)
  }

  return fullProps
}

export const bindInput = (
  component: Component,
  keys: string | string[],
  props?: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & AdditionalProps,
) => {
  const fullProps = bindProps(component, keys, props, false)
  return <input {...fullProps} />
}

export const bindCheckbox = (
  component: Component,
  keys: string | string[],
  props?: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & AdditionalProps,
) => {
  const fullProps = bindProps(component, keys, props, true)
  fullProps.type = 'checkbox'
  return <input {...fullProps} />
}

export const bindSelect = (
  component: Component,
  keys: string | string[],
  props: DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> & AdditionalProps,
  options: ReactNode[],
) => {
  const fullProps = bindProps(component, keys, props, false)
  return <select {...fullProps}>{options}</select>
}

const getValue = (obj: any, keys: string | string[], defaultValue?: boolean | string) => {
  if (Array.isArray(keys)) {
    if (keys.length === 0) {
      return obj
    }
    else {
      const newObj = getValue(obj, keys[0])
      return getValue(newObj, keys.slice(1))
    }
  }
  else {
    const key = keys
    return obj ? obj[key] : defaultValue
  }
}

const setValue = (obj: any, keys: string[], value: boolean | string) => {
  // obj [a,b] v -> setValue(obj.a, [b], v)
  // obj   [b] v -> obj.b = v

  const [key, ...restKeys] = keys

  if (keys.length === 1) {
    obj[key] = value
  }
  else {
    if (!(key in obj)) {
      obj[key] = {}
    }

    setValue(obj[key], restKeys, value)
  }
}
