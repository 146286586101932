// Information about the currently logged in user

import { UiSessionUser } from '../common/data-users'
import { Enums } from '../common/enums'
import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { Utils } from './utils'

let loggedInUser: UiSessionUser | null = null
let envInfo = null

// TODO: save separate location ids per location type
// TODO: also remember 'Template definitions' vs 'Product definitions'?

// TODO: different default type?
let lastLocationType = 'production'
let lastLocationId = null

let filterValues = null
let textSize = 14 // Default from Bootstrap

function initDefaultFilters(user) {
  filterValues = {
    'transfer-list': {
      time: Utils.getTimeFilterLast14Days(user.country),
      status: [
        { id: 1, value: Enums.transferStatuses.draft },
        { id: 2, value: Enums.transferStatuses.sent },
      ],
    },
    'sales-report-invoice': {
      time: Utils.getTimeFilterLast14Days(user.country),
    },
    'production-report': {
      time: Utils.getTimeFilterLast14Days(user.country),
    },
    'consumption-report': {
      time: Utils.getTimeFilterLast14Days(user.country),
    },
    'correction-report': {
      time: Utils.getTimeFilterLast14Days(user.country),
    },
    'earnings': {
      time: Utils.getTimeFilterCurrentMonth(user.country),
    },
    'expenses': {
      time: Utils.getTimeFilterCurrentMonth(user.country),
    },
  }
}

export const User = {
  isLoggedIn: function () {
    return Boolean(loggedInUser)
  },

  getUser: function (): UiSessionUser {
    return loggedInUser
  },

  setUser: function (user: UiSessionUser): void {
    loggedInUser = user

    i18n.setLanguage(User.getLanguage())

    if (user) {
      initDefaultFilters(user)
    }
  },

  getLanguage: function () {
    return loggedInUser ? loggedInUser.language : 'en'
  },

  setEnvInfo: function (envInfoParam) {
    envInfo = envInfoParam
  },

  getUploaded: function (dirName, fileName) {
    if (envInfo.name === 'production' || envInfo.name === 'staging') {
      return 'https://' + envInfo.s3BucketName + `.${envInfo.s3Endpoint || 's3.amazonaws.com'}/` + dirName + '/' + fileName
    }
    else if (envInfo.name === 'ui-test') {
      // We don't store the processed image data anywhere in UI test mode.
      // Return a data URI that doesn't display a valid image,
      // but also doesn't result in a 404 error and can be verified by test code.
      return 'data:,' + dirName + '/' + fileName
    }
    else { // development
      return envInfo.uploadDir + '/' + dirName + '/' + fileName
    }
  },

  setLastLocation: function (type, id?) {
    if (type !== lastLocationType || id !== null) {
      lastLocationType = type
      lastLocationId = id
    }
  },

  getLastLocationType: function () {
    return lastLocationType
  },

  getLastLocationId: function () {
    return lastLocationId
  },

  getFilter: function (filterSetKey) {
    return filterValues[filterSetKey]
  },

  setFilter: function (filterSetKey, fieldName, newValues) {
    if (!(filterSetKey in filterValues)) {
      filterValues[filterSetKey] = {}
    }

    filterValues[filterSetKey][fieldName] = newValues
    EventBus.fire('filters-updated')
  },

  clearFilter: function (filterSetKey) {
    filterValues[filterSetKey] = {}
    EventBus.fire('filters-updated')
  },

  resetFilters: function () {
    initDefaultFilters(User.getUser())
  },

  getTextSize: function () {
    return textSize
  },

  setTextSize: function (newSize) {
    textSize = newSize
    document.body.style.fontSize = newSize + 'px'
  },
}
