import update from 'immutability-helper'
import React = require('react')

import { CommonUtils } from '../common/common-utils'
import { AttributeDefinitionUsage, ValueGroup } from '../common/data-attribute-defs'
import { Category } from '../common/data-misc'
import { getDataService } from '../common/data-service'
import { Enums, Language } from '../common/enums'
import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { bindCheckbox, bindInput, bindProps } from './bind-utils'
import { DataEditor, DataEditorField } from './data-editor'
import { LoadingButton } from './loading-button'
import { MainMenu } from './main-menu'
import { router } from './router'
import { Selector } from './selector'
import { UiUtils } from './ui-utils'
import { User } from './user'
import { Utils } from './utils'
import { ValidationErrors, ValidationUtils } from './validation-utils'

const yesOrNo = function(boolean) {
  return boolean ? i18n.t('common.yes') : i18n.t('common.no')
}

interface EditAttributeDefinitionProps {
  isNew: boolean,
  id?: string, // Required if !isNew
}

interface State {
  loaded: boolean,
  attr: any,
  newIdCounter: number,
  categories?: Category[],
  validationErrors?: ValidationErrors,
  usage?: AttributeDefinitionUsage,
}

export class EditAttributeDefinition extends React.Component<EditAttributeDefinitionProps, State> {
  state: State = {
    loaded: false,
    attr: null,
    newIdCounter: 1,
  }

  private readonly NEW_ID_PREFIX = '__new'
  _isMounted = false

  componentDidMount() {
    this._isMounted = true
    this.reloadData()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  reloadData = () => {
    let attrPromise = null
    let usagePromise: Promise<AttributeDefinitionUsage> = null
    const DataService = getDataService()

    if (this.props.isNew) {
      attrPromise = Promise.resolve({
        names: {},
        pluralNames: {},
        contexts: [],
        valueGroups: [this.getNewGroup()],
        optional: true,
        textForCustom: false,
      })

      usagePromise = Promise.resolve({ combined: {}, byCategory: {} })
    }
    else {
      attrPromise = DataService.AttributeDefinitions.getAll().then((attrs) => {
        return CommonUtils.findById(attrs, this.props.id)
      })

      usagePromise = DataService.AttributeDefinitions.getUsage(this.props.id)
    }

    return Promise.all([
      attrPromise,
      usagePromise,
      DataService.Categories.getAll(),
    ])
    .then(([attr, usage, categories]) => {
      if (this._isMounted) {
        this.setState(
          { loaded: true, attr, usage, categories },
          EventBus.fireFunc('attribute-editor-rendered'),
        )
      }
    })
  }

  backToList = () => {
    router.setRoute('/data/attributes')
  }

  anyIncompleteNewValues = () => {
    return this.state.attr.valueGroups.some(
      (_valueGroup, groupIndex) => {
        return (this.refs['editor-' + groupIndex] as any).hasIncompleteNewValue()
      },
    )
  }

  getFirstDuplicateLabel = () => {
    let firstDuplicate = null
    const allLabels: Record<string, Partial<Record<Language, Record<string, true>>>> = {}

    this.state.attr.valueGroups.some(function(valueGroup) {
      const categoryKey = valueGroup.category || '*'
      CommonUtils.member(allLabels, categoryKey, {})

      return valueGroup.values.some(function(value) {
        return Enums.orderedLanguages.some(function(language) {
          const label = value.labels[language]

          CommonUtils.member(allLabels[categoryKey], language, {})

          if (label in allLabels[categoryKey][language]) {
            firstDuplicate = label
            return true
          }

          allLabels[categoryKey][language][label] = true
        })
      })
    })

    return firstDuplicate
  }

  getNewGroup = () => {
    return { values: [] }
  }

  addGroup = () => {
    return UiUtils.setState(this, {
      attr: update(this.state.attr, {
        valueGroups: { $push: [this.getNewGroup()] },
      }),
    })
  }

  deleteGroup = (groupIndex) => {
    return UiUtils.setState(this, {
      attr: update(this.state.attr, {
        valueGroups: { $splice: [[groupIndex, 1]] },
      }),
    })
    .then(EventBus.fireFunc('attribute-editor-rendered'))
  }

  performConfirmedSave = () => {
    const DataService = getDataService()

    return ValidationUtils.clear(this)
    .then(() => {
      const idsByCategory: Record<string, Record<string, true>> = {}

      this.state.attr.valueGroups.forEach(function(valueGroup) {
        const categoryKey = valueGroup.category || '*'

        valueGroup.values.forEach(function(value) {
          CommonUtils.member(idsByCategory, categoryKey, {})
          idsByCategory[categoryKey][value._id] = true
        })
      })

      const valueGroupPromises = this.state.attr.valueGroups.map((valueGroup) => {
        const categoryKey = valueGroup.category || '*'

        function shouldSkipId(id) {
          const alreadyUsed = id in idsByCategory[categoryKey]

          if (!alreadyUsed) {
            idsByCategory[categoryKey][id] = true
          }

          return Promise.resolve(alreadyUsed)
        }

        const valuePromises = valueGroup.values.map(async (value) => {
          let id = value._id

          if (id.indexOf(this.NEW_ID_PREFIX) === 0) {
            id = await CommonUtils.generateUniqueId(value.labels.en, shouldSkipId)
          }

          const newValue: any = { _id: id, labels: value.labels }

          if (value.sortKey !== undefined) {
            newValue.sortKey = value.sortKey
          }

          return newValue
        })

        return Promise.all(valuePromises)
        .then(function(values) {
          const newValueGroup: any = {}

          if (valueGroup.names) {
            const anyName = Enums.orderedLanguages.some(function(language) {
              return valueGroup.names[language]
            })

            if (anyName) {
              newValueGroup.names = valueGroup.names
            }
          }

          // Omit field entirely if value is falsy
          if (valueGroup.category) {
            newValueGroup.category = valueGroup.category
          }

          newValueGroup.values = values
          return newValueGroup
        })
      })

      return Promise.all(valueGroupPromises)
    })
    .then(async (valueGroups) => {
      const attr: any = {
        names: this.state.attr.names,
        pluralNames: this.state.attr.pluralNames,
        valueGroups,
      }

      if (this.props.isNew) {
        attr.contexts = this.state.attr.contexts
        attr.textForCustom = this.state.attr.textForCustom
        await DataService.AttributeDefinitions.create(attr)
      }
      else {
        await DataService.AttributeDefinitions.update(this.state.attr._id, attr)
      }
    })
    .then(this.backToList)
    .catch((error) => ValidationUtils.check(this, error))
  }

  save = async () => {
    let confirmed = true

    if (this.anyIncompleteNewValues()) {
      confirmed = await Utils.confirm(
        'You have entered some new values without clicking the "Add" button.' +
        ' Press OK to save the attribute anyway or Cancel to go back and add the new values.',
      )
    }
    else {
      const duplicateLabel = this.getFirstDuplicateLabel()

      if (duplicateLabel) {
        confirmed = await Utils.confirm(
          'The label "' + duplicateLabel + '" occurs more than once in its category.' +
          ' Are you sure you want to save the attribute?',
        )
      }
    }

    if (confirmed) {
      return this.performConfirmedSave()
    }
  }

  getCategoryElement = (groupIndex, valueGroup) => {
    const notLimitedDesc = i18n.t('data.attributes.not-limited')
    const language = User.getLanguage()

    if (!this.props.isNew && valueGroup.values.length) {
      let label

      if (!valueGroup.category) {
        label = notLimitedDesc
      }
      else {
        const currentCategory = CommonUtils.findById<Category>(this.state.categories, valueGroup.category)
        label = currentCategory.labels[language]
      }

      return <span className="lbl-category">{label}</span>
    }

    const categories = this.state.categories.slice()

    CommonUtils.sortAsStrings(categories, function(category) {
      return category.labels[User.getLanguage()]
    })

    return (
      <select
        {...bindProps(this,
          ['attr', 'valueGroups', groupIndex, 'category'],
          { className: 'inp-category' },
        )}
      >
        <option value="">{notLimitedDesc}</option>
        {categories.map(function(category) {
          return (
            <option key={category._id} value={category._id}>
              {category.labels[language]}
            </option>
          )
        })}
      </select>
    )
  }

  render() {
    const mainMenu = <MainMenu activeTab="data" />

    if (!this.state.loaded) {
      return (
        <div>
          {mainMenu}
          Loading...
        </div>
      )
    }

    const { attr } = this.state

    const userLanguage = User.getLanguage()

    let contextsElement = null
    let textForCustomElement = null

    if (this.props.isNew) {
      contextsElement = (
        <Selector
          id="contexts"
          multiple={true}
          values={Enums.orderedAttributeContexts.map(function(context) {
            return { key: context, label: i18n.t('enum.attribute-contexts.' + context) }
          })}
          selectedKeys={attr.contexts}
          onSelect={(value, isActive) => {
            let newContexts

            if (isActive) {
              newContexts = attr.contexts.concat([value])
            }
            else {
              newContexts = attr.contexts.filter(function(oldValue) {
                return oldValue !== value
              })
            }

            this.setState({
              attr: update(attr, { contexts: { $set: newContexts } }),
            })
          }}
        />
      )

      textForCustomElement = bindCheckbox(this,
        ['attr', 'textForCustom'], { id: 'chk-text-for-custom' },
      )
    }
    else {
      const contextNames = Enums.orderedAttributeContexts
      .filter(function(context) {
        return CommonUtils.arrayContains(attr.contexts, context)
      })
      .map(function(context) {
        return i18n.t('enum.attribute-contexts.' + context)
      })
      .join(', ')

      contextsElement = <span id="lbl-contexts">{contextNames}</span>

      textForCustomElement = (
        <span id="lbl-text-for-custom">
          {yesOrNo(attr.textForCustom)}
        </span>
      )
    }

    return (
      <div>
        {mainMenu}
        <div style={{ marginBottom: 5 }}>
          <a href="#/data/attributes">
            {i18n.t('data.attributes.back')}
          </a>
        </div>
        <p>
          <b>
            {i18n.t('data.attributes.save-note.note')}
            {': '}
          </b>
          {i18n.t('data.attributes.save-note')}
        </p>
        <table className="attr-header">
          <tbody>
            {Enums.orderedLanguages.map((language) => {
              const langName = i18n.t('enum.languages.' + language)

              return [
                <tr key={'singular-' + language}>
                  <td>
                    {i18n.t('common.name')}
                    {' ('}
                    {langName}
                    {', '}
                    {i18n.t('common.singular')}
                    ):
                  </td>
                  <td>
                    {bindInput(this,
                      ['attr', 'names', language],
                      { id: 'inp-name-singular-' + language },
                    )}
                    {ValidationUtils.render(this.state.validationErrors, 'names.' + language)}
                  </td>
                </tr>,
                <tr key={'plural-' + language}>
                  <td>
                    {i18n.t('common.name')}
                    {' ('}
                    {langName}
                    {', '}
                    {i18n.t('common.plural')}
                    ):
                  </td>
                  <td>
                    {bindInput(this,
                      ['attr', 'pluralNames', language],
                      { id: 'inp-name-plural-' + language },
                    )}
                    {ValidationUtils.render(this.state.validationErrors, 'pluralNames.' + language)}
                  </td>
                </tr>,
              ]
            })}
            <tr>
              <td>
                {i18n.t('data.attributes.contexts')}
                :
              </td>
              <td>
                {contextsElement}
                {ValidationUtils.render(this.state.validationErrors, 'contexts')}
              </td>
            </tr>
            {// TODO: only applies when contexts include products
            <tr>
              <td>
                {i18n.t('data.attributes.is-optional')}
                :
              </td>
              <td>
                <span id="lbl-optional">
                  {yesOrNo(attr.optional)}
                </span>
              </td>
            </tr>}
            <tr>
              <td>
                {i18n.t('data.attributes.freeform-for-custom')}
                :
              </td>
              <td>
                {textForCustomElement}
              </td>
            </tr>
          </tbody>
        </table>
        {attr.valueGroups.map((valueGroup: ValueGroup, groupIndex) => {
          const { usage } = this.state

          const values = valueGroup.values.slice()

          CommonUtils.sortAsStrings(values, function(value) {
            return value.sortKey || value.labels[userLanguage]
          })

          const fields = Enums.orderedLanguages.map((lang): DataEditorField => {
            return {
              id: 'lang-' + lang,
              header: i18n.t('enum.languages.' + lang),
              type: 'text',
              getValue: (obj) => obj.labels[lang],
              validationKey: (obj) => {
                const objInValues = CommonUtils.findById(valueGroup.values, obj.id)
                const valueIndex = valueGroup.values.indexOf(objInValues)
                return 'valueGroups.' + groupIndex + '.values.' + valueIndex + '.labels.' + lang
              },
            }
          })

          fields.push({
            id: 'sortKey',
            header: i18n.t('data.sort-key'),
            type: 'text',
            getValue: function(obj) {
              return obj.sortKey
            },
            getPlaceholder: function(obj, edits) {
              return edits['lang-' + userLanguage] || obj.labels[userLanguage]
            },
            validationKey: 'sortKey',
          })

          function getNewOrUpdates(fieldValues) {
            const labels = {}

            Enums.orderedLanguages.forEach(function(lang) {
              labels[lang] = fieldValues['lang-' + lang]
            })

            return {
              labels,
              sortKey: fieldValues['sortKey'],
            }
          }

          const isInUse = function(obj) {
            if (valueGroup.category) {
              return (
                valueGroup.category in usage.byCategory &&
                obj.id in usage.byCategory[valueGroup.category]
              )
            }
            else {
              return obj.id in usage.combined
            }
          }

          let deleteGroupButton = null

          if (!valueGroup.values.length) {
            deleteGroupButton = (
              <LoadingButton
                className="btn-delete-group"
                getPromise={() => this.deleteGroup(groupIndex)}
                text={i18n.t('data.attributes.value-group.delete')}
              />
            )
          }

          return (
            <div
              key={groupIndex}
              className="value-group"
              style={{ display: 'inline-block', marginBottom: '1.5em' }}
            >
              <div style={{ border: '1px solid #ddd' }}>
                <div className="pull-right" style={{ padding: '0.4em' }}>
                  {deleteGroupButton}
                </div>
                <div style={{ padding: '0.2em 0.4em', fontSize: '120%' }}>
                  <b>{i18n.t('data.attributes.value-group')}</b>
                </div>
                <table className="value-group-header">
                  <tbody>
                    <tr>
                      <td>
                        {i18n.t('common.category')}
                        :
                      </td>
                      <td>
                        {this.getCategoryElement(groupIndex, valueGroup)}
                      </td>
                    </tr>
                    {Enums.orderedLanguages.map((language) => {
                      const langName = i18n.t('enum.languages.' + language)

                      return (
                        <tr key={language}>
                          <td>
                            {i18n.t('common.name')}
                            {' ('}
                            {langName}
                            {', '}
                            {i18n.t('common.optional')}
                            ):
                          </td>
                          <td>
                            {bindInput(this,
                              ['attr', 'valueGroups', groupIndex, 'names', language],
                              { className: 'inp-group-name-' + language },
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              <DataEditor
                ref={'editor-' + groupIndex}
                canAdd={true}
                canDelete={function(obj) {
                  return !isInUse(obj)
                }}
                skipDeleteConfirmation={true}
                hideSaveButtons={true}
                fields={fields}
                objs={values}
                actions={{
                  add: (newObj) => {
                    newObj._id = this.NEW_ID_PREFIX + this.state.newIdCounter

                    const newValueGroup = update(valueGroup, {
                      values: { $push: [newObj] },
                    })

                    this.setState(
                      {
                        attr: update(this.state.attr, {
                          valueGroups: { $splice: [[groupIndex, 1, newValueGroup]] },
                        }),
                        newIdCounter: this.state.newIdCounter + 1,
                      },
                      EventBus.fireFunc('attribute-editor-rendered'),
                    )

                    return Promise.resolve({ ok: true })
                  },
                  delete: (id) => {
                    const newAttr = CommonUtils.clone(attr)

                    newAttr.valueGroups[groupIndex].values = valueGroup.values.filter(
                      function(value) {
                        return value._id !== id
                      },
                    )

                    this.setState({ attr: newAttr }, EventBus.fireFunc('attribute-editor-rendered'))
                    return Promise.resolve()
                  },
                  change: (id, newObj) => {
                    let sortKeyChanged = false

                    valueGroup.values = valueGroup.values.map(function(value) {
                      if (value._id === id) {
                        const oldSortKey = value.sortKey || value.labels.en
                        const newSortKey = newObj.sortKey || newObj.labels.en

                        if (newSortKey !== oldSortKey) {
                          sortKeyChanged = true
                        }

                        newObj._id = id
                        return newObj
                      }
                      else {
                        return value
                      }
                    })

                    if (sortKeyChanged) {
                      this.forceUpdate()
                    }
                  },
                }}
                getNew={getNewOrUpdates}
                getUpdates={getNewOrUpdates}
                reloadData={function() {
                  return Promise.resolve()
                }}
                validationErrors={this.state.validationErrors}
              />
              {ValidationUtils.render(this.state.validationErrors, 'valueGroups.' + groupIndex + '.values')}
            </div>
          )
        })}
        {ValidationUtils.render(this.state.validationErrors, 'valueGroups', function(error) {
          if (error.type === 'non-empty') {
            return 'At least one value group is required'
          }
        })}
        <div>
          <LoadingButton
            id="btn-add-group"
            getPromise={this.addGroup}
            text={i18n.t('data.attributes.value-group.add')}
          />
          {' '}
          <LoadingButton id="btn-save" getPromise={this.save} text={i18n.t('action.save')} />
          {' '}
          <button onClick={this.backToList}>
            {i18n.t('action.cancel')}
          </button>
        </div>
      </div>
    )
  }
}
