import { Component } from 'react'
import React = require('react')
import toastr from 'toastr/toastr'

import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { Utils } from './utils'

interface ValidationError {
  type: string, // TODO union of constants
}

export type ValidationErrors = Record<string, ValidationError>

export interface WithValidationErrors {
  validationErrors?: ValidationErrors,
}

export type ValidationField = string | { childOf: string }

interface ComponentWithIsMounted<P, S> extends Component<P, S> {
  _isMounted: boolean,
}

/*
 * Usage:
 *  - Wrap save logic with ValidationUtils.clear(this).then(function() { ... });
 *  - Add .catch((error) => ValidationUtils.check(this, error)) to the save operation.
 *  - Add ValidationUtils.render(this.state.validationErrors, 'fieldName') next to fields during render to show the errors.
 *    For more complex error-to-field mapping, an object can be passed instead of the
 *    fieldName string.
 *    Currently supported object structures:
 *     - ValidationUtils.render(this.state.validationErrors, { childOf: 'fieldName' })
 *       Matches validation errors on any child of fieldName
 *       e.g. { childOf: 'prices' } will match an error on prices.MAD
 */

const getErrors = (error: Error) => {
  if ('original' in error) {
    const { original } = error as { original?: any }

    if (original?.status === 409 && original.body.errorType === 'validation') {
      return original.body.validationErrors as ValidationErrors
    }
  }

  return null
}

const check = <S extends WithValidationErrors>(
  component: ComponentWithIsMounted<unknown, S>,
  error: Error,
) => {
  const errors = getErrors(error)

  if (errors && component._isMounted) {
    component.setState(
      { validationErrors: errors },
      EventBus.fireFunc('validation-errors-rendered'),
    )
  }

  // Promise chains usually have steps after this function that should be skipped
  // if there were validation errors, so we need to re-throw it.
  throw error
}

const clear = (component: Component) => {
  toastr.clear()

  return new Promise<void>(function(resolve) {
    component.setState({ validationErrors: {} }, resolve)
  })
}

const render = (
  errors: ValidationErrors | undefined,
  validationField: ValidationField,
  customMessageFunc?: (error: ValidationError) => string | undefined,
) => {
  if (!errors) {
    return null
  }

  let error: ValidationError | null = null
  let errorField = null

  if (typeof validationField === 'string') {
    error = errors[validationField]
    errorField = validationField
  }
  else if (typeof validationField === 'object') {
    Object.keys(errors).forEach(function(fieldName) {
      const validationError = errors[fieldName]

      if (validationField.childOf) {
        if (Utils.startsWith(fieldName, validationField.childOf + '.')) {
          error = validationError
          errorField = fieldName
        }
      }
    })
  }

  if (error) {
    let message = null

    if (customMessageFunc) {
      message = customMessageFunc(error)
    }

    if (!message) {
      message = i18n.t('validation.' + error.type)
    }

    // TODO deprecate id usage in tests, use class and data-fieldname instead
    return (
      <div
        id={'validation-error-' + errorField.replace(/\./g, '-')}
        className="validation-error"
        data-fieldname={errorField}
        style={{ color: 'red', fontSize: '80%' }}
      >
        {message}
      </div>
    )
  }
}

export const ValidationUtils = { getErrors, check, clear, render }
