import React = require('react')

import { i18n } from '../common/i18n'
import { GridSelection, GridSelectionProps } from './grid-selection'

interface MonthSelectionProps {
  value?: string,
  onChange: (year: string) => void,
}

export class MonthSelection extends React.Component<MonthSelectionProps> {
  getOptions = () => {
    const opts = []
    for (let idx = 1; idx <= 12; idx += 1) {
      const val = idx < 10 ? '0' + idx.toString() : idx.toString()

      opts.push(
        <option key={val} value={val}>
          {i18n.t('short-months.' + idx)}
        </option>,
      )
    }
    return opts
  }

  render() {
    const props: GridSelectionProps = {
      className: 'grid-selection-month',
      type: 'primary',
      value: this.props.value,
      onChange: this.props.onChange,
    }
    return (
      <GridSelection {...props}>
        {this.getOptions()}
      </GridSelection>
    )
  }
}
