import Immutable = require('immutable')
import type { ObjectId } from 'mongodb'
import { SuperAgentRequest } from 'superagent'

import { strnatcmp } from '../client-lib'
import { AttributeDefinition } from './data-attribute-defs'
import { Location } from './data-locations'
import { Category } from './data-misc'
import { Prices, ProductDefinition } from './data-product-defs'
import { Product } from './data-products'
import { TemplateDefinition } from './data-template-defs'
import { UserWithCountry } from './data-users'
import { Language } from './enums'
import { i18n } from './i18n'
import { AttributeCombination } from './types'

interface CompareResult {
  key?: string,
  type: 'array-length-mismatch' | 'extra-key' | 'missing-key' | 'value-mismatch',
}

interface CustomError extends Error {
  customErrorData: unknown,
}

export interface CombinationDetails {
  label: string,
  sortKey: string,
}

export interface DetailedEntry {
  productId: string,
  name: string,
  category: string,
  attributes: Record<string, CombinationDetails>,
  inventoryAmount: number,
  entryAmount: string,
  price: number,
  note: string,
  materialName: string,
  orderId?: string,
  priceOverride?: string,
}

function twoDigits(number: number) {
  if (number < 0) {
    throw new Error('negative values not supported')
  }

  if (number >= 100) {
    throw new Error('value must be less than 100')
  }

  return String(100 + Number(number)).substr(1)
}

const transform = (
  obj: unknown,
  replaceKey?: (key: string) => string,
  replaceValue?: (value: unknown) => unknown,
): unknown => {
  if (replaceValue) {
    const transformed = replaceValue(obj)

    if (transformed !== undefined) {
      return transformed
    }
  }

  if (Array.isArray(obj)) {
    return obj.map(function(element) {
      return transform(element, replaceKey, replaceValue)
    })
  }
  else if (obj && typeof obj === 'object') {
    if (obj instanceof Date) {
      return new Date(obj)
    }

    if (obj.constructor !== Object) {
      throw new Error('Cloning not supported for instances of ' + obj.constructor.name)
    }

    const clone = {}

    Object.keys(obj).forEach(function(key) {
      const newKey = replaceKey ? replaceKey(key) : key
      clone[newKey] = transform(obj[key], replaceKey, replaceValue)
    })

    return clone
  }
  else {
    return obj
  }
}

const mongoEscapeValue = (value: unknown): unknown => {
  if (CommonUtils.isObjectId(value)) {
    return value // Avoid cloning class instance
  }
}

let now = null

export const CommonUtils = {
  clone: function<T>(obj: T): T {
    // Transform without replacers
    return transform(obj) as T
  },

  mongoEscapeKey: function(key: string): string {
    return key.replace(/\$/gi, '%').replace(/\./gi, ':')
  },

  mongoUnescapeKey: function(key: string): string {
    return key.replace(/%/gi, '$').replace(/:/gi, '.')
  },

  isObjectId: function(id: unknown): boolean {
    // Can't use a simple instanceof check because it doesn't always work when
    // ObjectIDs are created by different versions of mongodb packages.
    // Even id.constructor.name isn't guaranteed to always be 'ObjectID'.

    return (
      id &&
      typeof id === 'object' &&
      typeof (id as { toHexString?: unknown }).toHexString === 'function'
    )
  },

  // Replaces '$' and '.' in keys so we can store the object in MongoDB
  mongoEscape: function(obj: unknown): unknown {
    return transform(obj, CommonUtils.mongoEscapeKey, mongoEscapeValue)
  },

  mongoUnescape: function(obj: unknown): unknown {
    return transform(obj, CommonUtils.mongoUnescapeKey)
  },

  // Case-insensitive
  contains: function(haystack: string, needle: string): boolean {
    return haystack.toLowerCase().indexOf(needle.toLowerCase()) !== -1
  },

  arrayContains: function<T>(array: T[], element: T): boolean {
    return array.indexOf(element) !== -1
  },

  compare: function<T, U>(inActual: T, inExpected: T, transformValue: (value: T) => U): CompareResult[] {
    const actual = transformValue ? transformValue(inActual) : inActual
    const expected = transformValue ? transformValue(inExpected) : inExpected

    const result: CompareResult[] = []

    function prefixSubResults(prefix: string | number, subResults: CompareResult[]) {
      subResults.forEach(function(subResult) {
        const key = 'key' in subResult ? prefix + '.' + subResult.key : String(prefix)
        result.push({ key, type: subResult.type })
      })
    }

    if (Array.isArray(actual) && Array.isArray(expected)) {
      if (actual.length !== expected.length) {
        result.push({ type: 'array-length-mismatch' })
      }
      else {
        actual.forEach(function(element, index) {
          prefixSubResults(index, CommonUtils.compare(element, expected[index], transformValue))
        })
      }
    }
    else if (actual && typeof actual === 'object' && expected && typeof expected === 'object') {
      Object.keys(actual).forEach(function(key) {
        if (key in expected) {
          prefixSubResults(key, CommonUtils.compare(actual[key], expected[key], transformValue))
        }
        else {
          result.push({ key, type: 'extra-key' })
        }
      })

      Object.keys(expected).forEach(function(key) {
        if (!(key in actual)) {
          result.push({ key, type: 'missing-key' })
        }
      })
    }
    else {
      if (actual !== expected) {
        result.push({ type: 'value-mismatch' })
      }
    }

    return result
  },

  equals: function<T, U>(inValue1: T, inValue2: T, transformValue?: (value: T) => U): boolean {
    const value1 = transformValue ? transformValue(inValue1) : inValue1
    const value2 = transformValue ? transformValue(inValue2) : inValue2

    if (Array.isArray(value1) && Array.isArray(value2)) {
      return value1.length === value2.length && value1.every(function(element, index) {
        return CommonUtils.equals(element, value2[index], transformValue)
      })
    }
    else if (value1 && typeof value1 === 'object' && value2 && typeof value2 === 'object') {
      const keys1 = Object.keys(value1)
      const keys2 = Object.keys(value2)

      return keys1.length === keys2.length && keys1.every(function(key) {
        return key in value2 && CommonUtils.equals(value1[key], value2[key], transformValue)
      })
    }
    else {
      return value1 === value2
    }
  },

  member: function<T, K extends keyof T>(map: T, key: K, def: T[K]): T[K] {
    if (!(key in map)) {
      map[key] = def
    }

    return map[key]
  },

  removeFromArray: function<T>(array: T[], value: T): void {
    const index = array.indexOf(value)

    if (index === -1) {
      throw new Error('value not found in array')
    }

    array.splice(index, 1)
  },

  indexById: function<K, T extends { _id: K }>(array: T[]): Immutable.Map<K, T> {
    const mapConstructionArray = array.map(function(obj): [K, T] {
      return [obj._id, obj]
    })

    return Immutable.Map(mapConstructionArray)
  },

  find: <T>(array: T[], matchFn: (value: T) => boolean): T | null => {
    if (!array) {
      return null
    }

    const matches = array.filter(matchFn)

    if (matches.length > 1) {
      throw new Error('Found more than one match')
    }

    return matches.length === 1 ? matches[0] : null
  },

  findByField: <T, K extends keyof T>(array: T[], fieldName: K, value: T[K]): T | null => {
    return CommonUtils.find(array, function(element) {
      return element[fieldName] === value
    })
  },

  findById: <T extends { _id: unknown }>(array: T[], id: T['_id']): T | null => {
    return CommonUtils.findByField(array, '_id', id)
  },

  sortAsStrings: function<T>(array: T[], getString: (value: T) => string): void {
    array.sort(function(element1, element2) {
      return strnatcmp(getString(element1), getString(element2))
    })
  },

  pricesEqual: function(price1: number, price2: number): boolean {
    return CommonUtils.round(price1) === CommonUtils.round(price2)
  },

  // TODO: rename to roundPrice?
  round: function(value: number): number {
    return parseInt(String(value * 100 + (value > 0 ? 0.5 : -0.5))) / 100
  },

  // Expects value to be a positive integer string
  breakThousands: function(value: string): string {
    if (/^[0-9]+$/.test(value) && value.length > 3) {
      const index = value.length - 3
      return CommonUtils.breakThousands(value.substr(0, index)) + ' ' + value.substr(index)
    }
    else {
      return value
    }
  },

  formatDecimal: function(valueParam: number, omitTrailingZeroes = false): string {
    if (isNaN(valueParam)) {
      throw new Error('Invalid number')
    }

    const negative = valueParam < 0
    const value = negative ? -valueParam : valueParam
    let str = String(parseInt(String(value * 100 + 0.5)))

    while (str.length < 3) {
      str = '0' + str
    }

    const full = (negative ? '-' : '') + CommonUtils.breakThousands(str.substring(0, str.length - 2))
    let frac = str.substring(str.length - 2)

    if (omitTrailingZeroes) {
      if (frac === '00') {
        return full
      }
      else if (frac[1] === '0') {
        frac = frac[0]
      }
    }

    return full + '.' + frac
  },

  stringOrObjectIdsEqual: function<T extends string | ObjectId>(id1: T, id2: T): boolean {
    if (typeof id1 === 'object' && typeof id2 === 'object') {
      return id1.equals(id2)
    }
    else {
      return id1 === id2
    }
  },

  getNow: function(): Date {
    return now ? new Date(now) : new Date()
  },

  // Only meant to be used in tests
  setNow: function(nowParam: string | Date): void {
    now = new Date(nowParam)
  },

  toUserTime: function(country: { gmtOffset: number }, date?: Date): Date {
    const timestamp = (date || CommonUtils.getNow()).getTime()
    return new Date(timestamp + country.gmtOffset * 3600000)
  },

  userDateToUTC: function(country: { gmtOffset: number }, dateYMD: string, timeHHMM: string): Date {
    let timezone = 'Z'

    if (country.gmtOffset !== 0) {
      // ex: '+02:00', '-01:00'
      timezone = (
        (country.gmtOffset > 0 ? '+' : '-') +
        twoDigits(Math.abs(country.gmtOffset)) + ':00'
      )
    }

    // Create date from ISO-8601 string
    return new Date(dateYMD + 'T' + timeHHMM + timezone)
  },

  utcDateYMD: function(date: Date): string {
    return (
      date.getUTCFullYear() + '-' +
      twoDigits(date.getUTCMonth() + 1) + '-' +
      twoDigits(date.getUTCDate())
    )
  },

  utcDateDMY: function(date: Date): string {
    return (
      twoDigits(date.getUTCDate()) + '/' +
      twoDigits(date.getUTCMonth() + 1) + '/' +
      date.getUTCFullYear()
    )
  },

  utcTime: function(date: Date): string {
    return (
      twoDigits(date.getUTCHours()) + ':' +
      twoDigits(date.getUTCMinutes())
    )
  },

  utcDateTime: function(date: Date): string {
    return CommonUtils.utcDateYMD(date) + ' ' + CommonUtils.utcTime(date)
  },

  utcReadableTimestamp: function(dateParam?: Date): string {
    const date = dateParam || CommonUtils.getNow()

    return (
      CommonUtils.utcDateYMD(date) + '-' +
      twoDigits(date.getUTCHours()) + '-' +
      twoDigits(date.getUTCMinutes()) + '-' +
      twoDigits(date.getUTCSeconds())
    )
  },

  getCurrentYear: function(): string {
    const date = CommonUtils.getNow()
    return date.getFullYear().toString()
  },

  getCurrentMonth: function(): string {
    const date = CommonUtils.getNow()
    const intMonth = date.getMonth() + 1
    return intMonth < 10 ? '0' + intMonth.toString() : intMonth.toString()
  },

  getLastDayOfMonth: function(date: Date): Date {
    const newDate = new Date(date)
    const month = newDate.getUTCMonth()
    newDate.setUTCMonth(month + 1) // next month
    newDate.setUTCDate(0) // last day of the previous month
    return newDate
  },

  getLastDayOfMonthYmd: function(date: Date): string {
    const lastDay = CommonUtils.getLastDayOfMonth(date)
    return CommonUtils.utcDateYMD(lastDay)
  },

  throwCustomError: function(errorData: unknown): never {
    throw CommonUtils.getCustomError(errorData)
  },

  getCustomError: function(errorDataParam: unknown): CustomError {
    let errorData = errorDataParam

    if (typeof errorData === 'string') {
      const message = errorData
      errorData = { errorType: 'custom', errorMessage: message }
    }

    const customError = new Error('Custom error') as CustomError
    customError.customErrorData = errorData
    return customError
  },

  generateUniqueId: function(
    string: string,
    shouldSkipId: (id: string) => Promise<boolean>,
  ): Promise<string> {
    function stringToId(str: string): string {
      // Convert to lowercase and replace sequences of
      // non-alphanumeric characters with single dashes
      return str.toLowerCase().replace(/[^a-z0-9]/g, ' ').trim().replace(/ +/g, '-')
    }

    const potentialId = stringToId(string || '')

    function tryIndex(index: number): Promise<string> {
      let id = potentialId

      if (index > 0) {
        id += '-' + index
      }

      return shouldSkipId(id).then(function(shouldSkip) {
        return shouldSkip ? tryIndex(index + 1) : id
      })
    }

    return tryIndex(0)
  },

  getUserFields: function<T>(user: UserWithCountry<T>): Omit<UserWithCountry<T>, 'passwordHash'> {
    // Return safe fields
    return {
      _id: user._id,
      username: user.username,
      givenName: user.givenName,
      familyName: user.familyName,
      country: user.country,
      language: user.language,
      role: user.role,
      allowedLocations: user.allowedLocations,
    }
  },

  requestToPromise: function<T>(req: SuperAgentRequest): Promise<T> {
    return new Promise<T>(function(resolve, reject) {
      req.end(function(err, res) {
        if (err) {
          reject(err)
        }
        else if (res.error) {
          const error: Error & { status?: number, body?: string, text?: string } = new Error(res.error.message)

          error.status = res.status
          error.body = res.body
          error.text = res.text

          reject(error)
        }
        else {
          resolve(res.body)
        }
      })
    })
  },

  // TODO: separate util modules for attribute-specific stuff, etc?

  attributeContextFilters: {
    materials: (attributeDefinition: AttributeDefinition): boolean => {
      return CommonUtils.arrayContains(attributeDefinition.contexts, 'materials')
    },
    templates: (attributeDefinition: AttributeDefinition): boolean => {
      return CommonUtils.arrayContains(attributeDefinition.contexts, 'templates')
    },
    products: (attributeDefinition: AttributeDefinition): boolean => {
      return CommonUtils.arrayContains(attributeDefinition.contexts, 'products')
    },
    templateProductDiff: (attributeDefinition: AttributeDefinition): boolean => {
      return (
        CommonUtils.arrayContains(attributeDefinition.contexts, 'products') &&
        !CommonUtils.arrayContains(attributeDefinition.contexts, 'templates')
      )
    },
  },

  getCombinationDetails: (
    combination: AttributeCombination,
    attribute: AttributeDefinition,
    categoryId: string,
    language: Language,
  ): CombinationDetails => {
    if (!combination && attribute.optional) {
      return { label: '', sortKey: '' }
    }
    else {
      return {
        label: CommonUtils.getAttributeComboLabel(
          attribute, combination.values, categoryId, language,
        ),

        sortKey: CommonUtils.getAttributeComboSortKey(
          attribute, combination.values, categoryId, language,
        ),
      }
    }
  },

  // TODO: reduce duplication?

  getAttributeDetails: (
    product,
    attrDefs: AttributeDefinition[],
    prodDef,
    tplDefs: TemplateDefinition[],
    language: Language,
  ): Record<string, CombinationDetails> => {
    const attributes: Record<string, CombinationDetails> = {}

    attrDefs.forEach(function(attribute) {
      let attrDetails: CombinationDetails

      if (prodDef.isCustom && attribute.textForCustom) {
        const text = prodDef.attributeCombinations[attribute._id]
        attrDetails = { label: text, sortKey: text }
      }
      else {
        const combination = CommonUtils.getCombination(product, prodDef, attribute, tplDefs)

        attrDetails = CommonUtils.getCombinationDetails(
          combination, attribute, prodDef.category, language,
        )
      }

      attributes[attribute._id] = attrDetails
    })

    return attributes
  },

  getAttributeLabel: (
    attribute: AttributeDefinition,
    valueId: string,
    categoryId: string,
    language: Language,
  ): string => {
    let label: string | null = null

    attribute.valueGroups.forEach(function(valueGroup) {
      if (valueGroup.category && valueGroup.category !== categoryId) {
        // Continue to next
        return
      }

      const attrValue = CommonUtils.findById(valueGroup.values, valueId)

      if (attrValue) {
        label = attrValue.labels[language]
      }
    })

    if (!label) {
      throw new Error('Invalid value id: ' + attribute._id + ' - ' + valueId)
    }

    return label
  },

  getAttributeComboLabel: (
    attribute: AttributeDefinition,
    values: string[],
    categoryId: string,
    language: Language,
  ): string => {
    if (!values) {
      // TODO: log warning through API?
      console.warn('No combination passed to getAttributeComboLabel')
      return null
    }

    const labels = values.map(function(valueId) {
      return CommonUtils.getAttributeLabel(attribute, valueId, categoryId, language)
    })

    return labels.join('/')
  },

  getAttributeSortKey: (
    attribute: AttributeDefinition,
    valueId: string,
    categoryId: string,
    language: Language,
  ): string => {
    let sortKey: string | null = null

    attribute.valueGroups.forEach(function(valueGroup) {
      if (valueGroup.category && valueGroup.category !== categoryId) {
        // Continue to next
        return
      }

      const attrValue = CommonUtils.findById(valueGroup.values, valueId)

      if (attrValue) {
        sortKey = attrValue.sortKey || attrValue.labels[language]
      }
    })

    if (!sortKey) {
      throw new Error('Invalid value id: ' + attribute._id + ' - ' + valueId)
    }

    return sortKey
  },

  getAttributeComboSortKey: (
    attribute: AttributeDefinition,
    values: string[],
    categoryId: string,
    language: Language,
  ): string => {
    if (!values) {
      // TODO: log warning through API?
      console.warn('No combination passed to getAttributeComboSortKey')
      return null
    }

    const sortKeys = values.map(function(valueId) {
      return CommonUtils.getAttributeSortKey(attribute, valueId, categoryId, language)
    })

    return sortKeys.join('/')
  },

  getCombinationIdFilter: function(combinationIds) {
    return function(combination) {
      return CommonUtils.arrayContains(combinationIds, combination.id)
    }
  },

  // TODO: take single templateDefinition as parameter
  getCombination: (
    inventoryItem,
    definition,
    attributeDefinition: AttributeDefinition,
    templateDefinitions: TemplateDefinition[],
  ): AttributeCombination => {
    let combinations: AttributeCombination[] = definition.attributeCombinations[attributeDefinition._id]

    if (
      definition.template &&
      CommonUtils.attributeContextFilters.templates(attributeDefinition)
    ) {
      const templateDefinition = CommonUtils.find(templateDefinitions, function(tplDef) {
        return CommonUtils.stringOrObjectIdsEqual(tplDef._id, definition.template)
      })

      const combinationIds = combinations

      combinations = templateDefinition.attributeCombinations[attributeDefinition._id].filter(
        CommonUtils.getCombinationIdFilter(combinationIds),
      )
    }

    const combinationId = inventoryItem.attributes[attributeDefinition._id]
    return CommonUtils.findByField(combinations, 'id', combinationId)
  },

  getPrices: function(
    item: { attributes: Record<string, number> },
    definition: ProductDefinition,
  ): Prices {
    let { prices } = definition

    // TODO: cache calculated prices in the db as we'll probably need bulk lookups later?

    if (definition.priceOverrides) {
      definition.priceOverrides.forEach(function(override) {
        const matches = Object.keys(override.attrs).every(function(attrId) {
          const expectedComboId = override.attrs[attrId]
          const actualComboId = item.attributes[attrId]
          return actualComboId === expectedComboId
        })

        if (matches) {
          prices = override.prices
        }
      })
    }

    return prices
  },

  getCategoryName: (
    productDefinition,
    categories: Category[],
    language: Language,
  ): string => {
    if (productDefinition.isCustom) {
      return i18n.t('common.custom')
    }
    else {
      const category = CommonUtils.findById(categories, productDefinition.category)
      return category.labels[language]
    }
  },

  getDetailedEntry: (
    product: Product,
    categories: Category[],
    attrDefs: AttributeDefinition[],
    tplDefs: TemplateDefinition[],
    prodDef: ProductDefinition,
    amount: string,
    loc: Location,
    language: Language,
    materialName: string | undefined,
  ): DetailedEntry => {
    const categoryName = CommonUtils.getCategoryName(prodDef, categories, language)
    const prices = CommonUtils.getPrices(product, prodDef)

    const detailedEntry: DetailedEntry = {
      productId: product._id,
      name: prodDef.name,
      category: categoryName,
      attributes: CommonUtils.getAttributeDetails(product, attrDefs, prodDef, tplDefs, language),
      inventoryAmount: product.amount,
      entryAmount: amount,
      price: prices[loc.currency],
      note: product.note,
      materialName,
    }

    if (prodDef.isCustom) {
      detailedEntry.orderId = prodDef.orderId
    }

    return detailedEntry
  },

  getLatestTransferTime: function(transfer) {
    return transfer.timeAccepted || transfer.timeSent || transfer.timeCreated
  },

  isSalesPoint: function(loc: Location): boolean {
    return loc.type === 'storage' || loc.type === 'shop'
  },

  isCriticalError: function(errorLogEntry): boolean {
    const { error } = errorLogEntry

    if ('customErrorData' in error) {
      const errorData = error.customErrorData
      const { errorType } = errorData

      if (errorType === 'validation') {
        return Object.keys(errorData.validationErrors).some(function(key) {
          const valError = errorData.validationErrors[key]
          return valError.type === 'unknown-keys'
        })
      }
      else if (errorType === 'no-permissions' || errorType === 'custom') {
        // TODO: some custom errors may be critical?
        return false
      }
      else {
        return true
      }
    }
    else {
      return true
    }
  },
}
