import { useEffect } from 'react'
import React = require('react')

import { CombinationDetails, CommonUtils } from '../common/common-utils'
import { AttributeDefinition } from '../common/data-attribute-defs'
import { Location } from '../common/data-locations'
import { ProductDefinition } from '../common/data-product-defs'
import { Product } from '../common/data-products'
import { TemplateDefinition } from '../common/data-template-defs'
import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { UiInvoiceEntry } from '../common/invoice-utils'
import { getDefaultCancelButton, Modal } from './modal'
import { Column, UiUtils } from './ui-utils'
import { useOpenModalEvent } from './use-open-modal-event'
import { User } from './user'

interface Row {
  id: string,
  index: number,
  attributes: Record<string, CombinationDetails>,
  amount: number,
  price: number,
}

export interface ChooseItemModalParams {
  mode?: 'add' | 'change',
  productDefinition?: ProductDefinition,
  products: Product[],
  updateNewEntries?: (newProduct: Product, newEntries: UiInvoiceEntry[]) => void,
}

interface ChooseItemModalProps {
  params: ChooseItemModalParams,
  attributeDefinitions: AttributeDefinition[],
  templateDefinitions: TemplateDefinition[],
  location: Location,
  replaceItem: (product: Product) => Promise<void>,
  modalId: string,
}

export const ChooseItemModal = (props: ChooseItemModalProps) => {
  const [isVisible, close] = useOpenModalEvent(props.modalId)

  useEffect(() => {
    if (isVisible) {
      // On open
      EventBus.fire('choose-item-modal-opened')
    } else {
      // On close
      EventBus.fire('choose-item-modal-closed')
    }
  }, [isVisible])

  if (!isVisible) {
    return <div />
  }

  const getColumnConf = () => {
    const columnConf: Column<Row>[] = []

    props.attributeDefinitions.forEach(function(attr) {
      columnConf.push({
        id: 'attr-' + attr._id,
        header: attr.names[User.getLanguage()],
        getCellContents: function(rowData) {
          return rowData.attributes[attr._id].label
        },
      })
    })

    columnConf.push({
      id: 'amount',
      header: i18n.t('common.amount'),
      getCellContents: function(rowData) {
        return rowData.amount
      },
    })

    // TODO: highlight if price is different from the item being replaced?
    columnConf.push({
      id: 'unit-price',
      header: i18n.t('common.unit-price'),
      getCellContents: function(rowData) {
        return rowData.price
      },
    })

    columnConf.push({
      id: 'action',
      header: i18n.t('action.action'),
      getCellContents: (rowData) => {
        return (
          <img
            className="table-btn btn-select"
            title={i18n.t('action.select')}
            src="img/check.png"
            onClick={() => {
              const product = props.params.products[rowData.index]
              props.replaceItem(product).then(close)
            }}
          />
        )
      },
    })

    return columnConf
  }

  const language = User.getLanguage()
  const { productDefinition } = props.params

  const rowsData = props.params.products.map((product, index): Row => {
    const prices = CommonUtils.getPrices(product, productDefinition)

    return {
      id: product._id,
      index,
      attributes: CommonUtils.getAttributeDetails(
        product,
        props.attributeDefinitions,
        productDefinition,
        props.templateDefinitions,
        language,
      ),
      amount: product.amount,
      price: prices[props.location.currency],
    }
  })

  const columnConf = getColumnConf()

  let text1: string
  let text2: string

  if (props.params.mode === 'change') {
    text1 = i18n.t('invoice.select-different-variation.1')
    text2 = i18n.t('invoice.select-different-variation.2')
  }
  else {
    text1 = i18n.t('invoice.select-variation.1')
    text2 = i18n.t('invoice.select-variation.2')
  }

  return (
    <div>
      <Modal
        title={i18n.t('invoice.choose-item')}
        closeModal={close}
        dialogClassName="choose-item"
        buttons={[getDefaultCancelButton()]}
      >
        <div style={{ marginBottom: '0.3em' }}>
          {text1}
          {' '}
          <b id="lbl-choose-product-name">
            {productDefinition.name}
          </b>
          {' '}
          {text2}
          {' '}
          <b id="lbl-choose-location-name">
            {props.location.names[language]}
          </b>
          :
        </div>
        {UiUtils.getTable(
          columnConf,
          rowsData,
          { tableId: 'tbl-choose-item', rowClassName: 'row-choose' },
        )}
      </Modal>
    </div>
  )
}
