import React = require('react')

import { SettingsInput } from '../common/data-misc'
import { getDataService } from '../common/data-service'
import { EventBus } from '../common/event-bus'
import { BackToIndexLink } from './back-to-index-link'
import { bindInput } from './bind-utils'
import { EmptyObject } from './empty-object'
import { LoadingButton } from './loading-button'
import { MainMenu } from './main-menu'
import { router } from './router'
import { Settings } from './settings'
import { Utils } from './utils'
import { ValidationErrors, ValidationUtils } from './validation-utils'

interface State {
  loaded: boolean,
  guideCommission?: number,
  cardCommission?: number,
  vatPercentage?: number,
  marraCashCardDiscount?: number,
  droitsDeTimbre?: number,
  eurRate?: number,
  usdRate?: number,
  validationErrors?: ValidationErrors,
}

export class SettingsEditor extends React.Component<EmptyObject, State> {
  state: State = { loaded: false }

  _isMounted = false

  componentDidMount() {
    this._isMounted = true
    this.reloadData()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  reloadData = () => {
    return getDataService().Settings.get()
    .then((settings) => {
      this.setState(
        {
          loaded: true,
          guideCommission: settings.guideCommission,
          cardCommission: settings.cardCommission,
          vatPercentage: settings.vatPercentage,
          marraCashCardDiscount: settings.marraCashCardDiscount,
          droitsDeTimbre: settings.droitsDeTimbre,
          eurRate: settings.eurRate,
          usdRate: settings.usdRate,
        },
        EventBus.fireFunc('settings-rendered'),
      )
    })
  }

  save = () => {
    return ValidationUtils.clear(this)
    .then(() => {
      const settings: SettingsInput = {
        guideCommission: this.state.guideCommission.toString(),
        cardCommission: this.state.cardCommission.toString(),
        vatPercentage: this.state.vatPercentage.toString(),
        marraCashCardDiscount: this.state.marraCashCardDiscount.toString(),
        droitsDeTimbre: this.state.droitsDeTimbre.toString(),
        eurRate: this.state.eurRate.toString(),
        usdRate: this.state.usdRate.toString(),
      }

      return getDataService().Settings.set(settings)
    })
    .then(function() {
      router.setRoute('/data')
      Settings.clearCache()
    })
    .catch((error) => ValidationUtils.check(this, error))
  }

  getInputProps = (fieldName) => {
    return bindInput(this, [fieldName], {
      id: 'inp-' + fieldName,
      validator: Utils.nonNegativeDecimalValidator,
      style: { width: '4em' },
    })
  }

  render() {
    const mainMenu = <MainMenu activeTab="data" />

    if (!this.state.loaded) {
      return (
        <div>
          {mainMenu}
          Loading...
        </div>
      )
    }

    return (
      <div>
        {mainMenu}
        <BackToIndexLink />
        <h3>Commissions</h3>
        <div>
          <div style={{ display: 'inline-block', width: '13em' }}>
            {'Guide\'s commission: '}
          </div>
          {this.getInputProps('guideCommission')}
          {' %'}
          {ValidationUtils.render(this.state.validationErrors, 'guideCommission')}
        </div>
        <div>
          <div style={{ display: 'inline-block', width: '13em', marginTop: '0.5em' }}>
            {'Card payment commission: '}
          </div>
          {this.getInputProps('cardCommission')}
          {' %'}
          {ValidationUtils.render(this.state.validationErrors, 'cardCommission')}
        </div>
        <div>
          <div style={{ display: 'inline-block', width: '13em', marginTop: '0.5em' }}>
            {'VAT percentage: '}
          </div>
          {this.getInputProps('vatPercentage')}
          {' %'}
          {ValidationUtils.render(this.state.validationErrors, 'vatPercentage')}
        </div>
        <div>
          <div style={{ display: 'inline-block', width: '13em', marginTop: '0.5em' }}>
            {'MarraCashCard discount: '}
          </div>
          {this.getInputProps('marraCashCardDiscount')}
          {' %'}
          {ValidationUtils.render(this.state.validationErrors, 'marraCashCardDiscount')}
        </div>
        <div>
          <div style={{ display: 'inline-block', width: '13em', marginTop: '0.5em' }}>
            {'Droits de timbre: '}
          </div>
          {this.getInputProps('droitsDeTimbre')}
          {' %'}
          {ValidationUtils.render(this.state.validationErrors, 'droitsDeTimbre')}
        </div>
        <h3>Exchange rates</h3>
        <div style={{ margin: '0.2em 0' }}>
          {'1 EUR = '}
          {this.getInputProps('eurRate')}
          {' MAD'}
          {ValidationUtils.render(this.state.validationErrors, 'eurRate')}
        </div>
        <div style={{ margin: '0.2em 0' }}>
          {'1 USD = '}
          {this.getInputProps('usdRate')}
          {' MAD'}
          {ValidationUtils.render(this.state.validationErrors, 'usdRate')}
        </div>
        <div style={{ marginTop: '1.5em' }}>
          <LoadingButton id="btn-save" getPromise={this.save} text="Save" />
          {' '}
          <button
            onClick={function() {
              router.setRoute('/data')
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    )
  }
}
