import React = require('react')

import { CommonUtils } from '../common/common-utils'
import { UserCountry } from '../common/data-misc'
import { getDataService } from '../common/data-service'
import { Enums } from '../common/enums'
import { EventBus } from '../common/event-bus'
import { BackToIndexLink } from './back-to-index-link'
import { DataEditor } from './data-editor'
import { EditorUtils } from './editor-utils'
import { MainMenu } from './main-menu'
import { User } from './user'

interface State {
  loaded: boolean,
  objs: UserCountry[],
}

export class UserCountryEditor extends React.Component<Record<string, never>, State> {
  state: State = { loaded: false, objs: [] }

  componentDidMount() {
    this.reloadData()
  }

  reloadData = () => {
    return getDataService().UserCountries.getAll()
    .then((objs) => {
      CommonUtils.sortAsStrings(objs, function (country) {
        return country.names[User.getLanguage()].toLowerCase()
      })

      this.setState({ loaded: true, objs }, EventBus.fireFunc('countries-rendered'))
    })
  }

  render() {
    const mainMenu = <MainMenu activeTab="data" />

    if (!this.state.loaded) {
      return (
        <div>
          {mainMenu}
          Loading...
        </div>
      )
    }

    const fields = EditorUtils.getLanguageFields('names')

    fields.push({
      id: 'gmt-offset',
      header: 'GMT Offset',
      type: 'number',
      getValue: function(obj) {
        return obj.gmtOffset
      },
      validationKey: 'gmtOffset',
    })

    fields.push({
      id: 'current-time',
      header: 'Current Time',
      type: 'derived',
      getValue: function(obj, edits) {
        let offset = obj.gmtOffset

        if ('gmt-offset' in edits) {
          offset = edits['gmt-offset']
        }

        if (isNaN(offset)) {
          offset = 0
        }

        const time = CommonUtils.toUserTime({ gmtOffset: offset })
        return CommonUtils.utcTime(time)
      },
    })

    function getNewOrUpdates(fieldValues) {
      const names = {}

      Enums.orderedLanguages.forEach(function(lang) {
        names[lang] = fieldValues['lang-' + lang]
      })

      return { names, gmtOffset: Number(fieldValues['gmt-offset']) }
    }

    return (
      <div>
        {mainMenu}
        <BackToIndexLink />
        <DataEditor
          canAdd={true}
          canDelete={true}
          fields={fields}
          objs={this.state.objs}
          actions={EditorUtils.getServiceActions(getDataService().UserCountries)}
          getNew={getNewOrUpdates}
          getUpdates={getNewOrUpdates}
          reloadData={this.reloadData}
        />
      </div>
    )
  }
}
