import React = require('react')

import { CommonUtils } from '../common/common-utils'
import { AttributeDefinition } from '../common/data-attribute-defs'
import { Correction } from '../common/data-correction-report'
import { Location } from '../common/data-locations'
import { Category } from '../common/data-misc'
import { ProductDefinition } from '../common/data-product-defs'
import { Product } from '../common/data-products'
import { getDataService } from '../common/data-service'
import { TemplateDefinition } from '../common/data-template-defs'
import { Template } from '../common/data-templates'
import { i18n } from '../common/i18n'
import { EmptyObject } from './empty-object'
import { Filter } from './filter'
import { FloatingHeader } from './floating-header'
import { MainMenu } from './main-menu'
import { ReportsMenu } from './reports-menu'
import { User } from './user'

interface State {
  loaded: boolean,
  entries: Correction[],
  locations: Location[],
  categories: Category[],
  attributeDefinitions: AttributeDefinition[],
  productDefinitions: ProductDefinition[],
  templateDefinitions: TemplateDefinition[],
  products: Product[],
  templates: Template[],
  usernames?: { _id: string, username: string }[],
}

export class CorrectionReport extends React.Component<EmptyObject, State> {
  state: State = {
    loaded: false,
    entries: undefined,
    locations: undefined,
    categories: undefined,
    attributeDefinitions: undefined,
    productDefinitions: undefined,
    templateDefinitions: undefined,
    products: undefined,
    templates: undefined,
  }
  _isMounted = false

  componentDidMount() {
    this._isMounted = true
    Filter.registerEvent(this)
    const DataService = getDataService()

    Promise.all([
      DataService.CorrectionReport.getAll(),
      DataService.Locations.getAll(),
      DataService.Categories.getAll(),
      DataService.AttributeDefinitions.getAll(),
      DataService.ProductDefinitions.getAll(),
      DataService.TemplateDefinitions.getAll(),

      // TODO: load dynamically only the needed products and templates?
      DataService.Products.getAll(),
      DataService.Templates.getAll(),

      DataService.Users.getUsernames(),
    ])
    .then(([
      entries,
      locations,
      categories,
      attributeDefinitions,
      productDefinitions,
      templateDefinitions,
      products,
      templates,
      usernames,
    ]) => {
      if (!this._isMounted) {
        return
      }

      const filteredAttributeDefinitions = attributeDefinitions.filter(
        CommonUtils.attributeContextFilters.products,
      )

      this.setState({
        loaded: true,
        entries,
        locations,
        categories,
        attributeDefinitions: filteredAttributeDefinitions,
        productDefinitions,
        templateDefinitions,
        products,
        templates,
        usernames,
      })
    })
  }

  componentWillUnmount() {
    this._isMounted = false
    Filter.unregisterEvent(this)
  }

  getUsername = (userId) => {
    const obj = CommonUtils.findById(this.state.usernames, userId)
    return obj.username
  }

  render() {
    const menus = [
      <MainMenu key="main" activeTab="reports" />,
      <ReportsMenu key="reports" activeTab="correction" />,
    ]

    if (!this.state.loaded) {
      return (
        <div>
          {menus}
          {i18n.t('common.loading')}
        </div>
      )
    }

    const language = User.getLanguage()

    const filterConf = { time: { type: 'date-range', labelKey: 'reports.date-time' } }

    const filterManager = Filter.createManager(
      'correction-report', filterConf, this.state.entries,
    )

    const colCount = 8 + this.state.attributeDefinitions.length

    const headerRow = (
      <tr>
        <th>
          {i18n.t('reports.date-time')}
          {filterManager.getIcon('time')}
        </th>
        <th>
          {i18n.t('reports.type')}
        </th>
        <th>
          {i18n.t('common.name')}
        </th>
        <th>
          {i18n.t('common.category')}
        </th>
        {this.state.attributeDefinitions.map(function(attr) {
          return (
            <th key={attr._id}>
              {attr.names[language]}
            </th>
          )
        })}
        <th>
          {i18n.t('common.location')}
        </th>
        <th>
          {i18n.t('reports.from-amount')}
        </th>
        <th>
          {i18n.t('reports.to-amount')}
        </th>
        <th>
          {i18n.t('common.user')}
        </th>
      </tr>
    )

    let entryRows = filterManager.getFiltered().map((entry) => {
      let items, definitions, filteredAttrDefs

      if (entry.type === 'product') {
        items = this.state.products
        definitions = this.state.productDefinitions
        filteredAttrDefs = this.state.attributeDefinitions
      }
      else if (entry.type === 'template') {
        items = this.state.templates
        definitions = this.state.templateDefinitions

        filteredAttrDefs = this.state.attributeDefinitions.filter(
          CommonUtils.attributeContextFilters.templates,
        )
      }
      else {
        throw new Error('Invalid type')
      }

      const item = CommonUtils.findById<any>(items, entry.item)
      const definition = CommonUtils.findById<any>(definitions, item.definition)

      const categoryName = CommonUtils.getCategoryName(definition, this.state.categories, language)
      const loc = CommonUtils.findById(this.state.locations, item.location)
      const userTime = CommonUtils.toUserTime(User.getUser().country, new Date(entry.time))

      const attributeDetails = CommonUtils.getAttributeDetails(
        item, filteredAttrDefs, definition, this.state.templateDefinitions, language,
      )

      return (
        <tr key={entry._id.toString()} className="row-entry">
          <td style={{ whiteSpace: 'nowrap' }}>
            {CommonUtils.utcDateTime(userTime)}
          </td>
          <td>
            {i18n.t('inventory.type.' + entry.type)}
          </td>
          <td>
            {definition.name}
          </td>
          <td>
            {categoryName}
          </td>
          {this.state.attributeDefinitions.map(function(attribute) {
            let label = ''

            if (attribute._id in attributeDetails) {
              label = attributeDetails[attribute._id].label
            }

            return <td key={attribute._id}>{label}</td>
          })}
          <td>
            {loc.names[language]}
          </td>
          <td>
            {entry.fromAmount}
          </td>
          <td>
            {entry.toAmount}
          </td>
          <td>
            {this.getUsername(entry.user)}
          </td>
        </tr>
      )
    })

    if (!entryRows.length) {
      const anyFilters = filterManager.anyFilters()

      entryRows = [
        <tr key="no-items">
          <td colSpan={colCount}>
            {anyFilters ? i18n.t('reports.no-filtered-items') : i18n.t('reports.no-items')}
          </td>
        </tr>,
      ]
    }

    return (
      <div>
        {menus}
        {filterManager.getSummary()}
        <table
          id="tbl-rep-corr"
          className="table table-bordered table-condensed table-striped"
        >
          <FloatingHeader headerRow={headerRow} />
          <tbody>
            {entryRows}
          </tbody>
        </table>
      </div>
    )
  }
}
