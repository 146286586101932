import { CommonUtils } from './common-utils'

type Listener = (payload?: unknown) => void

const listeners: Record<string, Listener[]> = {}
const singleUseListeners: Record<string, Listener[]> = {}

export const EventBus = {
  on: function(eventName: string, listener: Listener) {
    CommonUtils.member(listeners, eventName, []).push(listener)
  },

  off: function(eventName: string, listener: Listener) {
    const array = CommonUtils.member(listeners, eventName, [])
    const index = array.indexOf(listener)

    if (index === -1) {
      throw new Error('Listener not registered')
    }
    else {
      array.splice(index, 1)
    }
  },

  once: function(eventName: string, listener: Listener) {
    CommonUtils.member(singleUseListeners, eventName, []).push(listener)
  },

  fire: function(eventName: string, payload?: unknown) {
    let listenersToRun = []

    if (eventName in listeners) {
      listenersToRun = listeners[eventName]
    }

    if (eventName in singleUseListeners) {
      listenersToRun = listenersToRun.concat(singleUseListeners[eventName])
      singleUseListeners[eventName] = []
    }

    listenersToRun.forEach(function(listener) {
      setTimeout(function() {
        listener(payload)
      }, 0)
    })
  },

  fireFunc: function(eventName: string) {
    return function() {
      EventBus.fire(eventName)
    }
  },
}
