import request = require('superagent')

import { CommonUtils } from '../common/common-utils'
import { router } from './router'
import { User } from './user'

// Note: mocked in client tests via getApi()
export const API = {
  isLoggedIn: function () {
    return CommonUtils.requestToPromise<request.SuperAgentRequest>(request('GET', '/api/isLoggedIn'))
  },

  logIn: function (username, password) {
    const req = request('POST', '/api/login').send({ username, password })

    return CommonUtils.requestToPromise(req)
      .then(function (response: any) {
        User.setUser(response.user)
        return response
      })
  },

  logOut: function () {
    return CommonUtils.requestToPromise(request('POST', '/api/logout'))
      .then(function () {
        User.setUser(null)
      })
  },

  logError: function (error, messageParam) {
    return Promise.resolve()
      .then(function () {
        let message = messageParam
        let stack = null

        if (error && typeof error === 'object') {
          if (!message) {
            message = error.message
          }

          if ('stack' in error) {
            stack = error.stack
          }
        }

        const req = request('POST', '/api/logError')
          .send({
            message,
            stack,
            route: router.getRoute().join('/'),
            userAgent: navigator.userAgent,
          })

        return CommonUtils.requestToPromise(req)
      })
      .catch(function () {
        console.error('Failed to send error details to server')
      })
  },
}
