import React = require('react')

import { Access } from '../common/access'
import { CommonUtils } from '../common/common-utils'
import { getDataService } from '../common/data-service'
import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { bindProps } from './bind-utils'
import { EditAmountModal } from './edit-amount-modal'
import { FloatingHeader } from './floating-header'
import { MainMenu } from './main-menu'
import { router } from './router'
import { TabMenu } from './tab-menu'
import { User } from './user'
import { Utils } from './utils'

interface Location {
  _id: string,
  names: Record<string, string>,
}

interface LocationMenuProps {
  activeTab: string,
  locations: Location[],
}

// TODO: unduplicate with Inventory.LocationMenu?
const LocationMenu = ({ activeTab, locations }: LocationMenuProps) => (
  <TabMenu
    menuId="location"
    pills={true}
    tabs={locations.map((loc) => ({
      id: loc._id,
      route: '/cart/' + loc._id,
      name: loc.names[User.getLanguage()],
    }))}
    activeTab={activeTab}
  />
)

interface CartProps {
  locationId: string,
}

export class Cart extends React.Component<CartProps> {
  state = {
    loaded: false,
    attributeDefinitions: [],
    allLocations: [],
    cartLocations: [],
    entries: [],
    transferLocation: undefined,
  }

  _isMounted = false

  componentDidMount() {
    this._isMounted = true

    if (this.props.locationId) {
      this.loadEverything(this.props)
    }
    else {
      getDataService().Carts.getLocations()
      .then(function(cartLocationIds) {
        if (cartLocationIds.length) {
          router.setRoute('/cart/' + cartLocationIds[0])
        }
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidUpdate(prevProps: CartProps) {
    if (this.props.locationId !== prevProps.locationId) {
      this.loadEverything(this.props)
    }
  }

  loadEverythingNoArgs = () => {
    // A wrapper that ensures no arguments are passed to loadEverything.
    // May otherwise happen when used as a callback to a DataService operation.
    this.loadEverything()
    return null
  }

  // TODO: optimize - split items that need to be initialised once vs ones that need reloading
  loadEverything = (propsParam?) => {
    const props = propsParam || this.props

    // TODO: error if location is not a sales point

    User.setLastLocation('sales-points', props.locationId)

    const DataService = getDataService()
    const allLocsPromise = DataService.Locations.getAll()
    const cartLocsPromise = DataService.Carts.getLocations()
    const attrDefsPromise = DataService.AttributeDefinitions.getAll()
    const entriesPromise = DataService.Carts.getDetailedContents(props.locationId)

    Promise.all([allLocsPromise, cartLocsPromise, attrDefsPromise, entriesPromise])
    .then(([allLocations, cartLocationIds, attributeDefinitions, entries]) => {
      if (!this._isMounted) {
        return
      }

      const cartLocations = allLocations.filter(function(loc) {
        return CommonUtils.arrayContains(cartLocationIds, loc._id)
      })

      const filteredAttrDefs = attributeDefinitions.filter(
        CommonUtils.attributeContextFilters.products,
      )

      const compareFunc = Utils.getDetailCompareFunction(filteredAttrDefs)
      entries.sort(compareFunc)

      this.setState(
        {
          loaded: true,
          allLocations,
          cartLocations,
          attributeDefinitions: filteredAttrDefs,
          entries,
        },
          EventBus.fireFunc('cart-rendered'),
      )
    })
  }

  getLocation = () => {
    return CommonUtils.findById(this.state.allLocations, this.props.locationId)
  }

  addMore = () => {
    router.setRoute('/inventory/sales-points/' + this.props.locationId)
  }

  sell = () => {
    router.setRoute('/invoice/new/' + this.props.locationId)
  }

  transfer = () => {
    router.setRoute(
      '/transfers/new-from-cart/' + this.props.locationId +
      '/' + this.state.transferLocation,
    )
  }

  getEntryRow = (entry) => {
    const dynamicAttributeCells = this.state.attributeDefinitions.map(function(attribute) {
      return (
        <td key={attribute._id}>
          {entry.attributes[attribute._id].label}
        </td>
      )
    })

    const editAmountModalId = 'add-amount-modal-' + entry.productId

    let amountError = null

    if (entry.entryAmount > entry.inventoryAmount) {
      amountError = (
        <div style={{ fontSize: '80%', color: '#c00' }}>
          {i18n.t('cart.not-enough-in-inv')}
        </div>
      )
    }

    const loc = this.getLocation()

    let price

    if (entry.price || entry.price === 0) {
      price = CommonUtils.formatDecimal(entry.price, true) + ' ' + loc.currency
    }
    else {
      price = 'N/A'
    }

    return (
      <tr className="row-cart" key={entry.productId}>
        <td>
          {entry.name}
        </td>
        <td>
          {entry.category}
        </td>
        {loc.showOrderId && <td>{entry.orderId}</td>}
        {dynamicAttributeCells}
        <td>
          {price}
        </td>
        <td>
          {entry.entryAmount}
          {amountError}
        </td>
        <td className="note-column">
          {entry.note}
        </td>
        <td>
          {this.renderEditButton(editAmountModalId)}
          {this.renderDeleteButton(entry.productId)}
          <EditAmountModal
            modalId={editAmountModalId}
            entry={entry}
            afterSave={this.loadEverythingNoArgs}
          />
        </td>
      </tr>
    )
  }

  renderEditButton = (editAmountModalId) => {
    return (
      <img
        className="table-btn btn-edit-amount"
        title={i18n.t('cart.edit-amount')}
        src="img/edit.png"
        onClick={() => {
          EventBus.fire('open-modal', { modalId: editAmountModalId })
        }}
      />
    )
  }

  renderDeleteButton = (productId) => {
    return (
      <img
        className="table-btn btn-delete"
        title={i18n.t('action.delete')}
        src="img/delete.png"
        onClick={() => {
          Utils.confirmTr('confirm.delete.item')
          .then((confirmed) => {
            if (confirmed) {
              return getDataService().Carts.editAmount(productId, '0')
              .then(EventBus.fireFunc('cart-updated'))
              .then(this.loadEverythingNoArgs)
            }
          })
        }}
      />
    )
  }

  renderEntries = () => {
    const loc = this.getLocation()

    const headerRow = (
      <tr>
        <th>
          {i18n.t('common.name')}
        </th>
        <th>
          {i18n.t('common.category')}
        </th>
        {loc.showOrderId && <th>{i18n.t('inventory.order-id')}</th>}
        {this.state.attributeDefinitions.map(function(attr) {
          return (
            <th key={attr._id}>
              {attr.pluralNames[User.getLanguage()]}
            </th>
          )
        })}
        <th>
          {i18n.t('common.price')}
        </th>
        <th>
          {i18n.t('cart.amount-in-cart')}
        </th>
        <th>
          {i18n.t('common.note')}
        </th>
        <th>
          {i18n.t('action.action')}
        </th>
      </tr>
    )

    let entryRows = this.state.entries.map(this.getEntryRow)

    if (!entryRows.length) {
      const colCount = 6 + this.state.attributeDefinitions.length + (loc.showOrderId ? 1 : 0)

      entryRows = [
        <tr key="no-items">
          <td colSpan={colCount}>
            {i18n.t('cart.no-items', loc.names[User.getLanguage()])}
          </td>
        </tr>,
      ]
    }

    return (
      <table
        id="tbl-cart"
        className="table table-bordered table-condensed table-striped"
      >
        <FloatingHeader headerRow={headerRow} />
        <tbody>
          {entryRows}
        </tbody>
      </table>
    )
  }

  renderAddMoreButton = () => {
    const loc = this.getLocation()

    const canSell = Access.sales(User.getUser(), loc)
    const canTransfer = Access.transfer(User.getUser(), loc)

    if (canSell || canTransfer) {
      return (
        <button id="btn-add-more-items" onClick={this.addMore}>
          {i18n.t('cart.add-more-items')}
        </button>
      )
    }
  }

  renderSellButton = () => {
    const loc = this.getLocation()

    if (Access.sales(User.getUser(), loc)) {
      if (this.state.entries.length) {
        return (
          // TODO: rename to btn-sell?
          <button id="btn-sell-items" style={{ marginLeft: '0.5em' }} onClick={this.sell}>
            {i18n.t('cart.sell-items')}
          </button>
        )
      }
      else if (loc.sellBook) {
        return (
          <button style={{ marginLeft: '0.5em' }} onClick={this.sell}>
            {i18n.t('cart.create-empty-invoice')}
          </button>
        )
      }
    }
  }

  renderLocationDropdown = () => {
    const locations = this.state.allLocations.filter((loc) => {
      if (loc._id === this.props.locationId) {
        // Omit current location from destinations
        return false
      }

      return CommonUtils.isSalesPoint(loc)
    })

    return (
      <select
        key="dropdown"
        {...bindProps(this, ['transferLocation'])}
        id="inp-transfer-location"
      >
        {this.state.transferLocation ? null : <option value="" />}
        {locations.map(function(loc) {
          return (
            <option key={loc._id} value={loc._id}>
              {loc.names[User.getLanguage()]}
            </option>
          )
        })}
      </select>
    )
  }

  renderTransfer = () => {
    if (this.state.entries.length) {
      const loc = this.getLocation()

      if (Access.transfer(User.getUser(), loc)) {
        return [
          <button
            key="button"
            id="btn-transfer"
            disabled={!this.state.transferLocation}
            className={this.state.transferLocation ? '' : 'disabled'}
            style={{ marginLeft: '0.5em' }}
            onClick={this.transfer}
          >
            {i18n.t('cart.transfer-items')}
          </button>,
          ' ',
          i18n.t('cart.transfer-to'),
          ' ',
          this.renderLocationDropdown(),
        ]
      }
    }
  }

  render() {
    const mainMenu = <MainMenu activeTab="cart" />

    if (!this.props.locationId) {
      return (
        <div>
          {mainMenu}
          {i18n.t('cart.no-location-selected')}
        </div>
      )
    }

    if (!this.state.loaded) {
      return (
        <div>
          {mainMenu}
          {i18n.t('common.loading')}
        </div>
      )
    }

    return (
      <div>
        {mainMenu}
        <LocationMenu activeTab={this.props.locationId} locations={this.state.cartLocations} />
        {this.renderEntries()}
        {this.renderAddMoreButton()}
        {' '}
        {this.renderSellButton()}
        {' '}
        {this.renderTransfer()}
      </div>
    )
  }
}
