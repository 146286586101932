import jQuery = require('jquery')
window.jQuery = jQuery

import toastr from 'toastr/toastr'

import '../client-lib/excel-builder.compiled'

//Date picket css
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { superagent } from '../client-lib'
import { setDataService } from '../common/data-service'
import { createHttpDataService } from '../common/http-data-service'
import { setApi } from '../define'
import { API } from './api'
import { CommonConf } from './common-conf'
import { EnvBanner } from './env-banner'
import { ErrorHandler } from './error-handler'
import { router } from './router'
import { RouterConf } from './router-conf'
import { User } from './user'
import { Utils } from './utils'

window.addEventListener('load', async () => {
  const dataService = createHttpDataService(superagent as any)

  setApi(API)
  setDataService(dataService)

  CommonConf.initClient()

  CommonConf.setToastr(toastr)
  dataService.setErrorHandler(ErrorHandler.handleError)
  RouterConf.configure()

  // Avoid errors when reloading page after running tests and restarting server
  router.on('/run-tests', function () {
    router.setRoute('/product-defs')
  })

  const response = await API.isLoggedIn()
  User.setEnvInfo(response.envInfo)
  EnvBanner.init(response.envInfo.name)

  if (response.result) {
    User.setUser(response.user)
    const defaultRoute = Utils.getDefaultRoute(response.user)
    router.init(defaultRoute)
  }
  else {
    router.init('/login')
  }
})
