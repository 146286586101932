import { CommonUtils } from './common-utils'
import { Enums } from './enums'
import { AugmentedCategory } from './expense-category-path-builder'

const salesEarningCategoryId = 'sales'
const idSeparator = ' '

const arrayStartsWith = function(word: string[], prefix: string[]): boolean {
  if (prefix.length > word.length) {
    return false
  }

  let startsWith = true
  let idx = 0
  while (idx < prefix.length && startsWith) {
    startsWith = word[idx] === prefix[idx]
    idx += 1
  }
  return startsWith
}

export const AccountingUtils = {
  startYear: '2017',

  defaultCurrency: 'MAD',

  salesEarningCategoryId,

  // A real expense category _id (generated in the back end and used in the database)
  // cannot contain an underscore. We use this as a placeholder _id for new
  // expense category stubs added in the front end, that have not yet been saved.
  stubId: '_',

  idSeparator,

  pathSeparator: ' - ',

  isInvoiceExpenseCategory: function(expenseCategoryId: string) {
    return CommonUtils.arrayContains(Enums.orderedInvoiceExpenseCategories, expenseCategoryId)
  },

  isSalesEarningCategory: function(earningCategoryId: string) {
    return earningCategoryId === salesEarningCategoryId
  },

  extractExpenseCategoryParentId: function(id: string) {
    const lastSeparatorIdx = id.lastIndexOf(AccountingUtils.idSeparator)
    return lastSeparatorIdx > -1 ? id.slice(0, lastSeparatorIdx) : null
  },

  isStubId: function(id: string) {
    const lastSeparatorIdx = id.lastIndexOf(AccountingUtils.idSeparator)
    const idTail = id.slice(lastSeparatorIdx + 1)
    return idTail === AccountingUtils.stubId
  },

  expenseCategoryIdStartsWith: function(descendantCandidate: string, ancestorCandidate: string) {
    const arrDescendantCandidate = descendantCandidate.split(idSeparator)
    const arrAncestorCandidate = ancestorCandidate.split(idSeparator)
    return arrayStartsWith(arrDescendantCandidate, arrAncestorCandidate)
  },

  extractNextExpenseCategoryTree: function(categories: AugmentedCategory[]) {
    const tree: AugmentedCategory[] = []
    const rootId = categories[0]._id
    while (categories.length > 0 && AccountingUtils.expenseCategoryIdStartsWith(categories[0]._id, rootId)) {
      tree.push(categories.shift())
    }
    return tree
  },

  extractExpenseCategoryTrees: function(expenseCategoriesParam: AugmentedCategory[]) {
    const expenseCategories = CommonUtils.clone(expenseCategoriesParam)
    const trees: AugmentedCategory[][] = []
    while (expenseCategories.length > 0) {
      trees.push(AccountingUtils.extractNextExpenseCategoryTree(expenseCategories))
    }
    return trees
  },
}
