import React = require('react')

import { RawMaterial } from '../common/data-raw-materials'
import { getDataService } from '../common/data-service'
import { i18n } from '../common/i18n'
import { LoadingIcon } from './loading-icon'
import { SearchableMaterial } from './searchable-material'

interface RawMaterialSelectProps {
  productId: string,
  rawMaterials: RawMaterial[],
  onSave: () => void,
}

export class RawMaterialSelect extends React.Component<RawMaterialSelectProps> {
  state = {
    isSaving: false,
    isOpen: false,
    materialNameValue: '',
  }

  save = (rawMaterialId) => {
    this.setState({ isSaving: true })

    return getDataService().Products.setRawMaterial(this.props.productId, rawMaterialId)
    .then(() => {
      this.setState({ isOpen: false, materialNameValue: '', isSaving: false })
      this.props.onSave()
      return null
    })
  }

  renderButton = () => (
    <button onClick={() => this.setState({ isOpen: true })}>
      {i18n.t('invoice.set-raw-material')}
    </button>
  )

  render() {
    if (this.state.isSaving) {
      return <LoadingIcon />
    }

    if (!this.state.isOpen) {
      return this.renderButton()
    }

    return (
      <SearchableMaterial
        materials={this.props.rawMaterials}
        nameValue={this.state.materialNameValue}
        onChange={(val) => this.setState({ materialNameValue: val })}
        onSelect={(mat) => this.save(mat._id)}
        autoFocus={true}
      />
    )
  }
}
