import { saveAs } from 'file-saver'
import { ReactNode } from 'react'

import { CommonUtils } from '../common/common-utils'
import { Column, ExcelMetadata } from './ui-utils'

export const ReportUtils = {
  createExcelReport: function<Row>(rowsData: Row[], columnConf: Column<Row>[], contextParam: any) {
    const workbook = window.ExcelBuilder.Builder.createWorkbook()
    const styleSheet = workbook.getStyleSheet()

    const alignment = { vertical: 'top', wrapText: true }

    const headerStyle = styleSheet.createFormat({ font: { bold: true } })
    const bodyStyle = styleSheet.createFormat({ alignment })

    const totalsStyle = styleSheet.createFormat({
      alignment: { vertical: 'center', wrapText: true },
      font: { bold: true },
    })

    const context = CommonUtils.clone(contextParam)

    if (
      'dateTimeStyle' in context ||
      'bodyStyle' in context ||
      'columnLetters' in context ||
      'rowIndex' in context
    ) {
      throw new Error('Reserved key(s) used in context')
    }

    context.dateTimeStyle = styleSheet.createFormat(
      { alignment, format: 'yyyy-mm-dd hh:mm' },
    )

    context.bodyStyle = bodyStyle
    context.columnLetters = {}
    context.rowIndex = 1

    const sheet = workbook.createWorksheet()

    const excelColumns = columnConf.filter(function(column) {
      if (column.includeIf) {
        return column.includeIf('excel')
      }
      else {
        return true
      }
    })

    const headerRow = []
    const columns = []

    excelColumns.forEach(function(column) {
      headerRow.push({ value: column.header, metadata: { style: headerStyle.id } })
      columns.push({ width: column.excelWidth })

      const columnLetter = String.fromCharCode('A'.charCodeAt(0) + columns.length - 1)
      context.columnLetters[column.id] = columnLetter
    })

    const sheetData = [headerRow]

    rowsData.forEach(function(rowData) {
      context.rowIndex += 1

      const row = excelColumns.map(function(column) {
        let value

        if (column.getExcelValue) {
          value = column.getExcelValue(rowData, context)
        }
        else if (column.getCellContents) {
          value = column.getCellContents(rowData)
        }
        else {
          value = rowData[column.id]
        }

        if (value === null || value === undefined) {
          // Avoid seeing 'null' or 'undefined' in the table
          value = ''
        }

        let metadata: ExcelMetadata = { style: bodyStyle.id }

        if (column.getExcelMetadata) {
          metadata = column.getExcelMetadata(rowData, context)
        }

        return { value, metadata }
      })

      sheetData.push(row)
    })

    if (rowsData.length) {
      const anyTotalColumns = excelColumns.some(function(column) {
        // TODO: disable totals row in excel for some tables that otherwise have totals?
        return Boolean(column.getExcelTotalValue) || Boolean(column.getTotalRowContents)
      })

      if (anyTotalColumns) {
        let mergedToPrevious = false
        const a = 'A'.charCodeAt(0)
        const rowIndex = rowsData.length + 2

        if (context.totalsRowHeight) {
          sheet.setRowInstructions(rowIndex - 1, { height: context.totalsRowHeight })
        }

        const totalsRow = excelColumns.map(function(column, columnIndex) {
          let value: string | ReactNode = ''
          const metadata = { style: totalsStyle.id }

          if (column.getExcelTotalValue) {
            value = column.getExcelTotalValue(context)
          }
          else if (column.getTotalRowContents) {
            value = column.getTotalRowContents(context)
          }

          if (column.excelMergeTotalWithNext) {
            if (mergedToPrevious) {
              // Can be supported when needed, but currently keeping the logic simpler.
              throw new Error('Merging more than 2 consecutive columns is currently not supported')
            }

            const currentColumnLetter = String.fromCharCode(a + columnIndex)
            const nextColumnLetter = String.fromCharCode(a + columnIndex + 1)
            sheet.mergeCells(currentColumnLetter + rowIndex, nextColumnLetter + rowIndex)
            mergedToPrevious = true
          }
          else {
            mergedToPrevious = false
          }

          return { value, metadata }
        })

        sheetData.push(totalsRow)
      }
    }

    sheet.setData(sheetData)
    sheet.setColumns(columns)
    workbook.addWorksheet(sheet)

    return workbook
  },

  downloadExcelFile: async function(fileName: string, workbook) {
    const blob = await window.ExcelBuilder.Builder.createFile(workbook, { type: 'blob' })
    saveAs(blob, fileName)
  },

  toExcelTime: function(time: Date | number) {
    const excelEpoch = new Date('1899-12-30T00:00Z').getTime()

    // Time since epoch, converted from milliseconds to number of days
    return (Number(time) - excelEpoch) / (24 * 60 * 60 * 1000)
  },
}
