import { useRef } from 'react'
import React = require('react')

import { AccountingUtils } from '../common/accounting-utils'
import { CommonUtils } from '../common/common-utils'
import { EarningCategory } from '../common/data-earning-categories'
import { Earning } from '../common/data-earnings'
import { getDataService } from '../common/data-service'
import { i18n } from '../common/i18n'
import { bindInput, bindSelect } from './bind-utils'
import { Comp } from './comp'
import { getDefaultCancelButton, Modal, ModalButton } from './modal'
import { useOpenModalEvent } from './use-open-modal-event'
import { User } from './user'
import { Utils } from './utils'
import { ValidationErrors, ValidationUtils } from './validation-utils'

interface FormProps {
  earning?: Earning,
  earningCategories?: EarningCategory[],
}

export interface EditEarningModalProps {
  modalId: string,
  earning?: Earning,
  earningCategories: EarningCategory[],
  afterSave: () => void,
}

interface FormState {
  time: string,
  description: string,
  amount: string,
  earningCategory: string,
  _id?: string,
  validationErrors?: ValidationErrors,
}

const { FormRow } = Comp

class Form extends React.Component<FormProps, FormState> {
  static defaultProps = (function() {
    const now = CommonUtils.getNow()
    const date = CommonUtils.utcDateYMD(now)

    return {
      earning: {
        time: date,
        description: '',
        amount: '',
        earningCategory: AccountingUtils.salesEarningCategoryId,
      },
    }
  }())

  constructor(props) {
    super(props)

    const state = CommonUtils.clone(props.earning)

    if (!this.earningCategoryIdExists(props.earning.earningCategory)) {
      state.earningCategory = AccountingUtils.salesEarningCategoryId
    }

    this.state = state
  }

  _isMounted = false

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  save = () => {
    const earning = {
      time: this.state.time,
      description: this.state.description,
      amount: this.state.amount.toString(),
      earningCategory: this.state.earningCategory,
    }

    return ValidationUtils.clear(this)
    .then<unknown>(() => {
      const DataService = getDataService()

      if (this.state._id) {
        return DataService.Earnings.update(this.state._id, earning)
      } else {
        return DataService.Earnings.create(earning)
      }
    })
    .catch((error) => ValidationUtils.check(this, error))
  }

  earningCategoryIdExists = (id) => {
    const category = CommonUtils.findById(this.props.earningCategories, id)
    return category ? true : false
  }

  renderTimeRow = () => {
    return (
      <FormRow>
        {i18n.t('common.date')}
        {bindInput(this, 'time')}
        {ValidationUtils.render(this.state.validationErrors, 'time')}
      </FormRow>
    )
  }

  renderDescriptionRow = () => {
    return (
      <FormRow>
        {i18n.t('common.description')}
        {bindInput(this, 'description', { className: 'input-wide' })}
        {ValidationUtils.render(this.state.validationErrors, 'description')}
      </FormRow>
    )
  }

  renderAmountRow = () => {
    return (
      <FormRow>
        {i18n.t('common.amount')}
        {bindInput(this, 'amount', { className: 'input-narrow', validator: Utils.nonNegativeDecimalValidator })}
        {ValidationUtils.render(this.state.validationErrors, 'amount')}
      </FormRow>
    )
  }

  renderEarningCategoryDropdown = () => {
    if (this.props.earningCategories) {
      const lang = User.getLanguage()
      const opts = this.props.earningCategories.map(function(earningCategory) {
        return (
          <option key={earningCategory._id} value={earningCategory._id}>
            {earningCategory.labels[lang]}
          </option>
        )
      })
      return bindSelect(this, 'earningCategory', { className: 'input-wide' }, opts)
    } else {
      return <img src="img/loading.gif" />
    }
  }

  renderEarningCategoryRow = () => {
    return (
      <FormRow>
        {i18n.t('common.category')}
        {this.renderEarningCategoryDropdown()}
        {ValidationUtils.render(this.state.validationErrors, 'earningCategory')}
      </FormRow>
    )
  }

  render() {
    return (
      <div id="form-earn">
        {this.renderTimeRow()}
        {this.renderDescriptionRow()}
        {this.renderAmountRow()}
        {this.renderEarningCategoryRow()}
      </div>
    )
  }
}

export const EditEarningModal = (props: EditEarningModalProps) => {
  const [isVisible, close] = useOpenModalEvent(props.modalId)
  const formRef = useRef<Form>(null)

  if (!isVisible) {
    return <div />
  }

  const buttons: ModalButton[] = [
    {
      id: 'btn-save',
      title: i18n.t('action.save'),
      onClick: async () => {
        await formRef.current.save()
        await props.afterSave()
        close()
      },
    },
    getDefaultCancelButton(),
  ]

  return (
    <div>
      <Modal
        title={i18n.t('accounting.earnings.earning')}
        dialogClassName="narrow"
        closeModal={close}
        buttons={buttons}
      >
        <Form
          ref={formRef}
          earning={props.earning}
          earningCategories={props.earningCategories}
        />
      </Modal>
    </div>
  )
}
