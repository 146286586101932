import { CSSProperties } from 'react'
import React = require('react')

import { i18n } from '../common/i18n'

interface DisabledFilterIconProps {
  small?: boolean,
}

const getDivStyle = (small: boolean) => {
  const divStyle: CSSProperties = { position: 'relative' }

  if (small) {
    divStyle['display'] = 'inline-block'
  }
  else {
    divStyle['float'] = 'right'
  }

  return divStyle
}

const getImgStyle = (small: boolean) => {
  const imgStyle: CSSProperties = { cursor: 'not-allowed' }

  if (small) {
    imgStyle.width = '0.6em'
  }
  else {
    imgStyle.marginLeft = '0.1em'
  }

  return imgStyle
}

export const DisabledFilterIcon = ({ small = false }: DisabledFilterIconProps) => (
  <div style={getDivStyle(small)}>
    <img
      src="/img/filter-disabled.png"
      style={getImgStyle(small)}
      title={i18n.t('action.filter')}
    />
  </div>
)
