/**
 * Can be used to test whether changes to the body element's font size affect all elements correctly.
 * Will later probably be developed into the text size changing feature.
 * Currently not used on any page.
 */

 import React = require('react')

import { User } from './user'
import { ZoomIcon } from './zoom-icon'

export class SizeChanger extends React.Component {
  setSize = (size) => {
    User.setTextSize(size)
    this.forceUpdate()
  }

  zoomIn = () => {
    this.setSize(User.getTextSize() + 2)
  }

  zoomOut = () => {
    this.setSize(User.getTextSize() - 2)
  }

  renderZoomIn = () => {
    const enabled = User.getTextSize() <= 20

    return (
      <ZoomIcon
        in={true}
        onClick={enabled ? this.zoomIn : null}
        color={enabled ? 'black' : 'lightgrey'}
      />
    )
  }

  renderZoomOut = () => {
    const enabled = User.getTextSize() >= 10

    return (
      <ZoomIcon
        in={false}
        onClick={enabled ? this.zoomOut : null}
        color={enabled ? 'black' : 'lightgrey'}
      />
    )
  }

  render() {
    return (
      <div>
        {this.renderZoomIn()}
        {this.renderZoomOut()}
      </div>
    )
  }
}
