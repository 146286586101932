import { ObjectId } from 'mongodb'
import React = require('react')

import { Access } from '../common/access'
import { CommonUtils } from '../common/common-utils'
import { AttributeDefinition } from '../common/data-attribute-defs'
import { MaterialConsumption } from '../common/data-material-consumption-report'
import { MaterialCategory, MaterialProducer, MaterialWidth, SimpleAttr } from '../common/data-misc'
import { RawMaterial } from '../common/data-raw-materials'
import { getDataService } from '../common/data-service'
import { Enums } from '../common/enums'
import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { AttributeCombination } from '../common/types'
import { EditMaterialReportModal } from './edit-material-report-modal'
import { Filter, FilterManager } from './filter'
import { MainMenu } from './main-menu'
import { ReportUtils } from './report-utils'
import { ReportsMenu } from './reports-menu'
import { Column, UiUtils } from './ui-utils'
import { User } from './user'
import { Utils } from './utils'

interface Row {
  id: ObjectId,
  entry: MaterialConsumption,
  material: RawMaterial,
  userTime: Date,
  name: string,
  category: string,
  producer: string,
  width: string,
  attributes: Record<string, string>,
  price: number,
  value: number,
  amount: number,
  unit: string,
  location: string,
  note: string,
  username: string,
}

interface ListState {
  loaded: boolean,
  printView: boolean,
  attributeDefinitions: AttributeDefinition[],
  categories: SimpleAttr[],
  entries: MaterialConsumption[],
  materials: RawMaterial[],
  producers: SimpleAttr[],
  usernames: { _id: string, username: string }[],
  widths: SimpleAttr[],
}

class List extends React.Component<Record<string, never>, ListState> {
  state = {
    loaded: false,
    printView: false,
    attributeDefinitions: undefined as AttributeDefinition[] | undefined,
    categories: undefined as SimpleAttr[] | undefined,
    entries: undefined as MaterialConsumption[] | undefined,
    materials: undefined as RawMaterial[] | undefined,
    producers: undefined as SimpleAttr[] | undefined,
    usernames: undefined as { _id: string, username: string }[] | undefined,
    widths: undefined as SimpleAttr[] | undefined,
  }

  _isMounted = false

  componentDidMount() {
    this._isMounted = true
    const DataService = getDataService()
    Filter.registerEvent(this)

    Promise.all([
      DataService.RawMaterials.getAll(),
      DataService.MaterialConsumptionReport.getAll(),
      DataService.MaterialCategories.getAll(),
      DataService.MaterialProducers.getAll(),
      DataService.MaterialWidths.getAll(),
      DataService.AttributeDefinitions.getAll(),
      DataService.Users.getUsernames(),
    ])
    .then(([
      materials,
      entries,
      categories,
      producers,
      widths,
      attributeDefinitions,
      usernames,
    ]) => {
      if (!this._isMounted) {
        return
      }

      const filteredAttributeDefinitions = attributeDefinitions.filter(
        CommonUtils.attributeContextFilters.materials,
      )

      this.setState(
        {
          loaded: true,
          materials,
          entries,
          categories,
          producers,
          widths,
          attributeDefinitions: filteredAttributeDefinitions,
          usernames,
        },
        EventBus.fireFunc('materials-report-rendered'),
      )
    })
  }

  componentWillUnmount() {
    this._isMounted = false
    Filter.unregisterEvent(this)
  }

  reloadEntries = () => {
    const DataService = getDataService()
    this.setState({ loaded: false })

    DataService.MaterialConsumptionReport.getAll()
    .then((entries) => {
      if (this._isMounted) {
        this.setState(
          { loaded: true, entries },
          EventBus.fireFunc('materials-report-rendered'),
        )
      }
    })

    return null
  }

  print = () => {
    this.setState({ printView: true }, Utils.print)
  }

  leavePrintMode = () => {
    this.setState({ printView: false })
  }

  getFilterConf = () => {
    const language = User.getLanguage()
    const categories = this.state.categories.slice()

    categories.sort(function(cat1, cat2) {
      return cat1.labels[language].localeCompare(cat2.labels[language])
    })

    const producers = this.state.producers.slice()

    producers.sort(function(prod1, prod2) {
      return prod1.labels[language].localeCompare(prod2.labels[language])
    })

    // TODO: sort users

    const names = Utils.getSortedMaterialNames(this.state.materials)

    const filterConf = {
      time: { type: 'date-range', labelKey: 'reports.date-time' },
      name: {
        type: 'predefined',
        labelKey: 'common.name',
        options: names.map(function(matName) {
          return { value: matName, label: matName }
        }),
        getField: (entry) => {
          const material = CommonUtils.findById<RawMaterial>(this.state.materials, entry.material)
          return material.name
        },
      },
      category: {
        type: 'predefined',
        labelKey: 'common.category',
        options: categories.map(function(category) {
          return {
            value: category._id,
            label: category.labels[language],
          }
        }),
        getField: (entry) => {
          const material = CommonUtils.findById<RawMaterial>(this.state.materials, entry.material)
          return material.category
        },
      },
      producer: {
        type: 'predefined',
        labelKey: 'raw-materials.producer',
        options: producers.map(function(producer) {
          return {
            value: producer._id,
            label: producer.labels[language],
          }
        }),
        getField: (entry) => {
          const material = CommonUtils.findById<RawMaterial>(this.state.materials, entry.material)
          return material.producer
        },
      },
      user: {
        type: 'predefined',
        labelKey: 'common.user',
        options: this.state.usernames.map(function(userFields) {
          return {
            value: userFields._id,
            label: userFields.username,
          }
        }),
      },
    }

    this.state.attributeDefinitions.forEach((attribute) => {
      const comboSources = []

      this.state.entries.forEach((entry) => {
        const material = CommonUtils.findById<RawMaterial>(this.state.materials, entry.material)

        if (attribute._id in material.attributes || !attribute.optional) {
          const comboSource = { values: material.attributes[attribute._id], category: null }
          comboSources.push(comboSource)
        }
      })

      filterConf['attr-' + attribute._id] = {
        type: 'predefined-combo',
        label: attribute.names[language],
        options: Utils.getAttributeFilterOptions(attribute, language, comboSources),
        getField: (entry) => {
          const material = CommonUtils.findById<RawMaterial>(this.state.materials, entry.material)

          if (attribute.optional && !material.attributes[attribute._id]) {
            return []
          }

          return material.attributes[attribute._id]
        },
      }
    })

    return filterConf
  }

  getColumnConf = () => {
    const language = User.getLanguage()
    const columnConf: Column<Row>[] = []

    columnConf.push({
      id: 'time',
      header: i18n.t('reports.date-time'),
      filterFieldName: 'time',
      excelWidth: 16,
      getCellProperties: function() {
        return { style: { whiteSpace: 'nowrap' } }
      },
      getExcelMetadata: function(_rowData, context) {
        return { style: context.dateTimeStyle.id }
      },
      getCellContents: function(rowData) {
        return CommonUtils.utcDateTime(rowData.userTime)
      },
      getExcelValue: function(rowData) {
        return ReportUtils.toExcelTime(rowData.userTime)
      },
      getTotalRowProperties: function() {
        return { style: { verticalAlign: 'middle' } }
      },
      getTotalRowContents: function() {
        return i18n.t('common.total')
      },
    })

    columnConf.push({
      id: 'name',
      header: i18n.t('raw-materials.name'),
      filterFieldName: 'name',
      excelWidth: 20,
    })

    columnConf.push({
      id: 'category',
      header: i18n.t('common.category'),
      excelWidth: 15,
      filterFieldName: 'category',
    })

    columnConf.push({
      id: 'producer',
      header: i18n.t('raw-materials.producer'),
      excelWidth: 20,
      filterFieldName: 'producer',
    })

    columnConf.push({
      id: 'width',
      header: i18n.t('raw-materials.width'),
      excelWidth: 10,
    })

    this.state.attributeDefinitions.forEach(function(attr) {
      columnConf.push({
        id: 'attr-' + attr._id,
        header: attr.names[language],
        filterFieldName: 'attr-' + attr._id,
        excelWidth: 20,
        getCellContents: function(rowData) {
          return rowData.attributes[attr._id]
        },
      })
    })

    columnConf.push({
      id: 'price',
      header: i18n.t('common.price'),
      excelWidth: 10,
      getCellContents: function(rowData) {
        return CommonUtils.formatDecimal(rowData.price, true) + ' MAD'
      },
      getExcelValue: function(rowData) {
        return rowData.price
      },
    })

    columnConf.push({
      id: 'value',
      header: i18n.t('common.value'),
      excelWidth: 10,
      getCellContents: function(rowData) {
        return CommonUtils.formatDecimal(rowData.value, true) + ' MAD'
      },
      getExcelValue: function(_rowData, context) {
        return (
          context.columnLetters['price'] + context.rowIndex + '*' +
          context.columnLetters['amount-cut'] + context.rowIndex
        )
      },
      getExcelMetadata: function(_rowData, context) {
        return { type: 'formula', style: context.bodyStyle.id }
      },
      getTotalRowProperties: function() {
        return { style: { verticalAlign: 'middle' } }
      },
      getTotalRowContents: function(context) {
        return CommonUtils.formatDecimal(context.totalValue, true) + ' MAD'
      },
      getExcelTotalValue: function(context) {
        return context.totalValue
      },
    })

    columnConf.push({
      id: 'amount-cut',
      header: i18n.t('raw-materials.amount-cut'),
      excelWidth: 12,
      excelMergeTotalWithNext: true,
      getCellContents: function(rowData) {
        return CommonUtils.formatDecimal(rowData.amount, true) + ' ' + rowData.unit
      },
      getExcelValue: function(rowData) {
        return rowData.amount
      },
      getTotalRowContents: function(context) {
        return Object.keys(context.totalAmounts).map(function(unit) {
          const amount = context.totalAmounts[unit]

          if (amount > 0) {
            return (
              <div key={unit}>
                {CommonUtils.formatDecimal(amount, true)}
                {' '}
                {i18n.t('enum.material-units.' + unit)}
              </div>
            )
          }
        })
      },
      getExcelTotalValue: function(context) {
        const lines = []

        Object.keys(context.totalAmounts).forEach(function(unit) {
          const amount = context.totalAmounts[unit]

          if (amount > 0) {
            lines.push(
              CommonUtils.formatDecimal(amount, true) + ' ' + i18n.t('enum.material-units.' + unit),
            )
          }
        })

        return lines.join('\n')
      },
    })

    columnConf.push({
      id: 'unit',
      header: i18n.t('raw-materials.unit'),
      includeIf: function(view) {
        return view === 'excel'
      },
      excelWidth: 10,
    })

    columnConf.push({
      id: 'location',
      header: i18n.t('common.location'),
      excelWidth: 10,
    })

    columnConf.push({
      id: 'note',
      header: i18n.t('common.note'),
      excelWidth: 15,
      getCellProperties: function() {
        return { className: 'note-column' }
      },
    })

    columnConf.push({
      id: 'user',
      header: i18n.t('common.user'),
      filterFieldName: 'user',
      excelWidth: 15,
      getCellContents: function(rowData) {
        return rowData.username
      },
    })

    if (!this.state.printView) {
      columnConf.push({
        id: 'action',
        header: i18n.t('action.action'),
        includeIf: function(view) {
          // TODO: move permission check to outer condition?
          return view === 'table' && Access.manageRawMaterials(User.getUser())
        },
        getCellContents: (rowData) => {
          const modalId = 'edit-material-report-' + rowData.id

          const editButton = (
            <img
              className="table-btn btn-edit"
              title={i18n.t('action.edit')}
              src="img/edit.png"
              onClick={function() {
                EventBus.fire('open-modal', { modalId })
              }}
            />
          )

          const editModal = (
            <EditMaterialReportModal
              modalId={modalId}
              entry={rowData.entry}
              material={rowData.material}
              categories={this.state.categories}
              producers={this.state.producers}
              widths={this.state.widths}
              attributeDefinitions={this.state.attributeDefinitions}
              getUsername={this.getUsername}
              afterSave={this.reloadEntries}
            />
          )

          const undoButton = (
            <img
              className="table-btn btn-undo"
              title={i18n.t('action.undo')}
              src="img/undo.png"
              onClick={() => {
                Utils.confirmTr('confirm.undo.entry').then((confirmed) => {
                  if (confirmed) {
                    return getDataService().MaterialConsumptionReport.undo(rowData.id)
                    .then(this.reloadEntries)
                  }
                })
              }}
            />
          )

          return (
            <span>
              {editButton}
              {undoButton}
              {editModal}
            </span>
          )
        },
      })
    }

    return columnConf
  }

  getUsername = (userId): string => {
    const obj = CommonUtils.findById(this.state.usernames, userId)
    return obj.username
  }

  downloadExcelFile = (rowsData, columnConf, context) => {
    const userTime = CommonUtils.toUserTime(User.getUser().country, CommonUtils.getNow())
    const fileName = 'material-consumption-' + CommonUtils.utcDateYMD(userTime) + '.xlsx'

    context.totalsRowHeight = 30

    const workbook = ReportUtils.createExcelReport(rowsData, columnConf, context)
    ReportUtils.downloadExcelFile(fileName, workbook)
  }

  getRowsData = (context, filterManager: FilterManager<MaterialConsumption>) => {
    const language = User.getLanguage()

    context.totalValue = 0
    context.totalAmounts = {}

    Enums.orderedMaterialUnits.forEach(function(unit) {
      context.totalAmounts[unit] = 0
    })

    const rowsData = filterManager.getFiltered().map((entry): Row => {
      const material = CommonUtils.findById<RawMaterial>(this.state.materials, entry.material)

      context.totalAmounts[material.amount.unit] += entry.amount

      const value = entry.price * entry.amount
      context.totalValue += value

      const category = CommonUtils.findById<MaterialCategory>(this.state.categories, material.category)
      const producer = CommonUtils.findById<MaterialProducer>(this.state.producers, material.producer)
      const width = CommonUtils.findById<MaterialWidth>(this.state.widths, material.width)

      const attributes: Record<string, string> = {}

      this.state.attributeDefinitions.forEach(function(attribute) {
        if (attribute._id in material.attributes) {
          const combination: AttributeCombination = {
            id: 0, // Should be ignored
            values: material.attributes[attribute._id],
          }

          const details = CommonUtils.getCombinationDetails(combination, attribute, null, language)
          attributes[attribute._id] = details.label
        }
      })

      return {
        id: entry._id,
        entry,
        material,
        userTime: CommonUtils.toUserTime(User.getUser().country, new Date(entry.time)),
        name: material.name,
        category: category.labels[language],
        producer: producer.labels[language],
        width: width.labels[language],
        attributes,
        price: entry.price,
        value,
        amount: entry.amount,
        unit: i18n.t('enum.material-units.' + material.amount.unit),
        location: 'Al Nour', // TODO: un-hardcode
        note: entry.note,
        username: this.getUsername(entry.user),
      }
    })

    return rowsData
  }

  renderMenus = () => {
    if (!this.state.printView) {
      return [
        <MainMenu key="main" activeTab="reports" />,
        <ReportsMenu key="reports" activeTab="material-consumption" />,
      ]
    }
  }

  renderButtons = (rowsData, columnConf, context) => {
    if (this.state.printView) {
      return (
        <button
          id="btn-leave-print"
          className="no-print"
          onClick={this.leavePrintMode}
          style={{ marginBottom: '0.5em' }}
        >
          {i18n.t('common.leave-print-mode')}
        </button>
      )
    }

    const onClickExcel = () => {
      this.downloadExcelFile(rowsData, columnConf, context)
    }

    return (
      <div style={{ margin: '0.5em 0' }}>
        <button id="btn-excel" onClick={onClickExcel}>
          {i18n.t('reports.download-excel-file')}
        </button>
        {' '}
        <button id="btn-print" onClick={this.print}>
          {i18n.t('common.print')}
        </button>
      </div>
    )
  }

  renderPrintHeader = () => {
    if (this.state.printView) {
      return (
        <h3 style={{ fontWeight: 'bold' }}>
          {i18n.t('menu.main.reports')}
          {' - '}
          {i18n.t('menu.reports.material-consumption')}
        </h3>
      )
    }
  }

  renderTable = (
    columnConf: Column<Row>[],
    rowsData: Row[],
    context,
    filterManager: FilterManager<MaterialConsumption>,
  ) => {
    return UiUtils.getTable(columnConf, rowsData, {
      tableId: 'tbl-rep-mat',
      context,
      filterManager,
      noItemsText: i18n.t('reports.no-items'),
      noFilteredItemsText: i18n.t('reports.no-filtered-items'),
      printView: this.state.printView,
    })
  }

  render() {
    if (!this.state.loaded) {
      return (
        <div>
          {this.renderMenus()}
          {i18n.t('common.loading')}
        </div>
      )
    }

    const filterManager = Filter.createManager(
      'consumption-report', this.getFilterConf(), this.state.entries,
    )

    const context = {}
    const rowsData = this.getRowsData(context, filterManager)
    const columnConf = this.getColumnConf()

    return (
      <div>
        {this.renderMenus()}
        {this.renderButtons(rowsData, columnConf, context)}
        {this.renderPrintHeader()}
        {filterManager.getSummary()}
        {this.renderTable(columnConf, rowsData, context, filterManager)}
      </div>
    )
  }
}

export const MaterialConsumptionReport = {
  List,
}
