import React = require('react')

import { TabMenu } from './tab-menu'

interface DefinitionsMenuProps {
  activeTab: string,
}

export const DefinitionsMenu = (props: DefinitionsMenuProps) => (
  <TabMenu
    menuId="definitions"
    pills={true}
    tabs={[
      { id: 'templates', route: '/template-defs' },
      { id: 'products', route: '/product-defs' },
    ]}
    activeTab={props.activeTab}
  />
)
