import React = require('react')

import { Access } from '../common/access'
import { CommonUtils } from '../common/common-utils'
import { Invoice } from '../common/data-invoices'
import { Location } from '../common/data-locations'
import { getDataService } from '../common/data-service'
import { i18n } from '../common/i18n'
import { InvoiceSettings, InvoiceUtils } from '../common/invoice-utils'
import { FloatingHeader } from './floating-header'
import { InvoiceSearch } from './invoice-search'
import { InvoiceUiUtils } from './invoice-ui-utils'
import { MainMenu } from './main-menu'
import { ReportsMenu } from './reports-menu'
import { User } from './user'

interface InvoiceArchiveState {
  loaded: boolean,
  invoices: Invoice[],
  locations: Location[],
  invoiceSettings: InvoiceSettings,
}

export class InvoiceArchive extends React.Component<Record<string, never>, InvoiceArchiveState> {
  state = {
    loaded: false,
    invoices: null,
    locations: null,
    invoiceSettings: null,
  }

  componentDidMount() {
    this.load()
  }

  load = () => {
    const DataService = getDataService()

    Promise.all([
      DataService.Invoices.getArchived(),
      DataService.Locations.getByType('sales-points'),
      InvoiceUiUtils.getSettings(),
    ])
    .then(([invoices, locations, invoiceSettings]) => {
      this.setState({
        loaded: true,
        invoices,
        locations,
        invoiceSettings,
      })
    })
  }

  getDearchivableYears = () => {
    const mapYears = {}

    this.state.invoices.forEach(function(invoice) {
      const date = new Date(invoice.time)
      const year = date.getUTCFullYear().toString()
      mapYears[year] = true
    })

    return Object.keys(mapYears)
  }

  renderDearchiveButton = (year) => {
    return (
      <button
        key={'btn-dearchive-year-' + year}
        className="btn btn-default"
        onClick={() => {
          return getDataService().Invoices.dearchiveYear(year).then(() => {
            this.load()
            return null
          })
        }}
      >
        {year}
      </button>
    )
  }

  renderDearchiveButtons = () => {
    if (!this.state.loaded) {
      return null
    }

    const user = User.getUser()

    if (!Access.archiveInvoices(user)) {
      return null
    }

    const years = this.getDearchivableYears()

    if (!years.length) {
      return null
    }

    return (
      <div className="panel panel-default padded">
        {i18n.t('invoice.dearchive-by-year')}
        {': '}
        <div className="btn-group ">
          {years.map(this.renderDearchiveButton)}
        </div>
      </div>
    )
  }

  renderInvoiceLink = (invoiceId) => {
    return (
      <a
        className="lnk-invoice"
        href={'#/invoice/view/' + invoiceId}
        style={{ fontWeight: 'bold' }}
      >
        {i18n.t('invoice.invoice') + ' #' + invoiceId}
      </a>
    )
  }

  renderSearch = () => {
    if (!this.state.loaded) {
      return null
    }

    return <InvoiceSearch invoices={this.state.invoices} />
  }

  renderRow = (invoice) => {
    const userTime = CommonUtils.toUserTime(User.getUser().country, new Date(invoice.time))
    const time = CommonUtils.utcDateTime(userTime)

    const loc = CommonUtils.findById<Location>(this.state.locations, invoice.location)
    const invoiceValues = InvoiceUtils.getValues(invoice, loc.currency)
    const invoiceTotal = InvoiceUtils.getTotal(this.state.invoiceSettings, invoiceValues, false)

    return (
      <tr key={invoice._id}>
        <td>
          {this.renderInvoiceLink(invoice._id)}
          {' '}
          <span className="lbl-date">
            {time}
          </span>
        </td>
        <td>
          {loc.names[User.getLanguage()]}
        </td>
        <td className="text-right">
          {CommonUtils.formatDecimal(invoiceTotal) + ' ' + loc.currency}
        </td>
      </tr>
    )
  }

  renderTable = () => {
    const headerRow = (
      <tr>
        <th>
          {i18n.t('invoice.invoice')}
        </th>
        <th>
          {i18n.t('common.location')}
        </th>
        <th>
          {i18n.t('common.total')}
        </th>
      </tr>
    )

    return (
      <table
        className="table table-bordered table-condensed"
        style={{ width: '40%' }}
      >
        <FloatingHeader headerRow={headerRow} />
        <tbody>
          {this.state.invoices.map(this.renderRow)}
        </tbody>
      </table>
    )
  }

  render() {
    const content = this.state.loaded ? this.renderTable() : i18n.t('common.loading')

    return (
      <div>
        <MainMenu activeTab="reports" />
        <ReportsMenu activeTab="invoice-archive" />
        {this.renderSearch()}
        {this.renderDearchiveButtons()}
        {content}
      </div>
    )
  }
}
