import { CSSProperties } from 'react'
import React = require('react')

import { CommonUtils } from '../common/common-utils'
import { AttributeDefinition } from '../common/data-attribute-defs'
import { ProductDefinitionCreate } from '../common/data-product-defs'
import { TemplateDefinition } from '../common/data-template-defs'
import { i18n } from '../common/i18n'
import { Currency } from '../common/invoice-utils'
import { AttributeCombination } from '../common/types'
import { FloatingHeader } from './floating-header'
import { User } from './user'
import { Utils } from './utils'

interface PriceOverridesTableProps {
  definition: ProductDefinitionCreate,
  attributeDefinitions: AttributeDefinition[],
  templateDefinitions?: TemplateDefinition[],
  currencies: Currency[],
}

interface PriceOverridesPanelProps {
  definition: ProductDefinitionCreate,
  attributeDefinitions: AttributeDefinition[],
  templateDefinitions?: TemplateDefinition[],
  currencies: Currency[],
  updateView: () => void,
}

class PriceOverridesTable extends React.Component<PriceOverridesTableProps> {
  getComboCombos = (sortedAttributes) => {
    // Returns combinations of attribute value combinations for price overrides.
    // For example, a combination is [Divers, Lin] (fabric), but a combination of
    // combinations is [ [Divers, Lin] (fabric), [20 x 30, 40 x 40] (size) ].

    let comboCombos = [ {} ]

    sortedAttributes.forEach((attribute) => {
      const oldComboCombos = comboCombos
      comboCombos = []

      oldComboCombos.forEach((oldComboCombo) => {
        const combos = this.props.definition.attributeCombinations[attribute._id] as (
          (number | AttributeCombination)[]
        )

        const comboIds = combos.map(function(valueCombo) {
          return typeof valueCombo === 'number' ? valueCombo : valueCombo.id
        })

        comboIds.sort()

        comboIds.forEach(function(comboId) {
          const newComboCombo = CommonUtils.clone(oldComboCombo)
          newComboCombo[attribute._id] = comboId
          comboCombos.push(newComboCombo)
        })
      })
    })

    return comboCombos
  }

  getAttributeDefinition = (attrId) => {
    return CommonUtils.findById(this.props.attributeDefinitions, attrId)
  }

  render() {
    const language = User.getLanguage()
    const { definition } = this.props

    const sortedAttributes = definition.overrideAttributes.map(this.getAttributeDefinition)

    sortedAttributes.sort(function(attr1, attr2) {
      return attr1.orderIndex - attr2.orderIndex
    })

    const comboCombos = this.getComboCombos(sortedAttributes)

    const headerRow = (
      <tr>
        {sortedAttributes.map(function(attribute) {
          return (
            <th key={attribute._id}>
              {attribute.names[User.getLanguage()]}
            </th>
          )
        })}
        {this.props.currencies.map(function(currency) {
          return (
            <th key={currency}>
              {i18n.t('common.price') + ' (' + currency + ')'}
            </th>
          )
        })}
        <th>
          {i18n.t('action.action')}
        </th>
      </tr>
    )

    return (
      <table
        id="tbl-override"
        className="table table-bordered table-striped table-condensed"
        style={{ marginBottom: 0 }}
      >
        <FloatingHeader headerRow={headerRow} />
        <tbody>
          {comboCombos.map((comboCombo) => {
            const priceOverride = CommonUtils.find<any>(
              definition.priceOverrides,
              function(override) {
                return definition.overrideAttributes.every(function(attrId) {
                  return override.attrs[attrId] === comboCombo[attrId]
                })
              },
            )

            let priceElements = []
            let buttonClass = 'btn-override'
            let buttonText = i18n.t('action.override')

            let onClick = () => {
              const override = {
                attrs: CommonUtils.clone(comboCombo),
                prices: {
                  EUR: null,
                  MAD: null,
                 },
              }

              // Copy base prices
              this.props.currencies.map(function(currency) {
                override.prices[currency] = definition.prices[currency]
              })

              if (!definition.priceOverrides) {
                definition.priceOverrides = []
              }

              definition.priceOverrides.push(override)
              this.forceUpdate()
            }

            if (priceOverride) {
              priceElements = this.props.currencies.map((currency) => {
                return (
                  <input
                    key={currency}
                    className={'inp-override-' + currency}
                    style={{ width: '4em' }}
                    value={String(priceOverride.prices[currency] ?? '')}
                    onChange={(evt) => {
                      const { value } = evt.currentTarget

                      if (Utils.nonNegativeDecimalValidator(value)) {
                        priceOverride.prices[currency] = value
                        this.forceUpdate()
                      }
                    }}
                  />
                )
              })

              buttonClass = 'btn-base-price'
              buttonText = i18n.t('price-ovr.use-base-price')

              onClick = () => {
                CommonUtils.removeFromArray(definition.priceOverrides, priceOverride)
                this.forceUpdate()
              }
            }
            else {
              // Show base prices
              priceElements = this.props.currencies.map(function(currency) {
                return definition.prices[currency]
              })
            }

            const button = (
              <button className={buttonClass} onClick={onClick}>
                {buttonText}
              </button>
            )

            // e.g. 'fabric-3-color-2'
            const key = Object.keys(comboCombo).map(function(attrId) {
              return attrId + '-' + comboCombo[attrId]
            }).join('-')

            const fakeItem = { attributes: comboCombo }

            const attributeDetails = CommonUtils.getAttributeDetails(
              fakeItem, sortedAttributes, definition, this.props.templateDefinitions, language,
            )

            return (
              <tr key={key} className="row-override">
                {sortedAttributes.map(function(attribute) {
                  return (
                    <td key={attribute._id}>
                      {attributeDetails[attribute._id].label}
                    </td>
                  )
                })}
                {priceElements.map(function(priceElement, index) {
                  return <td key={index}>{priceElement}</td>
                })}
                <td>{button}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }
}

export class PriceOverridesPanel extends React.Component<PriceOverridesPanelProps> {
  anyOverrides = () => {
    return this.props.definition.overrideAttributes.length > 0
  }

  multiplyOverrides = (attrId) => {
    const newOverrides = []
    const combos = this.props.definition.attributeCombinations[attrId] as (number | AttributeCombination)[]
    const overrides = this.props.definition.priceOverrides || []

    for (const override of overrides) {
      for (const combo of combos) {
        const newOverride = CommonUtils.clone(override)
        newOverride.attrs[attrId] = typeof combo === 'number' ? combo : combo.id
        newOverrides.push(newOverride)
      }
    }

    this.props.definition.priceOverrides = newOverrides
  }

  getOverridesPanel = () => {
    if (!this.anyOverrides()) {
      return null
    }

    return (
      <div>
        <div style={{ overflowX: 'auto', margin: '1em 0' }}>
          <PriceOverridesTable
            definition={this.props.definition}
            attributeDefinitions={this.props.attributeDefinitions}
            templateDefinitions={this.props.templateDefinitions}
            currencies={this.props.currencies}
          />
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <h4>{i18n.t('price-ovr.title')}</h4>
        <div>
          <div>{i18n.t('price-ovr.ovr-based')}</div>
          {this.props.attributeDefinitions.map((attribute) => {
            const { definition } = this.props

            if (!definition.overrideAttributes) {
              definition.overrideAttributes = []
            }

            const ovrAttrs = definition.overrideAttributes

            const combos = this.props.definition.attributeCombinations[attribute._id]
            const disabled = !combos.length

            const style: CSSProperties = {}

            if (disabled) {
              style.color = 'silver'
            }

            return (
              <div key={attribute._id} style={style}>
                <input
                  type="checkbox"
                  id={'chk-attr-' + attribute._id}
                  checked={CommonUtils.arrayContains(ovrAttrs, attribute._id)}
                  disabled={disabled}
                  className={disabled ? 'disabled' : ''}
                  onChange={async (evt) => {
                    if (evt.currentTarget.checked) {
                      ovrAttrs.push(attribute._id)
                      this.multiplyOverrides(attribute._id)
                      this.props.updateView()
                    }
                    else if (
                      !definition.priceOverrides.length ||
                      (await Utils.confirm(i18n.t('price-ovr.confirm-remove-attr')))
                    ) {
                      CommonUtils.removeFromArray(ovrAttrs, attribute._id)
                      definition.priceOverrides = ovrAttrs.length ? [] : null
                      this.props.updateView()
                    }
                  }}
                />
                {' '}
                {attribute.pluralNames[User.getLanguage()]}
              </div>
            )
          })}
        </div>
        {this.getOverridesPanel()}
      </div>
    )
  }
}
