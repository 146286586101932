import _ from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'

import { DateRangePicker } from 'react-date-range'

import { RawMaterial } from '../common/data-raw-materials'

import { getDataService } from '../common/data-service'
import { i18n } from '../common/i18n'
import { Modal } from './modal'
import { useOpenModalEvent } from './use-open-modal-event'

interface ViewMaterialInOutModalProps {

  modalId: string,
  material?: RawMaterial,

}

interface DisplayState {
  consumption: {
    in: number,
    out: number,
  },
  material: RawMaterial,
  selectedRange: {
    startDate: Date,
    endDate: Date,
    key: string,
  },
}

class InOutComponent extends React.Component<ViewMaterialInOutModalProps, DisplayState> {

  constructor(props: ViewMaterialInOutModalProps) {
    super(props)

    const state: DisplayState = {
      consumption: {
        in: 0,
        out: 0,
      },
      material: props.material,
      selectedRange: {
        startDate: moment().startOf('month').toDate(),
        endDate: moment().endOf('month').toDate(),
        key: 'selection',
      },
    }

    this.state = state

  }

  _isMounted = false

  componentDidMount() {
    this._isMounted = true

    this.updateConsumptionNumbers()
  }

  async updateConsumptionNumbers() {

    const DataService = getDataService()

    const result = await DataService.MaterialYearlyReports.getAll({
      material: [this.state.material._id],
      timeFrom: this.state.selectedRange.startDate.toISOString(),
      timeTo: this.state.selectedRange.endDate.toISOString(),
    })

    const [report] = result

    if (!report) {
      this.state.consumption.in = 0
      this.state.consumption.out = 0
    }

    else {

      this.state.consumption.in = _.round(report.amountAdded, 4)
      this.state.consumption.out = _.round(report.amountConsumed, 4)

    }

    this.setState(this.state)

  }

  onDateRangeChange(ranges) {
    const { startDate, endDate } = ranges.selection

    this.state.selectedRange.startDate = startDate
    this.state.selectedRange.endDate = moment(endDate).endOf('day').toDate()
    this.updateConsumptionNumbers()
  }

  render() {

    return (
      <div>
        <b>{`${i18n.t('common.period')}:`}</b>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <DateRangePicker
                ranges={[this.state.selectedRange]}
                onChange={((ranges) => {
                  this.onDateRangeChange(ranges)
                })}
              ></DateRangePicker>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6">
              <div className="row">
                <p className="h3">{`${i18n.t('common.in')}:`}</p>
              </div>
              <div className="row">
                {this.state.consumption.in} {this.state.material?.amount.unit}
              </div>
            </div>
            <div className="col-xs-6">
              <div className="row">
                <p className="h3">{`${i18n.t('common.out')}:`}</p>
              </div>
              <div className="row">
                {this.state.consumption.out} {this.state.material?.amount.unit}
              </div>
            </div>
          </div>
        </div>
      </div>
    )

  }
}

export const ViewMaterialInOutModal = (props: ViewMaterialInOutModalProps) => {

  const [isVisible, close] = useOpenModalEvent(props.modalId)
  const [isClosingDisabled, setClosingDisabled] = useState(false)

  if (!isVisible) {
    return <div />
  }

  const closeUnlessDisabled = () => {
    if (!isClosingDisabled) {
      close()
    }
  }

  const buttons = []

  return (
    <div>
      <Modal
        title={i18n.t('raw-materials.in-out')}
        closeModal={closeUnlessDisabled}
        dialogClassName="view-material-consumption"
        buttons={buttons}
      >
        <InOutComponent
          modalId={props.modalId}
          material={props.material}
        ></InOutComponent>
      </Modal>
    </div>
  )

}