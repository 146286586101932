import React = require('react')
import ReactDOM = require('react-dom')

export const EnvBanner = {
  init: function(envName) {
    if (envName === 'staging' || envName === 'development') {
      const text = envName.toUpperCase() + ' MODE'
      const container = document.querySelector('#env-banner')
      ReactDOM.render(<div>{text}</div>, container)
    }
  },
}
