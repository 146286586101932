import React = require('react')

interface ZoomIconProps {
  size?: number,
  in?: boolean, // Zoom in or zoom out
  onClick?: () => void,
  color?: string,
}

const CENTER = 35
const RADIUS = 30
const STROKE_WIDTH = 8
const SYMBOL_RADIUS = 20
const HANDLE_START = CENTER + RADIUS / Math.sqrt(2)

export class ZoomIcon extends React.Component<ZoomIconProps> {
  static defaultProps = { size: 20, in: false, color: 'black' }

  renderHorizontalLine = () => {
    return (
      <line
        x1={CENTER - SYMBOL_RADIUS}
        y1={CENTER}
        x2={CENTER + SYMBOL_RADIUS}
        y2={CENTER}
        stroke={this.props.color}
        strokeWidth={STROKE_WIDTH}
      />
    )
  }

  renderVerticalLine = () => {
    if (this.props.in) {
      return (
        <line
          x1={CENTER}
          y1={CENTER - SYMBOL_RADIUS}
          x2={CENTER}
          y2={CENTER + SYMBOL_RADIUS}
          stroke={this.props.color}
          strokeWidth={STROKE_WIDTH}
        />
      )
    }
  }

  render() {
    return (
      <svg
        ref="icon"
        width={this.props.size}
        height={this.props.size}
        viewBox="0 0 100 100"
        onClick={this.props.onClick}
        style={{ cursor: this.props.onClick ? 'pointer' : null }}
      >
        <circle
          cx={CENTER}
          cy={CENTER}
          r={RADIUS}
          fill="transparent"
          stroke={this.props.color}
          strokeWidth={STROKE_WIDTH}
        />
        <line
          x1={HANDLE_START}
          y1={HANDLE_START}
          x2={90}
          y2={90}
          stroke={this.props.color}
          strokeWidth={STROKE_WIDTH}
        />
        {this.renderHorizontalLine()}
        {this.renderVerticalLine()}
      </svg>
    )
  }
}
