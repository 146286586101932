import React = require('react')

import { CommonUtils } from '../common/common-utils'
import { getDataService } from '../common/data-service'
import { Currency } from '../common/invoice-utils'
import { EditFormProps, DefinitionEditForm } from './definition-edit-form'
import { EditModalProps, DefinitionEditModal } from './definition-edit-modal'
import { DefinitionList } from './definition-list'
import { ProductDefinitions } from './product-definitions'

const currencies: Currency[] = ['MAD']

const EditForm = React.forwardRef((props: EditFormProps, ref: React.ForwardedRef<DefinitionEditForm>) => (
  <DefinitionEditForm
    ref={ref}
    {...props}
    isProduction={true}
    definitionService={getDataService().TemplateDefinitions}
    currencies={currencies}
    menuTabId="templates"
  />
))

const EditModal = (props: EditModalProps) => {
  return <DefinitionEditModal {...props} isProduction={true} editForm={EditForm} />
}

const List = () => (
  <DefinitionList
    isProduction={true}
    menuTabId="templates"
    editModal={EditModal}
    productDefinitionModal={ProductDefinitions.EditModal}
    definitionService={getDataService().TemplateDefinitions}
    currencies={currencies}
    attributeContextFilter={CommonUtils.attributeContextFilters.templates}
  />
)

export const TemplateDefinitions = {
  List,
  EditModal,
}
