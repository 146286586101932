import React = require('react')

import { i18n } from '../common/i18n'

interface TabMenuProps {
  menuId: string,
  tabs: any[],
  activeTab?: string,
  pills?: boolean,
}

export class TabMenu extends React.Component<TabMenuProps> {
  render() {
    return (
      <nav id={'menu-' + this.props.menuId}>
        <ul
          className={'nav nav-' + (this.props.pills ? 'pills' : 'tabs')}
          style={{ position: 'relative' }}
        >
          {this.props.tabs.map((tab) => {
            const href = '#' + tab.route
            const className = tab.id === this.props.activeTab ? 'active' : ''
            const tabName = tab.name || i18n.t('menu.' + this.props.menuId + '.' + tab.id)

            return (
              <li key={tab.id} id={'menu-item-' + tab.id} className={className}>
                <a href={href}>{tabName}</a>
              </li>
            )
          })}
          {this.props.children}
        </ul>
      </nav>
    )
  }
}
