import classNames from 'classnames'
import { ReactNode } from 'react'
import React = require('react')
import toastr from 'toastr/toastr'

import { CommonUtils } from '../common/common-utils'
import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { LoadingButton } from './loading-button'
import { Selector } from './selector'
import { Column, UiUtils } from './ui-utils'
import { Utils } from './utils'
import { ValidationErrors, ValidationUtils } from './validation-utils'

export interface DataEditorField {
  type?: 'text' | 'number' | 'boolean' | 'enum' | 'derived',
  id: string,
  header: string,
  immutable?: boolean,
  getValue: (obj: any, edits?: Record<string, unknown>) => unknown,
  getPlaceholder?: (obj: any, edits: Record<string, unknown>) => string,
  validationKey?: string | ((obj: any) => string),
  enumValues?: string[],
}

interface ActionResult {
  ok: boolean,
  validationErrors?: ValidationErrors,
  originalError?: { status?: number },
}

interface DataEditorProps {
  canAdd: boolean,
  canDelete: boolean | ((obj: any) => boolean),
  skipDeleteConfirmation?: boolean,
  hideSaveButtons?: boolean,
  fields: DataEditorField[],
  objs: any[],
  actions?: {
    save?: (id: string, obj: any) => Promise<ActionResult>,
    add?: (obj: any) => Promise<ActionResult>,
    delete?: (id: string) => Promise<void>,
    change?: (id: string, obj: any) => void,
  },
  getNew?: (edits: Record<string, unknown>) => unknown,
  getUpdates?: (fieldValues: Record<string, unknown>) => unknown,
  reloadData: () => Promise<void>,
  getAdditionalButtons?: (id: string) => ReactNode,
  validationErrors?: ValidationErrors,
}

interface State {
  edits: Record<string, Record<string, unknown>>,
  validationErrors?: ValidationErrors,
}

export class DataEditor extends React.Component<DataEditorProps, State> {
  private readonly ADD_ROW_ID = '__add__'

  constructor(props: DataEditorProps) {
    super(props)
    const edits: Record<string, Record<string, unknown>> = {}

    for (const obj of props.objs) {
      edits[obj._id] = {}
    }

    if (props.canAdd) {
      edits[this.ADD_ROW_ID] = {}
    }

    this.state = { edits, validationErrors: props.validationErrors }
  }

  _isMounted = false

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidUpdate(prevProps: DataEditorProps) {
    if (this.props.validationErrors !== prevProps.validationErrors) {
      this.setState({ validationErrors: this.props.validationErrors })
    }
  }

  getPrefixedValidationErrors = (errors: ValidationErrors, prefix: string) => {
    const prefixedErrors = CommonUtils.clone(this.state.validationErrors || {})

    for (const key of Object.keys(errors)) {
      prefixedErrors[prefix + '.' + key] = CommonUtils.clone(errors[key])
    }

    return prefixedErrors
  }

  clearValidationErrorsForPrefix = (prefix: string) => {
    toastr.clear()

    if (!this.state.validationErrors) {
      return Promise.resolve()
    }

    return new Promise<void>((resolve) => {
      const newErrors = {}

      for (const key of Object.keys(this.state.validationErrors)) {
        if (!key.startsWith(prefix + '.')) {
          newErrors[key] = this.state.validationErrors[key]
        }
      }

      this.setState({ validationErrors: newErrors }, resolve)
    })
  }

  getNewObj = (id: string) => {
    const edits = this.state.edits[id] || {}

    if (id === this.ADD_ROW_ID) {
      return this.props.getNew(edits)
    }
    else {
      const original = CommonUtils.findById(this.props.objs, id)

      const fieldValues: Record<string, unknown> = {}

      for (const field of this.props.fields) {
        if (field.type !== 'derived') {
          const value = field.id in edits ? edits[field.id] : field.getValue(original)
          fieldValues[field.id] = value
        }
      }

      return this.props.getUpdates(fieldValues)
    }
  }

  save = async (id: string) => {
    const newObj = this.getNewObj(id)

    await this.clearValidationErrorsForPrefix(id)
    let result: ActionResult

    if (id === this.ADD_ROW_ID) {
      result = await this.props.actions.add(newObj)
    }
    else {
      result = await this.props.actions.save(id, newObj)
    }

    if (result.ok) {
      await this.props.reloadData()
      // Reset the edits of this row
      const newEdits = CommonUtils.clone(this.state.edits)
      newEdits[id] = {}
      await UiUtils.setState(this, { edits: newEdits })
    }
    else if (result.validationErrors) {
      const prefixedErrors = this.getPrefixedValidationErrors(result.validationErrors, id)

      await UiUtils.setState(this, { validationErrors: prefixedErrors })
      EventBus.fire('validation-errors-rendered')
    }
    else {
      if (result.originalError && result.originalError.status === 401) {
        // Nothing to do, should already be handled in errorHandled
      }
      else {
        throw new Error('Unknown error occurred on save')
      }
    }
  }

  delete = async (id: string) => {
    if (
      this.props.skipDeleteConfirmation ||
      (await Utils.confirm('Are you sure you want to delete this entry?'))
    ) {
      await this.props.actions.delete(id)
      return this.props.reloadData()
    }
  }

  hasIncompleteNewValue = () => { // Public method
    const edits = this.state.edits[this.ADD_ROW_ID]

    return Object.keys(edits).some((fieldId) => Boolean(edits[fieldId]))
  }

  getFieldColumnConf = (field: DataEditorField): Column<{ id: string }> => {
    return {
      id: field.id,
      header: field.header,
      getCellContents: (obj) => {
        const adding = obj.id === this.ADD_ROW_ID
        const derived = field.type === 'derived'

        const edits = this.state.edits[obj.id] || {}

        let value

        if (derived) {
          // The edits param is only used with derived fields
          value = field.getValue(obj, edits)
        }
        else if (field.id in edits) {
          value = edits[field.id]
        }
        else if (adding) {
          value = null
        }
        else {
          value = field.getValue(obj)
        }

        let placeholder = null

        if (!adding) {
          placeholder = field.getPlaceholder ? field.getPlaceholder(obj, edits) : null
        }

        // Immutable fields are editable when adding and read-only when editing.
        // Derived fields are always read-only.
        if (derived || field.immutable && !adding) {
          return <div>{value}</div>
        }

        const setValue = (newValue: unknown) => {
          const newEdits = CommonUtils.clone(this.state.edits)

          if (!newEdits[obj.id]) {
            newEdits[obj.id] = {}
          }

          newEdits[obj.id][field.id] = newValue

          this.setState({ edits: newEdits }, () => {
            if (!adding && this.props.actions.change) {
              const newObj = this.getNewObj(obj.id)
              this.props.actions.change(obj.id, newObj)
            }
          })
        }

        let fieldElement = null

        if (field.type === 'text') {
          fieldElement = (
            <input
              value={String(value ?? '')}
              placeholder={String(placeholder ?? '')}
              onChange={(evt) => setValue(evt.currentTarget.value)}
              style={{ width: '20em' }}
            />
          )
        }
        else if (field.type === 'enum') {
          fieldElement = (
            <Selector
              className={'selector-' + field.id}
              horizontal={true}
              values={field.enumValues.map((enumValue) => ({ key: enumValue }))}
              selectedKey={value}
              onSelect={setValue}
            />
          )
        }
        else if (field.type === 'number') {
          fieldElement = (
            <input
              value={String(value ?? '')}
              onChange={(evt) => {
                const newValue = evt.currentTarget.value

                if (Utils.integerValidator(newValue)) {
                  setValue(newValue)
                }
              }}
              style={{ width: '3em' }}
            />
          )
        }
        else if (field.type === 'boolean') {
          fieldElement = (
            <input
              type="checkbox"
              checked={Boolean(value)}
              onChange={(evt) => setValue(evt.currentTarget.checked)}
            />
          )
        }
        else {
          throw new Error('Unknown field type: ' + field.type)
        }

        let validationKey: string

        if (typeof field.validationKey === 'function') {
          validationKey = field.validationKey(obj)
        }
        else {
          validationKey = obj.id + '.' + field.validationKey
        }

        return (
          <div>
            {fieldElement}
            {ValidationUtils.render(this.state.validationErrors, validationKey)}
          </div>
        )
      },
    }
  }

  render() {
    const columnConf: Column<{ id: string }>[] = []

    for (const field of this.props.fields) {
      columnConf.push(this.getFieldColumnConf(field))
    }

    columnConf.push({
      id: 'actions',
      header: i18n.t('action.actions'),
      getCellContents: (obj) => {
        const adding = obj.id === this.ADD_ROW_ID

        const addOrSave = () => this.save(obj.id)

        if (adding) {
          return <LoadingButton getPromise={addOrSave} text={i18n.t('action.add')} />
        }

        const edited = Object.keys(this.state.edits[obj.id] || {}).length > 0

        let saveButton = null
        let deleteButton = null

        if (!this.props.hideSaveButtons) {
          saveButton = (
            <LoadingButton
              className={classNames({ 'disabled': !edited })}
              disabled={!edited}
              getPromise={addOrSave}
              text="Save"
            />
          )
        }

        const canDeleteProp = this.props.canDelete
        let showDeleteButton: boolean
        let deleteButtonDisabled = false

        if (typeof canDeleteProp === 'function') {
          showDeleteButton = true
          deleteButtonDisabled = !canDeleteProp(obj)
        }
        else if (typeof canDeleteProp === 'boolean') {
          showDeleteButton = canDeleteProp
        }

        if (showDeleteButton) {
          deleteButton = (
            <LoadingButton
              disabled={deleteButtonDisabled}
              className={classNames({ 'disabled': deleteButtonDisabled })}
              getPromise={() => {
                return this.delete(obj.id)
              }}
              text={i18n.t('action.delete')}
            />
          )
        }

        let additionalButtons: ReactNode = null

        if (!adding && this.props.getAdditionalButtons) {
          additionalButtons = this.props.getAdditionalButtons(obj.id)
        }

        return (
          <div>
            {saveButton}
            {' '}
            {deleteButton}
            {' '}
            {additionalButtons}
          </div>
        )
      },
    })

    const rowsData = this.props.objs.map((obj) => {
      const rowData = CommonUtils.clone(obj)
      rowData.id = rowData._id
      return rowData
    })

    if (this.props.canAdd) {
      rowsData.push({ id: this.ADD_ROW_ID })
    }

    return UiUtils.getTable(columnConf, rowsData, { tableClassName: 'data-editor' })
  }
}
