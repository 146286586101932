import React, { useState } from "react";
import { useOpenModalEvent } from "./use-open-modal-event";
import { Modal } from "./modal";
import { i18n } from "../common/i18n";
import { DateRangePicker } from 'react-date-range';

import moment from 'moment';
import _ from 'lodash';

interface DateRange {
  startDate: Date;
  endDate: Date;
}

interface DatePickerModalProps {
  modalId: string,
  startDate?: Date,
  endDate?: Date,
  onDateChange: (range: DateRange) => any
};

interface DatePickerState {
  startDate: Date;
  endDate: Date;
  key: string;
}

class DatePickerComponent extends React.Component<DatePickerModalProps, DatePickerState> {

  constructor(props: DatePickerModalProps) {

    super(props);

    const state: DatePickerState = {
      startDate: props.startDate || moment().subtract(1, 'year').startOf('year').startOf('day').toDate(),
      endDate: props.endDate || moment().subtract(1, 'year').endOf('year').endOf('day').toDate(),
      key: 'selection',
    };

    this.state = state;

  }

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true
  }

  onDateRangeChange(ranges) {

    this.setState(_.assign(this.state, { startDate: ranges.selection.startDate, endDate: ranges.selection.endDate }));

    this.props.onDateChange(ranges.selection);

  }

  render() {


    return (
      <div>
        <DateRangePicker
          ranges={[this.state]}
          onChange={(ranges => {
            this.onDateRangeChange(ranges);
          })}
        ></DateRangePicker>
      </div>
    )

  }

}

export const DateRangePickerModal = (props: DatePickerModalProps) => {

  const [isVisible, close] = useOpenModalEvent(props.modalId);
  const [isClosingDisabled, setClosingDisabled] = useState(false);

  if (!isVisible) {
    return <div />
  }

  const closeUnlessDisabled = () => {
    if (!isClosingDisabled) {
      close()
    }
  }

  return (
    <div>
      <Modal
        title={i18n.t('raw-materials.in-out')}
        closeModal={closeUnlessDisabled}
        dialogClassName="view-material-consumption"
        buttons={[]}
      >
        <DatePickerComponent
          modalId={props.modalId}
          onDateChange={props.onDateChange}
          startDate={props.startDate}
          endDate={props.endDate}
        ></DatePickerComponent>
      </Modal>
    </div>
  )

}