import { HTMLAttributes } from 'react'
import React = require('react')

import { AccountingUtils } from '../common/accounting-utils'
import { CommonUtils } from '../common/common-utils'
import { EarningCategory } from '../common/data-earning-categories'
import { Earning } from '../common/data-earnings'
import { getDataService } from '../common/data-service'
import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { AccountingMenu } from './accounting-menu'
import { EditEarningModal } from './edit-earning-modal'
import { Filter, FilterManager } from './filter'
import { MainMenu } from './main-menu'
import { TableButton } from './table-button'
import { Column, UiUtils } from './ui-utils'
import { User } from './user'
import { Utils } from './utils'

interface Row extends Omit<Earning, '_id' | 'earningCategory'> {
  id: number,
  earningCategoryId: string,
  earningCategory?: EarningCategory,
  err?: string,
}

interface ListState {
  earningCategories: EarningCategory[],
  earnings: Earning[],
}

const { EditButton, DeleteButton } = TableButton

class List extends React.Component<Record<string, never>, ListState> {
  state = {
    earningCategories: null as EarningCategory[] | null,
    earnings: null as Earning[] | null,
  }

  _isMounted = false // Used by Filter.registerEvent

  componentDidMount() {
    this._isMounted = true
    Filter.registerEvent(this)

    const DataService = getDataService()
    Promise.all([
      DataService.EarningCategories.getAll(),
      DataService.Earnings.getAll(),
    ])
    .then(([earningCategories, earnings]) => {
      this.setState({
        earningCategories,
        earnings,
      })
    })
  }

  componentWillUnmount() {
    this._isMounted = false
    Filter.unregisterEvent(this)
  }

  loadEarnings = () => {
    return getDataService().Earnings.getAll()
    .then((earnings) => {
      this.setState({ earnings })
    })
  }

  getFilterManager = (): FilterManager<Earning> => {
    const filterConf = {
      time: {
        labelKey: 'common.date',
        type: 'date-range',
      },
    }
    return Filter.createManager('earnings', filterConf, this.state.earnings)
  }

  getColumnConf = (): Column<Row>[] => {
    return [
      {
        id: 'id',
        header: '#',
      },
      {
        id: 'time',
        filterFieldName: 'time',
        header: i18n.t('common.date'),
        getCellContents: function(rowData) {
          const date = new Date(rowData.time)
          return CommonUtils.utcDateDMY(date)
        },
      },
      {
        id: 'description',
        header: i18n.t('common.description'),
      },
      {
        id: 'earningCategory',
        header: i18n.t('common.category'),
        getCellProperties: function(rowData) {
          const props: HTMLAttributes<HTMLTableCellElement> = {}
          if (rowData.err) {
            props.className = 'text-red-bold'
          }
          return props
        },
        getCellContents: function(rowData) {
          if (rowData.earningCategory) {
            return rowData.earningCategory
          } else {
            return rowData.err
          }
        },
      },
      {
        id: 'amount',
        header: i18n.t('common.amount'),
        getCellContents: function(rowData) {
          const amount = CommonUtils.formatDecimal(rowData.amount)
          return amount + ' ' + AccountingUtils.defaultCurrency
        },
      },
      {
        id: 'action',
        header: i18n.t('action.action'),
        getCellContents: this.renderActions,
      },
    ]
  }

  getRowsData = (filterManager: FilterManager<Earning>) => {
    const { earningCategories } = this.state
    const earnings = filterManager.getFiltered()
    return earnings.map(function(earning) {
      const { _id, earningCategory: earningCategoryId, ...rest } = earning
      const row: Row = { ...rest, id: _id, earningCategoryId }

      const language = User.getLanguage()
      try {
        const earningCategory = CommonUtils.findById<any>(earningCategories, earning.earningCategory)
        row.earningCategory = earningCategory.labels[language]
      } catch (err) {
        row.err = i18n.t('accounting.earnings.no-earning-category-error', earning.earningCategory)
      }

      return row
    })
  }

  renderEditButton = (rowData) => {
    return (
      <EditButton
        onClick={function() {
          EventBus.fire('open-modal', { modalId: 'edit-earning-' + rowData.id })
        }}
      />
    )
  }

  renderEditModal = (rowData) => {
    return (
      <EditEarningModal
        modalId={'edit-earning-' + rowData.id}
        earning={{
          _id: rowData.id,
          time: rowData.time,
          description: rowData.description,
          amount: rowData.amount,
          earningCategory: rowData.earningCategoryId,
        }}
        earningCategories={this.state.earningCategories}
        afterSave={this.loadEarnings}
      />
    )
  }

  renderDeleteButton = (rowData) => {
    return (
      <DeleteButton
        onClick={() => {
          Utils.confirmTr('confirm.delete.earning').then((confirmed) => {
            if (confirmed) {
              return getDataService().Earnings.delete(rowData.id).then(this.loadEarnings)
            }
          })
        }}
      />
    )
  }

  renderActions = (rowData) => {
    return (
      <div>
        {this.renderEditButton(rowData)}
        {this.renderDeleteButton(rowData)}
        {this.renderEditModal(rowData)}
      </div>
    )
  }

  renderAddButton = () => {
    return (
      <div className="button-container">
        <button
          id="btn-add-new"
          onClick={function() {
            EventBus.fire('open-modal', { modalId: 'add-modal' })
          }}
        >
          {i18n.t('accounting.earnings.add-earning')}
        </button>
        <EditEarningModal
          modalId="add-modal"
          earningCategories={this.state.earningCategories}
          afterSave={this.loadEarnings}
        />
      </div>
    )
  }

  renderMainMenu = () => {
    return <MainMenu key="main" activeTab="accounting" />
  }

  renderAccountingMenu = () => {
    return <AccountingMenu activeTab="earnings" />
  }

  renderBody = () => {
    if (!this.state.earnings) {
      return <p>{i18n.t('common.loading')}</p>
    } else {
      const filterManager = this.getFilterManager()
      const columnConf = this.getColumnConf()
      const rowsData = this.getRowsData(filterManager)
      return (
        <div>
          <div>{this.renderAddButton()}</div>
          {filterManager.getSummary()}
          {UiUtils.getTable<Row>(columnConf, rowsData, { filterManager })}
        </div>
      )
    }
  }

  render() {
    return (
      <div>
        {this.renderMainMenu()}
        {this.renderAccountingMenu()}
        {this.renderBody()}
      </div>
    )
  }
}

export const Earnings = {
  List,
}
