import { ReactNode } from 'react'
import React = require('react')

import { Access } from '../common/access'
import { CommonUtils } from '../common/common-utils'
import { UserCountry } from '../common/data-misc'
import { getDataService } from '../common/data-service'
import { UiUser } from '../common/data-users'
import { Enums } from '../common/enums'
import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { getApi } from '../define'
import { EditUserModal } from './edit-user-modal'
import { router } from './router'
import { SizeChanger } from './size-changer'
import { TabMenu } from './tab-menu'
import { User } from './user'

interface MainMenuProps {
  activeTab?: string,
}

export class MainMenu extends React.Component<MainMenuProps> {
  state = {
    loaded: false,
    cartCount: 0,
    countries: null as UserCountry[] | null,
    transferCount: undefined as number | undefined,
  }

  _isMounted = false

  async componentDidMount() {
    this._isMounted = true

    // TODO: main menu gets mounted a lot as its parent component changes.
    // Cache the cart and transfer counts somewhere outside this component?
    // For example, in the User component?

    EventBus.on('cart-updated', this.reloadCartInfo)

    const user = User.getUser()

    const DataService = getDataService()
    const countriesPromise = DataService.UserCountries.getAll()

    const [cartCount, sentTransfers, locations, countries] = await Promise.all([
      DataService.Carts.getCount(),
      DataService.Transfers.getAll({ status: Enums.transferStatuses.sent }),
      DataService.Locations.getAll(),
      countriesPromise, // TODO: load countries only when opening user edit modal?
    ])

    if (this._isMounted) {
      const transferCount = sentTransfers.filter(function (transfer) {
        const toLocation = CommonUtils.findById(locations, transfer.to)
        return Access.transfer(user, toLocation)
      }).length

      this.setState(
        {
          loaded: true,
          cartCount,
          transferCount,
          countries,
        },
        EventBus.fireFunc('main-menu-rendered'),
      )
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    EventBus.off('cart-updated', this.reloadCartInfo)
  }

  reloadCartInfo = () => {
    getDataService().Carts.getCount().then((count) => {
      if (this._isMounted) {
        this.setState({ cartCount: count }, EventBus.fireFunc('cart-info-reloaded'))
      }
    })
  }

  render() {
    if (!this.state.loaded) {
      return <div>{i18n.t('common.loading')}</div>
    }

    const user = User.getUser()

    const offset = user.country.gmtOffset
    let timezone = 'GMT'

    if (offset !== 0) {
      timezone = 'GMT ' + (offset > 0 ? '+' : '') + offset
    }

    // TODO: update time dynamically?
    const time = CommonUtils.toUserTime(user.country)
    const timeString = CommonUtils.utcTime(time)

    let cartTabName = null
    let transferTabName = null

    if (this.state.cartCount > 0) {
      cartTabName = i18n.t('menu.main.cart') + ' (' + this.state.cartCount + ')'
    }

    if (this.state.transferCount > 0) {
      transferTabName = i18n.t('menu.main.transfers') + ' (' + this.state.transferCount + ')'
    }

    const tabs = []
    tabs.push({ id: 'raw-materials', route: '/raw-materials' })
    tabs.push({ id: 'products', route: Access.seeInventory(user) ? '/template-defs' : '/product-defs' })
    if (Access.seeInventory(user)) {
      tabs.push({ id: 'inventory', route: '/inventory' })
    }

    if (Access.cart(user)) {
      tabs.push({ id: 'cart', route: '/cart', name: cartTabName })
    }

    if (Access.manageMaterialOrders(user)) {
      tabs.push({ id: 'material-orders', route: '/material-orders' })
    }

    if (Access.seeTransfers(user)) {
      tabs.push({ id: 'transfers', route: '/transfers', name: transferTabName })
    }
    if (Access.seeProductionReports(user)) {
      tabs.push({ id: 'reports', route: '/reports' })
    }

    if (Access.manageCustomers(user)) {
      tabs.push({ id: 'customers', route: '/customers' })
    }

    let userElement: ReactNode
    let editUserModal: ReactNode = null

    if (Access.manageUsers(user)) {
      tabs.push({ id: 'users', route: '/users' })

      userElement = (
        <span id="username">
          {user.givenName}
          {' '}
          {user.familyName}
        </span>
      )
    }
    else {
      userElement = (
        <a
          id="username"
          onClick={() => {
            EventBus.fire('open-modal', { modalId: 'edit-user-modal' })
          }}
          style={{ cursor: 'pointer' }}
        >
          {user.givenName}
          {' '}
          {user.familyName}
        </a>
      )

      const formUser: UiUser = {
        ...CommonUtils.clone(user),
        country: user.country._id,
      }

      editUserModal = (
        <EditUserModal
          modalId="edit-user-modal"
          user={formUser}
          countries={this.state.countries}
          afterSave={() => {
            // Trigger re-render because User.getUser() has been updated
            this.forceUpdate()
          }}
        />
      )
    }

    if (Access.seeDataMenu(user)) {
      tabs.push({ id: 'data', route: '/data' })
    }

    if (Access.accounting(user)) {
      tabs.push({ id: 'accounting', route: '/accounting/expenses' })
    }

    return (
      <TabMenu menuId="main" tabs={tabs} activeTab={this.props.activeTab}>
        <div id="user-details" className="pull-right" style={{ fontSize: '85%' }}>
          <div style={{ display: 'inline-block' }}>
            <div className="text-right">
              {i18n.t('common.user')}
              {': '}
              {userElement}
            </div>
            <div className="text-right">
              <a
                id="lnk-logout"
                style={{ cursor: 'pointer' }}
                onClick={function () {
                  getApi().logOut().then(function () {
                    router.setRoute('/login')
                  })
                }}
              >
                {i18n.t('user.log-out')}
              </a>
            </div>
          </div>
          <div
            style={{
              display: 'inline-block',
              marginLeft: '0.5em',
              paddingLeft: '0.5em',
              borderLeft: '1px solid #ccc',
            }}
          >
            <div className="text-right">
              {i18n.t('user.country')}
              {': '}
              <span id="user-country">
                {user.country.names[user.language]}
              </span>
            </div>
            <div className="text-right">
              {timeString + ' (' + timezone + ')'}
            </div>
          </div>
        </div>
        <div className="pull-right" style={{ fontSize: '85%', marginRight: '2em' }}>
          <SizeChanger />
        </div>
        {editUserModal}
      </TabMenu>
    )
  }
}
