import React = require('react')

import { Customer } from '../common/data-customers'
import { Invoice } from '../common/data-invoices'
import { getDataService } from '../common/data-service'
import { i18n } from '../common/i18n'
import { Comp } from './comp'
import { EmptyObject } from './empty-object'
import { MainMenu } from './main-menu'
import { TableButton } from './table-button'
import { Column, UiUtils } from './ui-utils'
import { Utils } from './utils'
import { ValidationErrors, ValidationUtils } from './validation-utils'

interface Row {
  id?: string,
  name: string,
  contact: string,
  invoiceIds: number[],
}

interface State {
  loaded: boolean,
  searchValue: string,
  editedCustomerId: null,
  editedContact: string,
  invoices?: Invoice[],
  customers?: Customer[],
  validationErrors?: ValidationErrors,
}

const { LabelInput } = Comp

const {
  EditLoadingButton,
  DeleteLoadingButton,
  SaveLoadingButton,
  CancelLoadingButton,
} = TableButton

class List extends React.Component<EmptyObject, State> {
  state: State = {
    loaded: false,
    searchValue: '',
    editedCustomerId: null,
    editedContact: '',
  }

  _isMounted = false

  componentDidMount() {
    this._isMounted = true

    const DataService = getDataService()
    const customersPromise = DataService.Customers.getAll()
    const unarchivedInvoicesPromise = DataService.Invoices.getAll()
    const archivedInvoicesPromise = DataService.Invoices.getArchived()

    Promise.all([customersPromise, unarchivedInvoicesPromise, archivedInvoicesPromise])
    .then(([customers, unarchivedInvoices, archivedInvoices]) => {
      const invoices = unarchivedInvoices.concat(archivedInvoices).sort(function(a, b) {
        return a._id - b._id
      })

      this.setState({ loaded: true, customers, invoices })
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  handleSearchChange = (searchValue) => {
    this.setState({ searchValue })
  }

  editContact = (rowData) => {
    this.setState({
      editedCustomerId: rowData.id,
      editedContact: rowData.contact,
    })
  }

  save = () => {
    const DataService = getDataService()

    return ValidationUtils.clear(this)
    .then(() => {
      return DataService.Customers.update(
        this.state.editedCustomerId, this.state.editedContact,
      )
    })
    .then(DataService.Customers.getAll)
    .then((customers) => {
      this.setState({
        customers,
        editedCustomerId: null,
        editedContact: '',
      })
    })
    .catch((error) => ValidationUtils.check(this, error))
  }

  delete = (id) => {
    const DataService = getDataService()

    return Utils.confirmTr('confirm.delete.customer')
    .then((confirmed) => {
      if (confirmed) {
        return DataService.Customers.delete(id)
        .then(DataService.Customers.getAll)
        .then((customers) => this.setState({ customers }))
      }

      return
    })
  }

  cancelEdit = () => {
    this.setState({
      editedCustomerId: null,
      editedContact: '',
    })
  }

  getColumnConf = (): Column<Row>[] => {
    const { state } = this

    return [
      {
        id: 'name',
        header: i18n.t('common.name'),
      },
      {
        id: 'contact',
        header: i18n.t('invoice.contact-info'),
        getCellProperties: function(rowData) {
          if (rowData.id !== state.editedCustomerId) {
            return { className: 'customer-contact-view' }
          }

          return {}
        },
        getCellContents: this.renderContact,
      },
      {
        id: 'invoices',
        header: i18n.t('invoice.invoices'),
        getCellContents: this.renderInvoices,
      },
      {
        id: 'actions',
        header: i18n.t('action.actions'),
        getCellContents: this.renderActions,
      },
    ]
  }

  findCustomerInvoiceIds = (customerName: string) => {
    return this.state.invoices.filter(function(invoice) {
      return invoice.customerName === customerName
    })
    .map(function(invoice) {
      return invoice._id
    })
  }

  getRowsData = () => {
    const { state } = this

    return state.customers.filter(function(customer) {
      return customer.name.toLowerCase().indexOf(state.searchValue.toLowerCase()) !== -1
    })
    .map((customer): Row => {
      return {
        id: customer._id,
        name: customer.name,
        contact: customer.contact,
        invoiceIds: this.findCustomerInvoiceIds(customer.name),
      }
    })
  }

  renderContactEdit = () => {
    return (
      <div>
        <textarea
          className="textarea"
          value={this.state.editedContact}
          onChange={(evt) => this.setState({ editedContact: evt.currentTarget.value })}
        />
        {ValidationUtils.render(this.state.validationErrors, 'contact')}
      </div>
    )
  }

  renderContactView = (rowData) => {
    return (
      <div onClick={() => this.editContact(rowData)}>
        {rowData.contact}
      </div>
    )
  }

  renderContact = (rowData) => {
    return rowData.id === this.state.editedCustomerId ?
      this.renderContactEdit() : this.renderContactView(rowData)
  }

  renderInvoices = (rowData) => {
    return (
      <ul>
        {rowData.invoiceIds.map(function(invoiceId) {
          return (
            <li key={invoiceId}>
              <a
                className="lnk-invoice"
                href={'#/invoice/view/' + invoiceId}
                style={{ fontWeight: 'bold' }}
              >
                {invoiceId}
              </a>
            </li>
          )
        })}
      </ul>
    )
  }

  renderEditButton = (rowData) => {
    return <EditLoadingButton key="edit" onClick={() => this.editContact(rowData)} />
  }

  renderDeleteButton = (id) => {
    return <DeleteLoadingButton key="delete" onClick={() => this.delete(id)} />
  }

  renderSaveButton = () => {
    return <SaveLoadingButton key="save" onClick={this.save} />
  }

  renderCancelButton = () => {
    return <CancelLoadingButton key="cancel" onClick={this.cancelEdit} />
  }

  renderActions = (rowData) => {
    if (rowData.id === this.state.editedCustomerId) {
      return [this.renderSaveButton(), this.renderCancelButton()]
    }

    return [this.renderEditButton(rowData), this.renderDeleteButton(rowData.id)]
  }

  renderBody = () => {
    if (!this.state.loaded) {
      return <p>{i18n.t('common.loading')}</p>
    }

    const columnConf = this.getColumnConf()
    const rowsData = this.getRowsData()

    return (
      <div>
        <LabelInput
          className="customers-search"
          label={i18n.t('customer.search-by-name') + ':'}
          value={this.state.searchValue}
          onChange={this.handleSearchChange}
        />
        {UiUtils.getTable(columnConf, rowsData)}
      </div>
    )
  }

  render() {
    return (
      <div>
        <MainMenu key="main" activeTab="customers" />
        {this.renderBody()}
      </div>
    )
  }
}

export const Customers = { List }
