import { getApi } from '../define'

let toastr = null

export const CommonConf = {
  initClient: function() {
    function handleError(error, message) {
      if (toastr) {
        // Show uncaught errors on UI
        toastr.error(message)
      }

      const API = getApi()
      API.logError(error, message)
    }

    window.addEventListener('error', function(e) {
      handleError(e.error, e.message)
    })

    window.addEventListener('unhandledrejection', function(e) {
      const error = e.reason

      if (typeof error === 'object' && error.alreadyHandled) {
        // We can ignore errors that have already been handled.
        // See error-handler for more information.
        e.preventDefault()
      }
      else {
        handleError(error, error.message)
      }
    })
  },

  setToastr: function(toastrParam) {
    toastr = toastrParam
  },
}
