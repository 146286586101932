import { HTMLAttributes } from 'react'
import React = require('react')

import { AccountingUtils } from '../common/accounting-utils'
import { CommonUtils } from '../common/common-utils'
import { EarningCategory } from '../common/data-earning-categories'
import { getDataService } from '../common/data-service'
import { i18n } from '../common/i18n'
import { AccountingMenu } from './accounting-menu'
import { MainMenu } from './main-menu'
import { MonthSelection } from './month-selection'
import { Column, UiUtils } from './ui-utils'
import { User } from './user'
import { YearSelection } from './year-selection'

interface Row {
  id: string,
  amount: number,
}

interface EarningsSummaryState {
  isLoading: boolean,
  year: string,
  month: string,
  summary: (Record<string, number> | null),
  earningCategories: EarningCategory[],
}

export class EarningsSummary extends React.Component<Record<string, never>, EarningsSummaryState> {
  state = {
    isLoading: true,
    year: CommonUtils.getCurrentYear(),
    month: CommonUtils.getCurrentMonth(),
    summary: null as Record<string, number> | null,
    earningCategories: null,
  }

  componentDidMount() {
    const period = this.getPeriod(this.state.year, this.state.month)
    const DataService = getDataService()
    const summaryPromise = DataService.Earnings.summary(period.start, period.end)
    const categoriesPromise = DataService.EarningCategories.getAll()
    Promise.all([summaryPromise, categoriesPromise])
    .then(([summary, categories]) => {
      this.setState({
        isLoading: false,
        summary,
        earningCategories: categories,
      })
    })
  }

  getLastDayOfMonth = (year, strMonth) => {
    const intMonth = parseInt(strMonth) - 1
    const newDate = new Date(Date.UTC(year, intMonth, 1, 0, 0, 0))
    return CommonUtils.getLastDayOfMonthYmd(newDate)
  }

  getPeriod = (year, month) => {
    if (month) {
      return {
        start: year + '-' + month + '-01',
        end: this.getLastDayOfMonth(year, month),
      }
    } else {
      return {
        start: year + '-01-01',
        end: year + '-12-31',
      }
    }
  }

  setPeriod = (year, month?) => {
    this.setState({ isLoading: true })
    const period = this.getPeriod(year, month)
    getDataService().Earnings.summary(period.start, period.end)
    .then((summary) => {
      this.setState({
        isLoading: false,
        year,
        month,
        summary,
      })
    })
  }

  setYear = (year) => {
    this.setPeriod(year)
  }

  setMonth = (month) => {
    const newMonth = month === this.state.month ? null : month
    this.setPeriod(this.state.year, newMonth)
  }

  getColumnConf = (): Column<Row>[] => {
    return [
      {
        id: 'id',
        header: i18n.t('common.category'),
        getCellProperties: (rowData) => {
          const category = CommonUtils.findById(this.state.earningCategories, rowData.id)
          const props: HTMLAttributes<HTMLTableCellElement> = {}
          if (!category) {
            props.className = 'text-red-bold'
          }
          return props
        },
        getCellContents: (rowData) => {
          const category = CommonUtils.findById<any>(this.state.earningCategories, rowData.id)
          if (category) {
            const lang = User.getLanguage()
            return category.labels[lang]
          } else {
            return i18n.t('accounting.earnings.no-earning-category-error', rowData.id)
          }
        },
      },
      {
        id: 'amount',
        header: i18n.t('common.amount'),
        getCellContents: function(rowData) {
          return CommonUtils.formatDecimal(rowData.amount) + ' ' + AccountingUtils.defaultCurrency
        },
      },
    ]
  }

  getRowsData = () => {
    return Object.keys(this.state.summary).map((categoryId): Row => {
      return {
        id: categoryId,
        amount: this.state.summary[categoryId],
      }
    })
  }

  renderBody = () => {
    if (this.state.isLoading) {
      return <p>{i18n.t('common.loading')}</p>
    } else {
      const columnConf = this.getColumnConf()
      const rowsData = this.getRowsData()
      return UiUtils.getTable(columnConf, rowsData)
    }
  }

  render() {
    const { startYear } = AccountingUtils
    const currentYear = CommonUtils.getCurrentYear()
    return (
      <div>
        <MainMenu key="main" activeTab="accounting" />
        <AccountingMenu activeTab="earnings-summary" />
        <YearSelection
          start={startYear}
          end={currentYear}
          value={this.state.year}
          onChange={this.setYear}
        />
        <MonthSelection value={this.state.month} onChange={this.setMonth} />
        {this.renderBody()}
      </div>
    )
  }
}
