import { ChangeEvent } from 'react'
import React = require('react')

import { Invoice } from '../common/data-invoices'
import { i18n } from '../common/i18n'

const maxSearchResults = 10

interface InvoiceSearchProps {
  invoices?: Invoice[],
}

interface State {
  searchString: string,
}

export class InvoiceSearch extends React.Component<InvoiceSearchProps, State> {
  state: State = { searchString: '' }

  handleSearchChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const val = evt.target.value
    const intVal = parseInt(val, 10)

    if (val === '') {
      this.setState({ searchString: '' })
    } else if (!isNaN(intVal)) {
      this.setState({ searchString: String(intVal) })
    }
  }

  renderInvoiceLink = (invoiceId: number) => {
    const text = i18n.t('invoice.invoice') + ' #' + invoiceId

    return (
      <a
        className="lnk-invoice"
        href={'#/invoice/view/' + invoiceId}
        style={{ fontWeight: 'bold' }}
      >
        {text}
      </a>
    )
  }

  renderSearchResults = () => {
    const { searchString } = this.state

    if (!searchString) {
      return null
    }

    const { invoices } = this.props

    let idx = 0
    const results: number[] = []

    while (idx < invoices.length && results.length < maxSearchResults) {
      const invoice = invoices[idx]

      if (String(invoice._id).startsWith(searchString)) {
        results.push(invoice._id)
      }

      idx += 1
    }

    return (
      <ul id="invoice-search-results" className="search-results">
        {results.map((invoiceId) => {
          return (
            <li key={'search-result-' + invoiceId}>
              {this.renderInvoiceLink(invoiceId)}
            </li>
          )
        })}
      </ul>
    )
  }

  render() {
    return (
      <div className="padded">
        <p className="text-bold">
          {i18n.t('invoice.search-by-number')}
        </p>
        <input
          className="input-narrow"
          value={this.state.searchString}
          onChange={this.handleSearchChange}
        />
        {this.renderSearchResults()}
      </div>
    )
  }
}
