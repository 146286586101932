import _ from 'lodash'
import moment from 'moment'
import React, { useRef } from 'react'

import {
  MaterialOrder,
  OrderedMaterial,
} from '../../../common/data-material-order'
import { MaterialProducer } from '../../../common/data-misc'
import { RawMaterial } from '../../../common/data-raw-materials'
import { getDataService } from '../../../common/data-service'
import {
  AdditionalExpenseType,
  Enums,
  MaterialOrderStatus,
  PaymentType,
} from '../../../common/enums'
import { i18n } from '../../../common/i18n'
import { Modal, getDefaultCancelButton } from '../../modal'
import { useOpenModalEvent } from '../../use-open-modal-event'
import { ValidationUtils } from '../../validation-utils'

interface FormProps {
  order: MaterialOrder,
  producer: MaterialProducer,
  materials: RawMaterial[],
}

interface FormState {
  order: MaterialOrder,
}

class Form extends React.Component<FormProps, FormState> {
  constructor(props: FormProps) {
    super(props)

    this.state = {
      order: _.assign({}, props.order, {
        additionalExpenses: Enums.orderedAdditionalExpenseTypes.map((type) => {
          const match = props.order.additionalExpenses.find(
            (ex) => ex.type === type,
          )

          if (match) {
            return match
          }

          return {
            type,
            cost: 0,
          }
        }),
      }),
    }
  }

  _isMounted = false

  componentDidMount() {
    this._isMounted = true
  }

  updateExpense(type: AdditionalExpenseType, cost: number) {
    const expense = this.state.order.additionalExpenses.find(
      (e) => e.type === type,
    )

    if (expense) {
      expense.cost = cost

      return this.updateOrder({
        additionalExpenses: this.state.order.additionalExpenses,
      })
    }
  }

  updateOrderedMaterial(index: number, update: Partial<OrderedMaterial>) {
    const orderedMaterial = this.state.order.materials[index]

    if (orderedMaterial) {
      for (const key in update) {
        const value = update[key]

        if (_.isString(value) && (value === '-' || value.trim() === '')) {
          update[key] = null
        }
      }

      _.assign(orderedMaterial, update)

      return this.updateOrder({ materials: this.state.order.materials })
    }
  }

  updateOrder(update: Partial<MaterialOrder>) {
    const { order } = this.state

    for (const key in update) {
      const value = update[key]

      if (_.isString(value) && (value === '-' || value.trim() === '')) {
        update[key] = null
      }
    }

    if (update.materials) {
      for (const key in update) {
        const value = update[key]

        if (_.isString(value) && (value === '-' || value.trim() === '')) {
          update[key] = null
        }
      }
    }

    _.assign(order, update)

    order.totalCost = _.round(
      order.materials.reduce((acc, m) => (acc += m.cost), 0) +
        order.additionalExpenses.reduce((acc, e) => (acc += e.cost), 0),
      2,
    )

    this.setState({ order })
  }

  getStatusSelector(defaultStatus: MaterialOrderStatus) {
    return (
      <select
        defaultValue={defaultStatus}
        onChange={(event) =>
          this.updateOrder({
            status: event.target.value as MaterialOrderStatus,
          })
        }
      >
        {Enums.orderedMaterialOrderStatuses.map((status) => {
          return (
            <option key={status} value={status}>
              {i18n.t('enum.material-orders-statuses.' + status)}
            </option>
          )
        })}
      </select>
    )
  }

  getPaymentTypeSelector(defaultType: PaymentType = null) {
    return (
      <select
        defaultValue={defaultType}
        onChange={(event) =>
          this.updateOrder({ paymentType: event.target.value as PaymentType })
        }
      >
        <option key="" value={null}>
          -
        </option>
        {Enums.orderedPaymentTypes.map((type) => {
          return (
            <option key={type} value={type}>
              {i18n.t('enum.payment-types.' + type)}
            </option>
          )
        })}
      </select>
    )
  }

  renderMaterialList() {
    return this.state.order.materials.map((orderMaterial, index) => {
      const material = this.props.materials.find(
        (m) => m._id === orderMaterial.material,
      )

      return (
        <div key={index}>
          {this.row(
            i18n.t('material-orders.material-reference'),
            <span>{material.reference || material.name}</span>,
          )}
          {this.row(
            i18n.t('common.amount'),
            <div>
              <input
                type="number"
                step="0.01"
                defaultValue={orderMaterial.amount}
                onChange={(event) =>
                  event.target.value ?
                    this.updateOrderedMaterial(index, {
                        amount: parseInt(event.target.value, 10),
                      }) :
                    null
                }
              ></input>
              <span> {material.amount.unit}</span>
            </div>,
          )}
          <hr className="solid"></hr>
        </div>
      )
    })
  }

  renderAdditionalExpenses() {
    return (
      <div>
        <b>{i18n.t('common.additional-expenses')}</b>
        {this.state.order.additionalExpenses.map((expense, index) => {
          return (
            <div key={index}>
              {this.row(
                i18n.t('enum.additional-expenses.' + expense.type),
                <input
                  type="number"
                  step=".01"
                  defaultValue={expense.cost}
                  onChange={(event) =>
                    event.target.value ?
                      this.updateExpense(
                          expense.type,
                          parseFloat(event.target.value),
                        ) :
                      null
                  }
                ></input>,
              )}
            </div>
          )
        })}
      </div>
    )
  }

  row(fieldName, field, validationField?) {
    return (
      <div className="row" style={{ margin: '0.5em 0' }}>
        <div
          className="col-xs-5"
          style={{ fontWeight: 'bold', paddingLeft: 0 }}
        >
          {fieldName}
        </div>
        <div className="col-xs-7">
          {field}
          {ValidationUtils.render(null, validationField)}
        </div>
      </div>
    )
  }

  onSave() {
    const dataService = getDataService()

    const { order } = this.state

    return dataService.MaterialOrders.update(order._id, {
      status: order.status,
      invoice: order.invoice,
      expense: order.expense,
      paymentType: order.paymentType,
      timeOfPayment: order.timeOfPayment,
      materials: order.materials.map((m) => {
        return {
          material: m.material,
          amount: m.amount,
          originalCost: m.originalCost,
          cost: m.cost,
        }
      }),
      additionalExpenses: [], // order.additionalExpenses,
      deliveryNote: order.deliveryNote,
      verificationOfConformity: order.verificationOfConformity,
      note: order.note,
      archived: order.archived,
    })
  }

  render() {
    return (
      <div>
        <div id="form-edit-material-order">
          {this.row(
            i18n.t('material-orders.id') + ':',
            <span>{this.state.order._id}</span>,
          )}
          {this.row(
            i18n.t('material-orders.status'),
            this.getStatusSelector(this.state.order.status),
          )}
          {this.row(
            i18n.t('material-orders.payment-type'),
            this.getPaymentTypeSelector(this.state.order.paymentType),
          )}
          {this.row(
            i18n.t('material-orders.delivery-note'),
            <input
              type="text"
              defaultValue={this.state.order.deliveryNote}
              onChange={(event) =>
                this.updateOrder({ deliveryNote: event.target.value })
              }
            ></input>,
          )}
          {this.row(
            i18n.t('material-orders.verification-of-conformity'),
            <input
              type="text"
              defaultValue={this.state.order.verificationOfConformity}
              onChange={(event) =>
                this.updateOrder({
                  verificationOfConformity: event.target.value,
                })
              }
            ></input>,
          )}
          {this.row(
            i18n.t('material-orders.note'),
            <input
              type="text"
              defaultValue={this.state.order.note}
              onChange={(event) =>
                this.updateOrder({ note: event.target.value })
              }
            ></input>,
          )}
          {this.row(
            i18n.t('material-orders.expense') + ':',
            <input
              type="number"
              defaultValue={this.state.order.expense}
              onChange={(event) =>
                this.updateOrder({
                  expense: event.target.value ?
                    parseInt(event.target.value, 10) :
                    null,
                })
              }
            ></input>,
          )}
          {this.row(
            i18n.t('material-orders.invoice') + ':',
            <input
              type="number"
              defaultValue={this.state.order.invoice}
              onChange={(event) =>
                this.updateOrder({
                  invoice: event.target.value ?
                    parseInt(event.target.value, 10) :
                    null,
                })
              }
            ></input>,
          )}
          {this.row(
            i18n.t('material-orders.time-of-payment'),
            <input
              type="date"
              defaultValue={
                this.state.order.timeOfPayment ?
                  moment(this.state.order.timeOfPayment).format('yyyy-MM-DD') :
                  null
              }
              onChange={(event) =>
                this.updateOrder({
                  timeOfPayment: event.target.value ?
                    new Date(event.target.value) :
                    null,
                })
              }
            ></input>,
          )}
          <hr className="solid"></hr>
          {this.renderMaterialList()}
        </div>
        {this.row(
          i18n.t('material-orders.add-archive'),
          <input
            type="checkbox"
            defaultChecked={this.state.order.archived}
            onChange={(event) =>
              this.updateOrder({ archived: event.target.checked })
            }
          ></input>,
        )}
      </div>
    )
  }
}

interface EditMaterialOrdersProps {
  modalId: string,
  order: MaterialOrder,
  producer: MaterialProducer,
  materials: RawMaterial[],
  afterSave: () => void,
}

export const EditMaterialOrderModal = (props: EditMaterialOrdersProps) => {
  const [isVisible, close] = useOpenModalEvent(props.modalId)
  const formRef = useRef(null)

  if (!isVisible) {
    return <div />
  }

  return (
    <div>
      <Modal
        size="lg"
        title={i18n.t('menu.main.material-orders')}
        closeModal={close}
        dialogClassName="add-material-order"
        buttons={[
          {
            id: 'btn-save',
            title: i18n.t('action.save'),
            onClick: () => {
              return formRef.current.onSave().then(props.afterSave).then(close)
            },
          },
          getDefaultCancelButton(),
        ]}
      >
        <Form
          ref={formRef}
          order={props.order}
          producer={props.producer}
          materials={props.materials}
        />
      </Modal>
    </div>
  )
}
