import { useRef } from 'react'
import React = require('react')
import toastr from 'toastr/toastr'

import { Access } from '../common/access'
import { CommonUtils } from '../common/common-utils'
import { Location } from '../common/data-locations'
import { UserCountry } from '../common/data-misc'
import { getDataService } from '../common/data-service'
import { UiUser } from '../common/data-users'
import { Enums, Language, Role } from '../common/enums'
import { i18n } from '../common/i18n'
import { getApi } from '../define'
import { bindInput, bindProps } from './bind-utils'
import { getDefaultCancelButton, Modal } from './modal'
import { Selector } from './selector'
import { useOpenModalEvent } from './use-open-modal-event'
import { User } from './user'
import { Utils } from './utils'
import { ValidationErrors, ValidationUtils } from './validation-utils'

interface FormProps {
  isNew?: boolean,
  user?: UiUser,
  countries: UserCountry[],
}

interface FormState {
  loaded: boolean,
  user: {
    _id?: string,
    username: string,
    givenName: string,
    familyName: string,
    email?: string,
    country: string | null,
    language: Language | null,
    role: Role | null,
    allowedLocations?: string[],
    password?: string,
    deactivated?: boolean,
  },
  locations: Location[],
  oldPassword?: string,
  newPassword: string,
  repeatNewPassword: string,
  validationErrors?: ValidationErrors,
}

class Form extends React.Component<FormProps, FormState> {
  constructor(props: FormProps) {
    super(props)
    let user: FormState['user']

    if (props.isNew) {
      user = {
        username: '',
        givenName: '',
        familyName: '',
        email: '',
        country: null,
        language: null,
        role: null,
        allowedLocations: [],
      }
    }
    else {
      user = CommonUtils.clone(props.user)
    }

    this.state = {
      loaded: false,
      user,
      locations: [],
      newPassword: '',
      repeatNewPassword: '',
    }
  }

  componentDidMount() {
    this._isMounted = true

    getDataService().Locations.getByType('sales-points').then((locations) => {
      if (this._isMounted) {
        this.setState({ loaded: true, locations })
      }
    })
  }

  repeatPasswordInputRef = React.createRef<HTMLInputElement>()

  _isMounted = false

  componentWillUnmount() {
    this._isMounted = false
  }

  onSave = () => { // Public method
    if (this.state.repeatNewPassword !== this.state.newPassword) {
      toastr.error(i18n.t('user.passwords-no-match'))
      this.repeatPasswordInputRef.current.focus()

      // Return a promise that skips the remaining chain
      return Promise.reject(Utils.getAlreadyHandledError())
    }

    const user = CommonUtils.clone(this.state.user)

    if (this.state.newPassword !== '') {
      user.password = this.state.newPassword
    }

    const editingSelf = user._id === User.getUser()._id

    if (editingSelf && !this.canManageUsers()) {
      delete user.role
      delete user.allowedLocations
    }

    return ValidationUtils.clear(this)
    .then<unknown>(() => {
      if (this.props.isNew) {
        return getDataService().Users.create(user)
      }
      else {
        const id = user._id
        delete user._id
        delete user.deactivated
        return getDataService().Users.update(id, user, this.state.oldPassword)
      }
    })
    .then(() => {
      if (!this.props.isNew && editingSelf) {
        // Reload user details
        // (could create a separate API command for this but currently reusing isLoggedIn)
        return getApi().isLoggedIn()
        .then(function(response) {
          User.setUser(response.user)
        })
      }
    })
    .catch((error) => ValidationUtils.check(this, error))
  }

  canManageUsers = () => {
    return Access.manageUsers(User.getUser())
  }

  getCountryDropdown = () => {
    return (
      <select
        {...bindProps(this, ['user', 'country'], { id: 'inp-country', style: { width: '15em' } })}
      >
        {!this.state.user.country ? <option value="" /> : null}
        {this.props.countries.map(function(country) {
          return (
            <option key={country._id} value={country._id}>
              {country.names[User.getLanguage()]}
            </option>
          )
        })}
      </select>
    )
  }

  getLanguageDropdown = () => {
    return (
      <select
        {...bindProps(this, ['user', 'language'], { id: 'inp-language', style: { width: '15em' } })}
      >
        {!this.state.user.language && <option value="" />}
        {Enums.orderedLanguages.map(function(language) {
          const langName = i18n.t('enum.languages.' + language)
          return (
            <option key={language} value={language}>
              {langName}
            </option>
          )
        })}
      </select>
    )
  }

  getRoleDropdown = () => {
    if (!this.canManageUsers()) {
      const roleName = i18n.t('enum.roles.' + this.state.user.role)
      return <div id="lbl-role">{roleName}</div>
    }

    return (
      <select
        {...bindProps(this, ['user', 'role'], { id: 'inp-role', style: { width: '15em' } })}
      >
        {!this.state.user.role ? <option value="" /> : null}
        {Enums.orderedRoles.map(function(role) {
          return (
            <option key={role} value={role}>
              {i18n.t('enum.roles.' + role)}
            </option>
          )
        })}
      </select>
    )
  }

  getLocationSelector = () => {
    if (!this.canManageUsers()) {
      return this.state.user.allowedLocations.map((locationId) => {
        const loc = CommonUtils.findById(this.state.locations, locationId)

        return (
          <div key={locationId} className="lbl-allowed-location">
            {loc.names[User.getLanguage()]}
          </div>
        )
      })
    }

    return (
      <Selector
        id="select-allowed-location"
        multiple={true}
        values={this.state.locations.map(function(loc) {
          return { key: loc._id, label: loc.names[User.getLanguage()] }
        })}
        selectedKeys={this.state.user.allowedLocations || []}
        onSelect={(locationId, selected) => {
          const user = CommonUtils.clone(this.state.user)

          if (selected) {
            if (!user.allowedLocations) {
              user.allowedLocations = []
            }

            user.allowedLocations.push(locationId)
          }
          else {
            CommonUtils.removeFromArray(user.allowedLocations, locationId)
          }

          this.setState({ user })
        }}
        style={{ minWidth: '15em' }}
      />
    )
  }

  render() {
    if (!this.state.loaded) {
      return <div>{i18n.t('common.loading')}</div>
    }

    const row = (fieldName, field, validationField?) => {
      return (
        <div className="row" style={{ margin: '0.5em 0' }}>
          <div className="col-xs-5" style={{ fontWeight: 'bold', paddingLeft: 0 }}>
            {fieldName}
          </div>
          <div className="col-xs-7">
            {field}
            {ValidationUtils.render(this.state.validationErrors, validationField)}
          </div>
        </div>
      )
    }

    let allowedLocationsRow = null

    if (this.state.user.role && Access.isLocationBased(this.state.user.role)) {
      allowedLocationsRow = row(
        i18n.t('user.allowed-locations'),
        this.getLocationSelector(),
        'allowedLocations',
      )
    }

    let passwordEmptyNote = null
    let oldPasswordRow = null

    if (!this.props.isNew) {
      passwordEmptyNote = (
        <div style={{ fontSize: '90%' }}>
          {i18n.t('user.password-leave-empty')}
        </div>
      )

      if (!this.canManageUsers()) {
        oldPasswordRow = row(
          i18n.t('user.old-password'),
          bindInput(this, ['oldPassword'], {
            type: 'password', id: 'inp-old-password', style: { width: '15em' },
          }),
        )
      }
    }

    let repeatPasswordBorder = null

    if (this.state.repeatNewPassword !== this.state.newPassword) {
      repeatPasswordBorder = '1px solid #c00'
    }

    return (
      <form autoComplete="off" onSubmit={() => this.onSave()}>
        <div id="form-user">
          {row(
            i18n.t('user.username'),
            bindInput(this, ['user', 'username'], { id: 'inp-username', style: { width: '15em' } }),
            'username',
          )}
          {row(
            i18n.t('user.given-name'),
            bindInput(this, ['user', 'givenName'], { id: 'inp-givenName', style: { width: '15em' } }),
            'givenName',
          )}
          {row(
            i18n.t('user.family-name'),
            bindInput(this, ['user', 'familyName'], { id: 'inp-familyName', style: { width: '15em' } }),
            'familyName',
          )}
          {row(
              i18n.t('user.email'),
              bindInput(this, ['user', 'email'], { id: 'inp-email', type: 'email', name: 'email', style: { width: '15em' } }),
              'email',
          )}
          <div style={{ margin: '2em 0' }}>
            {row(
              i18n.t('user.country'),
              this.getCountryDropdown(),
              'country',
            )}
            {row(
              i18n.t('user.language'),
              this.getLanguageDropdown(),
              'language',
            )}
          </div>
          <div style={{ margin: '2em 0' }}>
            {row(
              i18n.t('user.role'),
              this.getRoleDropdown(),
              'role',
            )}
            {allowedLocationsRow}
          </div>
          {passwordEmptyNote}
          {oldPasswordRow}
          {row(
            i18n.t(this.props.isNew ? 'user.password' : 'user.new-password'),
            bindInput(this, ['newPassword'], {
              type: 'password',
              id: this.props.isNew ? 'inp-password' : 'inp-new-password',
              style: { width: '15em' },
            }),
            'passwordHash',
          )}
          {row(
            i18n.t(this.props.isNew ? 'user.repeat-password' : 'user.repeat-new-password'),
            bindInput(this,
              ['repeatNewPassword'],
              {
                ref: this.repeatPasswordInputRef,
                type: 'password',
                id: this.props.isNew ? 'inp-repeat-password' : 'inp-repeat-new-password',
                style: { width: '15em', border: repeatPasswordBorder, outline: 'none' },
              },
            ),
          )}
        </div>
      </form>
    )
  }
}

interface EditUserModalProps {
  isNew?: boolean,
  user?: UiUser,
  countries: UserCountry[],
  modalId: string,
  afterSave?: () => void,
}

export const EditUserModal = (props: EditUserModalProps) => {
  const [isVisible, close] = useOpenModalEvent(props.modalId)
  const formRef = useRef(null)

  if (!isVisible) {
    return <div />
  }

  return (
    <div>
      <Modal
        title={i18n.t('common.user')}
        closeModal={close}
        dialogClassName="edit-user"
        buttons={[
          {
            id: 'btn-save',
            title: i18n.t('action.save'),
            onClick: () => {
              return formRef.current.onSave()
              .then(props.afterSave)
              .then(close)
            },
          },
          getDefaultCancelButton(),
        ]}
      >
        <Form
          ref={formRef}
          isNew={props.isNew}
          user={props.user}
          countries={props.countries}
        />
      </Modal>
    </div>
  )
}
