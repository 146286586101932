import { Fancybox } from '@fancyapps/ui'
import classNames from 'classnames'
import { Attributes, Component, HTMLAttributes, ReactNode, useState } from 'react'
import React = require('react')

import { i18n } from '../common/i18n'
import { Id } from '../server/data-interface'
import { FilterManager } from './filter'
import { FloatingHeader } from './floating-header'

export interface ExcelMetadata {
  style: number,
  type?: 'formula',
}

export interface Column<Row> {
  id: string,
  header: string,
  getCellProperties?: (row: Row) => HTMLAttributes<HTMLTableCellElement>,
  getCellContents?: (row: Row) => ReactNode,
  filterFieldName?: string,
  includeIf?: (type: 'table' | 'excel') => boolean,
  excelWidth?: number,
  getExcelValue?: (row: Row, context: any) => string | number,
  getExcelMetadata?: (row: Row, context: any) => ExcelMetadata,
  getExcelTotalValue?: (context: any) => string,
  getTotalRowProperties?: () => HTMLAttributes<HTMLTableCellElement>,
  getTotalRowContents?: (context: any) => ReactNode,
  excelMergeTotalWithNext?: boolean,
}

interface TableOptions {
  context?: Record<string, unknown>,
  rowLimit?: number,
  tableId?: string,
  tableClassName?: string,
  rowClassName?: string,
  noItemsText?: string,
  noFilteredItemsText?: string,
  printView?: boolean,
  filterManager?: FilterManager<unknown>,
}

interface TableProps<Row> {
  columnConf: Column<Row>[],
  rowsData: Row[],
  options: TableOptions,
}

const Table = (props: TableProps<{ id?: Id, rowClassName?: string }>) => {
  const [isLimitDisabled, setLimitDisabled] = useState(false)

  const options = props.options || {}

  const renderLoadRemainingRow = () => (
    <tr>
      <td colSpan={props.columnConf.length}>
        {i18n.t('inventory.rows-omitted')}
        {' '}
        <button onClick={() => setLimitDisabled(true)}>
          {i18n.t('inventory.load-remaining-rows')}
        </button>
      </td>
    </tr>
  )

  const renderTotalsRow = (tableColumns: Column<unknown>[]) => {
    const anyTotalColumns = tableColumns.some(function (column) {
      return Boolean(column.getTotalRowContents)
    })

    if (!anyTotalColumns) {
      return
    }

    return (
      <tr className="row-total">
        {tableColumns.map(function (column) {
          let properties: Attributes & HTMLAttributes<HTMLTableCellElement> = {}
          let contents = null

          if (column.getTotalRowProperties) {
            properties = column.getTotalRowProperties()
          }

          if (column.getTotalRowContents) {
            contents = column.getTotalRowContents(options.context)
          }

          return <td key={column.id} {...properties}>{contents}</td>
        })}
      </tr>
    )
  }

  const tableColumns = props.columnConf.filter(function (column) {
    return column.includeIf ? column.includeIf('table') : true
  })

  const headerRow = (
    <tr>
      {tableColumns.map(function (column) {
        let filter = null

        if (column.filterFieldName && !options.printView) {
          filter = options.filterManager.getIcon(column.filterFieldName)
        }

        return (
          <th key={column.id}>
            {column.header}
            {filter}
          </th>
        )
      })}
    </tr>
  )

  let { rowsData } = props
  let overLimit = false

  if (
    options.rowLimit &&
    !options.printView &&
    !isLimitDisabled &&
    !options.filterManager.anyFilters() &&
    rowsData.length > options.rowLimit
  ) {
    overLimit = true
    rowsData = rowsData.slice(0, options.rowLimit)
  }

  const rowClassName = 'rowClassName' in options ? options.rowClassName : 'row-entry'

  let entryRows: ReactNode[] = rowsData.map(function (rowData) {
    return (
      <tr key={String(rowData.id)} className={rowData.rowClassName || rowClassName}>
        {tableColumns.map(function (column) {
          let properties: Attributes & HTMLAttributes<HTMLTableCellElement> = {}

          if (column.getCellProperties) {
            properties = column.getCellProperties(rowData)
          }

          const contents = (
            column.getCellContents ? column.getCellContents(rowData) : rowData[column.id]
          )

          return <td key={column.id} {...properties}>{contents}</td>
        })}
      </tr>
    )
  })

  const hasItems = entryRows.length > 0

  if (!hasItems) {
    let info = options.noItemsText || i18n.t('table.no-items')

    if (options.filterManager?.anyFilters()) {
      info = options.noFilteredItemsText || i18n.t('table.no-filtered-items')
    }

    entryRows = [
      <tr key="no-items">
        <td colSpan={tableColumns.length}>
          {info}
        </td>
      </tr>,
    ]
  }

  return (
    <table
      id={options.tableId}
      className={classNames(
        'table table-bordered table-condensed table-striped', options.tableClassName,
      )}
    >
      {options.printView ? (
        <thead>{headerRow}</thead>
      ) : (
        <FloatingHeader headerRow={headerRow} />
      )}
      <tbody>
        {entryRows}
        {overLimit && renderLoadRemainingRow()}
        {!overLimit && hasItems && renderTotalsRow(tableColumns)}
      </tbody>
    </table>
  )
}

export const UiUtils = {
  setState: function <S>(component: Component<unknown, S>, stateUpdate: Partial<S>) {
    return new Promise<void>(function (resolve) {
      component.setState<any>(stateUpdate, resolve)
    })
  },

  forceUpdate: function (component: Component) {
    return new Promise<void>(function (resolve) {
      component.forceUpdate(resolve)
    })
  },

  highlight: function (haystack: string, needle: string): ReactNode {
    if (!needle) {
      return haystack
    }

    const i = haystack.toLowerCase().indexOf(needle.toLowerCase())

    if (i === -1) {
      return haystack
    }
    else {
      const p1 = haystack.substr(0, i)
      const p2 = haystack.substr(i, needle.length)
      const p3 = haystack.substr(i + needle.length)
      return [p1, <b key="dummy">{p2}</b>, p3]
    }
  },

  // Opens Fancybox with a single image
  openFancybox: function (url: string, title: string, options?) {
    Fancybox.show(
      [{ src: url, caption: title }],
      {
        ...options,

        // Enabled by default, but gets stuck in an infinite loop if Fancybox is
        // opened while another modal is also open.
        trapFocus: false,
      },
    )
  },

  blobToImage: async function (blob: Blob) {
    const reader = new FileReader()
    const image = new Image()

    await new Promise(function (resolve) {
      reader.addEventListener('loadend', resolve)
      reader.readAsDataURL(blob)
    })

    await new Promise(function (resolve, reject) {
      image.addEventListener('load', resolve)

      image.addEventListener('error', function () {
        const error: Error & { imageProcessingFailed?: true } = new Error('Failed to process image')
        error.imageProcessingFailed = true
        reject(error)
      })

      image.src = reader.result as string
    })

    return image
  },

  getTable: function <Row extends { id?: Id, rowClassName?: string }>(
    columnConf: Column<Row>[],
    rowsData: Row[],
    options?: TableOptions,
  ) {
    return <Table columnConf={columnConf} rowsData={rowsData} options={options} />
  },
}
