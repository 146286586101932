import { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react'
import React = require('react')

import { CommonUtils } from '../common/common-utils'
import { RawMaterial } from '../common/data-raw-materials'
import { getDataService } from '../common/data-service'
import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { getDefaultCancelButton, Modal, ModalButton } from './modal'
import { useOpenModalEvent } from './use-open-modal-event'
import { Utils } from './utils'
import { ValidationErrors, ValidationUtils } from './validation-utils'

interface AddProps {
  modalId: string,
  material: RawMaterial,
  afterSave: () => void,
}

interface CutProps {
  modalId: string,
  material: RawMaterial,
  afterSave: () => void,
}

const row = (fieldName: string, field: ReactNode) => {
  return (
    <div className="row" style={{ margin: '0.5em 0' }}>
      <div className="col-xs-4" style={{ fontWeight: 'bold', paddingLeft: 0 }}>
        {fieldName}
      </div>
      <div className="col-xs-8">
        {field}
      </div>
    </div>
  )
}

const Add = (props: AddProps) => {
  const [isVisible, close] = useOpenModalEvent(props.modalId)
  const inputRef = useRef(null)
  const [amountToAdd, setAmountToAdd] = useState('')
  const [errors, setErrors] = useState<ValidationErrors>(null)

  useEffect(() => {
    if (isVisible) {
      // On open
      inputRef.current?.focus()
    } else {
      // On close
      setAmountToAdd('')
    }
  }, [isVisible])

  if (!isVisible) {
    return <div />
  }

  const buttons: ModalButton[] = [
    {
      id: 'btn-save',
      title: i18n.t('action.save'),
      onClick: async () => {
        setErrors(null)

        try {
          await getDataService().RawMaterials.addToAmount(material._id, amountToAdd)
          close()
          await props.afterSave()
        } catch (err) {
          const newErrors = ValidationUtils.getErrors(err)

          if (newErrors) {
            setErrors(newErrors)
            EventBus.fire('validation-errors-rendered')
          }
        }
      },
    },
    getDefaultCancelButton(),
  ]

  const { material } = props
  const unitName = i18n.t('enum.material-units.' + material.amount.unit)

  const form = (
    <div>
      {row(i18n.t('raw-materials.material'), material.name)}
      {row(
        i18n.t('raw-materials.current-amount'),
        <span id="lbl-current-amount">
          {CommonUtils.formatDecimal(material.amount.value, true)}
          {' '}
          {unitName}
        </span>,
      )}
      {row(
        i18n.t('raw-materials.amount-to-add'),
        <span>
          <input
            ref={inputRef}
            value={amountToAdd}
            onChange={(evt) => {
              const { value } = evt.currentTarget

              if (Utils.nonNegativeDecimalValidator(value)) {
                setAmountToAdd(value)
              }
            }}
            id="inp-amount-to-add"
            style={{ width: '4em' }}
          />
          {' '}
          {unitName}
          {ValidationUtils.render(errors, 'amountToAdd')}
        </span>,
      )}
      {row(
        i18n.t('raw-materials.new-amount'),
        <span id="lbl-new-amount">
          {CommonUtils.formatDecimal(Number(material.amount.value) + Number(amountToAdd), true)}
          {' '}
          {unitName}
        </span>,
      )}
    </div>
  )

  return (
    <div>
      <Modal
        title={i18n.t('raw-materials.add-to-amount')}
        closeModal={close}
        dialogClassName="add-material-amount"
        buttons={buttons}
      >
        {form}
      </Modal>
    </div>
  )
}

const Cut = (props: CutProps) => {
  const [isVisible, close] = useOpenModalEvent(props.modalId)
  const amountInputRef = useRef(null)
  const [amountToCut, setAmountToCut] = useState('')
  const [note, setNote] = useState('')
  const [errors, setErrors] = useState<ValidationErrors>(null)

  useEffect(() => {
    if (isVisible) {
      // On open
      amountInputRef.current?.focus()
    } else {
      // On close
      setAmountToCut('')
    }
  }, [isVisible])

  if (!isVisible) {
    return <div />
  }

  const calculateNewAmount = (): number => {
    return CommonUtils.round(
      Number(props.material.amount.value) - Number(amountToCut),
    )
  }

  const newAmountIsBelowThreshold = () => {
    const newAmt = calculateNewAmount()
    const { material } = props

    const materialHasThreshold = (
      material.amountThreshold !== null &&
      material.amountThreshold !== undefined
    )

    return materialHasThreshold && newAmt < material.amountThreshold || newAmt <= 0
  }

  const renderBelowThresholdWarning = () => {
    if (newAmountIsBelowThreshold()) {
      return (
        <p id="below-threshold-warning">
          {i18n.t('raw-materials.amount-below-threshold')}
        </p>
      )
    }
  }

  const renderNewAmountRow = () => {
    const { material } = props
    const newAmount = calculateNewAmount()
    const unitName = i18n.t('enum.material-units.' + material.amount.unit)
    const newAmountStyle: CSSProperties = newAmountIsBelowThreshold() ? { color: '#c00' } : {}

    return row(
      i18n.t('raw-materials.new-amount'),
      <span id="lbl-new-amount" style={newAmountStyle}>
        {newAmount}
        {' '}
        {unitName}
        {renderBelowThresholdWarning()}
      </span>,
    )
  }

  const buttons: ModalButton[] = [
    {
      id: 'btn-save',
      title: i18n.t('action.save'),
      onClick: async () => {
        setErrors(null)

        try {
          await getDataService().RawMaterials.cutAmount(material._id, amountToCut, note)
          close()
          await props.afterSave()
        } catch (err) {
          const newErrors = ValidationUtils.getErrors(err)

          if (newErrors) {
            setErrors(newErrors)
            EventBus.fire('validation-errors-rendered')
          }
        }
      },
    },
    getDefaultCancelButton(),
  ]

  const { material } = props
  const unitName = i18n.t('enum.material-units.' + material.amount.unit)

  let strAmountThreshold = ''

  if (material.amountThreshold !== null && material.amountThreshold !== undefined) {
    strAmountThreshold = CommonUtils.formatDecimal(material.amountThreshold, true) + ' ' + unitName
  }

  const form = (
    <div>
      {row(i18n.t('raw-materials.material'), material.name)}
      {row(
        i18n.t('raw-materials.price-per-unit'),
        <span id="lbl-price">
          {CommonUtils.formatDecimal(material.price, true)}
          {' MAD'}
        </span>,
      )}
      {row(
        i18n.t('raw-materials.current-amount'),
        <span id="lbl-current-amount">
          {CommonUtils.formatDecimal(material.amount.value, true)}
          {' '}
          {unitName}
        </span>,
      )}
      {row(
        i18n.t('common.amount-threshold'),
        <span>{strAmountThreshold}</span>,
      )}
      {row(
        i18n.t('raw-materials.amount-to-cut'),
        <span>
          <input
            ref={amountInputRef}
            value={amountToCut}
            onChange={(evt) => {
              const { value } = evt.currentTarget

              if (Utils.nonNegativeDecimalValidator(value)) {
                setAmountToCut(value)
              }
            }}
            id="inp-amount-to-cut"
            style={{ width: '4em' }}
          />
          {' '}
          {unitName}
          {ValidationUtils.render(errors, 'amountToCut')}
        </span>,
      )}
      {renderNewAmountRow()}
      {row(
        i18n.t('common.note'),
        <div>
          <textarea
            value={note}
            onChange={(evt) => setNote(evt.currentTarget.value)}
            id="inp-note"
            style={{ width: '20em', height: '7em' }}
          />
          {ValidationUtils.render(errors, 'note')}
        </div>,
      )}
    </div>
  )

  return (
    <div>
      <Modal
        title={i18n.t('raw-materials.cut-material')}
        closeModal={close}
        dialogClassName="cut-material-amount"
        buttons={buttons}
      >
        {form}
      </Modal>
    </div>
  )
}

export const RawMaterialModals = { Add, Cut }
