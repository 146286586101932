import React = require('react')

import { TabMenu } from './tab-menu'

interface AccountingMenuProps {
  activeTab?: string,
}

export const AccountingMenu = (props: AccountingMenuProps) => (
  <TabMenu
    menuId="accounting"
    pills={true}
    tabs={[
      {
        id: 'expenses',
        route: '/accounting/expenses',
      },
      {
        id: 'expenses-summary',
        route: '/accounting/expenses/summary',
      },
      {
        id: 'earnings',
        route: '/accounting/earnings',
      },
      {
        id: 'earnings-summary',
        route: '/accounting/earnings/summary',
      },
      {
        id: 'expense-categories',
        route: '/accounting/expenses/categories',
      },
      {
        id: 'earning-categories',
        route: '/accounting/earnings/categories',
      },
    ]}
    activeTab={props.activeTab}
  />
)
