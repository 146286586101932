import classNames from 'classnames'
import { ChangeEvent, ReactNode } from 'react'
import React = require('react')

interface FormRowProps {
  children: ReactNode[],
}

const FormRow = ({ children: [first, ...rest] }: FormRowProps) => (
  <div className="row row-form">
    <div className="col-xs-4 row-form-label">{first}</div>
    <div className="col-xs-8">{rest}</div>
  </div>
)

interface LabelInputProps {
  className?: string,
  label?: string,
  value?: string,
  onChange: (value: string) => void,
}

const LabelInput = ({ className = '', label, value = '', onChange }: LabelInputProps) => (
  <div className={className}>
    <div className="input-group input-group-sm">
      {label && <span className="input-group-addon">{label}</span>}
      <input
        className="form-control"
        value={value}
        onChange={(event) => onChange(event.currentTarget.value)}
      />
    </div>
  </div>
)

export interface CheckboxProps {
  className?: string,
  checked: boolean,
  disabled?: boolean,
  value?: string,
  label?: string,
  onChange: (event: ChangeEvent<HTMLInputElement>) => void,
  style?: Record<string, any>,
}

const Checkbox = (props: CheckboxProps) => {
  const {
    className,
    checked = false,
    disabled = false,
    value = '',
    label = '',
    onChange,
    style,
  } = props

  return (
    <div className={classNames('checkbox', className)} style={style}>
      <label>
        <input
          type="checkbox"
          checked={checked}
          disabled={disabled}
          value={value}
          onChange={onChange}
        />
        {' '}
        {label}
      </label>
    </div>
  )
}

interface DropdownElementProps {
  isActive?: boolean,
  onActivate: () => void,
  onDeactivate: () => void,
  onSelect: () => void,
  children: ReactNode,
}

const DropdownElement = (props: DropdownElementProps) => (
  <div
    className={classNames('dropdown-element', { active: props.isActive })}
    onMouseEnter={props.onActivate}
    onMouseLeave={props.onDeactivate}
    onClick={props.onSelect}
  >
    {props.children}
  </div>
)

interface DropdownProps {
  className?: string,
  children: ReactNode[],
}

const Dropdown = (props: DropdownProps) => (
  <div className={classNames('dropdown', props.className)}>
    {props.children}
  </div>
)

interface ValueLabelProps {
  onRemove: () => void,
  children: string,
}

const ValueLabel = (props: ValueLabelProps) => (
  <div>
    <span className="value-label">
      {props.children}
    </span>
    <span className="value-label-remove" onClick={props.onRemove}>
      {'\u00d7'}
    </span>
  </div>
)

export const Comp = {
  FormRow,
  LabelInput,
  Checkbox,
  DropdownElement,
  Dropdown,
  ValueLabel,
}
