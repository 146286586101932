import { Settings as TSettings } from '../common/data-misc'
import { getDataService } from '../common/data-service'

let promise: Promise<TSettings> = null

const getPromise = (): Promise<TSettings> => {
  if (promise === null) {
    promise = getDataService().Settings.get()
  }

  return promise
}

export const Settings = {
  get: async <K extends keyof TSettings>(key: K) => {
    const settings = await getPromise()
    return settings[key]
  },
  getMultiple: async (...keys: (keyof TSettings)[]) => {
    const settings = await getPromise()
    return keys.map((key) => settings[key])
  },
  clearCache: () => {
    promise = null
  },
}
