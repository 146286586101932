import { CSSProperties, ReactNode } from 'react'
import React = require('react')

import { CombinationDetails, CommonUtils } from '../common/common-utils'
import { AttributeDefinition } from '../common/data-attribute-defs'
import { Location } from '../common/data-locations'
import { Category } from '../common/data-misc'
import { Prices, ProductDefinition } from '../common/data-product-defs'
import { Product } from '../common/data-products'
import { TemplateDefinition } from '../common/data-template-defs'
import { Template } from '../common/data-templates'
import { i18n } from '../common/i18n'
import { Currency } from '../common/invoice-utils'
import { AttributeCombination, InventoryItem } from '../common/types'
import { Filter, FilterManager } from './filter'
import { ReportUtils } from './report-utils'
import { Column, UiUtils } from './ui-utils'
import { User } from './user'
import { Utils } from './utils'

export interface Row {
  id: string,
  definition: TemplateDefinition | ProductDefinition,
  item: InventoryItem,
  name: string,
  category: string,
  location: string,
  locationName: string,
  currency: Currency,
  attributes: Record<string, CombinationDetails>,
  prices: Prices,
  tailorCosts: TemplateDefinition['tailorCosts'],
  fabricCosts: TemplateDefinition['fabricCosts'],
  amount: number,
  values: Partial<Record<Currency, number>>,
  note: string | undefined,
  discontinued: boolean,
  amountThreshold?: number,
}

interface InventoryTableProps {
  id: string,
  rowClassName?: string,
  hasPermissions?: boolean,
  attributeDefinitions: AttributeDefinition[],
  locations: Location[],
  currency: Currency,
  categories: Category[],
  definitions: (TemplateDefinition | ProductDefinition)[],
  items: InventoryItem[],
  templateDefinitions?: TemplateDefinition[],
  getActionCellContents?: (
    definition: TemplateDefinition | ProductDefinition,
    item: InventoryItem,
  ) => ReactNode,
  getItem?: () => void,
  modifyColumns?: (columns: Column<Row>[]) => void,
  modifyFilterConf?: (filterConf: any) => void,
  noItemsText?: string,
  noFilteredItemsText?: string,
  hideCustomCategoryFilter?: boolean,
  filterSetKey: string,
  printView?: boolean,
  limitRows?: boolean,
  isProduction?: boolean,
}

export class InventoryTable extends React.Component<InventoryTableProps> {
  _isMounted = false // Used by Filter.registerEvent

  componentDidMount() {
    this._isMounted = true
    Filter.registerEvent(this)
  }

  componentWillUnmount() {
    this._isMounted = false
    Filter.unregisterEvent(this)
  }

  getFilterConf = () => {
    const language = User.getLanguage()
    const names = Utils.getSortedDefinitionNames(this.props.definitions)

    const filterConf = {
      name: {
        type: 'predefined',
        labelKey: 'common.name',
        options: names.map(function(prodName) {
          return { value: prodName, label: prodName }
        }),
        getField: (item) => {
          const definition = CommonUtils.findById<any>(this.props.definitions, item.definition)
          return definition.name
        },
      },
      category: Utils.getCategoryFilterConf(
        this.props.categories,
        language,
        !this.props.hideCustomCategoryFilter,
        (item) => {
          return CommonUtils.findById(this.props.definitions, item.definition)
        },
      ),
      amount: {
        type: 'number-range',
        labelKey: 'common.amount',
      },
    }

    this.props.attributeDefinitions.forEach((attribute) => {
      const comboSources = []

      this.props.definitions.forEach((definition) => {
        if ((definition as ProductDefinition).isCustom && attribute.textForCustom) {
          // Omit custom product freetext attributes from filters
        }
        else if (attribute._id in definition.attributeCombinations || !attribute.optional) {
          const combinations = definition.attributeCombinations[attribute._id] as (AttributeCombination | number)[]

          combinations.forEach((comboParam) => {
            const combination = Utils.ensureCombination(
              comboParam, attribute, definition, this.props.templateDefinitions,
            )

            const comboSource = { values: combination.values, category: definition.category }
            comboSources.push(comboSource)
          })
        }
      })

      filterConf['attr-' + attribute._id] = {
        type: 'predefined-combo',
        label: attribute.names[language],
        options: Utils.getAttributeFilterOptions(attribute, language, comboSources),
        getField: (item) => {
          return Utils.getCombinationValues(
            item, attribute, this.props.definitions, this.props.templateDefinitions,
          )
        },
      }
    })

    if (this.props.modifyFilterConf) {
      this.props.modifyFilterConf(filterConf)
    }

    return filterConf
  }

  getCurrencyColumnConf = (id: string, fieldName: string, translationKey: string) => {
    const { currency } = this.props

    return {
      id,
      header: i18n.t(translationKey) + ' (' + currency + ')',
      excelWidth: 15,
      getCellContents: function(rowData) {
        const cost = rowData[fieldName][currency]
        return cost ? CommonUtils.formatDecimal(cost, true) : ''
      },
      getExcelValue: function(rowData) {
        return rowData[fieldName][currency]
      },
    }
  }

  getTailorCostColumnConf = () => {
    return this.getCurrencyColumnConf('tailorCost', 'tailorCosts', 'tpl-def.tailor-cost')
  }

  getFabricCostColumnConf = () => {
    return this.getCurrencyColumnConf('fabricCost', 'fabricCosts', 'tpl-def.fabric-cost')
  }

  getPriceColumnConf = () => {
    return this.getCurrencyColumnConf('price', 'prices', 'common.price')
  }

  getColumnConf = () => {
    const columnConf: Column<Row>[] = []

    columnConf.push({
      id: 'name',
      header: i18n.t('common.name'),
      filterFieldName: 'name',
      excelWidth: 25,
      getCellProperties: function(rowData) {
        const style: CSSProperties = {}

        if (rowData.discontinued) {
          style.textDecoration = 'line-through'
        }

        return { style }
      },
    })

    columnConf.push({
      id: 'category',
      header: i18n.t('common.category'),
      excelWidth: 22,
      filterFieldName: 'category',
    })

    this.props.attributeDefinitions.forEach(function(attr) {
      columnConf.push({
        id: 'attr-' + attr._id,
        header: attr.names[User.getLanguage()],
        filterFieldName: 'attr-' + attr._id,
        excelWidth: 20,
        getCellContents: function(rowData) {
          return rowData.attributes[attr._id].label
        },
      })
    })

    if (this.props.isProduction) {
      const tailorCostColumnConf = this.getTailorCostColumnConf()
      columnConf.push(tailorCostColumnConf)
      const fabricCostColumnConf = this.getFabricCostColumnConf()
      columnConf.push(fabricCostColumnConf)
    }
    else {
      const priceColumnConf = this.getPriceColumnConf()
      columnConf.push(priceColumnConf)
    }

    columnConf.push({
      id: 'amount',
      header: i18n.t('common.amount'),
      filterFieldName: 'amount',
      excelWidth: 12,
      getCellProperties: function(rowData) {
        let className = ''
        const amountThreshold = rowData.amountThreshold || 0
        if (rowData.amount < amountThreshold) {
          className = 'text-red-bold'
        }

        return { className }
      },
    })

    columnConf.push({
      id: 'note',
      header: i18n.t('common.note'),
      excelWidth: 15,
      getCellProperties: function() {
        return { className: 'note-column' }
      },
    })

    if (this.props.hasPermissions && this.props.getActionCellContents && !this.props.printView) {
      columnConf.push({
        id: 'action',
        header: i18n.t('action.action'),
        includeIf: function(view) {
          return view === 'table'
        },
        getCellProperties: function() {
          return { style: { whiteSpace: 'nowrap' } }
        },
        getCellContents: (rowData) => {
          return this.props.getActionCellContents(rowData.definition, rowData.item)
        },
      })
    }

    if (this.props.modifyColumns) {
      this.props.modifyColumns(columnConf)
    }

    return columnConf
  }

  getFilterManager = (): FilterManager<Template | Product> => {
    const filterConf = this.getFilterConf()
    return Filter.createManager(this.props.filterSetKey, filterConf, this.props.items)
  }

  getTemplateCost = (currency: Currency, amt: number, tplDef: TemplateDefinition) => {
    const tailorCost = tplDef.tailorCosts[currency]
    const fabricCost = tplDef.fabricCosts[currency]

    return amt * (tailorCost + fabricCost)
  }

  getRowsData = (filterManager: FilterManager<Template | Product>, context) => {
    const language = User.getLanguage()
    const filteredItems = filterManager.getFiltered()
    .filter(function(item) {
      return !(item as Template).archived
    })

    context.totalValues = { 'MAD': 0, 'EUR': 0 }

    return filteredItems.map((item) => {
      const definition = CommonUtils.findById(this.props.definitions, item.definition)
      const { isProduction } = this.props
      const prices = isProduction ? null : CommonUtils.getPrices(item, definition as ProductDefinition)
      const tailorCosts = isProduction ? (definition as TemplateDefinition).tailorCosts : null
      const fabricCosts = isProduction ? (definition as TemplateDefinition).fabricCosts : null
      const values: Partial<Record<Currency, number>> = {}

      const loc = CommonUtils.findById<Location>(this.props.locations, item.location)

      if (!loc) {
        throw new Error('Location not found: ' + item.location)
      }

      if (isProduction) {
        const productionCurrency = 'MAD'

        const templateValue = this.getTemplateCost(
          productionCurrency,
          item.amount,
          definition as TemplateDefinition,
        )

        values[productionCurrency] = templateValue

        if (productionCurrency === loc.currency) {
          context.totalValues[productionCurrency] += templateValue
        }
      }
      else {
        Object.keys(prices).forEach(function(currency) {
          const price = prices[currency]

          if (price !== '') {
            const value = price * item.amount
            values[currency] = value

            if (currency === loc.currency) {
              context.totalValues[currency] += value
            }
          }
        })
      }

      const rowData: Row = {
        id: item._id,
        definition, // TODO: avoid putting definition and item in rowData?
        item,
        name: definition.name,
        category: CommonUtils.getCategoryName(definition, this.props.categories, language),
        location: item.location, // Used in inventory report
        locationName: loc.names[language], // Used in inventory report
        currency: loc.currency, // Used in inventory report
        attributes: CommonUtils.getAttributeDetails(
          item, this.props.attributeDefinitions, definition,
          this.props.templateDefinitions, language,
        ),
        prices,
        tailorCosts,
        fabricCosts,
        amount: item.amount,
        values,
        note: item.note,
        discontinued: definition.discontinued,
      }

      if ((item as Template).amountThreshold) {
        rowData.amountThreshold = (item as Template).amountThreshold
      }

      return rowData
    })
  }

  downloadExcelFile = (fileName: string) => { // Public method
    const filterManager = this.getFilterManager()
    const context = {}
    const rowsData = this.getRowsData(filterManager, context)
    const columnConf = this.getColumnConf()

    const workbook = ReportUtils.createExcelReport(rowsData, columnConf, context)
    ReportUtils.downloadExcelFile(fileName, workbook)
  }

  render() {
    const filterManager = this.getFilterManager()
    const columnConf = this.getColumnConf()
    const context = {}
    const rowsData = this.getRowsData(filterManager, context)
    const { printView } = this.props

    return (
      <div>
        {printView ? null : filterManager.getSummary()}
        {UiUtils.getTable(columnConf, rowsData, {
          tableId: this.props.id,
          rowClassName: this.props.rowClassName,
          filterManager,
          context,
          noItemsText: this.props.noItemsText || i18n.t('inventory.no-items'),
          noFilteredItemsText: this.props.noFilteredItemsText || i18n.t('inventory.no-filtered-items'),
          printView,
          rowLimit: this.props.limitRows ? 25 : null,
        })}
      </div>
    )
  }
}
