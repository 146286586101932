import { FormEvent } from 'react'
import React = require('react')

import { CommonUtils } from '../common/common-utils'
import { AttributeDefinition } from '../common/data-attribute-defs'
import { Category } from '../common/data-misc'
import {
  PricesInput,
  ProductDefinition,
  ProductDefinitionCreate,
  ProductDefinitionUpdate,
} from '../common/data-product-defs'
import { getDataService, ProductDefinitionOps, TemplateDefinitionOps } from '../common/data-service'
import { TemplateDefinition, TemplateDefinitionCreate } from '../common/data-template-defs'
import { i18n } from '../common/i18n'
import { Currency } from '../common/invoice-utils'
import { AttributeCombination, AttributeUsage } from '../common/types'
import { AttributeCombinationSet } from './attribute-combination-set'
import { bindCheckbox, bindInput, bindProps } from './bind-utils'
import { PriceOverridesPanel } from './price-overrides-panel'
import { User } from './user'
import { Utils } from './utils'
import { ValidationErrors, ValidationUtils } from './validation-utils'

export interface EditFormProps {
  menuTabId: string,
  definition?: ProductDefinition | TemplateDefinition,
  isNew?: boolean,
  template?: string,
  categories: Category[],
  attributeDefinitions: AttributeDefinition[],
}

interface DefinitionEditFormProps extends EditFormProps {
  isProduction: boolean,
  definitionService: TemplateDefinitionOps | ProductDefinitionOps,
  currencies: Currency[],
}

export type DefinitionWithoutId = ProductDefinitionCreate | TemplateDefinitionCreate

interface State {
  loaded: boolean,
  definition: DefinitionWithoutId,
  attributeUsage?: AttributeUsage,
  templateDefinitions?: TemplateDefinition[],
  validationErrors?: ValidationErrors,
}

export class DefinitionEditForm extends React.Component<DefinitionEditFormProps, State> {
  constructor(props: DefinitionEditFormProps) {
    super(props)
    let definition: DefinitionWithoutId

    if (props.isNew) {
      const common = {
        name: '',
        category: '',
        attributeCombinations: {},
        fabricUsage: undefined,
      }

      if (this.props.isProduction) {
        const costs = this.getInitialCosts()
        definition = {
          ...common,
          tailorCosts: costs.tailorCosts,
          fabricCosts: costs.fabricCosts,
        }
      } else {
        definition = {
          ...common,
          prices: this.getInitialPrices(),
          template: props.template,
          overrideAttributes: [],
          priceOverrides: null,
        }
      }
    } else {
      definition = CommonUtils.clone(props.definition) as any // TODO numeric vs string fields
    }

    this.state = {
      loaded: false,
      definition,
      attributeUsage: null,
      templateDefinitions: null,
    }
  }

  getInitialCosts = (): Pick<TemplateDefinitionCreate, 'tailorCosts' | 'fabricCosts'> => {
    const tailorCosts = {} as TemplateDefinitionCreate['tailorCosts']
    const fabricCosts = {} as TemplateDefinitionCreate['fabricCosts']

    for (const currency of this.props.currencies) {
      tailorCosts[currency] = ''
      fabricCosts[currency] = ''
    }

    return {
      tailorCosts,
      fabricCosts,
    }
  }

  getInitialPrices = (): PricesInput => {
    const prices = {} as PricesInput

    this.props.currencies.forEach(function (currency) {
      prices[currency] = ''
    })

    return prices
  }

  _isMounted = false

  componentDidMount() {
    this._isMounted = true
    let tplDefPromise = null
    let usagePromise: Promise<AttributeUsage> = null

    if (!this.props.isProduction) {
      tplDefPromise = getDataService().TemplateDefinitions.getAll()
    }

    if (!this.props.isNew) {
      usagePromise = this.props.definitionService.getAttributeUsage(this.props.definition._id)
    }

    Promise.all([tplDefPromise, usagePromise]).then(([templateDefinitions, usage]) => {
      if (!this._isMounted) {
        return
      }

      const stateUpdate: Partial<State> = {
        loaded: true,
        templateDefinitions,
        attributeUsage: usage,
      }

      if (!this.props.isProduction && this.props.isNew) {
        const definition = CommonUtils.clone(this.state.definition)
        this.syncFromTemplate(definition, templateDefinitions, false)
        stateUpdate.definition = definition
      }

      this.setState(stateUpdate as State)
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  syncFromTemplate = (
    definition,
    templateDefinitions: TemplateDefinition[],
    clearIfNone: boolean,
  ) => {
    const clearTemplateCombinations = () => {
      this.props.attributeDefinitions
        .filter(CommonUtils.attributeContextFilters.templates)
        .forEach(function (attribute) {
          definition.attributeCombinations[attribute._id] = []
        })
    }

    if (definition.template) {
      // Copy some values from the template definition

      const templateDefinition = CommonUtils.findById(templateDefinitions, definition.template)

      definition.category = templateDefinition.category
      clearTemplateCombinations()
    } else if (clearIfNone) {
      // Clear the values from the template definition

      definition.category = ''
      clearTemplateCombinations()
    }
  }

  cleanOverrides = (overrides, overrideAttributes, attributeCombinations) => {
    const result = []

    if (!overrides) {
      return result
    }

    function validateComboIds(comboIds) {
      // For every attribute...
      return overrideAttributes.every(function (attrId) {
        const expectedComboId = comboIds[attrId]

        // ...there must be a combo that matches the given comboId for this attribute
        return attributeCombinations[attrId].some(function (combo) {
          const actualComboId = typeof combo === 'number' ? combo : combo.id
          return actualComboId === expectedComboId
        })
      })
    }

    overrides.forEach(function (override) {
      // Override may have been defined for different attributes

      const attrsMatch = Utils.containSameElements(
        Object.keys(override.attrs), overrideAttributes,
      )

      // Even if attributes match, some combination ids may have become invalid
      if (attrsMatch && validateComboIds(override.attrs)) {
        Object.keys(override.prices).forEach(function (currency) {
          // Numeric values to strings, expected on server side
          if (override.prices[currency]) {
            override.prices[currency] = override.prices[currency].toString()
          }
        })

        result.push(override)
      }
    })

    return result
  }

  pricesToStrings = (prices) => {
    const newPrices = {}

    Object.keys(prices).forEach(function (currency) {
      newPrices[currency] = prices[currency].toString()
    })

    return newPrices
  }

  onSave = async () => {
    const definition: any = {
      name: this.state.definition.name,
      attributeCombinations: this.state.definition.attributeCombinations,
    }

    if (!this.props.isProduction) {
      definition.fabricUsage = Number(this.state.definition.fabricUsage)
    }

    if (this.props.isProduction) {
      const templateDefinition = this.state.definition as TemplateDefinitionCreate
      definition.tailorCosts = this.pricesToStrings(templateDefinition.tailorCosts)
      definition.fabricCosts = this.pricesToStrings(templateDefinition.fabricCosts)
    } else {
      const productDefinition = this.state.definition as ProductDefinitionCreate
      definition.prices = this.pricesToStrings(productDefinition.prices)
    }

    if (this.props.isNew) {
      definition.category = this.state.definition.category

      if (!this.props.isProduction) {
        const productDefinition = this.state.definition as ProductDefinitionCreate
        definition.template = productDefinition.template
      }
    } else {
      const definitionUpdate = this.state.definition as ProductDefinitionUpdate
      definition.discontinued = definitionUpdate.discontinued
    }

    if (!this.props.isProduction) {
      const productDefinition = this.state.definition as ProductDefinitionCreate

      // Get a properly ordered copy of this.state.definition.overrideAttributes
      let overrideAttributes = this.props.attributeDefinitions
        .map(function (attrDef) {
          return attrDef._id
        })
        .filter((attrId) => {
          return CommonUtils.arrayContains(productDefinition.overrideAttributes, attrId)
        })

      let priceOverrides = this.cleanOverrides(
        productDefinition.priceOverrides,
        overrideAttributes,
        this.state.definition.attributeCombinations,
      )

      if (!priceOverrides.length) {
        overrideAttributes = null
        priceOverrides = null
      }

      definition.overrideAttributes = overrideAttributes
      definition.priceOverrides = priceOverrides
    }

    try {
      await ValidationUtils.clear(this)

      if (this.props.isNew) {
        return await this.props.definitionService.create(definition)
      } else {
        return await this.props.definitionService.update(this.props.definition._id, definition)
      }
    } catch (error) {
      ValidationUtils.check(this, error)
    }
  }

  getCategoryDropdown = () => {
    let readOnly

    if (this.props.isNew) {
      const productDefinition = this.state.definition as ProductDefinitionCreate

      // Read-only if a source template has been selected
      readOnly = Boolean(productDefinition.template)

      if (!readOnly) {
        // Read-only if any category-specific attribute values have been selected
        readOnly = this.props.attributeDefinitions.some((attribute) => {
          const anyCategorySpecificGroups = attribute.valueGroups.some(function (valueGroup) {
            return Boolean(valueGroup.category)
          })

          if (anyCategorySpecificGroups) {
            const combos = this.state.definition.attributeCombinations[attribute._id] as AttributeCombination[] || []

            return combos.some(function (combo) {
              return combo.values.length > 0
            })
          }

          return false
        })
      }
    } else {
      readOnly = true
    }

    if (readOnly) {
      const currentCategory = CommonUtils.findById(this.props.categories, this.state.definition.category)
      return (
        <div id="lbl-category">
          {currentCategory.labels[User.getLanguage()]}
        </div>
      )
    }

    const categories = this.props.categories.slice()

    categories.sort(function (cat1, cat2) {
      return cat1.labels[User.getLanguage()].localeCompare(cat2.labels[User.getLanguage()])
    })

    return (
      <select
        {...bindProps(this,
          ['definition', 'category'],
          { id: 'inp-category', style: { width: '20em' } },
        )}
      >
        {this.props.isNew && <option value="" />}
        {categories.map(function (category) {
          return (
            <option key={category._id} value={category._id}>
              {category.labels[User.getLanguage()]}
            </option>
          )
        })}
      </select>
    )
  }

  getTemplateDropdown = () => {
    let readOnly

    const productDefinition = this.state.definition as ProductDefinitionCreate

    if (this.props.isNew) {
      // Read-only if any combinations have been manually
      // added to attributes with template context
      readOnly = !productDefinition.template && this.props.attributeDefinitions
        .filter(CommonUtils.attributeContextFilters.templates)
        .some((attribute) => {
          const combos = this.state.definition.attributeCombinations[attribute._id] || []
          return combos.length > 0
        })
    } else {
      readOnly = true
    }

    if (readOnly) {
      if (productDefinition.template) {
        const templateDefinition = CommonUtils.findById(
          this.state.templateDefinitions, productDefinition.template,
        )

        return (
          <div id="lbl-template">
            {templateDefinition.name}
          </div>
        )
      } else {
        return '-'
      }
    }

    const definitions = this.state.templateDefinitions.slice()

    definitions.sort(function (def1, def2) {
      return def1.name.localeCompare(def2.name)
    })

    return (
      <select
        id="inp-template"
        style={{ width: '20em' }}
        value={productDefinition.template}
        onChange={(evt: FormEvent<HTMLSelectElement>) => {
          const templateDefId = evt.currentTarget.value

          const newDefinition = CommonUtils.clone(productDefinition)
          newDefinition.template = templateDefId
          this.syncFromTemplate(newDefinition, this.state.templateDefinitions, true)

          this.setState({ definition: newDefinition })
        }}
      >
        <option value="" />
        {definitions.map(function (definition) {
          return (
            <option key={definition._id} value={definition._id}>
              {definition.name}
            </option>
          )
        })}
      </select>
    )
  }

  renderRow = (key, fieldName, field, validationField?, description?) => {
    return (
      <div key={key} className="row" style={{ margin: '0.5em 0' }}>
        <div
          className={this.props.isProduction ? 'col-sm-3' : 'col-md-3'}
          style={{ fontWeight: 'bold', paddingLeft: 0 }}
        >
          {fieldName}
        </div>
        <div className={this.props.isProduction ? 'col-sm-9' : 'col-md-9'}>
          {field}
          {description && ` ${description}`}
          {ValidationUtils.render(this.state.validationErrors, validationField)}
        </div>
      </div>
    )
  }

  renderCurrencyInput = (id, fieldName, currency) => {
    return (
      <div key={currency} style={{ marginBottom: '0.3em' }}>
        {bindInput(this,
          ['definition', fieldName, currency],
          {
            id,
            style: { width: '4em' },
            validator: Utils.nonNegativeDecimalValidator,
          },
        )}
        {' '}
        {i18n.t('currency.per-unit', currency)}
        {ValidationUtils.render(this.state.validationErrors, fieldName + '.' + currency)}
      </div>
    )
  }

  renderCostsRows = () => {
    return [
      this.renderRow(
        'tailorCosts',
        i18n.t('tpl-def.tailor-cost'),
        this.props.currencies.map((currency) => {
          return this.renderCurrencyInput('inp-tailor-cost-' + currency, 'tailorCosts', currency)
        }),
      ),
      this.renderRow(
        'fabricCosts',
        i18n.t('tpl-def.fabric-cost'),
        this.props.currencies.map((currency) => {
          return this.renderCurrencyInput('inp-fabric-cost-' + currency, 'fabricCosts', currency)
        }),
      ),
    ]
  }

  renderPricesRow = () => {
    return this.renderRow(
      undefined,
      i18n.t(this.props.currencies.length === 1 ? 'common.price' : 'common.prices'),
      this.props.currencies.map((currency) => {
        return this.renderCurrencyInput('inp-price-' + currency, 'prices', currency)
      }),
    )
  }

  renderCostsOrPricesRows = () => {
    return this.props.isProduction ? this.renderCostsRows() : this.renderPricesRow()
  }

  render() {
    if (!this.state.loaded) {
      return <div>{i18n.t('common.loading')}</div>
    }

    let sourceTemplateRow = null

    if (!this.props.isProduction) {
      sourceTemplateRow = this.renderRow(
        undefined,
        i18n.t('prod-def.template'),
        this.getTemplateDropdown(),
        ValidationUtils.render(this.state.validationErrors, 'template'),
      )
    }

    let discontinuedRow = null

    if (!this.props.isNew) {
      discontinuedRow = this.renderRow(
        undefined,
        i18n.t('common.discontinued'),
        <div>
          {bindCheckbox(this, ['definition', 'discontinued'])}
          {' '}
          {i18n.t('definition.discontinued-field-note')}
        </div>,
        'discontinued',
      )
    }

    const fields = (
      <div>
        {this.renderRow(
          undefined,
          i18n.t('common.name'),
          bindInput(this, ['definition', 'name'], { id: 'inp-name' }),
          'name',
        )}
        {sourceTemplateRow}
        {this.renderRow(undefined, i18n.t('common.category'), this.getCategoryDropdown(), 'category')}
        {this.renderCostsOrPricesRows()}
        {this.props.menuTabId === 'products' && (
          this.renderRow(
            undefined,
            i18n.t('common.fabricUsage'),
            bindInput(this, ['definition', 'fabricUsage'], { id: 'inp-fabricUsage', style: { width: '4em' } }),
            'fabricUsage',
            'meters per product',
          )
        )}
        {this.props.attributeDefinitions.map((attribute) => {
          let usage: Record<number, boolean> | null = null
          let sourceTemplate: TemplateDefinition | null = null

          if (!this.props.isNew) {
            usage = this.state.attributeUsage[attribute._id]
          }

          const productDefinition = this.state.definition as ProductDefinitionCreate

          if (productDefinition.template) {
            sourceTemplate = CommonUtils.findById(
              this.state.templateDefinitions,
              productDefinition.template,
            )
          }

          return this.renderRow(
            attribute._id,
            attribute.pluralNames[User.getLanguage()],
            <AttributeCombinationSet
              definition={this.state.definition}
              attribute={attribute}
              usage={usage}
              sourceTemplate={sourceTemplate}
              formValidationError={(field) => ValidationUtils.render(this.state.validationErrors, field)}
              updateView={(callback) => {
                this.forceUpdate(callback)
              }}
            />,
            'attributeCombinations.' + attribute._id,
          )
        })}
        {discontinuedRow}
      </div>
    )

    if (this.props.isProduction) {
      // For template definitions, just show the fields
      return <div id="form-def">{fields}</div>
    } else {
      // For product definitions, show the fields on the left and price overrides on the right

      const productDefinition = this.state.definition as ProductDefinitionCreate

      const leftClass = 'def-left col-xs-12 col-sm-6 col-lg-5'
      const rightClass = 'def-right col-xs-12 col-sm-6 col-lg-7'

      return (
        <div className="container-fluid" id="form-def">
          <div className="row">
            <div className={leftClass}>
              {fields}
            </div>
            <div className={rightClass}>
              <PriceOverridesPanel
                definition={productDefinition}
                attributeDefinitions={this.props.attributeDefinitions}
                templateDefinitions={this.state.templateDefinitions}
                currencies={this.props.currencies}
                // Needed for coordinating updates between child components
                updateView={() => {
                  this.forceUpdate()
                }}
              />
            </div>
          </div>
        </div>
      )
    }
  }
}
