import { ReactElement } from 'react'
import React = require('react')
import ReactDOM = require('react-dom')
import toastr from 'toastr/toastr'

import { AttributeDefinitionListEditor } from './attribute-definition-list-editor'
import { Cart } from './cart'
import { CorrectionReport } from './correction-report'
import { Customers } from './customers'
import { DataManagement } from './data-management'
import { EarningCategories } from './earning-categories'
import { Earnings } from './earnings'
import { EarningsSummary } from './earnings-summary'
import { EditAttributeDefinition } from './edit-attribute-definition'
import { ErrorReport } from './error-report'
import { ExpenseCategories } from './expense-categories'
import { Expenses } from './expenses'
import { ExpensesSummary } from './expenses-summary'
import { Inventory } from './inventory'
import { InventoryReport } from './inventory-report'
import { InvoiceArchive } from './invoice-archive'
import { InvoiceCorrect } from './invoice-correct'
import { InvoicePrint } from './invoice-print'
import { InvoiceView } from './invoice-view'
import { LocationEditor } from './location-editor'
import { Login } from './login'
import { MaterialConsumptionReport } from './material-consumption-report'
import { MaterialOrders } from './components/MaterialOrders/material-orders'
import { ProductDefinitions } from './product-definitions'
import { ProductionReport } from './production-report'
import { RawMaterials } from './raw-materials'
import { router } from './router'
import { SalesReport } from './sales-report'
import { SettingsEditor } from './settings-editor'
import { TemplateDefinitions } from './template-definitions'
import { Transfers } from './transfers'
import { User } from './user'
import { UserCountryEditor } from './user-country-editor'
import { Users } from './users'

const render = (element: ReactElement) => {
  ReactDOM.render(element, document.getElementById('main'))
}

function configure() {
  router.on('/login', () => render(<Login />))
  router.on('/users', () => render(<Users.List />))
  router.on('/raw-materials', () => render(<RawMaterials.List />))
  router.on('/material-orders', () => render(<MaterialOrders.List />))
  router.on('/template-defs', () => render(<TemplateDefinitions.List />))
  router.on('/product-defs', () => render(<ProductDefinitions.List />))

  // TODO: route definition with optional segments?

  router.on('/inventory', function () {
    const locationId = User.getLastLocationId()

    render(
      <Inventory.List
        key={locationId}
        locationType={User.getLastLocationType()}
        locationId={locationId}
      />,
    )
  })

  router.on('/inventory/:locationType', function (locationType: string) {
    User.setLastLocation(locationType)
    const locationId = User.getLastLocationId()

    render(
      <Inventory.List
        key={locationId}
        locationType={locationType}
        locationId={locationId}
      />,
    )
  })

  router.on('/inventory/:locationType/:locationId', function (locationType: string, locationId: string) {
    User.setLastLocation(locationType, locationId)

    render(
      <Inventory.List
        key={locationId}
        locationType={locationType}
        locationId={locationId}
      />,
    )
  })

  router.on('/cart', function () {
    // TODO: get last location for sales points (separate by type)
    render(<Cart locationId={User.getLastLocationId()} />)
  })

  router.on('/cart/:locationId', function (locationId: string) {
    render(<Cart locationId={locationId} />)
  })

  router.on('/invoice/new/:locationId', function (locationId: string) {
    render(<InvoiceView isNew={true} locationId={locationId} />)
  })

  router.on('/invoice/view/:invoiceId', function (invoiceId: string) {
    render(<InvoiceView invoiceId={invoiceId} />)
  })

  router.on('/invoice/print/:invoiceId', function (invoiceId: string) {
    render(<InvoicePrint invoiceId={invoiceId} />)
  })

  router.on('/invoice/correct/:invoiceId', function (invoiceId: string) {
    render(<InvoiceCorrect invoiceId={invoiceId} />)
  })

  router.on('/transfers', function () {
    render(<Transfers.List />)
  })

  router.on('/transfers/new/:fromLocation/:toLocation', function (fromLocation: string, toLocation: string) {
    render(
      <Transfers.View
        isNew={true}
        fromCart={false}
        fromLocation={fromLocation}
        toLocation={toLocation}
      />,
    )
  })

  router.on('/transfers/new-from-cart/:fromLocation/:toLocation', function (fromLocation: string, toLocation: string) {
    render(
      <Transfers.View
        isNew={true}
        fromCart={true}
        fromLocation={fromLocation}
        toLocation={toLocation}
      />,
    )
  })

  router.on('/transfers/view/:transferId', function (transferId: string) {
    render(<Transfers.View transferId={transferId} />)
  })

  router.on('/transfers/correct/:transferId', function (transferId: string) {
    render(<Transfers.View transferId={transferId} correctionMode={true} />)
  })

  function salesReport() {
    render(<SalesReport.List />)
  }

  // TODO: remember last viewed report?
  router.on('/reports', salesReport)

  router.on('/reports/sales', salesReport)

  router.on('/reports/production', function () {
    render(<ProductionReport.List />)
  })

  router.on('/reports/inventory', function () {
    render(<InventoryReport />)
  })

  router.on('/reports/material-consumption', function () {
    render(<MaterialConsumptionReport.List />)
  })

  router.on('reports/correction', function () {
    render(<CorrectionReport />)
  })

  router.on('reports/invoice-archive', function () {
    render(<InvoiceArchive />)
  })

  router.on('reports/errors', function () {
    render(<ErrorReport />)
  })

  router.on('customers', function () {
    render(<Customers.List />)
  })

  router.on('data', function () {
    render(<DataManagement.Index />)
  })

  router.on('data/categories', function () {
    render(<DataManagement.ProductCategories />)
  })

  router.on('data/attributes', function () {
    render(<AttributeDefinitionListEditor />)
  })

  router.on('data/attributes/add', function () {
    render(<EditAttributeDefinition isNew={true} />)
  })

  router.on('data/attributes/edit/:attrId', function (attrId: string) {
    render(<EditAttributeDefinition isNew={false} id={attrId} />)
  })

  router.on('data/locations', function () {
    render(<LocationEditor />)
  })

  router.on('data/material-categories', function () {
    render(<DataManagement.MaterialCategories />)
  })

  router.on('data/material-producers', function () {
    render(<DataManagement.MaterialProducers />)
  })

  router.on('data/material-widths', function () {
    render(<DataManagement.MaterialWidths />)
  })

  router.on('data/user-countries', function () {
    render(<UserCountryEditor />)
  })

  router.on('data/settings', function () {
    render(<SettingsEditor />)
  })

  router.on('/accounting/expenses', function () {
    render(<Expenses.List />)
  })

  router.on('/accounting/expenses/summary', function () {
    render(<ExpensesSummary />)
  })

  router.on('/accounting/expenses/categories', function () {
    render(<ExpenseCategories.List />)
  })

  router.on('/accounting/earnings', function () {
    render(<Earnings.List />)
  })

  router.on('/accounting/earnings/summary', function () {
    render(<EarningsSummary />)
  })

  router.on('/accounting/earnings/categories', function () {
    render(<EarningCategories.List />)
  })

  let onEveryRoute = null

  // Enable Google Analytics if not running locally
  if (location.hostname !== 'localhost' && location.hostname !== '127.0.0.1') {
    window.ga = (...args: any[]) => window.ga.q.push(args)
    window.ga.q = []
    window.ga.l = Date.now()

    const scriptTag = document.createElement('script')
    scriptTag.src = 'https://www.google-analytics.com/analytics.js'
    document.head.appendChild(scriptTag)

    window.ga('create', 'UA-58229927-1', 'auto')

    onEveryRoute = () => window.ga('send', 'pageview', '/' + router.getRoute().join('/'))
  }

  router.configure({
    on: onEveryRoute,
    notfound: function () {
      toastr.error('Invalid route: ' + router.getRoute())
    },
  })
}

export const RouterConf = {
  configure,
  render,
}
