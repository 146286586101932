import React = require('react')
import toastr from 'toastr/toastr'

import { Access } from '../common/access'
import { CommonUtils } from '../common/common-utils'
import { AttributeDefinition, Username } from '../common/data-attribute-defs'
import { Location } from '../common/data-locations'
import { Category } from '../common/data-misc'
import { ProductDefinition } from '../common/data-product-defs'
import { Product } from '../common/data-products'
import { getDataService } from '../common/data-service'
import { TemplateDefinition } from '../common/data-template-defs'
import { Transfer, TransferEntry } from '../common/data-transfers'
import { Enums } from '../common/enums'
import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { bindInput, bindProps } from './bind-utils'
import { Filter } from './filter'
import { FloatingHeader } from './floating-header'
import { InventoryTable, Row } from './inventory-table'
import { LoadingButton } from './loading-button'
import { MainMenu } from './main-menu'
import { router } from './router'
import { Column } from './ui-utils'
import { User } from './user'
import { Utils } from './utils'
import { ValidationErrors, ValidationUtils } from './validation-utils'

interface ViewProps {
  isNew?: boolean,
  correctionMode?: boolean,

  // Required if viewing existing
  transferId?: string,

  // Required if creating new
  fromCart?: boolean,

  fromLocation?: string,
  toLocation?: string,
}

interface ViewState {
  loaded: boolean,
  anyChanges: boolean,
  canEdit: boolean,
  isReturn: boolean,
  fromLocId: string,
  toLocId: string,
  status: string,
  name: string,
  addAmounts: Record<string, string>,
  removeAmounts: Record<string, string>,
  entries: TransferEntry[],
  corrections: TransferEntry[],
  allInventoryItems: Product[],
  locations: Location[],
  attributeDefinitions: AttributeDefinition[],
  categories: Category[],
  productDefinitions: ProductDefinition[],
  inventoryItems: Product[],
  templateDefinitions: TemplateDefinition[],
  validationErrors: ValidationErrors,
  originalTransfer: undefined,
  latestTime: undefined,
}

class View extends React.Component<ViewProps, ViewState> {
  state = {
    loaded: false,
    anyChanges: false,
    fromLocId: undefined,
    toLocId: undefined,
    entries: undefined,
    corrections: undefined,
    allInventoryItems: undefined,
    addAmounts: undefined,
    removeAmounts: undefined,
    canEdit: undefined,
    locations: undefined,
    status: undefined,
    attributeDefinitions: undefined,
    categories: undefined,
    productDefinitions: undefined,
    inventoryItems: undefined,
    templateDefinitions: undefined,
    validationErrors: undefined,
    isReturn: undefined,
    originalTransfer: undefined,
    latestTime: undefined,
    name: undefined,
  }

  _isMounted = false

  componentDidMount() {
    this._isMounted = true
    this.loadEverything(this.props)
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidUpdate(prevProps: ViewProps) {
    if (
      this.props.isNew !== prevProps.isNew ||
      this.props.correctionMode !== prevProps.correctionMode ||
      this.props.transferId !== prevProps.transferId ||
      this.props.fromCart !== prevProps.fromCart ||
      this.props.fromLocation !== prevProps.fromLocation ||
      this.props.toLocation !== prevProps.toLocation
    ) {
      this.loadEverything(this.props)
    }
  }

  // TODO: optimize - split items that need to be initialised once vs ones that need reloading
  loadEverything = async (props) => {
    this.setState({ loaded: false })

    const DataService = getDataService()
    const attrDefPromise = DataService.AttributeDefinitions.getAll()
    const catPromise = DataService.Categories.getAll()
    const locPromise = DataService.Locations.getByType('sales-points')
    const prodDefPromise = DataService.ProductDefinitions.getAll()
    const tplDefPromise = DataService.TemplateDefinitions.getAll()
    let originalTransferPromise = null

    let isCreator

    const stateUpdate: Partial<ViewState> = {
      loaded: true,
      anyChanges: false,
      corrections: null,
      addAmounts: {},
      removeAmounts: {},
    }

    if (props.isNew) {
      let entries = []

      if (props.fromCart) {
        stateUpdate.anyChanges = true
        entries = await DataService.Carts.getContents(props.fromLocation)
      }

      isCreator = true

      stateUpdate.name = i18n.t('transfers.new-transfer')
      stateUpdate.fromLocId = props.fromLocation
      stateUpdate.toLocId = props.toLocation
      stateUpdate.entries = entries
      stateUpdate.status = Enums.transferStatuses.draft
      stateUpdate.isReturn = false
      stateUpdate.latestTime = null
    }
    else {
      const transfer = await DataService.Transfers.getById(props.transferId)
      isCreator = User.getUser()._id === transfer.user

      stateUpdate.name = transfer.name
      stateUpdate.fromLocId = transfer.from
      stateUpdate.toLocId = transfer.to
      stateUpdate.entries = transfer.entries.slice()
      stateUpdate.status = transfer.status
      stateUpdate.isReturn = transfer.isReturn
      stateUpdate.latestTime = CommonUtils.getLatestTransferTime(transfer)

      if (transfer.isReturn) {
        originalTransferPromise = DataService.Transfers.getById(transfer.originalTransfer)
      }

      if (props.correctionMode) {
        stateUpdate.corrections = CommonUtils.clone(transfer.entries)
      }
    }

    const inventoryPromise = DataService.Products.getByLocation(stateUpdate.fromLocId)

    const [
      attributeDefinitions,
      categories,
      locations,
      productDefinitions,
      templateDefinitions,
      inventoryItems,
      originalTransfer,
    ] = await Promise.all([
      attrDefPromise,
      catPromise,
      locPromise,
      prodDefPromise,
      tplDefPromise,
      inventoryPromise,
      originalTransferPromise,
    ])

    if (!this._isMounted) {
      return
    }

    stateUpdate.attributeDefinitions = attributeDefinitions
    stateUpdate.categories = categories
    stateUpdate.locations = locations
    stateUpdate.productDefinitions = productDefinitions
    stateUpdate.templateDefinitions = templateDefinitions
    stateUpdate.allInventoryItems = inventoryItems
    stateUpdate.inventoryItems = inventoryItems
    stateUpdate.originalTransfer = originalTransfer

    stateUpdate.entries = this.sortEntriesWithState(stateUpdate.entries, {
      categories,
      productDefinitions,
      allInventoryItems: inventoryItems,
      inventoryItems,
      attributeDefinitions,
      templateDefinitions,
    })

    stateUpdate.canEdit = isCreator && stateUpdate.status === Enums.transferStatuses.draft

    if (stateUpdate.canEdit) {
      stateUpdate.inventoryItems = inventoryItems.filter(function(item) {
        return item.amount > 0
      })

      stateUpdate.inventoryItems = Utils.sortInventory(
        stateUpdate.inventoryItems, User.getLanguage(), categories,
        attributeDefinitions, productDefinitions, templateDefinitions, null,
      )

      const initAmounts = function(productId) {
        if (!(productId in stateUpdate.addAmounts)) {
          stateUpdate.addAmounts[productId] = '1'
          stateUpdate.removeAmounts[productId] = '1'
        }
      }

      stateUpdate.inventoryItems.forEach(function(product) {
        initAmounts(product._id)
      })

      // Some entries may refer to items that have been filtered from the top table.
      stateUpdate.entries.forEach(function(entry) {
        initAmounts(entry.product)
      })
    }

    this.setState<any>(stateUpdate, EventBus.fireFunc('transfer-rendered'))
  }

  commonSave = async () => {
    // Returns a promise that resolves to the transfer ID

    const DataService = getDataService()

    if (this.props.isNew) {
      const result = await DataService.Transfers.create(
        this.state.fromLocId,
        this.state.toLocId,
        this.state.entries,
        this.props.fromCart,
      )

      return result.id
    }
    else {
      await DataService.Transfers.update(this.props.transferId, this.state.entries)
      return this.props.transferId
    }
  }

  onSave = () => {
    return this.commonSave()
    .then((transferId) => {
      if (this.props.isNew) {
        router.setRoute('/transfers/view/' + transferId)
      }
      else if (this._isMounted) {
        this.setState({ anyChanges: false })
      }
    })
  }

  onSend = async () => {
    let { transferId } = this.props

    if (this.props.isNew || this.state.anyChanges) {
      transferId = await this.commonSave()
    }

    await getDataService().Transfers.send(transferId)
    this.returnToList()
  }

  onDelete = async () => {
    if (await Utils.confirmTr('confirm.delete.transfer')) {
      await getDataService().Transfers.delete(this.props.transferId)
      this.returnToList()
    }
  }

  onAcceptWithCorrections = () => {
    const corrections = this.state.corrections
    .filter((correction) => {
      // Only keep corrections where the amount was changed
      const entry = this.getEntryByItemId(correction.product)
      return Number(correction.amount) !== Number(entry.amount)
    })
    .map(function(correction) {
      // Server expects numeric amounts, not strings
      const clone = CommonUtils.clone(correction)
      clone.amount = parseInt(clone.amount)
      return clone
    })

    return getDataService().Transfers.accept(this.props.transferId, corrections)
    .then(this.returnToList)
  }

  getItem = (entry, allInventoryItemsParam?) => {
    const allInventoryItems = allInventoryItemsParam || this.state.allInventoryItems
    return CommonUtils.findById(allInventoryItems, entry.product)
  }

  sortEntries = (entries) => {
    return this.sortEntriesWithState(entries, this.state)
  }

  sortEntriesWithState = (entries, state) => {
    let items = entries.map((entry) => {
      return this.getItem(entry, state.allInventoryItems)
    })

    items = Utils.sortInventory(
      items, User.getLanguage(), state.categories, state.attributeDefinitions,
      state.productDefinitions, state.templateDefinitions, null,
    )

    return items.map(function(item) {
      return CommonUtils.findByField<TransferEntry, 'product'>(entries, 'product', item._id)
    })
  }

  getEntryByItemId = (itemId) => {
    return CommonUtils.findByField<TransferEntry, 'product'>(this.state.entries, 'product', itemId)
  }

  getEntry = (item) => {
    return this.getEntryByItemId(item._id)
  }

  getCorrectionByItemId = (itemId) => {
    return CommonUtils.findByField<TransferEntry, 'product'>(this.state.corrections, 'product', itemId)
  }

  getInventoryActionCellContents = (_definition, item) => {
    return (
      <div>
        {bindInput(this, ['addAmounts', item._id], {
          className: 'inp-add',
          style: { width: '2em' },
          validator: Utils.nonNegativeIntegerValidator,
        })}
        {' '}
        <button
          className="btn-add"
          style={{ marginRight: '0.5em' }}
          onClick={() => {
            let entry = this.getEntry(item)
            let shouldAdd = false

            if (!entry) {
              entry = { product: item._id, amount: 0 }
              shouldAdd = true
            }

            const amountToAdd = Number(this.state.addAmounts[item._id])
            const newAmount = Number(entry.amount) + amountToAdd

            if (newAmount > item.amount) {
              toastr.error(i18n.t('transfers.amount-too-high'))
            }
            else {
              entry.amount = newAmount // TODO: clone and update via setState?
              const state: Pick<ViewState, 'anyChanges' | 'entries'> = {
                anyChanges: true,
                entries: this.state.entries,
              }
              if (shouldAdd) {
                const newEntries = this.state.entries.slice()
                newEntries.push(entry)
                state.entries = this.sortEntries(newEntries)
              }

              this.setState(state)
            }
          }}
        >
          +
        </button>
      </div>
    )
  }

  getTransferActionCellContents = (_definition, item) => {
    return (
      <div>
        {bindInput(this, ['removeAmounts', item._id], {
          className: 'inp-remove',
          style: { width: '2em' },
          validator: Utils.nonNegativeIntegerValidator,
        })}
        {' '}
        <button
          className="btn-remove"
          style={{ marginRight: '0.5em' }}
          onClick={() => {
            const entry = this.getEntry(item)
            const amountToRemove = Number(this.state.removeAmounts[item._id])
            const newAmount = Number(entry.amount) - amountToRemove

            if (newAmount < 0) {
              toastr.error(i18n.t('transfers.amount-too-low'))
            }
            else {
              // TODO: clone and update via setState?
              if (newAmount === 0) {
                CommonUtils.removeFromArray(this.state.entries, entry)
              }
              else {
                entry.amount = newAmount
              }

              this.setState({ anyChanges: true })
            }
          }}
        >
          -
        </button>
      </div>
    )
  }

  returnToList = () => {
    router.setRoute('/transfers')
  }

  returnToCart = () => {
    router.setRoute('/cart/' + this.props.fromLocation)
  }

  render() {
    const mainMenu = <MainMenu activeTab="transfers" />

    if (!this.state.loaded) {
      return (
        <div>
          {mainMenu}
          {i18n.t('common.loading')}
        </div>
      )
    }

    const user = User.getUser()
    const { canEdit } = this.state

    const fromLocation = CommonUtils.findById<Location>(this.state.locations, this.state.fromLocId)
    const toLocation = CommonUtils.findById<Location>(this.state.locations, this.state.toLocId)

    const canAccept = (
      this.state.status === Enums.transferStatuses.sent &&
      Access.transfer(user, toLocation)
    )

    const orderIdColumn = {
      id: 'orderId',
      header: i18n.t('inventory.order-id'),
      getCellContents: function(rowData) {
        return rowData.definition.isCustom ? rowData.definition.orderId : null
      },
    }

    let inventoryTable = null

    if (canEdit) {
      inventoryTable = (
        <InventoryTable
          id="tbl-inv-items"
          rowClassName="row-item"
          hasPermissions={true}
          attributeDefinitions={this.state.attributeDefinitions}
          locations={[fromLocation]}
          currency={fromLocation.currency}
          categories={this.state.categories}
          definitions={this.state.productDefinitions}
          // TODO: sort?
          items={this.state.inventoryItems}
          templateDefinitions={this.state.templateDefinitions}
          getActionCellContents={this.getInventoryActionCellContents}
          modifyColumns={function(columnConf) {
            if (fromLocation.showOrderId) {
              const index = Utils.findIndexByField<Column<Row>, 'id'>(columnConf, 'id', 'category') + 1
              columnConf.splice(index, 0, orderIdColumn)
            }
          }}
          filterSetKey="inventory"
          isProduction={false}
        />
      )
    }

    const transferTable = (
      <InventoryTable
        id="tbl-transfer-items"
        rowClassName="row-item"
        hasPermissions={canEdit}
        attributeDefinitions={this.state.attributeDefinitions}
        locations={[fromLocation, toLocation]}
        currency={toLocation.currency}
        categories={this.state.categories}
        definitions={this.state.productDefinitions}
        items={this.state.entries.map((entry) => {
          // Make sure getItem is not given a second arg
          return this.getItem(entry)
        })}
        templateDefinitions={this.state.templateDefinitions}
        getActionCellContents={this.getTransferActionCellContents}
        modifyFilterConf={(filterConf) => {
          filterConf['amount'].getField = (item) => {
            const entry = this.getEntryByItemId(item._id)
            return entry.amount
          }
        }}
        modifyColumns={(columnConf: Column<Row>[]) => {
          // TODO: break down into smaller functions

          if (fromLocation.showOrderId) {
            const categoryIndex = Utils.findIndexByField(columnConf, 'id', 'category') + 1
            columnConf.splice(categoryIndex, 0, orderIdColumn)
          }

          const amountColumn = CommonUtils.findByField(columnConf, 'id', 'amount')

          amountColumn.getCellContents = (rowData) => {
            const entry = this.getEntryByItemId(rowData.id)
            let { amount } = entry

            if ('correctedFromAmount' in entry) {
              amount += ' (' + i18n.t('transfers.corrected-from', String(entry.correctedFromAmount)) + ')'
            }

            let details = null

            if (canEdit) {
              // TODO: could use rowData.item if it doesn't get removed
              const inventoryItem = CommonUtils.findById<Product>(this.state.allInventoryItems, rowData.id)

              // TODO: both numeric?
              if (entry.amount > inventoryItem.amount) {
                details = (
                  <div style={{ color: '#c00', fontSize: '80%' }}>
                    {i18n.t('transfers.not-enough-in-inv')}
                  </div>
                )
              }
            }
            else if (canAccept) {
              const entryIndex = this.state.entries.indexOf(entry)
              details = ValidationUtils.render(this.state.validationErrors, 'entries.' + entryIndex + '.amount')
            }

            return (
              <div>
                {amount}
                {details}
              </div>
            )
          }

          if (canAccept) {
            if (this.props.correctionMode) {
              columnConf.push({
                id: 'actualAmount',
                header: i18n.t('transfers.actual-amount'),
                getCellContents: (rowData) => {
                  const correction = this.getCorrectionByItemId(rowData.id)

                  return (
                    <input
                      className="inp-actual-amount"
                      style={{ width: '4em' }}
                      value={String(correction.amount ?? '')}
                      onChange={(evt) => {
                        const { value } = evt.currentTarget

                        if (Utils.nonNegativeIntegerValidator(value)) {
                          correction.amount = Number(value)
                          this.forceUpdate()
                        }
                      }}
                    />
                  )
                },
              })

              columnConf.push({
                id: 'reason',
                header: i18n.t('transfers.correction-reason'),
                getCellContents: (rowData) => {
                  const correction = this.getCorrectionByItemId(rowData.id)
                  const entry = this.getEntryByItemId(rowData.id)

                  const corrected = Number(correction.amount) !== Number(entry.amount)

                  return (
                    <input
                      className="inp-reason"
                      disabled={!corrected}
                      value={String(correction.reason ?? '')}
                      onChange={(evt) => {
                        correction.reason = evt.currentTarget.value
                        this.forceUpdate()
                      }}
                    />
                  )
                },
              })
            }
            else if (this.state.isReturn) {
              columnConf.push({
                id: 'reason',
                header: i18n.t('transfers.correction-reason'),
                getCellContents: (rowData) => {
                  const entry = this.getEntryByItemId(rowData.id)
                  return entry.reason
                },
              })
            }
          }

          if (canEdit) {
            const noteIndex = Utils.findIndexByField(columnConf, 'id', 'note')
            columnConf.splice(noteIndex, 1)
          }
        }}
        noItemsText={i18n.t('transfers.no-items')}
        noFilteredItemsText={i18n.t('transfers.no-filtered-items')}
        filterSetKey="transfer-contents"
        isProduction={false}
      />
    )

    const buttons = []

    let onCancel = this.props.fromCart ? this.returnToCart : this.returnToList

    if (canEdit) {
      buttons.push(
        <LoadingButton
          key="save"
          id="btn-save"
          disabled={!this.state.anyChanges}
          className={this.state.anyChanges ? '' : 'disabled'}
          style={{ marginRight: '0.4em' }}
          getPromise={this.onSave}
          text={i18n.t('action.save')}
        />,
      )

      // TODO: disable send button also if for any entry, there are not enough items in the inventory?
      buttons.push(
        <LoadingButton
          key="send"
          id="btn-send"
          disabled={!this.state.entries.length}
          className={this.state.entries.length ? '' : 'disabled'}
          style={{ marginRight: '0.4em' }}
          getPromise={this.onSend}
          text={i18n.t('action.send')}
        />,
      )

      if (!this.props.isNew) {
        buttons.push(
          <LoadingButton
            key="delete"
            id="btn-delete"
            style={{ marginRight: '0.4em' }}
            getPromise={this.onDelete}
            text={i18n.t('action.delete')}
          />,
        )
      }
    }

    if (canAccept) {
      if (this.props.correctionMode) {
        buttons.push(
          <LoadingButton
            key="accept"
            id="btn-accept-with-corrections"
            style={{ marginRight: '0.4em' }}
            getPromise={this.onAcceptWithCorrections}
            text={i18n.t('transfers.accept-with-corrections')}
          />,
        )

        onCancel = () => {
          router.setRoute('/transfers/view/' + this.props.transferId)
        }
      }
      else {
        buttons.push(
          <LoadingButton
            key="accept"
            id="btn-accept"
            style={{ marginRight: '0.4em' }}
            text={i18n.t('action.accept')}
            getPromise={() => {
              return ValidationUtils.clear(this)
              .then(() => {
                return getDataService().Transfers.accept(this.props.transferId, null)
              })
              .then(this.returnToList)
              .catch((error) => ValidationUtils.check(this, error))
            }}
          />,
        )

        if (!this.state.isReturn) {
          // Although it is technically feasible to create return transfers of
          // return transfers and this would work almost without any modifications
          // (except for negative amount validation), we're currently preventing
          // users from doing this for the sake of workflow simplicity.

          buttons.push(
            <button
              key="correct"
              id="btn-correct"
              style={{ marginRight: '0.4em' }}
              onClick={() => {
                this.setState({ loaded: false })
                router.setRoute('/transfers/correct/' + this.props.transferId)
              }}
            >
              {i18n.t('transfers.correct-amounts')}
            </button>,
          )
        }
      }
    }

    if (canEdit || canAccept) {
      buttons.push(
        <button key="cancel" id="btn-cancel" onClick={onCancel}>
          {i18n.t('action.cancel')}
        </button>,
      )
    }

    let returnTransferInfo = null

    if (this.state.isReturn) {
      const originalTransferName = this.state.originalTransfer.name

      returnTransferInfo = (
        <div id="lbl-return-transfer" style={{ margin: '0.4em 0' }}>
          {i18n.t('transfers.return-transfer-info')}
          {' '}
          <a
            id="lnk-original"
            href={'#/transfers/view/' + this.state.originalTransfer._id}
          >
            {originalTransferName}
          </a>
        </div>
      )
    }

    let dateElement = null

    if (!this.props.isNew) {
      const userTime = CommonUtils.toUserTime(user.country, new Date(this.state.latestTime))

      dateElement = (
        <div style={{ margin: '0.4em 0' }}>
          {i18n.t('common.date')}
          {': '}
          <b id="lbl-date">
            {CommonUtils.utcDateTime(userTime)}
          </b>
        </div>
      )
    }

    return (
      <div>
        {mainMenu}
        <div style={{ marginTop: '1em' }}>
          <a id="lnk-back-to-list" href="#/transfers">
            {i18n.t('action.back-to-list')}
          </a>
        </div>
        <div
          id="lbl-name"
          style={{ fontWeight: 'bold', fontSize: '130%', margin: '0.4em 0 0.8em' }}
        >
          {this.state.name}
        </div>
        {returnTransferInfo}
        <div style={{ margin: '0.4em 0' }}>
          {i18n.t('transfers.status')}
          {': '}
          <b id="lbl-status">
            {i18n.t('enum.transfer-statuses.' + this.state.status)}
          </b>
        </div>
        {dateElement}
        <div style={{ margin: '0.4em 0' }}>
          {i18n.t('transfers.from')}
          {': '}
          <b id="lbl-from">
            <a href={'#/inventory/sales-points/' + this.state.fromLocId}>
              {fromLocation.names[User.getLanguage()]}
            </a>
          </b>
        </div>
        {inventoryTable}
        <div style={{ margin: '0.4em 0' }}>
          {i18n.t('transfers.to')}
          {': '}
          <b id="lbl-to">
            <a href={'#/inventory/sales-points/' + this.state.toLocId}>
              {toLocation.names[User.getLanguage()]}
            </a>
          </b>
        </div>
        {transferTable}
        <div>{buttons}</div>
      </div>
    )
  }
}

interface ListState {
  loaded: boolean,
  transfers: Transfer[],
  from: string,
  to: string,
  usernames: Username[],
  locations: Location[],
}

class List extends React.Component<Record<string, null>, ListState> {
  state = {
    loaded: false,
    transfers: [],
    locations: undefined,
    usernames: undefined,
    from: undefined,
    to: undefined,
  }

  _isMounted = false

  componentDidMount() {
    this._isMounted = true
    Filter.registerEvent(this)
    const DataService = getDataService()

    Promise.all([
      DataService.Transfers.getAll(),
      DataService.Locations.getByType('sales-points'),
      DataService.Users.getUsernames(),
    ])
    .then(([transfers, locations, usernames]) => {
      if (!this._isMounted) {
        return
      }

      this.setState(
        {
          loaded: true,
          transfers,
          locations,
          usernames,
        },
        EventBus.fireFunc('transfers-rendered'),
      )
    })
  }

  componentWillUnmount() {
    this._isMounted = false
    Filter.unregisterEvent(this)
  }

  getFilterConf = () => {
    const locations = this.state.locations.slice()

    locations.sort(function(loc1, loc2) {
      return loc1.names[User.getLanguage()].localeCompare(loc2.names[User.getLanguage()])
    })

    const getLocationFilterConf = function(labelKey) {
      return {
        type: 'predefined',
        labelKey,
        options: locations.map(function(loc) {
          return { value: loc._id, label: loc.names[User.getLanguage()] }
        }),
      }
    }

    // TODO: sort users

    return {
      time: {
        type: 'date-range',
        labelKey: 'common.date',
        getField: CommonUtils.getLatestTransferTime,
      },
      status: Utils.getEnumFilterConf(
        Enums.orderedTransferStatuses, 'transfer-statuses', 'transfers.status',
      ),
      from: getLocationFilterConf('transfers.from'),
      to: getLocationFilterConf('transfers.to'),
      pieces: {
        type: 'number-range',
        labelKey: 'transfers.pieces',
        getField: (transfer) => this.getPieces(transfer),
      },
      user: {
        type: 'predefined',
        labelKey: 'common.user',
        options: this.state.usernames.map(function(userFields) {
          return {
            value: userFields._id,
            label: userFields.username,
          }
        }),
      },
    }
  }

  getUsername = (userId) => {
    const obj = CommonUtils.findById<Username>(this.state.usernames, userId)
    return obj.username
  }

  getPieces = (transfer) => {
    let original = 0
    let current = 0

    transfer.entries.forEach(function(entry) {
      current += entry.amount
      original += 'correctedFromAmount' in entry ? entry.correctedFromAmount : entry.amount
    })

    if (current === original) {
      return original.toString()
    }
    else {
      return current + ' (' + i18n.t('transfers.corrected-from', String(original)) + ')'
    }
  }

  getLocationDropdown = (fieldName, locations) => {
    return (
      <select {...bindProps(this, [fieldName], { id: 'inp-location-' + fieldName })}>
        {!this.state[fieldName] && <option value="" />}
        {locations.map(function(loc) {
          return (
            <option key={loc._id} value={loc._id}>
              {loc.names[User.getLanguage()]}
            </option>
          )
        })}
      </select>
    )
  }

  render() {
    const mainMenu = <MainMenu activeTab="transfers" />

    if (!this.state.loaded) {
      return (
        <div>
          {mainMenu}
          {i18n.t('common.loading')}
        </div>
      )
    }

    const user = User.getUser()

    const filterManager = Filter.createManager(
      'transfer-list', this.getFilterConf(), this.state.transfers,
    )

    const colCount = 7

    const headerRow = (
      <tr>
        <th>
          {i18n.t('common.name')}
        </th>
        <th>
          {i18n.t('common.date')}
          {filterManager.getIcon('time')}
        </th>
        <th>
          {i18n.t('transfers.status')}
          {filterManager.getIcon('status')}
        </th>
        <th>
          {i18n.t('transfers.from')}
          {filterManager.getIcon('from')}
        </th>
        <th>
          {i18n.t('transfers.to')}
          {filterManager.getIcon('to')}
        </th>
        <th>
          {i18n.t('transfers.pieces')}
          {filterManager.getIcon('pieces')}
        </th>
        <th>
          {i18n.t('common.user')}
          {filterManager.getIcon('user')}
        </th>
      </tr>
    )

    let transferRows = filterManager.getFiltered().map((transfer) => {
      const fromLocation = CommonUtils.findById<Location>(this.state.locations, transfer.from)
      const toLocation = CommonUtils.findById<Location>(this.state.locations, transfer.to)

      const userTime = CommonUtils.toUserTime(user.country, new Date(CommonUtils.getLatestTransferTime(transfer)))

      return (
        <tr className="row-transfer" key={transfer._id}>
          <td>
            <a className="lnk-transfer" href={'#/transfers/view/' + transfer._id}>
              {transfer.name}
            </a>
          </td>
          <td>{CommonUtils.utcDateTime(userTime)}</td>
          <td>{i18n.t('enum.transfer-statuses.' + transfer.status)}</td>
          <td>{fromLocation.names[User.getLanguage()]}</td>
          <td>{toLocation.names[User.getLanguage()]}</td>
          <td>{this.getPieces(transfer)}</td>
          <td>{this.getUsername(transfer.user)}</td>
        </tr>
      )
    })

    if (!transferRows.length) {
      const anyFilters = filterManager.anyFilters()

      transferRows = [
        <tr key="no-items">
          <td colSpan={colCount}>
            {anyFilters ? i18n.t('transfers.no-filtered-transfers') : i18n.t('transfers.no-transfers')}
          </td>
        </tr>,
      ]
    }

    let addNewElement = null

    const fromLocations = this.state.locations.filter(function(loc) {
      return Access.transfer(user, loc)
    })

    if (fromLocations.length) {
      const addButtonDisabled = (
        !this.state.from || !this.state.to || this.state.from === this.state.to
      )

      addNewElement = (
        <div style={{ marginBottom: '0.5em' }}>
          {i18n.t('transfers.from')}
          {' '}
          {this.getLocationDropdown('from', fromLocations)}
          {' '}
          {i18n.t('transfers.to').toLowerCase()}
          {' '}
          {this.getLocationDropdown('to', this.state.locations)}
          {' '}
          <button
            id="btn-add-new"
            disabled={addButtonDisabled}
            className={addButtonDisabled ? 'disabled' : ''}
            onClick={() => {
              router.setRoute('/transfers/new/' + this.state.from + '/' + this.state.to)
            }}
          >
            {i18n.t('action.add-new')}
          </button>
        </div>
      )
    }

    return (
      <div>
        {mainMenu}
        {addNewElement}
        {filterManager.getSummary()}
        <table
          id="tbl-transfer"
          className="table table-bordered table-condensed table-striped"
        >
          <FloatingHeader headerRow={headerRow} />
          <tbody>{transferRows}</tbody>
        </table>
      </div>
    )
  }
}

export const Transfers = { List, View }
