import React = require('react')

import { CommonUtils } from '../common/common-utils'
import { ActualPaymentKey } from '../common/data-invoices'
import { i18n } from '../common/i18n'

import {
  ActualPaymentOption,
  Currency,
  InvoiceSettings,
  InvoiceUtils,
  InvoiceValues,
} from '../common/invoice-utils'

import { Settings } from './settings'
import { Utils } from './utils'

export interface Rates {
  MAD: number,
  USD: number,
  EUR: number,
}

const defaultBookPrices = { 'MAD': 500, 'EUR': 45 }

interface ActualPaymentRowProps {
  rowKey: string,
  option?: ActualPaymentOption,
  amount: string,
  uncoveredAmount: number,
  locationCurrency?: Currency,
  rates: Rates,
  onChange: (value: string) => void,
  payAll: () => void,
}

class ActualPaymentRow extends React.Component<ActualPaymentRowProps> {
  renderLink = () => {
    const props = {
      className: 'lnk-pay-all',
      style: { display: 'inline-block', width: '12em', cursor: 'pointer' },
      onClick: this.props.payAll,
    }

    const text = InvoiceUtils.getActualPaymentOptionString(this.props.option, false)
    return <a {...props}>{text}</a>
  }

  renderUncovered = () => {
    const { rates } = this.props

    const uncovered = Math.ceil(
      this.props.uncoveredAmount *
      rates[this.props.locationCurrency] /
      rates[this.props.option.currency],
    )

    if (uncovered !== 0) {
      return (
        <span
          className="lbl-uncovered"
          style={{ color: uncovered > 0 ? 'green' : 'red' }}
        >
          {uncovered > 0 ? ' +' : ' '}
          {Utils.formatInteger(uncovered)}
        </span>
      )
    }
  }

  render() {
    const props = { id: 'actual-payment-row-' + this.props.rowKey, style: { margin: '0.2em 0' } }

    return (
      <div {...props}>
        {this.renderLink()}
        <input
          className="inp-actual-payment"
          style={{ width: '5em' }}
          value={this.props.amount}
          onChange={(evt) => {
            const { value } = evt.currentTarget

            if (Utils.decimalValidator(value)) {
              this.props.onChange(value)
            }
          }}
        />
        {this.renderUncovered()}
      </div>
    )
  }
}

export const InvoiceUiUtils = {
  getActualPaymentRows: function(
    actualPayments: Partial<Record<ActualPaymentKey, number | string>>, // TODO string only
    uncoveredAmount: number,
    currency: Currency,
    rates: Rates,
    total: number,
    updatePayments: (payments: Partial<Record<ActualPaymentKey, number | string>>) => void,
  ) {
    return InvoiceUtils.getActualPaymentOptions(currency)
    .map(function(option) {
      const key = InvoiceUtils.getActualPaymentKey(option)
      const amount = String(actualPayments[key] || '')

      return (
        <ActualPaymentRow
          key={key}
          rowKey={key}
          option={option}
          amount={amount}
          uncoveredAmount={uncoveredAmount}
          locationCurrency={currency}
          rates={rates}
          onChange={function(newAmount) {
            const newPayments = CommonUtils.clone(actualPayments)
            newPayments[key] = newAmount
            updatePayments(newPayments)
          }}
          payAll={function() {
            const newPayments = {}

            Object.keys(actualPayments).forEach(function(otherKey) {
              let value = 0

              if (otherKey === key) {
                const otherCurrency = key.substring(0, 3)

                value = Math.ceil(total * rates[currency] / rates[otherCurrency])
              }

              newPayments[otherKey] = value ? value.toString() : ''
            })

            updatePayments(newPayments)
          }}
        />
      )
    })
  },
  validateActualPayments: function(
    invoiceSettings: InvoiceSettings,
    rates: Rates,
    invoiceValues: InvoiceValues,
    deductVat: boolean,
    actualPayments: Partial<Record<ActualPaymentKey, number | string>>, // TODO string only
  ): Promise<boolean> {
    const total = InvoiceUtils.getTotal(invoiceSettings, invoiceValues, deductVat)
    const covered = InvoiceUtils.getCoveredAmount(actualPayments, invoiceValues.currency, rates)
    const uncovered = total - covered

    // The promise chain is here to support multiple warnings if needed
    let promise = Promise.resolve(true)

    if (uncovered > 1 || uncovered < -1) {
      promise = promise.then(Utils.runIfTrue(function() {
        return Utils.confirm(i18n.t(
          'invoice.uncovered-warning',
          CommonUtils.formatDecimal(uncovered, true),
          invoiceValues.currency,
        ))
      }))
    }

    return promise
  },
  getSettings: function(): Promise<InvoiceSettings> {
    return InvoiceUtils.getSettings(Settings.getMultiple)
  },
  getExchangeRates: async function(): Promise<Rates> {
    const [eurRate, usdRate] = await Settings.getMultiple('eurRate', 'usdRate')
    return { MAD: 1, EUR: eurRate as number, USD: usdRate as number }
  },
  getBookDesc: function(forPrint: boolean): string {
    // When printing, always use English
    return forPrint ? i18n.lt('en', 'invoice.book-name') : i18n.t('invoice.book-name')
  },
  getDefaultBookPrice: function(currency: Currency): number {
    if (!(currency in defaultBookPrices)) {
      throw new Error('Invalid book currency: ' + currency)
    }

    return defaultBookPrices[currency]
  },
}
