import React = require('react')

import { CommonUtils } from '../common/common-utils'
import { AttributeDefinition } from '../common/data-attribute-defs'
import { TemplateDefinition } from '../common/data-template-defs'
import { i18n } from '../common/i18n'
import { AttributeCombination as TAttributeCombination } from '../common/types'
import { AttributeCombination } from './attribute-combination'
import { DefinitionWithoutId } from './definition-edit-form'
import { User } from './user'
import { ValidationField } from './validation-utils'

interface AttributeCombinationSetProps {
  definition: DefinitionWithoutId,
  attribute: AttributeDefinition,
  usage?: Record<number, boolean>,
  sourceTemplate?: TemplateDefinition,
  formValidationError: (field: ValidationField) => void,
  updateView: (callback?: () => void) => void,
}

const generateNewComboId = (combinations: TAttributeCombination[]): number => {
  // Find max id (include 0 in case of empty list) and increment by 1
  const ids = combinations.map((combination) => combination.id)
  return Math.max(0, ...ids) + 1
}

export class AttributeCombinationSet extends React.Component<AttributeCombinationSetProps> {
  render() {
    const { attribute, definition } = this.props

    const attrId = attribute._id
    const allCombos = definition.attributeCombinations

    if (!(attrId in allCombos)) {
      allCombos[attrId] = []
    }

    const combinations = allCombos[attrId]

    let fromTemplate = false

    if (this.props.sourceTemplate) {
      // Read only if this attribute applies in the template context
      fromTemplate = CommonUtils.attributeContextFilters.templates(attribute)
    }

    if (fromTemplate) {
      const numericCombos = combinations as number[]
      const tplCombinations = this.props.sourceTemplate.attributeCombinations[attribute._id]

      return (
        <div id={'attr-combo-set-' + attribute._id}>
          {tplCombinations.map((combination) => {
            const isInUse = this.props.usage?.[combination.id]
            const enabled = CommonUtils.arrayContains(numericCombos, combination.id)

            return (
              <div key={combination.id} className="lbl-combo">
                <input
                  type="checkbox"
                  id={'chk-attr-combo-' + attribute._id + '-' + combination.id}
                  className="chk-attr-combo"
                  checked={enabled}
                  disabled={isInUse}
                  onChange={(evt) => {
                    if (evt.currentTarget.checked) {
                      // TODO: sort to maintain the order from the template? Sort on server side?
                      numericCombos.push(combination.id)
                    }
                    else {
                      CommonUtils.removeFromArray(numericCombos, combination.id)
                    }

                    this.props.updateView()
                  }}
                />
                {' '}
                {CommonUtils.getAttributeComboLabel(
                  attribute,
                  combination.values,
                  definition.category,
                  User.getLanguage(),
                )}
                {isInUse && (
                  <span className="lbl-in-use">
                    {' (' + i18n.t('attrs.in-use') + ')'}
                  </span>
                )}
              </div>
            )
          })}
        </div>
      )
    }

    const nonNumericCombos = combinations as TAttributeCombination[]

    return (
      <div id={'attr-combo-set-' + attribute._id}>
        {nonNumericCombos.map((combination, index) => {
          let deleteButton = null

          const isInUse = this.props.usage?.[combination.id]

          if (!isInUse) {
            deleteButton = (
              <img
                className="table-btn btn-delete-combo"
                title={i18n.t('action.delete')}
                src="img/delete.png"
                onClick={() => {
                  combinations.splice(index, 1)
                  this.props.updateView()
                }}
              />
            )
          }
          else {
            // Show a label instead of a delete button
            deleteButton = (
              <span className="lbl-in-use">
                {'(' + i18n.t('attrs.in-use') + ')'}
              </span>
            )
          }

          return (
            <div key={combination.id}>
              <div
                className="attr-combo"
                style={{ whiteSpace: 'nowrap', marginBottom: '0.3em' }}
              >
                <AttributeCombination
                  ref={'combo-' + combination.id}
                  attribute={attribute}
                  values={combination.values}
                  categoryId={definition.category}
                  onChange={(newValues) => {
                    combination.values = newValues
                    this.props.updateView()
                  }}
                />
                {' '}
                {deleteButton}
              </div>
              {this.props.formValidationError({
                childOf: 'attributeCombinations.' + attribute._id + '.' + index,
              })}
            </div>
          )
        })}
        <a
          className="lnk-add-new"
          onClick={() => {
            const newId = generateNewComboId(nonNumericCombos)
            nonNumericCombos.push({ id: newId, values: [] })
            this.props.updateView(() => {
              (this.refs['combo-' + newId] as AttributeCombination).focusInput()
            })
          }}
          style={{ cursor: 'pointer' }}
        >
          {i18n.t('action.add-new')}
        </a>
      </div>
    )
  }
}
