import React = require('react')

import { i18n } from '../common/i18n'

export class BackToIndexLink extends React.Component {
  render() {
    return (
      <div style={{ marginBottom: 5 }}>
        <a href="#/data">
          {i18n.t('data.back-to-index')}
        </a>
      </div>
    )
  }
}
