import { useRef } from 'react'
import React = require('react')
import toastr from 'toastr/toastr'

import { CommonUtils } from '../common/common-utils'
import { AttributeDefinition } from '../common/data-attribute-defs'
import { Location } from '../common/data-locations'
import { SimpleAttr } from '../common/data-misc'
import { PricesInput, ProductDefinition } from '../common/data-product-defs'
import { Production } from '../common/data-production-report'
import { Product } from '../common/data-products'
import { getDataService } from '../common/data-service'
import { TemplateDefinition } from '../common/data-template-defs'
import { i18n } from '../common/i18n'
import { AttributeCombination } from './attribute-combination'
import { AttributeCombinationSelector } from './attribute-combination-selector'
import { bindInput } from './bind-utils'
import { getDefaultCancelButton, Modal, ModalButton } from './modal'
import { useOpenModalEvent } from './use-open-modal-event'
import { User } from './user'
import { Utils } from './utils'
import { ValidationErrors, ValidationUtils } from './validation-utils'

interface EditProductionReportModalProps {
  modalId: string,
  entry: Production,
  product: Product,
  categories: SimpleAttr[],
  attributeDefinitions: AttributeDefinition[],
  productDefinition: ProductDefinition,
  templateDefinitions: TemplateDefinition[],
  templateDefinition?: TemplateDefinition,
  templateLocation?: Location,
  location: Location,
  getUsername: (userId: string) => string,
  afterSave?: () => void,
}

interface FormProps {
  entry: Production,
  product: Product,
  categories: SimpleAttr[],
  attributeDefinitions: AttributeDefinition[],
  productDefinition: ProductDefinition,
  templateDefinitions: TemplateDefinition[],
  templateDefinition?: TemplateDefinition,
  templateLocation?: Location,
  location: Location,
  getUsername: (user: string) => string,
}

interface FormState {
  entry: Production,
  date: string,
  time: string,
  amount: string,
  attributes: any,
  name: string,
  orderId: string,
  prices: PricesInput,
  validationErrors?: ValidationErrors,
}

class Form extends React.Component<FormProps, FormState> {
  constructor(props) {
    super(props)
    const userTime = this.getUserTime()

    const state: any = {
      entry: CommonUtils.clone(props.entry),
      date: CommonUtils.utcDateYMD(userTime),
      time: CommonUtils.utcTime(userTime),
      amount: props.entry.amount.toString(),
    }

    const { productDefinition } = props

    if (productDefinition.isCustom) {
      state.attributes = CommonUtils.clone(productDefinition.attributeCombinations)
      state.name = productDefinition.name
      state.orderId = productDefinition.orderId

      state.prices = {}

      Object.keys(productDefinition.prices).forEach(function(currency) {
        state.prices[currency] = productDefinition.prices[currency].toString()
      })
    }
    else {
      state.attributes = CommonUtils.clone(props.product.attributes)
    }

    this.state = state
  }

  dateInputRef = React.createRef<HTMLInputElement>()

  _isMounted = false

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getUserTime = () => {
    return CommonUtils.toUserTime(User.getUser().country, new Date(this.props.entry.time))
  }

  onSave = () => {
    return ValidationUtils.clear(this)
      .then(() => {
        // TODO: unduplicate date/time handling code with material consumption report modal

        const entry = {
          amount: this.state.amount,
          time: this.state.entry.time,
        }

        const userTime = this.getUserTime()

        // Update datetime only if the user has changed it, otherwise we'll
        // lose precision beyond minutes for no reason.
        if (
          this.state.date !== CommonUtils.utcDateYMD(userTime) ||
          this.state.time !== CommonUtils.utcTime(userTime)
        ) {
          this.validateDateTime(this.state.date, this.state.time)

          const utcTime = CommonUtils.userDateToUTC(
            User.getUser().country, this.state.date, this.state.time,
          )

          entry.time = utcTime.toISOString()
        }

        // TODO: prompt user about updating storage if amount changed?

        return getDataService().ProductionReport.update(
          this.state.entry._id, entry, this.state.attributes, this.state.name,
          this.state.orderId, this.state.prices,
        )
      })
      .catch((error) => ValidationUtils.check(this, error))

  }

  validateDateTime = (date, time) => {
    const dateStr = date + ' ' + time

    // Append Z to parse as a UTC date
    const dateObj = new Date(dateStr + 'Z')
    const processedDateStr = CommonUtils.utcDateTime(dateObj)

    if (processedDateStr !== dateStr) {
      // TODO: log warning through API?
      console.error(dateStr + ' does not match ' + processedDateStr)
      toastr.error(i18n.t('reports.invalid-date-time'))

      this.dateInputRef.current.focus()

      // Skip the rest of the promise chain
      throw Utils.getAlreadyHandledError()
    }
  }

  render() {
    // TODO: validation errors on all fields

    const language = User.getLanguage()

    const { productDefinition } = this.props

    const row = (key, fieldName, field, validationField?) => {
      return (
        <div key={key} className="row" style={{ margin: '0.5em 0' }}>
          <div className="col-xs-4" style={{ fontWeight: 'bold', paddingLeft: 0 }}>
            {fieldName}
          </div>
          <div className="col-xs-8">
            {field}
            {ValidationUtils.render(this.state.validationErrors, validationField)}
          </div>
        </div>
      )
    }

    let templateRows = []

    if (this.props.templateDefinition) {
      templateRows = [
        row(
          'name',
          i18n.t('inventory.from-template'),
          <div id="lbl-from-template">
            {this.props.templateDefinition.name}
          </div>,
        ),
        row(
          'loc',
          i18n.t('inventory.original-location'),
          <div id="lbl-original-location">
            {this.props.templateLocation.names[language]}
          </div>,
        ),
      ]
    }

    let productElement
    let orderIdRow = null
    let pricesRow = null

    if (productDefinition.isCustom) {
      productElement = bindInput(this,
        ['name'], { id: 'inp-name', style: { width: '20em' } },
      )

      orderIdRow = row(undefined,
        i18n.t('inventory.order-id'),
        bindInput(this,
          ['orderId'], { id: 'inp-order-id', style: { width: '20em' } },
        ),
        'orderId',
      )

      pricesRow = row(undefined,
        i18n.t('common.prices'),
        <div>
          {['MAD', 'EUR'].map((currency) => {
            return (
              <div key={currency} style={{ marginBottom: '0.3em' }}>
                {bindInput(this,
                  ['prices', currency],
                  { id: 'inp-price-' + currency, style: { width: '4em' } },
                )}
                {' '}
                {currency}
                {ValidationUtils.render(this.state.validationErrors, 'prices.' + currency)}
              </div>
            )
          })}
        </div>,
      )
    }
    else {
      productElement = <div id="lbl-name">{productDefinition.name}</div>
    }

    return (
      <div>
        <div id="form-entry">
          {row(undefined,
            i18n.t('inventory.product'),
            productElement,
            'name',
          )}
          {orderIdRow}
          {this.props.attributeDefinitions.map((attribute) => {
            let combinationElement

            if (productDefinition.isCustom) {
              if (productDefinition.isCustom && attribute.textForCustom) {
                combinationElement = bindInput(this,
                  ['attributes', attribute._id],
                  { id: 'inp-attr-' + attribute._id, style: { width: '20em' } },
                )
              }
              else {
                const combinations = this.state.attributes[attribute._id]

                if (!combinations && attribute.optional) {
                  combinationElement = '-'
                }
                else {
                  const [combination] = combinations

                  // Wrap in similar divs as on definition form so we can use the same test utils
                  combinationElement = (
                    <div id={'attr-combo-set-' + attribute._id}>
                      <div className="attr-combo">
                        <AttributeCombination
                          ref={'combo-' + attribute._id}
                          attribute={attribute}
                          values={combination ? combination.values : []}
                          onChange={(newValues) => {
                            if (newValues.length) {
                              if (!combinations.length) {
                                combinations.push({ id: 1, values: newValues })
                              }
                              else {
                                combinations[0].values = newValues
                              }
                            }
                            else {
                              combinations.length = 0
                            }

                            this.forceUpdate() // TODO: use setState?
                          }}
                        />
                      </div>
                    </div>
                  )
                }
              }
            }
            else if (attribute._id in productDefinition.attributeCombinations || !attribute.optional) {
              combinationElement = (
                <AttributeCombinationSelector
                  attribute={attribute}
                  definition={productDefinition}
                  templateDefinitions={this.props.templateDefinitions}
                  selectedComboId={this.state.attributes[attribute._id]}
                  onSelect={(comboId) => {
                    const newAttrs = CommonUtils.clone(this.state.attributes)
                    newAttrs[attribute._id] = comboId
                    this.setState({ attributes: newAttrs })
                  }}
                />
              )
            }
            else {
              combinationElement = '-'
            }

            return row(
              attribute._id,
              attribute.names[language],
              combinationElement,
              'attributes.' + attribute._id,
            )
          })}
          {pricesRow}
          {row(undefined,
            i18n.t('common.amount'),
            bindInput(this, ['amount'], {
              id: 'inp-amount',
              style: { width: '4em' },
              validator: Utils.nonNegativeIntegerValidator,
            }),
            'entry.amount',
          )}
          {row(undefined,
            i18n.t('common.location'),
            <div id="lbl-location">
              {this.props.location.names[language]}
            </div>, // TODO: editable?
          )}
          {// TODO: vertical gap? Also on material consumption report modal
          templateRows}
          {row(undefined,
            i18n.t('common.user'),
            <div id="lbl-user">
              {this.props.getUsername(this.state.entry.user)}
            </div>,
          )}
          {row(undefined,
            i18n.t('reports.date-time'),
            // TODO: date picker?
            <div>
              {bindInput(this, ['date'], { ref: this.dateInputRef, style: { width: '6.2em' } })}
              {' '}
              {bindInput(this, ['time'], { style: { width: '3.5em' } })}
            </div>,
          )}
        </div>
      </div>
    )
  }
}

export const EditProductionReportModal = (props: EditProductionReportModalProps) => {
  const [isVisible, close] = useOpenModalEvent(props.modalId)
  const formRef = useRef<Form>(null)

  if (!isVisible) {
    return <div />
  }

  const buttons: ModalButton[] = [
    {
      id: 'btn-save',
      title: i18n.t('action.save'),
      onClick: async () => {
        await formRef.current.onSave()
        await props.afterSave?.()
        close()
      },
    },
    getDefaultCancelButton(),
  ]

  return (
    <div>
      <Modal
        title={i18n.t('reports.edit-entry')}
        closeModal={close}
        dialogClassName="edit-production-report"
        buttons={buttons}
      >
        <Form
          ref={formRef}
          entry={props.entry}
          product={props.product}
          categories={props.categories}
          attributeDefinitions={props.attributeDefinitions}
          productDefinition={props.productDefinition}
          templateDefinitions={props.templateDefinitions}
          templateDefinition={props.templateDefinition}
          templateLocation={props.templateLocation}
          location={props.location}
          getUsername={props.getUsername}
        />
      </Modal>
    </div>
  )
}
