import React = require('react')

import { CommonUtils } from '../common/common-utils'
import { AttributeDefinition } from '../common/data-attribute-defs'
import { getDataService } from '../common/data-service'
import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { BackToIndexLink } from './back-to-index-link'
import { DataEditor, DataEditorField } from './data-editor'
import { EmptyObject } from './empty-object'
import { LoadingButton } from './loading-button'
import { MainMenu } from './main-menu'
import { router } from './router'
import { User } from './user'

interface State {
  loaded: boolean,
  objs: AttributeDefinition[],
}

export class AttributeDefinitionListEditor extends React.Component<EmptyObject, State> {
  state: State = { loaded: false, objs: [] }

  componentDidMount() {
    this.reloadData()
  }

  reloadData = async () => {
    const objs = await getDataService().AttributeDefinitions.getAll()
    objs.sort((attr1, attr2) => attr1.orderIndex - attr2.orderIndex)
    this.setState({ loaded: true, objs }, EventBus.fireFunc('attributes-rendered'))
  }

  moveUp = (id: string) => {
    // Find previous item and move it down

    let prevId = null, promise = null

    for (const attr of this.state.objs) {
      if (attr._id === id) {
        promise = this.moveDown(prevId)
      }

      prevId = attr._id
    }

    return promise
  }

  moveDown = (id: string) => {
    return getDataService().AttributeDefinitions.moveDown(id).then(this.reloadData)
  }

  render() {
    const mainMenu = <MainMenu activeTab="data" />

    if (!this.state.loaded) {
      return (
        <div>
          {mainMenu}
          Loading...
        </div>
      )
    }

    const attrs = this.state.objs

    const fields: DataEditorField[] = [
      {
        id: 'name',
        header: i18n.t('common.name'),
        immutable: true,
        getValue: function(obj) {
          return obj.names[User.getLanguage()]
        },
      },
    ]

    const firstOrderIndex = attrs[0].orderIndex
    const lastOrderIndex = attrs[attrs.length - 1].orderIndex

    const getAdditionalButtons = (id: string) => {
      const attr = CommonUtils.findById(attrs, id)

      let moveUpButton = null
      let moveDownButton = null

      if (attr.orderIndex !== firstOrderIndex) {
        moveUpButton = (
          <LoadingButton
            getPromise={() => this.moveUp(id)}
            text={i18n.t('action.move-up')}
          />
        )
      }

      if (attr.orderIndex !== lastOrderIndex) {
        moveDownButton = (
          <LoadingButton
            getPromise={() => this.moveDown(id)}
            text={i18n.t('action.move-down')}
          />
        )
      }

      const editButton = (
        <button onClick={() => router.setRoute('/data/attributes/edit/' + id)}>
          {i18n.t('action.edit')}
        </button>
      )

      return (
        <span>
          {editButton}
          {' '}
          {moveUpButton}
          {' '}
          {moveDownButton}
        </span>
      )
    }

    return (
      <div>
        {mainMenu}
        <BackToIndexLink />
        <div style={{ marginBottom: '0.5em' }}>
          <button
            id="add-new"
            onClick={() => router.setRoute('/data/attributes/add')}
          >
            {i18n.t('data.attributes.add-new')}
          </button>
        </div>
        <DataEditor
          canAdd={false}
          canDelete={false}
          hideSaveButtons={true}
          fields={fields}
          objs={attrs}
          reloadData={this.reloadData}
          getAdditionalButtons={getAdditionalButtons}
        />
      </div>
    )
  }
}
