import { ReadStream } from 'fs'

import { SuperAgentStatic } from 'superagent'

import { CommonUtils } from './common-utils'

import {
  AttributeDefinitionOps,
  CartOps,
  CorrectionReportOps,
  CustomerOps,
  DataService,
  EarningCategoryOps,
  EarningOps,
  ErrorReportOps,
  ExpenseCategoryOps,
  ExpenseOps,
  InvoiceOps,
  LocationOps,
  MaterialConsumptionReportOps,
  MaterialOrderOps,
  MaterialYearlyReportOps,
  ProductDefinitionOps,
  ProductionReportOps,
  ProductLinkOps,
  ProductOps,
  RawMaterialOps,
  SettingsOps,
  SimpleAttrOps,
  TailorOrderOps,
  TemplateDefinitionOps,
  TemplateOps,
  TransferOps,
  UserCountryOps,
  UserOps,
} from './data-service'

export interface HttpDataService extends DataService {
  setRequest: (request: SuperAgentStatic) => void,
  setTransformUrl: (transform: (url: string) => string) => void,
  setErrorHandler: (handler: (error: Error) => void) => void,
}

export const createHttpDataService = (requestFn: SuperAgentStatic) => {
  let request: SuperAgentStatic = requestFn
  let transformUrl: (url: string) => string = null
  let errorHandler: (error: Error) => void = null

  function httpQuery(method: 'GET' | 'POST' | 'PUT' | 'DELETE', url: string) {
    const fullUrl = transformUrl ? transformUrl(url) : url
    const req = request(method, fullUrl)

    let bodyUsed = false
    let multipartUsed = false

    const helper = {
      body: function (object) {
        if (method === 'GET') {
          req.query(object)
        }
        else {
          // Must be called after prepareRequest, otherwise it may interfere with setting cookies
          req.send(object)
        }

        bodyUsed = true
        return helper
      },
      multipartField: function (fieldName, value) {
        if (value !== undefined) {
          req.field(fieldName, JSON.stringify(value))
        }

        multipartUsed = true
        return helper
      },
      multipartFile: function (fieldName, contents: Blob | ReadStream) {
        // In the browser, contents must be a Blob or File object.
        // In Node, it must be a readable stream or a Buffer.

        if (contents) {
          req.attach(fieldName, contents)
        }

        multipartUsed = true
        return helper
      },
      send: function <T>(): Promise<T> {
        if (bodyUsed && multipartUsed) {
          throw new Error('Cannot mix body and multipart in same request')
        }

        return CommonUtils.requestToPromise(req).catch(errorHandler) as any
      },
    }

    return helper
  }

  function get<T>(url, params?): Promise<T> {
    return httpQuery('GET', url).body(params).send()
  }

  function post<T>(url, params?): Promise<T> {
    return httpQuery('POST', url).body(params).send()
  }

  function put<T>(url, params?): Promise<T> {
    return httpQuery('PUT', url).body(params).send()
  }

  function del(url, params?): Promise<void> {
    return httpQuery('DELETE', url).body(params).send()
  }

  function getUserOps() {
    const url = '/api/data/users'

    const ops: UserOps = {
      getAll: function () {
        return get(url)
      },

      getUsernames: function () {
        return get(url, { usernames: true })
      },

      create: function (obj) {
        return post(url, { obj })
      },

      update: function (id, obj, oldPassword) {
        return put(url + '/' + id, { obj, oldPassword })
      },

      deactivate: function (id) {
        return put(url + '/deactivate/' + id)
      },

      activate: function (id) {
        return put(url + '/activate/' + id)
      },

      delete: function (id) {
        return del(url + '/' + id)
      },
    }

    return ops
  }

  function getUserCountryOps() {
    const url = '/api/data/user-countries'

    const ops: UserCountryOps = {
      getAll: function () {
        return get(url)
      },

      create: function (obj) {
        return post(url, { obj })
      },

      update: function (id, obj) {
        return put(url + '/' + id, { obj })
      },

      delete: function (id) {
        return del(url + '/' + id)
      },
    }

    return ops
  }

  function getCategoryOps() {
    const url = '/api/data/categories'

    const ops: SimpleAttrOps = {
      getAll: function () {
        return get(url)
      },

      create: function (obj) {
        return post(url, { obj })
      },

      update: function (id, obj) {
        return put(url + '/' + id, { obj })
      },

      delete: function (id) {
        return del(url + '/' + id)
      },
    }

    return ops
  }

  function getMaterialCategoryOps() {
    const url = '/api/data/material-categories'

    const ops: SimpleAttrOps = {
      getAll: function () {
        return get(url)
      },

      create: function (obj) {
        return post(url, { obj })
      },

      update: function (id, obj) {
        return put(url + '/' + id, { obj })
      },

      delete: function (id) {
        return del(url + '/' + id)
      },
    }

    return ops
  }

  function getMaterialProducerOps() {
    const url = '/api/data/material-producers'

    const ops: SimpleAttrOps = {
      getAll: function () {
        return get(url)
      },

      create: function (obj) {
        return post(url, { obj })
      },

      update: function (id, obj) {
        return put(url + '/' + id, { obj })
      },

      delete: function (id) {
        return del(url + '/' + id)
      },
    }

    return ops
  }

  function getMaterialWidthOps() {
    const url = '/api/data/material-widths'

    const ops: SimpleAttrOps = {
      getAll: function () {
        return get(url)
      },

      create: function (obj) {
        return post(url, { obj })
      },

      update: function (id, obj) {
        return put(url + '/' + id, { obj })
      },

      delete: function (id) {
        return del(url + '/' + id)
      },
    }

    return ops
  }

  function getSettingsOps() {
    const url = '/api/data/settings'

    const ops: SettingsOps = {
      get: function () {
        return get(url)
      },

      set: function (obj) {
        return put(url, { obj })
      },
    }

    return ops
  }

  function getLocationOps() {
    const url = '/api/data/locations'

    const ops: LocationOps = {
      getAll: function () {
        return get(url)
      },

      getByType: function (type) {
        return get(url, { type })
      },

      getById: function (id) {
        return get(url + '/' + id)
      },

      create: function (obj) {
        return post(url, { obj })
      },

      update: function (id, obj) {
        return put(url + '/' + id, { obj })
      },

      delete: function (id) {
        return del(url + '/' + id)
      },
    }

    return ops
  }

  function getAttributeDefinitionOps() {
    const url = '/api/data/attribute-definitions'

    const ops: AttributeDefinitionOps = {
      getAll: function () {
        return get(url)
      },

      create: function (obj) {
        return post(url, { obj })
      },

      update: function (id, obj) {
        return put(url + '/' + id, { obj })
      },

      moveDown: function (id) {
        return put(url + '/' + id + '/move-down')
      },

      getUsage: function (id) {
        return get(url + '/' + id + '/usage')
      },
    }

    return ops
  }

  function getRawMaterialOps() {
    const url = '/api/data/raw-materials'

    const ops: RawMaterialOps = {
      getAll: function (includeDeleted: boolean = false) {
        return get(url + `?includeDeleted=${includeDeleted}`)
      },

      create: function (obj) {
        return post(url, { obj })
      },

      update: function (id, obj, removePhoto, photoBlob) {
        return httpQuery('POST', url + '/' + id)
          .multipartField('obj', obj)
          .multipartField('removePhoto', removePhoto)
          .multipartFile('photo', photoBlob)
          .send()
      },

      delete: function (id) {
        return del(url + '/' + id)
      },

      addToAmount: function (id, amountToAdd, newPrice?) {
        return put(url + '/' + id + '/amount', { amountToAdd, newPrice })
      },

      cutAmount: function (id, amountToCut, note) {
        return del(url + '/' + id + '/amount', { amountToCut, note })
      },
    }

    return ops
  }

  function getTemplateDefinitionOps() {
    const url = '/api/data/template-definitions'

    const ops: TemplateDefinitionOps = {
      getAll: function () {
        return get(url)
      },

      create: function (obj) {
        return post(url, { obj })
      },

      update: function (id, obj) {
        return put(url + '/' + id, { obj })
      },

      delete: function (id) {
        return del(url + '/' + id)
      },

      getAttributeUsage: function (id) {
        return get(url + '/' + id + '/attribute-usage')
      },
    }

    return ops
  }

  function getProductDefinitionOps() {
    const url = '/api/data/product-definitions'

    const ops: ProductDefinitionOps = {
      getAll: function () {
        return get(url)
      },

      getNames: function () {
        return get(url, { names: true })
      },

      getNonCustom: function () {
        return get(url, { nonCustom: true })
      },

      getByTemplate: function (template) {
        return get(url, { template })
      },

      getByInventoryLocation: function (loc) {
        return get(url, { location: loc })
      },

      create: function (obj) {
        return post(url, { obj })
      },

      update: function (id, obj) {
        return put(url + '/' + id, { obj })
      },

      delete: function (id) {
        return del(url + '/' + id)
      },

      getAttributeUsage: function (id) {
        return get(url + '/' + id + '/attribute-usage')
      },
    }

    return ops
  }

  function getProductLinkOps() {
    const url = '/api/data/product-links'

    const ops: ProductLinkOps = {
      getAll: function () {
        return get(url)
      },
    }

    return ops
  }

  function getTemplateOps() {
    const url = '/api/data/templates'

    const ops: TemplateOps = {
      getAll: function () {
        return get(url)
      },

      getByLocation: function (loc) {
        return get(url, { location: loc })
      },

      create: function (obj) {
        return post(url, { obj })
      },

      update: function (id, obj) {
        return put(url + '/' + id, { obj })
      },

      archive: function (id) {
        return put(url + '/archive/' + id)
      },

      delete: function (id) {
        return del(url + '/' + id)
      },

      addToAmount: function (id, amountToAdd) {
        return put(url + '/' + id + '/amount', { amountToAdd })
      },

      transform: function (templateId, productDefinitionId, attributes, amount, orderId) {
        const params = {
          productDefinitionId,
          attributes,
          amount,
          orderId,
        }

        return post(url + '/' + templateId + '/transformations', params)
      },
    }

    return ops
  }

  function getProductOps() {
    const url = '/api/data/products'

    const ops: ProductOps = {
      getAll: function () {
        return get(url)
      },

      getByLocation: function (loc) {
        return get(url, { location: loc })
      },

      create: function (obj) {
        return post(url, { obj })
      },

      createCustom: function (definition, item) {
        return post(url + '/custom', { definition, item })
      },

      update: function (id, obj, definition) {
        return put(url + '/' + id, { obj, definition })
      },

      addToAmount: function (id, amountToAdd) {
        return put(url + '/' + id + '/amount', { amountToAdd })
      },

      setRawMaterial: function (id, rawMaterialId) {
        return put(url + '/' + id + '/material', { rawMaterialId })
      },

      convertToCustom: function (id) {
        return put(url + '/' + id + '/convert')
      },
    }

    return ops
  }

  function getCartOps() {
    const url = '/api/data/carts'

    const ops: CartOps = {
      getContents: function (loc) {
        return get(url, { location: loc })
      },

      getDetailedContents: function (loc) {
        return get(url + '/detailed', { location: loc })
      },

      getCount: function () {
        return get(url + '/count')
      },

      addAmount: function (productId, amountToAdd) {
        return post(url + '/amount', { productId, amountToAdd })
      },

      editAmount: function (productId, amount) {
        return put(url + '/amount', { productId, amount })
      },

      getLocations: function () {
        return get(url + '/locations')
      },
    }

    return ops
  }

  function getInvoiceOps() {
    const url = '/api/data/invoices'

    const ops: InvoiceOps = {
      getAll: function () {
        return get(url)
      },

      getArchived: function () {
        return get(url + '/archive')
      },

      getById: function (id) {
        return get(url + '/' + id)
      },

      getDetailed: function (id) {
        return get(url + '/' + id + '/detailed')
      },

      confirm: function (invoice) {
        return post(url, { invoice })
      },

      correct: function (
        id, entries, customerName, customerContact, note, book, shipping, isExport,
        actualPayments, guideCommission, marraCashCard,
      ) {
        return put(url + '/' + id, {
          entries,
          customerName,
          customerContact,
          note,
          book,
          shipping,
          export: isExport,
          actualPayments,
          guideCommission,
          marraCashCard,
        })
      },

      undo: function (id) {
        return del(url + '/' + id)
      },

      archiveYear: function (year) {
        return put(url + '/archive-year/' + year)
      },

      dearchiveYear: function (year) {
        return put(url + '/dearchive-year/' + year)
      },
    }

    return ops
  }

  function getCustomerOps() {
    const url = '/api/data/customers'

    const ops: CustomerOps = {
      getAll: function () {
        return get(url)
      },

      update: function (id, contact) {
        return put(url + '/' + id, { contact })
      },

      delete: function (id) {
        return del(url + '/' + id)
      },
    }

    return ops
  }

  function getTransferOps() {
    const url = '/api/data/transfers'

    const ops: TransferOps = {
      getAll: function (filters) {
        return get(url, { filters: JSON.stringify(filters) })
      },

      getById: function (id) {
        return get(url + '/' + id)
      },

      create: function (fromLoc, toLoc, entries, clearCart) {
        return post(url, { from: fromLoc, to: toLoc, entries, clearCart })
      },

      update: function (id, entries) {
        return put(url + '/' + id, { entries })
      },

      send: function (id) {
        return post(url + '/' + id + '/send')
      },

      delete: function (id) {
        return del(url + '/' + id)
      },

      accept: function (id, corrections) {
        return post(url + '/' + id + '/accept', { corrections })
      },
    }

    return ops
  }

  function getProductionReportOps() {
    const url = '/api/data/production-report'

    const ops: ProductionReportOps = {
      getAll: function () {
        return get(url)
      },

      update: function (id, entry, attributes, prodName, orderId, prices) {
        return put(url + '/' + id, {
          entry,
          attributes,
          name: prodName,
          orderId,
          prices,
        })
      },

      undo: function (id) {
        return del(url + '/' + id)
      },
    }

    return ops
  }

  function getMaterialConsumptionReportOps() {
    const url = '/api/data/material-consumption-report'

    const ops: MaterialConsumptionReportOps = {
      getAll: function () {
        return get(url)
      },

      update: function (id, obj) {
        return put(url + '/' + id, { obj })
      },

      undo: function (id) {
        return del(url + '/' + id)
      },
    }

    return ops
  }

  function getCorrectionReportOps() {
    const url = '/api/data/correction-report'

    const ops: CorrectionReportOps = {
      getAll: function () {
        return get(url)
      },
    }

    return ops
  }

  function getErrorReportOps() {
    const url = '/api/data/error-report'

    const ops: ErrorReportOps = {
      getServiceErrors: function () {
        return get(url + '/service')
      },

      hideServiceError: function (id) {
        return del(url + '/service/' + id)
      },

      getUiErrors: function () {
        return get(url + '/ui')
      },

      hideUiError: function (id) {
        return del(url + '/ui/' + id)
      },

      getFailedTransactions: function () {
        return get(url + '/transactions')
      },

      hideFailedTransaction: function (id) {
        return del(url + '/transactions/' + id)
      },
    }

    return ops
  }

  function getExpenseOps() {
    const url = '/api/data/accounting/expenses'

    const ops: ExpenseOps = {
      getAll: function () {
        return get(url)
      },

      create: function (obj) {
        return post(url, { obj })
      },

      update: function (id, obj) {
        return put(url + '/' + id, { obj })
      },

      delete: function (id) {
        return del(url + '/' + id)
      },

      summary: function (start, end) {
        return get(url + '/summary/' + start + '/' + end)
      },
    }

    return ops
  }

  function getExpenseCategoryOps() {
    const url = '/api/data/accounting/expense/categories'

    const ops: ExpenseCategoryOps = {
      getAll: function () {
        return get(url)
      },

      create: function (labelEn, labelFr, parentId) {
        const obj: any = {
          labels: {
            en: labelEn,
            fr: labelFr,
          },
        }
        if (parentId) {
          obj.parentId = parentId
        }
        return post(url, { obj })
      },

      update: function (id, obj) {
        return put(url + '/' + id, { obj })
      },

      delete: function (id) {
        return del(url + '/' + id)
      },
    }

    return ops
  }

  function getEarningOps() {
    const url = '/api/data/accounting/earnings'

    const ops: EarningOps = {
      getAll: function () {
        return get(url)
      },

      create: function (obj) {
        return post(url, { obj })
      },

      update: function (id, obj) {
        return put(url + '/' + id, { obj })
      },

      delete: function (id) {
        return del(url + '/' + id)
      },

      summary: function (start, end) {
        return get(url + '/summary/' + start + '/' + end)
      },
    }

    return ops
  }

  function getEarningCategoryOps() {
    const url = '/api/data/accounting/earning/categories'

    const ops: EarningCategoryOps = {
      getAll: function () {
        return get(url)
      },

      create: function (labelEn, labelFr) {
        const obj = {
          labels: {
            en: labelEn,
            fr: labelFr,
          },
        }
        return post(url, { obj })
      },

      update: function (id, obj) {
        return put(url + '/' + id, { obj })
      },

      delete: function (id) {
        return del(url + '/' + id)
      },
    }

    return ops
  }

  function getMaterialYearlyReportOps() {

    const url = '/api/data/material-yearly-reports';

    const ops: MaterialYearlyReportOps = {
      getAll: function (filters) {
        return get(url, { filters: JSON.stringify(filters) });
      }
    }

    return ops;

  }

  function getMaterialOrderOps() {

    const url = '/api/data/material-orders';

    const ops: MaterialOrderOps = {
      getAll: function () {
        return get(url);
      },
      getById: function (id) {
        return get(url + '/' + id)
      },
      create: function (obj) {
        return post(url, { obj });
      },
      update: function (id, obj) {
        return put(url + '/' + id, { obj });
      },
      delete: function (id) {
        return del(url + '/' + id);
      },
      updateMaterialStock: function (id) {
        return put(url + '/' + id + '/updateMaterialStock')
      }
    };

    return ops;

  }

  function getTailorOrderOps() {

    const url = '/api/data/tailor-orders';

    const ops: TailorOrderOps = {
      getAll: function () {
        return get(url);
      },
      create: function (obj) {
        return post(url, { obj });
      },
      update: function (id, obj) {
        return put(url + '/' + id, { obj });
      },
      getOrderComments: function (orderId) {
        return get(url + '/' + orderId + '/comments');
      },
      createComment: function (orderId, obj) {
        return post(url + '/' + orderId + '/comments', { obj })
      },
      updateComment: function (orderId, commentId, obj) {
        return put(url + '/' + orderId + '/comments/' + commentId, { obj })
      },
      deleteComment: function (orderId, commentId) {
        return del(url + '/' + orderId + '/comments/' + commentId)
      }
    }

    return ops;

  }

  const dataService: HttpDataService = {
    setRequest: (newRequest) => {
      request = newRequest
    },
    setTransformUrl: (func) => {
      transformUrl = func
    },
    setErrorHandler: (func) => {
      errorHandler = func
    },

    Users: getUserOps(),
    UserCountries: getUserCountryOps(),
    Categories: getCategoryOps(),
    MaterialCategories: getMaterialCategoryOps(),
    MaterialProducers: getMaterialProducerOps(),
    MaterialWidths: getMaterialWidthOps(),
    Settings: getSettingsOps(),
    Locations: getLocationOps(),
    AttributeDefinitions: getAttributeDefinitionOps(),
    RawMaterials: getRawMaterialOps(),
    TemplateDefinitions: getTemplateDefinitionOps(),
    ProductDefinitions: getProductDefinitionOps(),
    ProductLinks: getProductLinkOps(),
    Templates: getTemplateOps(),
    Products: getProductOps(),
    Carts: getCartOps(),
    Invoices: getInvoiceOps(),
    Customers: getCustomerOps(),
    Transfers: getTransferOps(),
    ProductionReport: getProductionReportOps(),
    MaterialConsumptionReport: getMaterialConsumptionReportOps(),
    CorrectionReport: getCorrectionReportOps(),
    ErrorReport: getErrorReportOps(),
    Expenses: getExpenseOps(),
    ExpenseCategories: getExpenseCategoryOps(),
    Earnings: getEarningOps(),
    EarningCategories: getEarningCategoryOps(),
    MaterialYearlyReports: getMaterialYearlyReportOps(),
    MaterialOrders: getMaterialOrderOps(),
    TailorOrders: getTailorOrderOps()
  }

  return dataService
}
