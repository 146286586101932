import React = require('react')

import { AccountingUtils } from '../common/accounting-utils'
import { CommonUtils } from '../common/common-utils'
import { Expense } from '../common/data-expenses'
import { getDataService } from '../common/data-service'
import { EventBus } from '../common/event-bus'
import { AugmentedCategory } from '../common/expense-category-path-builder'
import { i18n } from '../common/i18n'
import { AccountingMenu } from './accounting-menu'
import { EditExpenseModal, EditExpenseModalProps } from './edit-expense-modal'
import { ExpenseCategoryFilter } from './expense-category-filter'
import { Filter, FilterManager } from './filter'
import { MainMenu } from './main-menu'
import { TableButton } from './table-button'
import { Column, UiUtils } from './ui-utils'
import { User } from './user'
import { Utils } from './utils'

interface Row extends Omit<Expense, '_id' | 'expenseCategory'> {
  id: number,
  expenseCategoryId: string,
  expenseCategory?: string,
  err?: string,
}

interface ListState {
  expenseCategories: AugmentedCategory[],
  expenses: Expense[],
}

const { EditButton, DeleteButton } = TableButton

class List extends React.Component<Record<string, never>, ListState> {
  state = {
    expenseCategories: null as AugmentedCategory[] | null,
    expenses: null as Expense[] | null,
  }

  _isMounted = false // Used by Filter.registerEvent

  componentDidMount() {
    this._isMounted = true
    Filter.registerEvent(this)

    const DataService = getDataService()
    Promise.all([
      DataService.ExpenseCategories.getAll(),
      DataService.Expenses.getAll(),
    ])
    .then(([expenseCategories, expenses]) => {
      this.setState({
        expenseCategories,
        expenses,
      })
      return null
    })
  }

  componentWillUnmount() {
    this._isMounted = false
    Filter.unregisterEvent(this)
  }

  loadExpenses = () => {
    return getDataService().Expenses.getAll()
    .then((expenses) => {
      this.setState({ expenses })
    })
  }

  getFilterManager = (): FilterManager<Expense> => {
    const { expenseCategories } = this.state
    const language = User.getLanguage()
    const filterConf = {
      time: {
        labelKey: 'common.date',
        type: 'date-range',
      },
      expenseCategory: {
        labelKey: 'common.category',
        type: 'custom',
        options: expenseCategories.map(function(expenseCategory) {
          return {
            value: expenseCategory._id,
            label: expenseCategory.paths[language],
          }
        }),
        FormClass: ExpenseCategoryFilter.Form,
        SummaryClass: ExpenseCategoryFilter.Summary,
        filterFn: function(expense, objFilterValues) {
          const filterValues = Object.keys(objFilterValues)
          return filterValues.some(function(expenseCategoryId) {
            return AccountingUtils.expenseCategoryIdStartsWith(expense.expenseCategory, expenseCategoryId)
          })
        },
        isFiltered: function(filterValues) {
          return Object.keys(filterValues).length !== 0
        },
        clearFn: function() {
          return {}
        },
      },
    }
    return Filter.createManager('expenses', filterConf, this.state.expenses)
  }

  getColumnConf = (): Column<Row>[] => {
    return [
      {
        id: 'id',
        header: '#',
      },
      {
        id: 'time',
        filterFieldName: 'time',
        header: i18n.t('common.date'),
        getCellContents: function(rowData) {
          const date = new Date(rowData.time)
          return CommonUtils.utcDateDMY(date)
        },
      },
      {
        id: 'description',
        header: i18n.t('common.description'),
      },
      {
        id: 'expenseCategory',
        filterFieldName: 'expenseCategory',
        header: i18n.t('common.category'),
        getCellProperties: function(rowData) {
          let className = ''
          if (rowData.err) {
            className = 'text-red-bold'
          }

          return { className }
        },
        getCellContents: function(rowData) {
          if (rowData.expenseCategory) {
            return rowData.expenseCategory
          } else {
            return rowData.err
          }
        },
      },
      {
        id: 'amount',
        header: i18n.t('common.amount'),
        getCellContents: function(rowData) {
          const amount = CommonUtils.formatDecimal(rowData.amount)
          return amount + ' ' + AccountingUtils.defaultCurrency
        },
      },
      {
        id: 'action',
        header: i18n.t('action.action'),
        getCellContents: this.renderActions,
      },
    ]
  }

  getRowsData = (filterManager: FilterManager<Expense>) => {
    const { expenseCategories } = this.state
    const expenses = filterManager.getFiltered()
    return expenses.map(function(expense) {
      const { _id, expenseCategory: expenseCategoryId, ...rest } = expense
      const row: Row = { ...rest, id: _id, expenseCategoryId }

      const language = User.getLanguage()

      try {
        const expenseCategory = CommonUtils.findById(expenseCategories, expense.expenseCategory)
        row.expenseCategory = expenseCategory.paths[language]
      } catch (err) {
        row.err = i18n.t('accounting.expenses.no-expense-category-error', expense.expenseCategory)
      }

      return row
    })
  }

  renderEditButton = (rowData) => {
    return (
      <EditButton
        onClick={function() {
          EventBus.fire('open-modal', { modalId: 'edit-expense-' + rowData.id })
        }}
      />
    )
  }

  renderEditModal = (rowData) => {
    const modalProps: EditExpenseModalProps = {
      modalId: 'edit-expense-' + rowData.id,
      expense: {
        _id: rowData.id,
        time: rowData.time,
        description: rowData.description,
        amount: rowData.amount,
        expenseCategory: rowData.expenseCategoryId,
      },
      expenseCategories: this.state.expenseCategories,
      afterSave: this.loadExpenses,
    }
    return <EditExpenseModal {...modalProps} />
  }

  renderDeleteButton = (rowData) => {
    return (
      <DeleteButton
        onClick={() => {
          Utils.confirmTr('confirm.delete.expense').then((confirmed) => {
            if (confirmed) {
              return getDataService().Expenses.delete(rowData.id).then(this.loadExpenses)
            }
          })
        }}
      />
    )
  }

  renderActions = (rowData) => {
    return (
      <div>
        {this.renderEditButton(rowData)}
        {this.renderDeleteButton(rowData)}
        {this.renderEditModal(rowData)}
      </div>
    )
  }

  renderAddButton = () => {
    const btnProps = {
      id: 'btn-add-new',
      onClick: function() {
        EventBus.fire('open-modal', { modalId: 'add-modal' })
      },
    }
    const modalProps: EditExpenseModalProps = {
      modalId: 'add-modal',
      expenseCategories: this.state.expenseCategories,
      afterSave: this.loadExpenses,
    }
    return (
      <div className="button-container">
        <button {...btnProps}>
          {i18n.t('accounting.expenses.add-expense')}
        </button>
        <EditExpenseModal {...modalProps} />
      </div>
    )
  }

  renderMainMenu = () => {
    return <MainMenu key="main" activeTab="accounting" />
  }

  renderAccountingMenu = () => {
    return <AccountingMenu activeTab="expenses" />
  }

  renderBody = () => {
    if (!this.state.expenses) {
      return <p>{i18n.t('common.loading')}</p>
    } else {
      const filterManager = this.getFilterManager()
      const columnConf = this.getColumnConf()
      const rowsData = this.getRowsData(filterManager)
      return (
        <div>
          <div>
            {this.renderAddButton()}
          </div>
          {filterManager.getSummary()}
          {UiUtils.getTable(columnConf, rowsData, { filterManager })}
        </div>
      )
    }
  }

  render() {
    return (
      <div>
        {this.renderMainMenu()}
        {this.renderAccountingMenu()}
        {this.renderBody()}
      </div>
    )
  }
}

export const Expenses = {
  List,
}
