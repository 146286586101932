import { ObjectId } from 'mongodb'
import React = require('react')

import { Access } from '../common/access'
import { CombinationDetails, CommonUtils } from '../common/common-utils'
import { AttributeDefinition } from '../common/data-attribute-defs'
import { Location } from '../common/data-locations'
import { Category } from '../common/data-misc'
import { ProductDefinition } from '../common/data-product-defs'
import { Production } from '../common/data-production-report'
import { Product } from '../common/data-products'
import { getDataService } from '../common/data-service'
import { TemplateDefinition } from '../common/data-template-defs'
import { Template } from '../common/data-templates'
import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { AttributeCombination } from '../common/types'
import { EditProductionReportModal } from './edit-production-report-modal'
import { Filter, FilterManager } from './filter'
import { MainMenu } from './main-menu'
import { ReportUtils } from './report-utils'
import { ReportsMenu } from './reports-menu'
import { Column, ExcelMetadata, UiUtils } from './ui-utils'
import { User } from './user'
import { Utils } from './utils'

interface Row {
  id: ObjectId,
  entry: Production,
  product: Product,
  template: Template,
  productDefinition: ProductDefinition,
  templateDefinition: TemplateDefinition,
  templateLocation: Location,
  location: Location,
  userTime: Date,
  amount: number,
  tplUnitCost: number,
  tplCost: number,
  prodUnitPrice: number,
  prodValue: number,
  name: string,
  category: string,
  attributes: Record<string, CombinationDetails>,
  locationName: string,
  username: string,
}

interface ListState {
  loaded: boolean,
  printView: boolean,
  attributeDefinitions: AttributeDefinition[],
  categories: Category[],
  entries: Production[],
  locations: Location[],
  productDefinitions: ProductDefinition[],
  products: Product[],
  templateDefinitions: TemplateDefinition[],
  templates: Template[],
  usernames: { _id: string, username: string }[],
}

class List extends React.Component<Record<string, never>, ListState> {
  state = {
    loaded: false,
    printView: false,
    attributeDefinitions: undefined as AttributeDefinition[] | undefined,
    categories: undefined as Category[] | undefined,
    entries: undefined as Production[] | undefined,
    locations: undefined as Location[] | undefined,
    productDefinitions: undefined as ProductDefinition[] | undefined,
    products: undefined as Product[] | undefined,
    templateDefinitions: undefined as TemplateDefinition[] | undefined,
    templates: undefined as Template[] | undefined,
    usernames: undefined as { _id: string, username: string }[] | undefined,
  }

  _isMounted = false

  componentDidMount() {
    this._isMounted = true
    const DataService = getDataService()
    Filter.registerEvent(this)

    Promise.all([
      DataService.ProductionReport.getAll(),
      DataService.Locations.getAll(),
      DataService.Categories.getAll(),
      DataService.AttributeDefinitions.getAll(),
      DataService.ProductDefinitions.getAll(),
      DataService.TemplateDefinitions.getAll(),

      // TODO: load dynamically only the needed products and templates?
      DataService.Products.getAll(),
      DataService.Templates.getAll(),

      DataService.Users.getUsernames(),
    ])
    .then(([
      entries,
      locations,
      categories,
      attributeDefinitions,
      productDefinitions,
      templateDefinitions,
      products,
      templates,
      usernames,
    ]) => {
      if (!this._isMounted) {
        return
      }

      const filteredAttributeDefinitions = attributeDefinitions.filter(
        CommonUtils.attributeContextFilters.products,
      )

      this.setState(
        {
          loaded: true,
          entries,
          locations,
          categories,
          attributeDefinitions: filteredAttributeDefinitions,
          productDefinitions,
          templateDefinitions,
          products,
          templates,
          usernames,
        },
        EventBus.fireFunc('production-report-rendered'),
      )
    })
  }

  componentWillUnmount() {
    this._isMounted = false
    Filter.unregisterEvent(this)
  }

  reloadEntries = () => {
    const DataService = getDataService()
    this.setState({ loaded: false })

    // Also need to reload products and product definitions as these may have changed too.
    Promise.all([
      DataService.ProductionReport.getAll(),
      DataService.ProductDefinitions.getAll(),
      DataService.Products.getAll(), // TODO: load only needed?
    ])
    .then(([entries, productDefinitions, products]) => {
      if (!this._isMounted) {
        return
      }

      this.setState(
        {
          loaded: true,
          entries,
          productDefinitions,
          products,
        },
        EventBus.fireFunc('production-report-rendered'),
      )
    })

    return null
  }

  getFilterConf = () => {
    const language = User.getLanguage()
    const names = Utils.getSortedDefinitionNames(this.state.productDefinitions)

    // TODO: sort users

    const filterConf = {
      name: {
        type: 'predefined',
        labelKey: 'common.name',
        options: names.map(function(prodName) {
          return { value: prodName, label: prodName }
        }),
        getField: (entry) => {
          const product: Product = CommonUtils.findById<any>(this.state.products, entry.product)
          const productDefinition: ProductDefinition = CommonUtils.findById(this.state.productDefinitions, product.definition)
          return productDefinition.name
        },
      },
      category: Utils.getCategoryFilterConf(
        this.state.categories,
        language,
        true,
        (entry) => {
          const product: Product = CommonUtils.findById<any>(this.state.products, entry.product)
          return CommonUtils.findById(this.state.productDefinitions, product.definition)
        },
      ),
      time: { type: 'date-range', labelKey: 'reports.date-time' },
      user: {
        type: 'predefined',
        labelKey: 'common.user',
        options: this.state.usernames.map(function(userFields) {
          return {
            value: userFields._id,
            label: userFields.username,
          }
        }),
      },
    }

    this.state.attributeDefinitions.forEach((attribute) => {
      const comboSources = []

      this.state.productDefinitions.forEach((definition) => {
        if (definition.isCustom && attribute.textForCustom) {
          // Omit custom product freetext attributes from filters
        }
        else if (attribute._id in definition.attributeCombinations || !attribute.optional) {
          const combinations = definition.attributeCombinations[attribute._id] as (AttributeCombination | number)[]

          combinations.forEach((comboParam) => {
            const combination = Utils.ensureCombination(
              comboParam, attribute, definition, this.state.templateDefinitions,
            )

            const comboSource = { values: combination.values, category: definition.category }
            comboSources.push(comboSource)
          })
        }
      })

      filterConf['attr-' + attribute._id] = {
        type: 'predefined-combo',
        label: attribute.names[language],
        options: Utils.getAttributeFilterOptions(attribute, language, comboSources),
        getField: (entry) => {
          const product = CommonUtils.findById(this.state.products, entry.product)

          return Utils.getCombinationValues(
            product, attribute, this.state.productDefinitions, this.state.templateDefinitions,
          )
        },
      }
    })

    return filterConf
  }

  getColumnConf = () => {
    const columnConf: Column<Row>[] = []

    columnConf.push({
      id: 'time',
      header: i18n.t('reports.date-time'),
      filterFieldName: 'time',
      excelWidth: 16,
      getCellProperties: function() {
        return { style: { whiteSpace: 'nowrap' } }
      },
      getCellContents: function(rowData) {
        return CommonUtils.utcDateTime(rowData.userTime)
      },
      getTotalRowContents: function() {
        return i18n.t('common.total')
      },
      getExcelValue: function(rowData) {
        return ReportUtils.toExcelTime(rowData.userTime)
      },
      getExcelMetadata: function(_rowData, context) {
        return { style: context.dateTimeStyle.id }
      },
    })

    columnConf.push({
      id: 'amount',
      header: i18n.t('common.amount'),
      excelWidth: 10,
      getCellContents: function(rowData) {
        return rowData.amount
      },
      getTotalRowContents: function(context) {
        return context.totalAmount
      },
    })

    columnConf.push({
      id: 'template-unit-cost',
      header: i18n.t('inventory.template-unit-cost'),
      excelWidth: 20,
      getCellContents: function(rowData) {
        return rowData.template ? CommonUtils.formatDecimal(rowData.tplUnitCost, true) + ' MAD' : ''
      },
      getExcelValue: function(rowData) {
        return rowData.tplUnitCost
      },
    })

    columnConf.push({
      id: 'template-cost',
      header: i18n.t('inventory.template-total-cost'),
      excelWidth: 15,
      getCellContents: function(rowData) {
        return rowData.template ? CommonUtils.formatDecimal(rowData.tplCost, true) + ' MAD' : ''
      },
      getExcelValue: function(rowData, context) {
        if (!rowData.template) {
          return ''
        }

        return (
          context.columnLetters['amount'] + context.rowIndex + '*' +
          context.columnLetters['template-unit-cost'] + context.rowIndex
        )
      },
      getExcelMetadata: function(rowData, context) {
        const metadata: ExcelMetadata = { style: context.bodyStyle.id }

        if (rowData.template) {
          metadata.type = 'formula'
        }

        return metadata
      },
      getTotalRowContents: function(context) {
        return CommonUtils.formatDecimal(context.totalTemplateValue, true) + ' MAD'
      },
      getExcelTotalValue: function(context) {
        return context.totalTemplateValue
      },
    })

    columnConf.push({
      id: 'product-unit-price',
      header: i18n.t('inventory.product-unit-price'),
      excelWidth: 20,
      getCellContents: function(rowData) {
        return CommonUtils.formatDecimal(rowData.prodUnitPrice, true) + ' MAD'
      },
      getExcelValue: function(rowData) {
        return rowData.prodUnitPrice
      },
    })

    columnConf.push({
      id: 'product-value',
      header: i18n.t('inventory.product-value'),
      excelWidth: 15,
      getCellContents: function(rowData) {
        return CommonUtils.formatDecimal(rowData.prodValue, true) + ' MAD'
      },
      getExcelValue: function(_rowData, context) {
        return (
          context.columnLetters['amount'] + context.rowIndex + '*' +
          context.columnLetters['product-unit-price'] + context.rowIndex
        )
      },
      getExcelMetadata: function(_rowData, context) {
        return { type: 'formula', style: context.bodyStyle.id }
      },
      getTotalRowContents: function(context) {
        return CommonUtils.formatDecimal(context.totalProductValue, true) + ' MAD'
      },
      getExcelTotalValue: function(context) {
        return context.totalProductValue
      },
    })

    columnConf.push({
      id: 'name',
      header: i18n.t('inventory.product'),
      excelWidth: 25,
      filterFieldName: 'name',
      getCellContents: function(rowData) {
        return rowData.name
      },
    })

    columnConf.push({
      id: 'category',
      header: i18n.t('common.category'),
      excelWidth: 22,
      filterFieldName: 'category',
      getCellContents: function(rowData) {
        return rowData.category
      },
    })

    this.state.attributeDefinitions.forEach(function(attr) {
      columnConf.push({
        id: 'attr-' + attr._id,
        header: attr.names[User.getLanguage()],
        filterFieldName: 'attr-' + attr._id,
        excelWidth: 20,
        getCellContents: function(rowData) {
          return rowData.attributes[attr._id].label
        },
      })
    })

    columnConf.push({
      id: 'location',
      header: i18n.t('common.location'),
      excelWidth: 25,
      getCellContents: function(rowData) {
        return rowData.locationName
      },
    })

    columnConf.push({
      id: 'user',
      header: i18n.t('common.user'),
      filterFieldName: 'user',
      excelWidth: 15,
      getCellContents: function(rowData) {
        return rowData.username
      },
    })

    if (!this.state.printView) {
      columnConf.push({
        id: 'action',
        header: i18n.t('action.action'),
        includeIf: function(view) {
          return view === 'table' && Access.manageProductionReport(User.getUser())
        },
        getCellProperties: function() {
          return { style: { whiteSpace: 'nowrap' } }
        },
        getCellContents: (rowData) => {
          const modalId = 'edit-production-report-' + rowData.id

          const editButton = (
            <img
              className="table-btn btn-edit"
              title={i18n.t('action.edit')}
              src="img/edit.png"
              onClick={function() {
                EventBus.fire('open-modal', { modalId })
              }}
            />
          )

          const editModal = (
            <EditProductionReportModal
              modalId={modalId}
              entry={rowData.entry}
              product={rowData.product}
              categories={this.state.categories}
              attributeDefinitions={this.state.attributeDefinitions}
              productDefinition={rowData.productDefinition}
              templateDefinitions={this.state.templateDefinitions}
              templateDefinition={rowData.templateDefinition}
              templateLocation={rowData.templateLocation}
              location={rowData.location}
              getUsername={this.getUsername}
              afterSave={this.reloadEntries}
            />
          )

          const undoButton = (
            <img
              className="table-btn btn-undo"
              title={i18n.t('action.undo')}
              src="img/undo.png"
              onClick={() => {
                Utils.confirmTr('confirm.undo.entry').then((confirmed) => {
                  if (confirmed) {
                    return getDataService().ProductionReport.undo(rowData.id).then(this.reloadEntries)
                  }
                })
              }}
            />
          )

          return (
            <span>
              {editButton}
              {undoButton}
              {editModal}
            </span>
          )
        },
      })
    }

    return columnConf
  }

  getUsername = (userId) => {
    const obj = CommonUtils.findById<any>(this.state.usernames, userId)
    return obj.username
  }

  downloadExcelFile = (rowsData, columnConf, context) => {
    const userTime = CommonUtils.toUserTime(User.getUser().country, CommonUtils.getNow())
    const fileName = 'production-' + CommonUtils.utcDateYMD(userTime) + '.xlsx'

    const workbook = ReportUtils.createExcelReport(rowsData, columnConf, context)
    ReportUtils.downloadExcelFile(fileName, workbook)
  }

  print = () => {
    this.setState({ printView: true }, Utils.print)
  }

  leavePrintMode = () => {
    this.setState({ printView: false })
  }

  getRowsData = (context, filterManager: FilterManager<Production>) => {
    const language = User.getLanguage()

    context.totalAmount = 0
    context.totalTemplateValue = 0
    context.totalProductValue = 0

    const rowsData = filterManager.getFiltered().map((entry): Row => {
      const product = CommonUtils.findById(this.state.products, entry.product)
      const productDefinition = CommonUtils.findById(this.state.productDefinitions, product.definition)
      let template: Template | null = null
      let templateDefinition: TemplateDefinition | null = null
      let templateLocation: Location | null = null

      if (entry.template) {
        template = CommonUtils.findById(this.state.templates, entry.template)

        if (!productDefinition.isCustom && template.definition !== productDefinition.template) {
          throw new Error('Template definition does not match')
        }

        templateDefinition = CommonUtils.findById(
          this.state.templateDefinitions, template.definition,
        )

        templateLocation = CommonUtils.findById(this.state.locations, template.location)
      }

      context.totalAmount += entry.amount
      context.totalProductValue += entry.amount * productDefinition.prices.MAD

      let tplUnitCost: number | null = null
      let tplCost: number | null = null

      if (template) {
        const tailorCost = templateDefinition.tailorCosts.MAD
        const fabricCost = templateDefinition.fabricCosts.MAD
        tplUnitCost = tailorCost + fabricCost
        tplCost = entry.amount * tplUnitCost
        context.totalTemplateValue += tplCost
      }

      const loc = CommonUtils.findById<Location>(this.state.locations, product.location)

      const attributes = CommonUtils.getAttributeDetails(
        product, this.state.attributeDefinitions, productDefinition,
        this.state.templateDefinitions, language,
      )

      return {
        id: entry._id,
        entry,
        product,
        template,
        productDefinition,
        templateDefinition,
        templateLocation,
        location: loc,
        userTime: CommonUtils.toUserTime(User.getUser().country, new Date(entry.time)),
        amount: entry.amount,
        tplUnitCost,
        tplCost,
        prodUnitPrice: productDefinition.prices.MAD,
        prodValue: productDefinition.prices.MAD * entry.amount,
        name: productDefinition.name,
        category: CommonUtils.getCategoryName(productDefinition, this.state.categories, language),
        attributes,
        locationName: loc.names[language],
        username: this.getUsername(entry.user),
      }
    })

    return rowsData
  }

  renderMenus = () => {
    if (!this.state.printView) {
      return [
        <MainMenu key="main" activeTab="reports" />,
        <ReportsMenu key="reports" activeTab="production" />,
      ]
    }
  }

  renderButtons = (rowsData, columnConf, context) => {
    if (this.state.printView) {
      return (
        <button
          id="btn-leave-print"
          className="no-print"
          onClick={this.leavePrintMode}
          style={{ marginBottom: '0.5em' }}
        >
          {i18n.t('common.leave-print-mode')}
        </button>
      )
    }

    const onClickExcel = () => this.downloadExcelFile(rowsData, columnConf, context)

    return (
      <div style={{ margin: '0.5em 0' }}>
        <button id="btn-excel" onClick={onClickExcel}>
          {i18n.t('reports.download-excel-file')}
        </button>
        {' '}
        <button id="btn-print" onClick={this.print}>
          {i18n.t('common.print')}
        </button>
      </div>
    )
  }

  renderPrintHeader = () => {
    if (this.state.printView) {
      return (
        <h3 style={{ fontWeight: 'bold' }}>
          {i18n.t('menu.main.reports')}
          {' - '}
          {i18n.t('menu.reports.production')}
        </h3>
      )
    }
  }

  renderTable = (
    columnConf: Column<Row>[],
    rowsData: Row[],
    context,
    filterManager: FilterManager<Production>,
  ) => {
    return UiUtils.getTable(columnConf, rowsData, {
      tableId: 'tbl-rep-prod',
      context,
      filterManager,
      noItemsText: i18n.t('reports.no-items'),
      noFilteredItemsText: i18n.t('reports.no-filtered-items'),
      printView: this.state.printView,
    })
  }

  render() {
    if (!this.state.loaded) {
      return (
        <div>
          {this.renderMenus()}
          {i18n.t('common.loading')}
        </div>
      )
    }

    const filterManager = Filter.createManager(
      'production-report', this.getFilterConf(), this.state.entries,
    )

    const context = {}
    const rowsData = this.getRowsData(context, filterManager)
    const columnConf = this.getColumnConf()

    return (
      <div>
        {this.renderMenus()}
        {this.renderButtons(rowsData, columnConf, context)}
        {this.renderPrintHeader()}
        {filterManager.getSummary()}
        {this.renderTable(columnConf, rowsData, context, filterManager)}
      </div>
    )
  }
}

export const ProductionReport = {
  List,
}
