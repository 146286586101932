import React = require('react')

import { AccountingUtils } from '../common/accounting-utils'
import { CommonUtils } from '../common/common-utils'
import { getDataService } from '../common/data-service'
import { AugmentedCategory } from '../common/expense-category-path-builder'
import { i18n } from '../common/i18n'
import { LangStr } from '../common/types'
import { AccountingMenu } from './accounting-menu'
import { EmptyObject } from './empty-object'
import { ExpenseCategoriesForest } from './expense-categories-forest'
import { LoadingIcon } from './loading-icon'
import { MainMenu } from './main-menu'
import { TableButton } from './table-button'
import { Utils } from './utils'

interface State {
  expenseCategories: AugmentedCategory[] | null,
}

const { AddButton } = TableButton

const { idSeparator, stubId } = AccountingUtils

class List extends React.Component<EmptyObject, State> {
  state: State = {
    expenseCategories: null,
  }

  componentDidMount() {
    return getDataService().ExpenseCategories.getAll()
    .then(this.setExpenseCategories)
  }

  setExpenseCategories = (expenseCategories: AugmentedCategory[]) => {
    this.setState({ expenseCategories })
  }

  handleExpenseCategoryChange = (id: string, labels: LangStr) => {
    const expenseCategories = CommonUtils.clone(this.state.expenseCategories)
    const expenseCategory = CommonUtils.findById(expenseCategories, id)
    expenseCategory.labels = labels
    this.setExpenseCategories(expenseCategories)
  }

  checkResultSuccess = (res) => {
    if (!res.success) {
      throw new Error(i18n.t('api.error'))
    }
    return res
  }

  updateExpenseCategory = (id: string) => {
    const expenseCategory = CommonUtils.findById(this.state.expenseCategories, id)
    const updateVal = CommonUtils.clone(expenseCategory)
    delete updateVal._id
    delete updateVal.paths
    return getDataService().ExpenseCategories.update(expenseCategory._id, updateVal)
    .then(this.checkResultSuccess)
  }

  saveStub = (id: string) => {
    const expenseCategories = CommonUtils.clone(this.state.expenseCategories)
    const parentId = AccountingUtils.extractExpenseCategoryParentId(id)
    const expenseCategory = CommonUtils.findById(expenseCategories, id)
    const { labels } = expenseCategory
    return getDataService().ExpenseCategories.create(labels.en, labels.fr, parentId)
    .then(this.checkResultSuccess)
    .then((res) => {
      expenseCategory._id = res.id
      this.setExpenseCategories(expenseCategories)
    })
  }

  saveExpenseCategory = (id: string) => {
    return AccountingUtils.isStubId(id) ? this.saveStub(id) : this.updateExpenseCategory(id)
  }

  getExpenseCategoryStub = (parentId: string) => {
    return {
      _id: parentId ? parentId + idSeparator + stubId : stubId,
      labels: { en: '', fr: '' },
    }
  }

  getStubInsertIdx = (parentId: string) => {
    let categoryId = null
    let idx = 0
    while (categoryId !== parentId) {
      categoryId = this.state.expenseCategories[idx]._id
      idx += 1
    }
    let isDescendantOfStubParent = true
    let insertIdx
    while (isDescendantOfStubParent && idx < this.state.expenseCategories.length) {
      categoryId = this.state.expenseCategories[idx]._id
      isDescendantOfStubParent = Utils.startsWith(categoryId, parentId)
      if (!isDescendantOfStubParent) {
        insertIdx = idx
      }
      idx += 1
    }
    insertIdx = insertIdx || idx
    return insertIdx
  }

  addExpenseCategoryStub = (parentId?: string) => {
    const expenseCategories = CommonUtils.clone(this.state.expenseCategories)
    const parentExpenseCategory = CommonUtils.findById(expenseCategories, parentId)
    const stub = this.getExpenseCategoryStub(parentId) as AugmentedCategory // TODO no paths
    if (parentExpenseCategory) {
      const insertIdx = this.getStubInsertIdx(parentId)
      expenseCategories.splice(insertIdx, 0, stub)
    } else {
      expenseCategories.push(stub)
    }
    this.setExpenseCategories(expenseCategories)
  }

  removeExpenseCategoryFromState = (id: string) => {
    const expenseCategories = CommonUtils.clone(this.state.expenseCategories)
    const idx = Utils.findIndexByField<any, '_id'>(expenseCategories, '_id', id)
    expenseCategories.splice(idx, 1)
    this.setExpenseCategories(expenseCategories)
  }

  removeExpenseCategory = (id: string) => {
    if (AccountingUtils.isStubId(id)) {
      this.removeExpenseCategoryFromState(id)
    } else {
      getDataService().ExpenseCategories.delete(id)
      .then(this.checkResultSuccess)
      .then(() => {
        this.removeExpenseCategoryFromState(id)
      })
    }
  }

  hasStub = () => {
    const stub = CommonUtils.findById(this.state.expenseCategories, stubId)
    return stub !== null
  }

  renderAddButton = () => {
    return (
      <AddButton
        disabled={this.hasStub()}
        onClick={() => {
          this.addExpenseCategoryStub()
        }}
      />
    )
  }

  renderTree = () => {
    return (
      <ExpenseCategoriesForest
        expenseCategories={this.state.expenseCategories}
        onChange={this.handleExpenseCategoryChange}
        onSave={this.saveExpenseCategory}
        onDelete={this.removeExpenseCategory}
        onAdd={this.addExpenseCategoryStub}
      />
    )
  }

  renderBody = () => {
    if (!this.state.expenseCategories) {
      return <LoadingIcon />
    } else {
      return (
        <div>
          {this.renderAddButton()}
          {this.renderTree()}
        </div>
      )
    }
  }

  render() {
    return (
      <div>
        <MainMenu key="main" activeTab="accounting" />
        <AccountingMenu activeTab="expense-categories" />
        {this.renderBody()}
      </div>
    )
  }
}

export const ExpenseCategories = {
  List,
}
