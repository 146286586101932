import { LocationOps, SimpleAttrOps, UserCountryOps } from '../common/data-service'
import { Enums } from '../common/enums'
import { i18n } from '../common/i18n'
import { DataEditorField } from './data-editor'
import { ValidationUtils } from './validation-utils'

type Service = SimpleAttrOps | UserCountryOps | LocationOps

export const EditorUtils = {
  getServiceActions: function(service: Service) {
    const checkValidationErrors = (error) => {
      const errors = ValidationUtils.getErrors(error)
      return { ok: false, validationErrors: errors, originalError: error.original }
    }

    return {
      save: async (id, newObj) => {
        try {
          await service.update(id, newObj)
          return { ok: true }
        }
        catch (error) {
          return checkValidationErrors(error)
        }
      },
      add: async (newObj) => {
        try {
          await service.create(newObj)
          return { ok: true }
        } catch (error) {
          return checkValidationErrors(error)
        }
      },
      delete: async (id) => {
        await service.delete(id)
      },
    }
  },
  getLanguageFields: (fieldName: string) => {
    return Enums.orderedLanguages.map((lang): DataEditorField => ({
      id: 'lang-' + lang,
      header: i18n.lt('en', 'enum.languages.' + lang),
      type: 'text',
      getValue: (obj) => obj[fieldName][lang],
      validationKey: fieldName + '.' + lang,
    }))
  },
}
