import { ChangeEvent } from 'react'
import React = require('react')
import toastr from 'toastr/toastr'

import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { getApi } from '../define'
import { LoadingIcon } from './loading-icon'
import { UiUtils } from './ui-utils'

interface ImageUploadProps {
  existingUrl?: string,
  title?: string,
  afterFancyboxShow?: () => void,
  afterFancyboxClose?: () => void,
}

interface State {
  processing: boolean,
  existingRemoved: boolean,
  url: string | null,
  file: File | null,
  thumbSize: { width: number, height: number } | null,
}

export class ImageUpload extends React.Component<ImageUploadProps, State> {
  state: State = {
    processing: Boolean(this.props.existingUrl),
    existingRemoved: false,
    url: null,
    file: null,
    thumbSize: null,
  }

  uploadRef = React.createRef<HTMLInputElement>()

  componentDidMount() {
    if (this.props.existingUrl) {
      const img = new Image()
      img.addEventListener('load', this.onExistingLoaded)
      img.addEventListener('error', this.onExistingFailed)
      img.src = this.props.existingUrl
    }

    EventBus.fire('image-upload-mounted', { setFile: this.setFile })
  }

  getBlob = () => { // Public method
    return this.state.file
  }

  isExistingRemoved = () => { // Public method
    return this.state.existingRemoved && !this.state.url
  }

  openImageChooser = () => {
    // Simulate a click on the hidden upload element
    this.uploadRef.current.click()
  }

  onExistingLoaded = (evt) => {
    UiUtils.setState(this, {
      processing: false,
      url: this.props.existingUrl,
      thumbSize: this.getThumbnailSize(evt.target as HTMLImageElement),
    })
    .then(EventBus.fireFunc('existing-image-loaded'))
  }

  onExistingFailed = () => {
    // Note: this state is normal during UI tests as we don't use real images.

    getApi().logError(new Error('Failed to load image ' + this.props.existingUrl))

    UiUtils.setState(this, {
      processing: false,
      url: this.props.existingUrl,
      thumbSize: { width: 100, height: 100 },
    })
    .then(EventBus.fireFunc('existing-image-loaded'))
  }

  openFancybox = () => {
    UiUtils.openFancybox(this.state.url, this.props.title, {
      on: {
        // After open
        'done': this.props.afterFancyboxShow,

        // After close
        'destroy': this.props.afterFancyboxClose,
      },
    })
  }

  limitSize = (width: number, height: number, maxWidth: number, maxHeight: number) => {
    const ratio = width / height
    const limited = { width, height }

    if (limited.height > maxHeight) {
      limited.height = maxHeight
      limited.width = limited.height * ratio
    }

    if (limited.width > maxWidth) {
      limited.width = maxWidth
      limited.height = limited.width / ratio
    }

    return limited
  }

  getThumbnailSize = (image: HTMLImageElement) => {
    return this.limitSize(image.naturalWidth, image.naturalHeight, 160, 100)
  }

  onFileChosen = (evt: ChangeEvent<HTMLInputElement>) => {
    const { files } = evt.target

    if (files.length === 1) {
      this.setFile(files[0])
    }
  }

  setFile = (file: File) => {
    if (file.type === 'image/png' || file.type === 'image/jpeg') {
      this.setState({ processing: true, file, url: null, thumbSize: null })

      UiUtils.blobToImage(file)
      .then((image) => {
        return UiUtils.setState(this, {
          processing: false,
          url: image.src,
          thumbSize: this.getThumbnailSize(image),
        })
      })
      .then(EventBus.fireFunc('uploaded-image-rendered'))
      .catch((error) => {
        this.setState({ processing: false, file: null })

        if (error.imageProcessingFailed) {
          toastr.error(i18n.t('image-upload.failed'))
        }
        else {
          throw error
        }
      })
    }
    else {
      toastr.warning(i18n.t('image-upload.expected-format'))
    }
  }

  removeImage = () => {
    const hadExisting = Boolean(this.props.existingUrl)

    UiUtils.setState(this, {
      file: null, url: null, thumbSize: null, existingRemoved: hadExisting,
    })
    .then(EventBus.fireFunc('image-removed'))
  }

  renderAddButton = () => {
    if (!this.state.url && !this.state.processing) {
      // Render 'plus' icon
      return (
        <svg className="btn-add-image" width={100} height={100}>
          <rect x={46} y={30} width={8} height={40} fill="hsl(40, 15%, 75%)" />
          <rect x={30} y={46} width={40} height={8} fill="hsl(40, 15%, 75%)" />
        </svg>
      )
    }
  }

  renderImage = () => {
    if (this.state.processing) {
      return (
        <div style={{ margin: 25 }}>
          <LoadingIcon size={40} />
        </div>
      )
    }
    else if (this.state.url) {
      const { thumbSize } = this.state

      return (
        <img
          src={this.state.url}
          width={thumbSize.width}
          height={thumbSize.height}
          title={i18n.t('image-upload.view-larger')}
        />
      )
    }
  }

  renderChangeButton = () => {
    if (this.state.url) {
      return (
        <img
          className="btn-change-image"
          src="img/change.png"
          style={{ width: 20, marginLeft: 5, cursor: 'pointer' }}
          title={i18n.t('image-upload.change')}
          onClick={this.openImageChooser}
        />
      )
    }
  }

  renderRemoveButton = () => {
    if (this.state.url) {
      return (
        <img
          className="btn-remove-image"
          src="img/delete.png"
          style={{ width: 20, marginLeft: 5, cursor: 'pointer' }}
          title={i18n.t('image-upload.remove')}
          onClick={this.removeImage}
        />
      )
    }
  }

  render() {
    let onClick = null
    const { url } = this.state

    if (url) {
      onClick = this.openFancybox
    }
    else if (!this.state.processing) {
      onClick = this.openImageChooser
    }

    const style = onClick ? { cursor: 'pointer' } : null

    return (
      <div>
        <input
          // Hidden upload element
          ref={this.uploadRef}
          type="file"
          onChange={this.onFileChosen}
          style={{ display: 'none' }}
        />
        <div className="upload-image-container" style={style} onClick={onClick}>
          {this.renderAddButton()}
          {this.renderImage()}
        </div>
        {this.renderChangeButton()}
        {this.renderRemoveButton()}
      </div>
    )
  }
}
