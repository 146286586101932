import toastr from 'toastr/toastr'

import { i18n } from '../common/i18n'
import { router } from './router'
import { Utils } from './utils'

let logWarn = function(message) {
  console.warn(message)
}

let logError = function(message) {
  console.error(message)
}

export const ErrorHandler = {
  handleError,
  setLoggers,
}

function handleError(error) {
  let errorType = null

  if ('body' in error && error.body && 'errorType' in error.body) {
    errorType = error.body.errorType
  }

  if (error.status === 401) {
    if (errorType === 'no-permissions') {
      toastr.error(i18n.t('api.no-permissions'))
      logError(i18n.t('api.no-permissions'))
    }
    else {
      logWarn('Not logged in, redirecting to login page')
      router.setRoute('/login')
    }
  }
  else if (error.status === 409 && errorType) {
    if (errorType === 'validation') {
      const isUnexpected = Object.keys(error.body.validationErrors).some(function(key) {
        const valError = error.body.validationErrors[key]
        return valError.type === 'unknown-keys'
      })

      if (isUnexpected) {
        toastr.error(i18n.t('api.unexpected-validation-error'))
      }
      else {
        toastr.warning(i18n.t('api.validation-errors'))
      }

      logWarn('There are validation errors: ' + JSON.stringify(error.body.validationErrors))
    }
    else if (errorType === 'duplicate-key') {
      // Don't auto-close
      toastr.warning(i18n.t('api.duplicate-key-error'), '', { timeOut: 0 })
      logError('Duplicate key error')
    }
    else if (errorType === 'custom') {
      const message = error.body.errorMessage
      toastr.error(message, '', { timeOut: 0 })
      logError(message)
    }
    else {
      throw new Error('Unhandled error type: ' + errorType)
    }
  }
  else if (error.status === 404) {
    const empty = error.text === '{}' // TODO: send better 404 errors from server?

    toastr.error(
      i18n.t('api.not-found') + (empty ? '' : ': ' + error.text),
      i18n.t('api.error'),
    )

    logError('Error ' + error.status + (empty ? '' : ': ' + error.text))
  }
  else if (error.text) {
    toastr.error(error.text, i18n.t('api.error'))
    logError('Error ' + error.status + ': ' + error.text)
  }
  else if (error.status) {
    logError('Error ' + error.status)
  }
  else {
    toastr.error(error.message, i18n.t('api.error'))
    logError('Error ' + error.message)
  }

  // Even though we've already handled this error, we need to throw something to skip
  // any remaining steps of the promise chain. So we return an already handled error
  // that can be ignored or, if necessary, processed (for example, to display validation
  // errors on form fields).
  // Catching these properly at the ends of the chains would require boilerplate code
  // everywhere where DataService is used, so instead there's a window.onerror handler
  // set up in common-conf that ignores errors where .alreadyHandled = true.

  const handledError: Error & { original?: Error } = Utils.getAlreadyHandledError()
  handledError.original = error
  throw handledError
}

function setLoggers(warn, error) {
  logWarn = warn
  logError = error
}
