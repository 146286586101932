import React = require('react')
import toastr from 'toastr/toastr'

import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { getApi } from '../define'
import { bindInput } from './bind-utils'
import { LoadingButton } from './loading-button'
import { router } from './router'
import { Utils } from './utils'

export class Login extends React.Component {
  state = { username: '', password: '' }

  usernameRef = React.createRef<HTMLInputElement>()
  buttonRef = React.createRef<LoadingButton>()

  componentDidMount() {
    this.usernameRef.current.focus()
    EventBus.fire('login-rendered')

    const preload = new Image()
    preload.src = 'img/loading.gif'
  }

  logIn = () => {
    return getApi().logIn(this.state.username, this.state.password)
    .then(function(response) {
      if (response.success) {
        const defaultRoute = Utils.getDefaultRoute(response.user)
        router.setRoute(defaultRoute)
      }
      else {
        toastr.error(i18n.t('user.failed-log-in'))
      }
    })
  }

  onKeyUp = (evt) => {
    if (evt.keyCode === 13) { // Enter
      this.buttonRef.current.onClick()
    }
  }

  render() {
    return (
      <div id="form-login" onKeyUp={this.onKeyUp}>
        <div className="field">
          {i18n.t('user.username')}
          {bindInput(this, 'username', { ref: this.usernameRef, id: 'inp-username', className: 'form-control' })}
        </div>
        <div className="field">
          {i18n.t('user.password')}
          {bindInput(this, 'password', { id: 'inp-password', type: 'password', className: 'form-control' })}
        </div>
        <div className="field">
          <LoadingButton
            ref={this.buttonRef}
            id="btn-login"
            text={i18n.t('user.log-in')}
            getPromise={this.logIn}
          />
        </div>
      </div>
    )
  }
}
