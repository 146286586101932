import classNames from 'classnames'
import { CSSProperties } from 'react'
import React = require('react')

import { CommonUtils } from '../common/common-utils'

interface SelectorProps {
  id?: string,
  className?: string,
  style?: CSSProperties,
  multiple?: boolean,
  horizontal?: boolean,
  values: {
    key?: string | number,
    label?: string,
  }[],
  selectedKey?: string | number,
  selectedKeys?: (string | number)[],
  onSelect: (comboId: string, isActive: boolean) => void,
}

export class Selector extends React.Component<SelectorProps> {
  render() {
    return (
      <div
        id={this.props.id}
        className={classNames(
          this.props.className,
          this.props.horizontal ? 'btn-group' : 'btn-group-vertical',
        )}
        style={this.props.style}
        data-toggle="buttons"
      >
        {this.props.values.map((value) => {
          let isActive

          if (this.props.multiple) {
            isActive = CommonUtils.arrayContains(this.props.selectedKeys, value.key)
          }
          else {
            isActive = value.key === this.props.selectedKey
          }

          return (
            <label
              key={value.key}
              className={'btn btn-default' + (isActive ? ' active': '')}
            >
              <input
                type="radio"
                onClick={this.props.onSelect.bind(null, value.key, !isActive)}
              />
              {'label' in value ? value.label : value.key}
            </label>
          )
        })}
      </div>
    )
  }
}
