import React = require('react')

import { CommonUtils } from '../common/common-utils'
import { AttributeDefinition } from '../common/data-attribute-defs'
import { ProductDefinition } from '../common/data-product-defs'
import { TemplateDefinition } from '../common/data-template-defs'
import { AttributeCombination } from '../common/types'
import { Selector } from './selector'
import { User } from './user'
import { Utils } from './utils'

interface AttributeCombinationSelectorProps {
  attribute: AttributeDefinition,
  definition: TemplateDefinition | ProductDefinition,
  templateDefinitions?: TemplateDefinition[],
  selectedComboId?: string | number,
  onSelect: (comboId: string) => void,
}

export const AttributeCombinationSelector = (props: AttributeCombinationSelectorProps) => {
  const { attribute, definition } = props

  const combos = definition.attributeCombinations[attribute._id] as (AttributeCombination | number)[]

  const values = combos.map((comboParam) => {
    const combination = Utils.ensureCombination(
      comboParam,
      attribute,
      definition,
      props.templateDefinitions,
    )

    const label = CommonUtils.getAttributeComboLabel(
      attribute,
      combination.values,
      definition.category,
      User.getLanguage(),
    )

    return { key: combination.id, label }
  })

  return (
    <Selector
      id={`attr-combo-selector-${attribute._id}`}
      className="attr-combo-selector"
      values={values}
      selectedKey={props.selectedComboId}
      onSelect={props.onSelect}
    />
  )
}
