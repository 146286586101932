import { useRef, useState } from 'react'
import React = require('react')

import { strnatcmp } from '../client-lib'
import { Access } from '../common/access'
import { CommonUtils } from '../common/common-utils'
import { AttributeDefinition } from '../common/data-attribute-defs'
import { SimpleAttr } from '../common/data-misc'
import { RawMaterial, RawMaterialCreate, RawMaterialUpdate } from '../common/data-raw-materials'
import { getDataService } from '../common/data-service'
import { Enums } from '../common/enums'
import { i18n } from '../common/i18n'
import { AttributeCombination as TAttributeCombination } from '../common/types'
import { AttributeCombination } from './attribute-combination'
import { bindCheckbox, bindInput, bindProps } from './bind-utils'
import { ImageUpload } from './image-upload'
import { getDefaultCancelButton, Modal, ModalButton } from './modal'
import { useOpenModalEvent } from './use-open-modal-event'
import { User } from './user'
import { Utils } from './utils'
import { ValidationErrors, ValidationUtils } from './validation-utils'

interface EditMaterialModalProps {
  modalId: string,
  isNew?: boolean,
  material?: RawMaterial,
  categories: SimpleAttr[],
  producers: SimpleAttr[],
  widths: SimpleAttr[],
  attributeDefinitions: AttributeDefinition[],
  afterSave?: () => void,
}

interface FormProps {
  isNew?: boolean,
  material?: RawMaterial,
  categories: SimpleAttr[],
  producers: SimpleAttr[],
  widths: SimpleAttr[],
  attributeDefinitions: AttributeDefinition[],
  disableClosing: () => void,
  enableClosing: () => void,
}

interface FormState {
  material: RawMaterialUpdate | RawMaterialCreate,
  existingPhotoUrl?: string,
  validationErrors?: ValidationErrors,
}

class Form extends React.Component<FormProps, FormState> {
  constructor(props: FormProps) {
    super(props)
    const state: FormState = {
      material: null,
    }

    if (props.isNew) {
      const material: RawMaterialCreate = {
        attributes: {},
        amount: { value: undefined, unit: undefined },
        price: undefined,
        width: undefined,
        name: undefined,
        category: undefined,
        producer: undefined,
      }

      state.material = material
    }
    else {
      const material: RawMaterialUpdate = {
        _id: props.material._id,
        attributes: props.material.attributes,
        // The amount may have lost accuracy (e.g. 54.09999... instead of 54.1)
        // which can break the field editor. TODO: find better, more generic solution?
        // Also need to convert decimals to strings for validation.
        amount: { value: CommonUtils.round(props.material.amount.value).toString(), unit: props.material.amount.unit },
        amountThreshold: props.material.amountThreshold ? props.material.amountThreshold.toString() : undefined,
        price: props.material.price.toString(),
        // Ensure boolean if missing
        discontinued: props.material.discontinued || false,
        width: props.material.width,
        name: props.material.name,
        category: props.material.category,
        producer: props.material.producer,
      }

      state.material = material

      if (props.material.photo) {
        state.existingPhotoUrl = User.getUploaded('fabrics', props.material.photo)
      }
    }
    this.state = state
  }

  photoUploadRef = React.createRef<ImageUpload>()

  _isMounted = false

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  productionManagerSave = () => {
    const material = this.state.material as RawMaterialUpdate
    const materialId = material._id
    const threshold = { amountThreshold: this.state.material.amountThreshold ? String(this.state.material.amountThreshold) : '0' }

    return ValidationUtils.clear(this)
      .then(function () {
        return getDataService().RawMaterials.update(materialId, threshold, false, null)
      })
      .catch((error) => ValidationUtils.check(this, error))
  }

  onSave = () => {
    if (this.userIsProductionManager()) {
      return this.productionManagerSave()
    }

    return ValidationUtils.clear(this)
      .then(async () => {
        const extendedPermissions = Access.manageRawMaterialsExtended(User.getUser())
        const DataService = getDataService()

        if (this.props.isNew) {
          const stateMaterial = this.state.material as RawMaterialCreate
          await DataService.RawMaterials.create({
            name: stateMaterial.name,
            width: stateMaterial.width,
            price: stateMaterial.price,
            category: stateMaterial.category,
            producer: stateMaterial.producer,
            attributes: stateMaterial.attributes,
            reference: stateMaterial.reference,
            note: stateMaterial.note,
            amount: { value: stateMaterial.amount.value ? String(stateMaterial.amount.value) : '', unit: stateMaterial.amount.unit ? stateMaterial.amount.unit : undefined },
            amountThreshold: stateMaterial.amountThreshold ? String(stateMaterial.amountThreshold) : '',
          })
        }
        else {
          const stateMaterial = this.state.material as RawMaterialUpdate
          const material: RawMaterialUpdate = {
            name: stateMaterial.name,
            category: stateMaterial.category,
            price: stateMaterial.price,
            producer: stateMaterial.producer,
            attributes: stateMaterial.attributes,
            reference: stateMaterial.reference,
            note: stateMaterial.note,
            amountThreshold: stateMaterial.amountThreshold ? String(stateMaterial.amountThreshold) : '',
            discontinued: stateMaterial.discontinued,
          }

          if (extendedPermissions) {
            material.amount = {
              value: this.state.material.amount.value ? String(this.state.material.amount.value) : '',
            }
            material.price = this.state.material.price ? String(this.state.material.price) : ''
            material.width = this.state.material.width
          }

          const removePhoto = this.photoUploadRef.current.isExistingRemoved()
          const photoBlob = this.photoUploadRef.current.getBlob()

          await DataService.RawMaterials.update(
            stateMaterial._id, material, removePhoto, photoBlob,
          )
        }
      })
      .catch((error) => ValidationUtils.check(this, error))
  }

  userIsProductionManager = () => {
    const user = User.getUser()
    return user.role === Enums.roles.production
  }

  amountIsBelowThreshold = () => {
    const hasThreshold = this.state.material.amountThreshold !== null &&
      this.state.material.amountThreshold !== undefined

    const amount = this.state.material.amount.value
    const threshold = this.state.material.amountThreshold

    return hasThreshold && amount < threshold
  }

  renderAttributesRows = () => {
    const language = User.getLanguage()
    const userIsProductionManager = this.userIsProductionManager()
    const { material } = this.state

    return this.props.attributeDefinitions.map((attribute) => {
      if (!(attribute._id in material.attributes)) {
        material.attributes[attribute._id] = []
      }

      const values = material.attributes[attribute._id]
      let attrElem
      if (userIsProductionManager) {
        const combination: TAttributeCombination = {
          id: 0, // Should be ignored
          values: material.attributes[attribute._id],
        }

        const details = CommonUtils.getCombinationDetails(combination, attribute, null, language)
        attrElem = details.label
      } else {
        attrElem = (
          <AttributeCombination
            ref={'combo-' + attribute._id}
            attribute={attribute}
            values={values}
            onChange={(newValues) => {
              material.attributes[attribute._id] = newValues
              this.forceUpdate()
            }}
          />
        )
      }
      return this.renderRow(
        attribute._id,
        attribute.names[User.getLanguage()],

        // Wrap in similar divs as on definition form so we can use the same test utils
        <div id={'attr-combo-set-' + attribute._id}>
          <div className="attr-combo">
            {attrElem}
          </div>
        </div>,
        'attributes.' + attribute._id,
      )
    })
  }

  renderNameRow = () => {
    let nameField
    if (this.userIsProductionManager()) {
      nameField = <span>{this.state.material.name}</span>
    } else {
      nameField = bindInput(this,
        ['material', 'name'], { id: 'inp-name', style: { width: '20em' } },
      )
    }

    return this.renderRow(undefined,
      i18n.t('raw-materials.name'),
      nameField,
      'name',
    )
  }

  renderUnitDropdown = () => {
    const stateMaterial = this.state.material as RawMaterialCreate

    if (!this.props.isNew) {
      return i18n.t('enum.material-units.' + stateMaterial.amount.unit)
    }

    return (
      <select {...bindProps(this, ['material', 'amount', 'unit'], { id: 'inp-unit' })}>
        {!stateMaterial.amount.unit && <option value="" />}
        {Enums.orderedMaterialUnits.map(function (unit) {
          return (
            <option key={unit} value={unit}>
              {i18n.t('enum.material-units.' + unit)}
            </option>
          )
        })}
      </select>
    )
  }

  renderWidthDropdown = () => {
    if (!this.props.isNew && !Access.manageRawMaterialsExtended(User.getUser())) {
      const currentWidth = CommonUtils.findById(this.props.widths, this.state.material.width)
      return (
        <div id="lbl-width">
          {currentWidth.labels[User.getLanguage()]}
        </div>
      )
    }

    const widths = this.props.widths.slice()

    widths.sort(function (width1, width2) {
      return strnatcmp(width1.labels[User.getLanguage()], width2.labels[User.getLanguage()])
    })

    return (
      <select {...bindProps(this, ['material', 'width'], { id: 'inp-width' })}>
        {!this.state.material.width && <option value="" />}
        {widths.map(function (width) {
          return (
            <option key={width._id} value={width._id}>
              {width.labels[User.getLanguage()]}
            </option>
          )
        })}
      </select>
    )
  }

  renderProducerDropdown = () => {
    const producers = this.props.producers.slice()

    producers.sort(function (prod1, prod2) {
      return prod1.labels[User.getLanguage()].localeCompare(prod2.labels[User.getLanguage()])
    })

    return (
      <select
        {...bindProps(this, ['material', 'producer'], { id: 'inp-producer', style: { width: '20em' } })}
      >
        {!this.state.material.producer && <option value="" />}
        {producers.map(function (producer) {
          return (
            <option key={producer._id} value={producer._id}>
              {producer.labels[User.getLanguage()]}
            </option>
          )
        })}
      </select>
    )
  }

  renderCategoryDropdown = () => {
    const categories = this.props.categories.slice()

    categories.sort(function (cat1, cat2) {
      return cat1.labels[User.getLanguage()].localeCompare(cat2.labels[User.getLanguage()])
    })

    return (
      <select
        {...bindProps(this, ['material', 'category'], { id: 'inp-category', style: { width: '20em' } })}
      >
        {!this.state.material.category && <option value="" />}
        {categories.map(function (category) {
          return (
            <option key={category._id} value={category._id}>
              {category.labels[User.getLanguage()]}
            </option>
          )
        })}
      </select>
    )
  }

  renderRow = (key, fieldName, field, validationField?) => {
    return (
      <div key={key} className="row" style={{ margin: '0.5em 0' }}>
        <div className="col-xs-4" style={{ fontWeight: 'bold', paddingLeft: 0 }}>
          {fieldName}
        </div>
        <div className="col-xs-8">
          {field}
          {ValidationUtils.render(this.state.validationErrors, validationField)}
        </div>
      </div>
    )
  }

  renderBelowThresholdWarning = () => {
    if (this.amountIsBelowThreshold() && !this.props.isNew) {
      return (
        <p id="below-threshold-warning" className="text-red">
          {i18n.t('raw-materials.amount-below-threshold')}
        </p>
      )
    }
  }

  renderAmountRow = () => {
    const user = User.getUser()
    const extendedPermissions = Access.manageRawMaterialsExtended(user)
    let amountValueField

    if (this.props.isNew || extendedPermissions) {
      amountValueField = bindInput(this,
        ['material', 'amount', 'value'],
        { id: 'inp-amount', style: { width: '4em' }, validator: Utils.nonNegativeDecimalValidator },
      )
    } else {
      amountValueField = CommonUtils.formatDecimal(parseFloat(this.state.material.amount.value), true)
    }

    return this.renderRow(undefined,
      i18n.t('common.amount'),
      <div id="fld-amount">
        <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
          {amountValueField}
          {ValidationUtils.render(this.state.validationErrors, 'amount.value')}
        </div>
        {' '}
        <div style={{ display: 'inline-block' }}>
          {this.renderUnitDropdown()}
          {ValidationUtils.render(this.state.validationErrors, 'amount.unit')}
        </div>
        {this.renderBelowThresholdWarning()}
      </div>,
    )
  }

  renderAmountThresholdRow = () => {
    const stateMaterial = this.state.material as RawMaterialCreate
    const amountThresholdField = bindInput(this,
      ['material', 'amountThreshold'],
      {
        id: 'inp-amount-threshold',
        style: { width: '4em' },
        validator: Utils.nonNegativeDecimalValidator,
      },
    )

    const { unit } = stateMaterial.amount
    const unitTranslation = unit ? i18n.t('enum.material-units.' + unit) : ''

    return this.renderRow(undefined,
      i18n.t('common.amount-threshold'),
      <span id="fld-amount-threshold">
        {amountThresholdField}
        {' '}
        {unitTranslation}
      </span>,
      'amountThreshold',
    )
  }

  renderNoteRow = () => {
    let noteField
    if (this.userIsProductionManager()) {
      noteField = <span>{this.state.material.note}</span>
    } else {
      noteField = (
        <textarea
          {...bindProps(this, ['material', 'note'], {
            id: 'inp-note',
            style: { width: '20em', height: '7em' },
          })}
        />
      )
    }

    return this.renderRow(undefined,
      i18n.t('common.note'),
      noteField,
      'note',
    )
  }

  renderPhotoRow = () => {
    if (this.props.isNew || this.userIsProductionManager()) {
      return null
    }

    return this.renderRow(undefined,
      i18n.t('raw-materials.photo'),
      <div>
        <ImageUpload
          ref={this.photoUploadRef}
          existingUrl={this.state.existingPhotoUrl}
          title={this.state.material.name}
          // Don't close edit modal when Esc is pressed while Fancybox is active.
          // During this time, Esc should only close the Fancybox, but keep the edit modal open.
          afterFancyboxShow={this.props.disableClosing}
          afterFancyboxClose={this.props.enableClosing}
        />
      </div>,
    )
  }

  renderDiscontinuedRow = () => {
    if (!this.props.isNew && !this.userIsProductionManager()) {
      return this.renderRow(undefined,
        i18n.t('common.discontinued'), bindCheckbox(this, ['material', 'discontinued']),
      )
    }
  }

  render() {
    const user = User.getUser()
    const extendedPermissions = Access.manageRawMaterialsExtended(user)

    let priceField

    if (this.props.isNew || extendedPermissions) {
      priceField = bindInput(this,
        ['material', 'price'],
        { id: 'inp-price', style: { width: '4em' }, validator: Utils.nonNegativeDecimalValidator },
      )
    } else {
      priceField = CommonUtils.formatDecimal(parseFloat(this.state.material.price), true)
    }

    return (
      <div>
        <div id="form-mat">
          {this.renderNameRow()}
          {this.renderRow(undefined,
            i18n.t('common.category'),
            this.renderCategoryDropdown(),
            'category',
          )}
          {this.renderRow(undefined,
            i18n.t('raw-materials.producer'),
            this.renderProducerDropdown(),
            'producer',
          )}
          {this.renderAttributesRows()}
          {this.renderRow(undefined,
            i18n.t('raw-materials.width'),
            this.renderWidthDropdown(),
            'width',
          )}
          {this.renderAmountRow()}
          {this.renderAmountThresholdRow()}
          {this.renderRow(undefined,
            i18n.t('common.price'),
            <div id="fld-price">
              {priceField}
              {' MAD'}
            </div>,
            'price',
          )}
          {this.renderRow(undefined,
            i18n.t('common.location'),
            <div id="lbl-location">Al Nour</div>, // TODO: un-hardcode
          )}
          {this.renderNoteRow()}
          {this.renderPhotoRow()}
          {this.renderDiscontinuedRow()}
        </div>
      </div>
    )
  }
}

export const EditMaterialModal = (props: EditMaterialModalProps) => {
  const [isVisible, close] = useOpenModalEvent(props.modalId)
  const formRef = useRef<Form>(null)
  const [isClosingDisabled, setClosingDisabled] = useState(false)

  if (!isVisible) {
    return <div />
  }

  const closeUnlessDisabled = () => {
    if (!isClosingDisabled) {
      close()
    }
  }

  const buttons: ModalButton[] = [
    {
      id: 'btn-save',
      title: i18n.t('action.save'),
      onClick: async () => {
        await formRef.current.onSave()
        props.afterSave?.()
        closeUnlessDisabled()
      },
    },
    getDefaultCancelButton(),
  ]

  return (
    <div>
      <Modal
        title={i18n.t('raw-materials.material')}
        closeModal={closeUnlessDisabled}
        dialogClassName="edit-material"
        buttons={buttons}
      >
        <Form
          ref={formRef}
          isNew={props.isNew}
          material={props.material}
          categories={props.categories}
          producers={props.producers}
          widths={props.widths}
          attributeDefinitions={props.attributeDefinitions}
          disableClosing={() => setClosingDisabled(true)}
          enableClosing={() => setClosingDisabled(false)}
        />
      </Modal>
    </div>
  )
}
