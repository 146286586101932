import React = require('react')

import { AccountingUtils } from '../common/accounting-utils'
import { CommonUtils } from '../common/common-utils'
import { EarningCategory } from '../common/data-earning-categories'
import { getDataService } from '../common/data-service'
import { i18n } from '../common/i18n'
import { AccountingCategoriesTable } from './accounting-categories-table'
import { AccountingMenu } from './accounting-menu'
import { EmptyObject } from './empty-object'
import { LoadingIcon } from './loading-icon'
import { MainMenu } from './main-menu'

export interface CategoryState extends EarningCategory {
  changed: boolean,
}

interface State {
  earningCategories: CategoryState[] | null,
}

class List extends React.Component<EmptyObject, State> {
  state: State = {
    earningCategories: null,
  }

  async componentDidMount() {
    const earningCategories = await getDataService().EarningCategories.getAll()

    this.setEarningCategories(earningCategories.map((category) => {
      return { ...category, changed: false }
    }))
  }

  setEarningCategories = (earningCategories) => {
    this.setState({ earningCategories })
  }

  handleEarningCategoryChange = (idx: number, labels) => {
    const earningCategories = CommonUtils.clone(this.state.earningCategories)
    const earningCategory = earningCategories[idx]
    earningCategory.labels = labels
    earningCategory.changed = true
    this.setEarningCategories(earningCategories)
  }

  getUpdateValue = (earningCategory) => {
    const val = CommonUtils.clone(earningCategory)
    delete val.changed
    delete val._id
    return val
  }

  setEarningCategoryNotChanged = (idx) => {
    const earningCategories = CommonUtils.clone(this.state.earningCategories)
    earningCategories[idx].changed = false
    this.setEarningCategories(earningCategories)
  }

  checkResultSuccess = (res) => {
    if (!res.success) {
      throw new Error(i18n.t('api.error'))
    }
    return res
  }

  updateEarningCategory = async (idx: number) => {
    const earningCategory = this.state.earningCategories[idx]
    const updateVal = this.getUpdateValue(earningCategory)
    const res = await getDataService().EarningCategories.update(earningCategory._id, updateVal)
    this.checkResultSuccess(res)
    this.setEarningCategoryNotChanged(idx)
  }

  pushNewEarningCategory = (id, labels) => {
    const earningCategory = {
      _id: id,
      labels,
      changed: false,
    }
    const earningCategories = CommonUtils.clone(this.state.earningCategories)
    earningCategories.push(earningCategory)
    this.setEarningCategories(earningCategories)
  }

  addEarningCategory = (labels) => {
    getDataService().EarningCategories.create(labels.en, labels.fr)
    .then(this.checkResultSuccess)
    .then((res) => {
      this.pushNewEarningCategory(res.id, labels)
    })
  }

  removeEarningCategory = (idx) => {
    const earningCategories = CommonUtils.clone(this.state.earningCategories)
    earningCategories.splice(idx, 1)
    this.setEarningCategories(earningCategories)
  }

  deleteEarningCategory = async (idx: number) => {
    const earningCategory = this.state.earningCategories[idx]
    const res = await getDataService().EarningCategories.delete(earningCategory._id)
    this.checkResultSuccess(res)
    this.removeEarningCategory(idx)
  }

  canDeleteEarningCategory = (earningCategory) => {
    return !AccountingUtils.isSalesEarningCategory(earningCategory._id)
  }

  renderMainMenu = () => {
    return <MainMenu key="main" activeTab="accounting" />
  }

  renderAccountingMenu = () => {
    return <AccountingMenu activeTab="earning-categories" />
  }

  renderBody = () => {
    if (!this.state.earningCategories) {
      return <LoadingIcon />
    } else {
      return (
        <AccountingCategoriesTable
          categories={this.state.earningCategories}
          canDelete={this.canDeleteEarningCategory}
          onCategoryChange={this.handleEarningCategoryChange}
          onCategorySave={this.updateEarningCategory}
          onCategoryDelete={this.deleteEarningCategory}
          onCategoryAdd={this.addEarningCategory}
        />
      )
    }
  }

  render() {
    return (
      <div>
        {this.renderMainMenu()}
        {this.renderAccountingMenu()}
        {this.renderBody()}
      </div>
    )
  }
}

export const EarningCategories = {
  List,
}
