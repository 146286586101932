// TODO move and/or rename the file

// TODO types
let api: any
let sharp: any

export const setApi = (value) => { api = value }
export const setSharp = (value) => { sharp = value }

export const getApi = () => api
export const getSharp = () => sharp
