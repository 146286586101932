import { DetailedHTMLProps, ImgHTMLAttributes } from 'react'
import React = require('react')

import { i18n } from '../common/i18n'

interface TableButtonBaseProps {
  imgSrc: string,
  onClick: () => void,
  disabled?: boolean,
  title?: string,
}

interface TableButtonProps {
  onClick: () => void,
  disabled?: boolean,
}

interface LoadingTableButtonProps {
  onClick: React.MouseEventHandler<HTMLImageElement>,
  disabled?: boolean,
}

class TableButtonBase extends React.Component<TableButtonBaseProps> {
  static defaultProps = {
    disabled: false,
  }

  render() {
    const props: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> = {
      className: this.props.disabled ? 'table-btn-disabled' : 'table-btn',
      src: this.props.imgSrc,
    }
    if (this.props.title) {
      props.title = this.props.title
    }
    if (!this.props.disabled) {
      props.onClick = this.props.onClick
    }
    return <img {...props} />
  }
}

const createButton = function(displayName, actionName, imgFileName) {
  return class extends React.Component<TableButtonProps> {
    static displayName = 'TableButton.' + displayName
    render() {
      return (
        <TableButtonBase
          disabled={this.props.disabled}
          title={i18n.t('action.' + actionName)}
          imgSrc={'img/' + imgFileName}
          onClick={this.props.onClick}
        />
      )
    }
  }
}

const createLoadingButton = function(displayName) {
  return class extends React.Component<LoadingTableButtonProps> {
    static displayName = 'TableButton.Loading.' + displayName

    state = { isLoading: false }

    _isMounted = false

    componentDidMount() {
      this._isMounted = true
    }

    componentWillUnmount() {
      this._isMounted = false
    }

    handleClick = (evt) => {
      this.setState({ isLoading: true }, async () => {
        try {
          await this.props.onClick(evt)
        }
        finally {
          if (this._isMounted) {
            this.setState({ isLoading: false })
          }
        }
      })
    }

    render() {
      if (this.state.isLoading) {
        return <img src="img/loading.gif" style={{ margin: '0 3px' }} />
      }

      const Component = TableButton[displayName]

      return (
        <Component
          onClick={this.handleClick}
          disabled={this.props.disabled}
        />
      )
    }
  }
}

export const TableButton = {
  AddButton: createButton('AddButton', 'add', 'add.png'),
  EditButton: createButton('EditButton', 'edit', 'edit.png'),
  SaveButton: createButton('SaveButton', 'save', 'check.png'),
  DeleteButton: createButton('DeleteButton', 'delete', 'delete.png'),
  CancelButton: createButton('CancelButton', 'cancel', 'undo.png'),
  AddLoadingButton: createLoadingButton('AddButton'),
  EditLoadingButton: createLoadingButton('EditButton'),
  SaveLoadingButton: createLoadingButton('SaveButton'),
  DeleteLoadingButton: createLoadingButton('DeleteButton'),
  CancelLoadingButton: createLoadingButton('CancelButton'),
}
