import classNames from 'classnames'
import { ButtonHTMLAttributes } from 'react'
import React = require('react')

import { EventBus } from '../common/event-bus'

interface LoadingButtonProps {
  text: string,
  getPromise: () => Promise<void>,
  id?: string,
  style?: object,
  className?: string,
  disabled?: boolean,
  disabledTitle?: string,
}

export class LoadingButton extends React.Component<LoadingButtonProps> {
  state = { loading: false }

  _isMounted = false

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  onClick = async () => { // This method may be called from other modules
    this.setState({ loading: true })

    try {
      await this.props.getPromise()
    }
    finally {
      if (this._isMounted) {
        this.setState({ loading: false }, EventBus.fireFunc('loading-button-restored'))
      }
    }
  }

  render() {
    if (this.state.loading) {
      return <img src="img/loading.gif" style={{ margin: '0 3px' }} />
    }
    else {
      const props: ButtonHTMLAttributes<HTMLButtonElement> = {
        id: this.props.id,
        className: classNames(this.props.className, { 'disabled': this.props.disabled }),
        style: this.props.style,
        disabled: this.props.disabled,
        onClick: this.onClick,
        title: this.props.disabled ? this.props.disabledTitle : null,
      }

      return <button {...props}>{this.props.text}</button>
    }
  }
}
