import { CSSProperties } from 'react'
import React = require('react')

import { Access } from '../common/access'
import { CommonUtils, DetailedEntry } from '../common/common-utils'
import { AttributeDefinition } from '../common/data-attribute-defs'
import { Customer } from '../common/data-customers'
import { ActualPaymentKey, InvoiceConfirm } from '../common/data-invoices'
import { Location } from '../common/data-locations'
import { RawMaterial } from '../common/data-raw-materials'
import { getDataService } from '../common/data-service'
import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { Currency, InvoiceSettings, InvoiceUtils, InvoiceValues, UiInvoiceBook } from '../common/invoice-utils'
import { bindCheckbox, bindInput, bindProps } from './bind-utils'
import { InvoiceUiUtils, Rates } from './invoice-ui-utils'
import { LoadingButton } from './loading-button'
import { MainMenu } from './main-menu'
import { RawMaterialSelect } from './raw-material-select'
import { ReportsMenu } from './reports-menu'
import { router } from './router'
import { SearchableCustomer } from './searchable-customer'
import { Column, UiUtils } from './ui-utils'
import { User } from './user'
import { Utils } from './utils'
import { ValidationErrors, ValidationUtils } from './validation-utils'

interface InvoiceViewProps {
  isNew?: boolean,
  locationId?: string,
  invoiceId?: string,
}

interface State {
  loaded: boolean,
  location?: Location,
  attributeDefinitions: AttributeDefinition[],
  time: Date | string,
  entries: DetailedEntry[],
  book: UiInvoiceBook,
  shipping: string | number,
  droitsDeTimbre: boolean,
  export: boolean,
  marraCashCard: boolean,
  guideCommission: boolean,
  actualPayments: Partial<Record<ActualPaymentKey, string | number>> | null, // TODO string only
  corrected: boolean,
  rawMaterials: RawMaterial[],
  invoiceSettings?: InvoiceSettings,
  rates?: Rates,
  customerName?: string,
  customerContact?: string,
  note?: string,
  validationErrors?: ValidationErrors,
  customers?: Customer[],
}

export interface Row {
  id: string,
  index?: number,
  currency: Currency,
  amount: number | string,
  description: string,
  hasOverride?: boolean,
  defaultPrice?: string | number,
  unitPrice: string | number,
  entryTotal: number,
  priceOverride?: string,
  type?: 'book',
  rowClassName?: string,
}

export class InvoiceView extends React.Component<InvoiceViewProps, State> {
  state: State = {
    loaded: false,
    location: null,
    attributeDefinitions: [],
    time: '',
    entries: [],
    book: null,
    shipping: '',
    droitsDeTimbre: false,
    export: false,
    marraCashCard: false,
    guideCommission: false,
    actualPayments: null,
    corrected: false,
    rawMaterials: [],
  }

  _isMounted = false

  componentDidMount() {
    this._isMounted = true
    this.loadEverything(this.props)
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidUpdate(prevProps: InvoiceViewProps) {
    if (
      this.props.isNew !== prevProps.isNew ||
      this.props.locationId !== prevProps.locationId ||
      this.props.invoiceId !== prevProps.invoiceId
    ) {
      this.loadEverything(this.props)
    }
  }

  // TODO: optimize - split items that need to be initialised once vs ones that need reloading
  loadEverything = async (props: InvoiceViewProps) => {
    const DataService = getDataService()

    this.setState({ loaded: false })
    const stateUpdate: Partial<State> = { loaded: true }

    const attrDefsPromise = DataService.AttributeDefinitions.getAll()
    const customersPromise = DataService.Customers.getAll()
    const ratesPromise = InvoiceUiUtils.getExchangeRates()
    const invoiceSettingsPromise = InvoiceUiUtils.getSettings()
    const rawMaterialsPromise = DataService.RawMaterials.getAll()

    let { locationId } = props

    if (props.isNew) {
      stateUpdate.time = CommonUtils.getNow()
      const entries = await DataService.Carts.getDetailedContents(locationId)

      for (const entry of entries) {
        entry.priceOverride = null
      }

      // TODO: sort?
      stateUpdate.entries = entries
    }
    else {
      const invoice = await DataService.Invoices.getDetailed(props.invoiceId)
      stateUpdate.time = invoice.time
      stateUpdate.entries = invoice.entries
      stateUpdate.guideCommission = invoice.guideCommission
      stateUpdate.book = invoice.book
      stateUpdate.shipping = invoice.shipping || 0
      stateUpdate.droitsDeTimbre = invoice.droitsDeTimbre
      stateUpdate.export = invoice.export
      stateUpdate.marraCashCard = invoice.marraCashCard
      stateUpdate.customerName = invoice.customerName
      stateUpdate.customerContact = invoice.customerContact
      stateUpdate.note = invoice.note
      stateUpdate.actualPayments = invoice.actualPayments
      stateUpdate.corrected = invoice.corrected
      locationId = invoice.location
    }

    User.setLastLocation('sales-points', locationId)
    const locationPromise = DataService.Locations.getById(locationId)

    const [
      attributeDefinitions,
      customers,
      rates,
      invoiceSettings,
      loc,
      rawMaterials,
    ] = await Promise.all([
      attrDefsPromise,
      customersPromise,
      ratesPromise,
      invoiceSettingsPromise,
      locationPromise,
      rawMaterialsPromise,
    ])

    if (!this._isMounted) {
      return
    }

    const filteredAttrDefs = attributeDefinitions.filter(
      CommonUtils.attributeContextFilters.products,
    )

    if (props.isNew) {
      stateUpdate.actualPayments = InvoiceUtils.getActualPaymentMap('', loc.currency)
      const compareFunc = Utils.getDetailCompareFunction(filteredAttrDefs)
      stateUpdate.entries.sort(compareFunc)
    }

    if (loc.sellBook) {
      if (stateUpdate.book) {
        stateUpdate.book.quantity = String(stateUpdate.book.quantity)
        stateUpdate.book.price = String(stateUpdate.book.price)
      }
      else if (props.isNew) {
        stateUpdate.book = {
          quantity: '',
          price: String(InvoiceUiUtils.getDefaultBookPrice(loc.currency)),
        }
      }
    }

    stateUpdate.attributeDefinitions = filteredAttrDefs
    stateUpdate.customers = customers
    stateUpdate.rates = rates
    stateUpdate.invoiceSettings = invoiceSettings
    stateUpdate.location = loc
    stateUpdate.rawMaterials = rawMaterials

    this.setState<any>(stateUpdate, EventBus.fireFunc('invoice-rendered'))
  }

  getInvoiceValues = (): InvoiceValues => {
    const book = CommonUtils.clone(this.state.book)

    // Convert '-' to 0 instead of NaN while negative number is not fully entered
    if (book && book.price === '-') {
      book.price = 0
    }

    return {
      currency: this.state.location.currency,
      entries: this.state.entries,
      shipping: this.state.shipping,
      marraCashCard: this.state.marraCashCard,
      book,
    }
  }

  allPricesAreValid = () => {
    return this.state.entries.every(function(entry) {
      return InvoiceUtils.hasOverride(entry) ? entry.priceOverride !== '' : Boolean(entry.price)
    })
  }

  confirm = () => {
    return InvoiceUiUtils.validateActualPayments(
      this.state.invoiceSettings, this.state.rates, this.getInvoiceValues(),
      this.shouldDeductVat(), this.state.actualPayments,
    )
    .then(Utils.runIfTrue<any>(() => {
      return ValidationUtils.clear(this)
      .then(() => {
        const invoice: InvoiceConfirm = {
          location: this.state.location._id,
          entries: this.state.entries.map((entry) => {
            const unitPrice = Number(InvoiceUtils.getEntryUnitPrice(
              this.state.invoiceSettings, entry, this.state.export,
            ))

            return {
              product: entry.productId,
              amount: Number(entry.entryAmount),
              price: String(CommonUtils.round(unitPrice)),
            }
          }),
          guideCommission: this.state.guideCommission,
          shipping: String(this.state.shipping),
          export: this.state.export,
          marraCashCard: this.state.marraCashCard,
          customerName: this.state.customerName,
          customerContact: this.state.customerContact,
          note: this.state.note,
          actualPayments: this.state.actualPayments,
        }

        const { book } = this.state

        if (book?.quantity) {
          invoice.book = book
        }

        if (this.state.droitsDeTimbre) {
          invoice.droitsDeTimbre = true
        }

        return getDataService().Invoices.confirm(invoice)
      })
      .then(function(result) {
        router.setRoute('/invoice/view/' + result.id)
      })
      .then(EventBus.fireFunc('cart-updated'))
      .catch((error) => ValidationUtils.check(this, error))
    }))
  }

  cancel = () => {
    router.setRoute('/cart/' + this.state.location._id)
  }

  print = () => {
    window.open('#/invoice/print/' + this.props.invoiceId, '_blank')
  }

  correct = () => {
    router.setRoute('/invoice/correct/' + this.props.invoiceId)
  }

  shouldDeductVat = () => {
    return this.props.isNew && this.state.export
  }

  getRowsData = () => {
    const { invoiceSettings } = this.state
    const deductVat = this.shouldDeductVat()
    const loc = this.state.location

    const rowsData = this.state.entries.map((entry, entryIndex): Row => {
      const attributeLabels = this.state.attributeDefinitions
      .map(function(attribute) {
        return entry.attributes[attribute._id].label
      })
      .filter(function(label) {
        return label !== ''
      })

      let description = entry.name + ' (' + attributeLabels.join('; ') + ')'

      if (entry.materialName) {
        description += ' (' + entry.materialName + ')'
      }

      return {
        id: entry.productId,
        index: entryIndex,
        currency: loc.currency,
        amount: entry.entryAmount,
        description,
        hasOverride: InvoiceUtils.hasOverride(entry),
        defaultPrice: InvoiceUtils.getDefaultPrice(invoiceSettings, entry, deductVat),
        unitPrice: InvoiceUtils.getEntryUnitPriceWithoutVat(entry),
        entryTotal: InvoiceUtils.getEntryTotal(invoiceSettings, entry, deductVat),
        priceOverride: entry.priceOverride,
      }
    })

    if (loc.sellBook) {
      const { book } = this.state

      if (book) {
        // Convert '-' to 0 instead of NaN while negative number is not fully entered
        const price = book.price === '-' ? 0 : Number(book.price)

        rowsData.push({
          id: '$book', // Must not conflict with any other row id
          type: 'book',
          rowClassName: 'row-book',
          currency: loc.currency,
          amount: Number(book.quantity),
          description: InvoiceUiUtils.getBookDesc(false),
          unitPrice: price,
          entryTotal: Number(book.quantity) * price,
        })
      }
    }

    return rowsData
  }

  getColumnConf = () => {
    const columnConf: Column<Row>[] = []

    columnConf.push({
      id: 'quantity',
      header: i18n.t('invoice.quantity'),
      getCellContents: (rowData) => {
        return (
          <div>
            {this.renderAmount(rowData)}
            {ValidationUtils.render(this.state.validationErrors, 'entries.' + rowData.index + '.amount')}
          </div>
        )
      },
    })

    columnConf.push({
      id: 'item',
      header: i18n.t('invoice.item'),
      getCellContents: (rowData) => {
        let rawMaterialSelect = null
        const user = User.getUser()
        if (Access.setProductRawMaterial(user)) {
          rawMaterialSelect = (
            <RawMaterialSelect
              productId={rowData.id}
              rawMaterials={this.state.rawMaterials}
              onSave={() => {
                this.loadEverything(this.props)
              }}
            />
          )
        }

        return (
          <span>
            <span>
              {rowData.description}
            </span>
            {' '}
            {rawMaterialSelect}
          </span>
        )
      },
    })

    columnConf.push({
      id: 'unit-price',
      header: i18n.t('common.unit-price'),
      getCellProperties: function() {
        return { style: { whiteSpace: 'nowrap' } }
      },
      getCellContents: this.renderUnitPrice,
    })

    columnConf.push({
      id: 'total',
      header: i18n.t('common.total'),
      getCellProperties: function() {
        return { style: { minWidth: '7em' } }
      },
      getCellContents: this.renderRowTotal,
    })

    return columnConf
  }

  getTotal = () => {
    return InvoiceUtils.getTotal(
      this.state.invoiceSettings, this.getInvoiceValues(), this.shouldDeductVat(),
    )
  }

  getCoveredAmount = () => {
    return InvoiceUtils.getCoveredAmount(
      this.state.actualPayments, this.state.location.currency, this.state.rates,
    )
  }

  getDroitsDeTimbre = () => {
    return InvoiceUtils.getDroitsDeTimbre(
      this.state.invoiceSettings,
      this.state.rates,
      this.state.location.currency,
      this.state.actualPayments,
    )
  }

  calculateGuideCommission = () => {
    const { invoiceSettings } = this.state
    const total = this.getTotal() - Number(this.state.shipping)

    return InvoiceUtils.getGuideCommission(invoiceSettings, total)
  }

  updateCustomerName = (customerName) => {
    this.setState({ customerName })
  }

  selectCustomer = (customer) => {
    this.setState({
      customerName: customer.name,
      customerContact: customer.contact,
    })
  }

  renderMenus = () => {
    if (this.props.isNew) {
      return <MainMenu activeTab="cart" />
    }
    else {
      return [
        <MainMenu key="main" activeTab="reports" />,
        <ReportsMenu key="reports" activeTab="sales" />,
      ]
    }
  }

  renderTitle = () => {
    const title = (this.props.isNew ?
      i18n.t('invoice.new-invoice') :
      i18n.t('invoice.invoice-number', this.props.invoiceId)
    )

    const style: CSSProperties = { display: 'inline-block', width: '60%', fontSize: '2em', fontWeight: 'bold' }

    return <div id="invoice-title" style={style}>{title}</div>
  }

  renderDateAndLocation = () => {
    const userTime = CommonUtils.toUserTime(User.getUser().country, new Date(this.state.time))

    const style: CSSProperties = {
      display: 'inline-block',
      width: '40%',
      textAlign: 'right',
      verticalAlign: 'bottom',
    }

    return (
      <div style={style}>
        <div>
          <b>
            {i18n.t('common.date')}
            {': '}
          </b>
          <span id="lbl-date">
            {CommonUtils.utcDateDMY(userTime)}
          </span>
        </div>
        <div>
          <b>
            {i18n.t('common.location')}
            {': '}
          </b>
          <a
            id="lnk-location"
            href={'#/inventory/sales-points/' + this.state.location._id}
          >
            {this.state.location.names[User.getLanguage()]}
          </a>
        </div>
      </div>
    )
  }

  renderAmount = (rowData) => {
    if (rowData.type === 'book' && this.props.isNew) {
      return (
        <div>
          {bindInput(this, ['book', 'quantity'], {
            id: 'inp-book-quantity',
            style: { width: '5em' },
            validator: Utils.nonNegativeIntegerValidator,
          })}
          {ValidationUtils.render(this.state.validationErrors, 'book.quantity')}
        </div>
      )
    }
    else {
      return rowData.amount
    }
  }

  renderUnitPrice = (rowData) => {
    if (!this.props.isNew) {
      return (
        <div>
          {CommonUtils.formatDecimal(rowData.unitPrice)}
          {' '}
          {rowData.currency}
        </div>
      )
    }
    else if (rowData.type === 'book') {
      return (
        <span>
          {bindInput(this, ['book', 'price'], {
            id: 'inp-book-price',
            style: { width: '5em' },
            validator: Utils.decimalValidator,
          })}
          {' '}
          {rowData.currency}
          {ValidationUtils.render(this.state.validationErrors, 'book.price')}
        </span>
      )
    }
    else if (rowData.hasOverride) {
      return this.renderOverriddenUnitPrice(rowData)
    }
    else {
      return this.renderOverridableUnitPrice(rowData)
    }
  }

  renderOverriddenUnitPrice = (rowData) => {
    let overrideNote = null

    if (!isNaN(rowData.defaultPrice)) {
      overrideNote = [
        ' (',
        <span key="dummy" className="lbl-overridden-from">
          {i18n.t(
            'invoice.overridden-from',
            CommonUtils.formatDecimal(rowData.defaultPrice, true),
            rowData.currency,
          )}
        </span>,
        ')',
      ]
    }

    return (
      <div>
        <input
          className="inp-override"
          style={{ width: '5em' }}
          value={rowData.priceOverride}
          onChange={(evt) => {
            const { value } = evt.currentTarget

            if (Utils.nonNegativeDecimalValidator(value)) {
              const newEntries = CommonUtils.clone(this.state.entries)
              newEntries[rowData.index].priceOverride = value
              this.setState({ entries: newEntries })
            }
          }}
          onBlur={(evt) => {
            const { value } = evt.currentTarget

            if (Number(value) === CommonUtils.round(rowData.defaultPrice)) {
              const newEntries = CommonUtils.clone(this.state.entries)
              delete newEntries[rowData.index].priceOverride
              this.setState({ entries: newEntries })
            }
          }}
        />
        {' '}
        {rowData.currency}
        {overrideNote}
        {ValidationUtils.render(this.state.validationErrors, 'entries.' + rowData.index + '.price')}
      </div>
    )
  }

  renderOverridableUnitPrice = (rowData) => {
    let priceText

    if (isNaN(rowData.defaultPrice)) {
      priceText = 'N/A'
    }
    else {
      priceText = CommonUtils.formatDecimal(rowData.defaultPrice) + ' ' + rowData.currency
    }

    return (
      <div>
        <div
          className="lbl-unit-price"
          style={{ display: 'inline-block', minWidth: '6.5em' }}
        >
          {priceText}
        </div>
        {' '}
        <button
          className="btn-override"
          onClick={(evt) => {
            let override = ''

            if (rowData.defaultPrice) {
              override = String(CommonUtils.round(rowData.defaultPrice))
            }

            const newEntries = CommonUtils.clone(this.state.entries)
            newEntries[rowData.index].priceOverride = override

            const parentCell = Utils.findParent(evt.target as HTMLButtonElement, 'td')

            this.setState({ entries: newEntries }, function() {
              const node = parentCell.querySelector<HTMLInputElement>('input.inp-override')
              node.focus()

              // Select the entire value
              node.selectionStart = 0
              node.selectionEnd = node.value.length
            })
          }}
        >
          {i18n.t('action.override')}
        </button>
      </div>
    )
  }

  renderRowTotal = (rowData) => {
    const total = rowData.entryTotal

    if (isNaN(total)) {
      return 'N/A'
    }
    else {
      return CommonUtils.formatDecimal(total) + ' ' + rowData.currency
    }
  }

  renderCustomerName = () => {
    if (this.props.isNew) {
      return (
        <SearchableCustomer
          customers={this.state.customers}
          nameValue={this.state.customerName || ''}
          onChange={this.updateCustomerName}
          onSelect={this.selectCustomer}
        />
      )
    }
    else if (this.state.customerName) {
      return (
        <div style={{ marginBottom: 10 }}>
          <div style={{ fontWeight: 'bold' }}>
            {i18n.t('invoice.recipient-name')}
            :
          </div>
          <div id="lbl-recipient-name" style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.customerName}
          </div>
        </div>
      )
    }
  }

  renderCustomerContact = () => {
    if (this.props.isNew) {
      // TODO: verify in automated tests?
      return (
        <div>
          <div style={{ fontWeight: 'bold' }}>
            {i18n.t('invoice.contact-info')}
            :
          </div>
          <textarea
            {...bindProps(this,
              ['customerContact'],
              { id: 'inp-customer-contact', style: { width: '100%', height: '12em' } },
            )}
          />
        </div>
      )
    }
    else if (this.state.customerContact) {
      return (
        <div style={{ marginBottom: 10 }}>
          <div style={{ fontWeight: 'bold' }}>
            {i18n.t('invoice.contact-info')}
            :
          </div>
          <div id="lbl-customer-contact" style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.customerContact}
          </div>
        </div>
      )
    }
  }

  renderNotes = () => {
    if (this.props.isNew) {
      return (
        <div>
          <div style={{ fontWeight: 'bold' }}>
            {i18n.t('invoice.notes')}
            :
          </div>
          <textarea
            {...bindProps(this,
              ['note'], { id: 'inp-notes', style: { width: '100%', height: '12em' } },
            )}
          />
        </div>
      )
    }
    else if (this.state.note) {
      return (
        <div>
          <div style={{ fontWeight: 'bold' }}>
            {i18n.t('invoice.notes')}
            :
          </div>
          <div id="lbl-note" style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.note}
          </div>
        </div>
      )
    }
  }

  renderShippingFee = () => {
    const { currency } = this.state.location

    if (this.props.isNew) {
      return (
        <div style={{ margin: '0.4em 0' }}>
          {i18n.t('invoice.shipping-fee')}
          :
          {bindInput(this, ['shipping'], {
              id: 'inp-shipping',
              style: { width: '5em', marginLeft: '0.7em' },
              validator: Utils.decimalValidator,
            })}
          {' '}
          {currency}
        </div>
      )
    }
    else if (this.state.shipping) {
      return (
        <div>
          <b>
            {i18n.t('invoice.shipping-fee')}
            {': '}
          </b>
          <span className="lbl-shipping-fee">
            {CommonUtils.formatDecimal(Number(this.state.shipping))}
            {' '}
            {currency}
          </span>
        </div>
      )
    }
  }

  renderExport = () => {
    if (this.props.isNew) {
      return (
        <div>
          {bindCheckbox(this, ['export'], { id: 'chk-export' })}
          {' '}
          {i18n.t('invoice.export-invoice')}
        </div>
      )
    }
  }

  renderMarraCashCard = () => {
    if (this.props.isNew || this.state.marraCashCard) {
      const { currency } = this.state.location
      const settings = this.state.invoiceSettings

      const invoiceValues = this.getInvoiceValues()

      const discount = InvoiceUtils.getMarraCashCardDiscount(
        settings, invoiceValues, this.shouldDeductVat(),
      )

      const text = i18n.t('invoice.marra-cash-card-discount', String(CommonUtils.round(discount)), currency)

      if (this.props.isNew) {
        return (
          <div>
            {bindCheckbox(this, ['marraCashCard'], { id: 'chk-marra-cash-card' })}
            {' '}
            <span id="lbl-marra-cash-card">
              {text}
            </span>
          </div>
        )
      }
      else if (this.state.marraCashCard) {
        return <div id="lbl-marra-cash-card">{text}</div>
      }
    }
  }

  renderTotals = () => {
    const { invoiceSettings, location: { currency } } = this.state
    const total = this.getTotal()

    const totals = InvoiceUtils.calculateTotals(invoiceSettings, total, this.state.export)

    return (
      <table className="invoice-totals">
        <tbody>
          <tr>
            <td style={{ fontWeight: 'bold' }}>
              {i18n.t('invoice.subtotal')}
              :
            </td>
            <td className="value cell-subtotal">
              {CommonUtils.formatDecimal(totals.subtotal)}
              {' '}
              {currency}
            </td>
          </tr>
          <tr>
            <td style={{ fontWeight: 'bold' }}>
              {i18n.t('invoice.vat')}
              :
            </td>
            <td className="value cell-vat">
              {CommonUtils.formatDecimal(totals.vat)}
              {' '}
              {currency}
            </td>
          </tr>
          <tr>
            <td style={{ fontWeight: 'bold' }}>
              {i18n.t('common.total')}
              :
            </td>
            <td className="value cell-total">
              {CommonUtils.formatDecimal(totals.total)}
              {' '}
              {currency}
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  renderGuideCommission = () => {
    const { currency } = this.state.location
    const guideCommission = this.calculateGuideCommission()

    if (this.props.isNew) {
      if (currency === 'MAD') {
        return (
          <div>
            {bindCheckbox(this, ['guideCommission'], { id: 'chk-guide-commission' })}
            {' '}
            {i18n.t('invoice.guide-commission')}
            {' ('}
            <span className="lbl-guide-commission">
              {CommonUtils.formatDecimal(guideCommission)}
              {' '}
              {currency}
            </span>
            )
          </div>
        )
      }
    }
    else if (this.state.guideCommission) {
      return (
        <div>
          <b>
            {i18n.t('invoice.guide-commission')}
            {': '}
          </b>
          <span className="lbl-guide-commission">
            {CommonUtils.formatDecimal(guideCommission)}
            {' '}
            {currency}
          </span>
        </div>
      )
    }
  }

  renderActualPaymentRows = () => {
    const { currency } = this.state.location

    if (!this.props.isNew || this.state.droitsDeTimbre) {
      return InvoiceUtils.getSortedActualPayments(
        this.state.actualPayments, this.state.rates, currency, false,
      )
      .map(function(payment) {
        return (
          <div
            key={payment.key}
            id={'actual-payment-row-' + payment.key}
            style={{ margin: '0.2em 0' }}
          >
            {payment.str}
          </div>
        )
      })
    }
    else {
      const total = this.getTotal()
      const covered = this.getCoveredAmount()
      const uncovered = total - covered

      return InvoiceUiUtils.getActualPaymentRows(
        this.state.actualPayments,
        uncovered,
        currency,
        this.state.rates,
        total,
        (newActualPayments) => {
          this.setState({ actualPayments: newActualPayments })
        },
      )
    }
  }

  renderActualPaymentHint = () => {
    if (this.props.isNew && !this.state.droitsDeTimbre) {
      return (
        <div style={{ fontSize: '80%', width: '28em', marginTop: '1em' }}>
          {i18n.t('invoice.actual-payment-hint')}
        </div>
      )
    }
  }

  renderDroitsDeTimbre = () => {
    const ddt = this.getDroitsDeTimbre()

    if (ddt !== 0) {
      const { currency } = this.state.location

      if (this.props.isNew) {
        return (
          <div style={{ color: '#c00', marginTop: 10 }}>
            {bindCheckbox(this, ['droitsDeTimbre'], { id: 'chk-droits-de-timbre' })}
            {' '}
            <span id="lbl-droits-de-timbre">
              {i18n.t('invoice.droits-de-timbre-paid', String(CommonUtils.round(ddt)), currency)}
            </span>
          </div>
        )
      }
      else if (this.state.droitsDeTimbre) {
        return (
          <div id="lbl-droits-de-timbre" style={{ marginTop: '0.5em' }}>
            <b>
              {i18n.t('invoice.droits-de-timbre')}
              :
            </b>
            {' '}
            {CommonUtils.round(ddt)}
            {' '}
            {currency}
          </div>
        )
      }
    }
  }

  renderCardCommission = () => {
    const cardCommission = InvoiceUtils.getCardCommission(
      this.state.invoiceSettings, this.state.actualPayments,
    )

    if (cardCommission) {
      // TODO: add label verification to tests
      return (
        <div style={{ marginTop: '0.5em' }}>
          <b>
            {i18n.t('invoice.card-commission')}
            :
          </b>
          {' '}
          <span className="lbl-card-commission">
            {CommonUtils.formatDecimal(cardCommission)}
            {' MAD'}
          </span>
        </div>
      )
    }
  }

  renderConfirmButton = () => {
    if (this.props.isNew) {
      let disabled = false
      let disabledTitle = null

      const total = this.getTotal()
      const covered = this.getCoveredAmount()
      const uncovered = total - covered

      if (covered === 0 && uncovered > 0) {
        disabled = true
        disabledTitle = i18n.t('invoice.no-actual-payments')
      }

      if (!disabled && !this.state.droitsDeTimbre) {
        const ddt = this.getDroitsDeTimbre()

        if (ddt > 0) {
          disabled = true
          disabledTitle = i18n.t('invoice.droits-de-timbre-needed')
        }
      }

      return (
        <LoadingButton
          getPromise={this.confirm}
          text={i18n.t('invoice.confirm-sale')}
          disabled={disabled}
          disabledTitle={disabledTitle}
          id="btn-confirm"
        />
      )
    }
  }

  renderCancelButton = () => {
    if (this.props.isNew) {
      return (
        <button id="btn-cancel" onClick={this.cancel}>
          {i18n.t('action.cancel')}
        </button>
      )
    }
  }

  renderPrintButton = () => {
    if (!this.props.isNew) {
      return (
        <button id="btn-print" onClick={this.print}>
          {i18n.t('invoice.print')}
        </button>
      )
    }
  }

  renderCorrectButton = () => {
    if (!this.props.isNew) {
      const user = User.getUser()
      let hasPermission

      if (this.state.corrected) {
        // Only admins and power users can correct an already corrected invoice
        hasPermission = Access.editFullInvoice(user, this.state.location)
      }
      else {
        hasPermission = Access.sales(user, this.state.location)
      }

      if (hasPermission) {
        return (
          <button id="btn-correct" onClick={this.correct}>
            {i18n.t('invoice.correct')}
          </button>
        )
      }
    }
  }

  renderDetails = () => {
    if (!this.props.isNew || this.allPricesAreValid()) {
      return (
        <div>
          <div id="notes-parent">
            {this.renderCustomerName()}
            {this.renderCustomerContact()}
            {this.renderNotes()}
          </div>
          {this.renderShippingFee()}
          {this.renderExport()}
          {this.renderMarraCashCard()}
          {this.renderTotals()}
          {this.renderGuideCommission()}
          <div style={{ marginTop: '1em', marginBottom: '0.5em', fontWeight: 'bold' }}>
            {i18n.t('invoice.actual-payments')}
            :
          </div>
          <div id="actual-payments">
            {this.renderActualPaymentRows()}
          </div>
          {this.renderActualPaymentHint()}
          {this.renderDroitsDeTimbre()}
          {this.renderCardCommission()}
          <div style={{ marginTop: '1em' }}>
            {this.renderConfirmButton()}
            {' '}
            {this.renderCancelButton()}
            {' '}
            {this.renderPrintButton()}
            {' '}
            {this.renderCorrectButton()}
            {' '}
          </div>
        </div>
      )
    }
    else {
      return (
        <div id="lbl-prices-missing">
          {i18n.t('invoice.prices-missing', this.state.location.currency)}
        </div>
      )
    }
  }

  render() {
    if (!this.state.loaded) {
      return (
        <div>
          {this.renderMenus()}
          {i18n.t('common.loading')}
        </div>
      )
    }

    const columnConf = this.getColumnConf()
    const rowsData = this.getRowsData()

    return (
      <div>
        {this.renderMenus()}
        <div id="form-invoice" style={{ maxWidth: 1200 }}>
          <div style={{ marginBottom: '0.3em' }}>
            {this.renderTitle()}
            {this.renderDateAndLocation()}
          </div>
          {ValidationUtils.render(this.state.validationErrors, 'entries')}
          {UiUtils.getTable(columnConf, rowsData, {
            tableId: 'tbl-invoice', rowClassName: 'row-invoice',
          })}
          {this.renderDetails()}
        </div>
      </div>
    )
  }
}
