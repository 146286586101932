import React = require('react')

interface ProgressBarProps {
  percentComplete: number,
}

export const ProgressBar = ({ percentComplete }: ProgressBarProps) => (
  <div className="progress-bar-container">
    <div className="progress-bar" style={{ width: percentComplete + '%' }} />
  </div>
)
