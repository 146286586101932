import update from 'immutability-helper'

import { strnatcmp } from '../client-lib'
import { CommonUtils } from '../common/common-utils'
import { AttributeDefinition } from '../common/data-attribute-defs'
import { Location } from '../common/data-locations'
import { OrderedMaterial } from '../common/data-material-order'
import { Category, UserCountry } from '../common/data-misc'
import { ProductDefinition } from '../common/data-product-defs'
import { Product } from '../common/data-products'
import { RawMaterial } from '../common/data-raw-materials'
import { TemplateDefinition } from '../common/data-template-defs'
import { Template } from '../common/data-templates'
import { UiUser } from '../common/data-users'
import { Enums, Language } from '../common/enums'
import { i18n } from '../common/i18n'
import { AttributeCombination } from '../common/types'

interface ComboSource {
  values: string[],
  category: string,
}

interface AttributeFilterOption {
  id: string,
  value: string[],
  label: string,
  sortKey: string,
}

interface AdditionalCost {
  shipping?: number,
  analysis?: number,
  storage?: number,
  delivery?: number,
  customs?: number,
  other?: number,
}

let testMode = false
const preparedConfirmationResponses: boolean[] = []
const expectedConfirmationMessages = []
let onNextConfirm: (() => void) | null = null

// TODO: split methods between UiUtils and CommonUtils?
export const Utils = {

  // TODO: move to UiUtils or implement without React
  merge: function(a, b) {
    return update(a, { $merge: b || {} })
  },

  byId: function<T extends { _id: string }>(array: T[]): Record<string, T> {
    const byId: Record<string, T> = {}

    array.forEach(function(obj) {
      if (!obj._id) {
        throw new Error('Object has no _id')
      }

      if (obj._id in byId) {
        throw new Error('Duplicate _id: ' + obj._id)
      }

      byId[obj._id] = obj
    })

    return byId
  },

  findIndex: function<T>(array: T[], matcher: (value: T) => boolean): number {
    for (let i = 0; i < array.length; i += 1) {
      if (matcher(array[i])) {
        return i
      }
    }

    return -1
  },

  findIndexByField: function<T, K extends keyof T>(array: T[], fieldName: K, value: T[K]): number {
    return Utils.findIndex(array, function(element) {
      return element[fieldName] === value
    })
  },

  // TODO: move validators to UiUtils
  decimalValidator: function(value: string): boolean {
    // Allow integers or decimals with up to 2 digits after the period.
    // As this is evaluated on each key press, it must also allow strings
    // like "-", "2." and "" before the value has been completely typed.
    // A more strict validation is performed on the server.
    return /^-?(\d+\.?\d?\d?)?$/.test(value)
  },

  nonNegativeDecimalValidator: function(value: string): boolean {
    // Allow integers or decimals with up to 2 digits after the period.
    // As this is evaluated on each key press, it must also allow strings
    // like "2." and "" before the value has been completely typed.
    // A more strict validation is performed on the server.
    return /^(\d+\.?\d?\d?)?$/.test(value)
  },

  integerValidator: function(value: string): boolean {
    // As this is evaluated on each key press, it must also allow strings
    // like "-" and "" before the value has been completely typed.
    // A more strict validation is performed on the server.
    return /^-?(0|[1-9]\d*)?$/.test(value)
  },

  nonNegativeIntegerValidator: function(value: string): boolean {
    // As this is evaluated on each key press, it must also allow empty string.
    // A more strict validation is performed on the server.
    return /^(0|[1-9]\d*)?$/.test(value)
  },

  formatInteger: function(value: number): string {
    if (value < 0) {
      return '-' + CommonUtils.breakThousands((-value).toString())
    }
    else {
      return CommonUtils.breakThousands(value.toString())
    }
  },

  getAlreadyHandledError: function(): Error {
    // Used to skip elements of promise chains after errors have been handled.
    // If it reaches window.onerror, it will be silently ignored.

    const error: Error & { alreadyHandled?: boolean } = new Error('Handled error - can be ignored')
    error.alreadyHandled = true
    return error
  },

  // Discards keys, retains values (TODO: inline)
  objectToArray: function<T>(obj: Record<string, T>): T[] {
    return Object.values(obj)
  },

  // Checks whether two arrays contain the same elements, regardless of order.
  // There must be no duplicate elements. Not optimized for large arrays.
  containSameElements: function<T>(array1: T[], array2: T[]): boolean {
    if (array1.length !== array2.length) {
      return false
    }

    // For avoiding duplicates
    const seenIndexes = {}

    return array1.every(function(element) {
      const index = array2.indexOf(element)

      if (index === -1) {
        return false
      }
      else {
        if (index in seenIndexes) {
          throw new Error('Found multiple instances of ' + element)
        }

        seenIndexes[index] = true
        return true
      }
    })
  },

  // Case-insensitive
  startsWith: function(haystack: string, needle: string): boolean {
    return haystack.toLowerCase().substr(0, needle.length) === needle.toLowerCase()
  },

  upperCaseFirst: function(string: string): string {
    return string[0].toUpperCase() + string.substr(1)
  },

  // TODO: move to UiUtils
  findParent: function(node: Element, selector): Element | null {
    if (!node.parentNode) {
      return null
    }

    const parent = node.parentNode as Element

    if (parent.matches(selector)) {
      return parent
    }
    else {
      return Utils.findParent(parent, selector)
    }
  },

  // TODO: move to UiUtils
  getTimeFilterLast14Days: function(country: UserCountry): { from: string, to: string } {
    const end = CommonUtils.getNow()

    const start = new Date(end)
    start.setUTCDate(start.getUTCDate() - 13)

    return {
      from: CommonUtils.utcDateYMD(CommonUtils.toUserTime(country, start)),
      to: CommonUtils.utcDateYMD(CommonUtils.toUserTime(country, end)),
    }
  },

  getTimeFilterCurrentMonth: function(country: UserCountry): { from: string, to: string } {
    const now = CommonUtils.getNow()

    const start = new Date(now)
    start.setUTCDate(1)

    const end = CommonUtils.getLastDayOfMonth(now)

    return {
      from: CommonUtils.utcDateYMD(CommonUtils.toUserTime(country, start)),
      to: CommonUtils.utcDateYMD(CommonUtils.toUserTime(country, end)),
    }
  },

  // TODO: move to User?
  getDefaultRoute: function(user: UiUser): string {
    const { role } = user

    // Choose default page based on role
    if (role === Enums.roles.production) {
      return '/inventory/production'
    }
    else if (role === Enums.roles.rawMaterials) {
      return '/raw-materials'
    }
    else if (role === Enums.roles.secretary) {
      return '/inventory/sales-points'
    }
    else if (role === Enums.roles.sales) {
      if (user.allowedLocations.length) {
        return '/inventory/sales-points/' + user.allowedLocations[0]
      }
      else {
        return '/inventory/sales-points'
      }
    }
    else {
      return '/product-defs'
    }
  },

  setTestMode: function(): void {
    testMode = true
  },

  // TODO: move to UiUtils
  print: function(): void {
    if (!testMode) {
      print()
    }
  },

  // TODO: move to UiUtils
  confirm: async function(message: string): Promise<boolean> {
    if (testMode) {
      if (!preparedConfirmationResponses.length) {
        throw new Error('No confirmation response prepared for "' + message + '"')
      }
      else {
        if (expectedConfirmationMessages.length) {
          const expectedMessage = expectedConfirmationMessages.shift()

          if (message !== expectedMessage) {
            throw new Error(
              'Confirmation message "' + message + '" does not match expected message "' +
              expectedMessage + '"',
            )
          }
        }

        const result = preparedConfirmationResponses.shift()

        if (onNextConfirm) {
          const func = onNextConfirm
          onNextConfirm = null
          func()
        }

        return result
      }
    }
    else {
      return new Promise<boolean>(function(resolve) {
        // Using setTimeout to avoid a use case that may become unsupported in Chrome:
        // https://bugs.chromium.org/p/chromium/issues/detail?id=605517
        setTimeout(function() {
          resolve(confirm(message))
        }, 0)
      })
    }
  },

  // TODO: move to UiUtils
  confirmTr: function(i18nKey: string): Promise<boolean> {
    return Utils.confirm(i18n.t(i18nKey))
  },

  // Used by tests
  prepareConfirmationResponse: function(response: boolean): void {
    if (response === true || response === false) {
      preparedConfirmationResponses.push(response)
    }
    else {
      throw new Error('Invalid confirmation response: ' + response)
    }
  },

  // Used by tests
  expectConfirmationMessage: function(message: string): void {
    expectedConfirmationMessages.push(message)
  },

  // Used by tests
  waitForNextConfirmation: function(): Promise<void> {
    return new Promise<void>(function(resolve) {
      onNextConfirm = resolve
    })
  },

  // Utility for adding conditional steps to a promise chain that must only run
  // if the previous promise resolves to the boolean true.
  // If the previous promise does not resolve to true, this step resolves to false
  // so the next step can also use runIfTrue.
  // Useful in combination with Utils.confirm()
  runIfTrue: function<T>(func: () => T): (shouldRun: boolean) => (T | false) {
    return (shouldRun) => {
      if (shouldRun === true) {
        return func()
      }
      else {
        return false
      }
    }
  },

  // TODO: move to UiUtils
  getEnumFilterConf: function(enumIds: string[], i18nPrefix: string, labelKey: string) {
    return {
      type: 'predefined',
      labelKey,
      options: enumIds.map(function(enumId) {
        return {
          value: enumId,
          label: i18n.t('enum.' + i18nPrefix + '.' + enumId),
        }
      }),
    }
  },

  getCategoryFilterConf: function(categories, language, includeCustom, getDefinition) {
    // Using exclamation mark as a symbol that doesn't occur in other valid IDs
    const customCategory = '!custom'

    const categoryOptions = categories.map(function(category) {
      return { value: category._id, label: category.labels[language] }
    })

    categoryOptions.sort(function(opt1, opt2) {
      return opt1.label.localeCompare(opt2.label)
    })

    if (includeCustom) {
      categoryOptions.push({ value: customCategory, label: i18n.t('common.custom') })
    }

    return {
      type: 'predefined',
      labelKey: 'common.category',
      options: categoryOptions,
      getField: function(obj) {
        const definition = getDefinition(obj)
        return definition.isCustom ? customCategory : definition.category
      },
    }
  },

  // TODO: separate util modules for attribute-specific stuff, etc?

  // If combination is from a linked template definition on a product definition,
  // the parameter will be an id that gets converted to a full combination object.
  // Otherwise returns the full object as is.
  ensureCombination: function(
    combination: AttributeCombination | number,
    attribute: AttributeDefinition,
    definition,
    templateDefinitions: TemplateDefinition[],
  ): AttributeCombination {
    if (definition.template && CommonUtils.attributeContextFilters.templates(attribute)) {
      const combinationId = combination as number
      const templateDefinition = CommonUtils.findById(templateDefinitions, definition.template)
      const templateCombos = templateDefinition.attributeCombinations[attribute._id]
      return CommonUtils.findByField(templateCombos, 'id', combinationId)
    }

    return combination as AttributeCombination
  },

  getDefinitionCompareFunction: function(language: Language, categories: Category[]) {
    // Compare first by category name, then by definition name

    return function(def1, def2) {
      const category1 = CommonUtils.findById(categories, def1.category).labels[language]
      const category2 = CommonUtils.findById(categories, def2.category).labels[language]

      let result = category1.localeCompare(category2)

      if (result === 0) {
        result = def1.name.localeCompare(def2.name)
      }

      return result
    }
  },

  // If locations param is not provided, the items will not be sorted by location.
  // Often used when all items are from the same location.
  sortInventory: function(
    items: (Template | Product)[],
    language: Language,
    categories: Category[],
    attributeDefinitions: AttributeDefinition[],
    definitions: ProductDefinition[], // TODO or TemplateDefinition[]?
    templateDefinitions: TemplateDefinition[],
    locations: Location[],
  ): any[] {
    interface Sortable {
      key: (string | number)[],
      item: Template | Product,
    }

    const categoriesById = Utils.byId(categories)
    const definitionsById = Utils.byId(definitions)
    const locationsById = locations ? Utils.byId(locations) : null

    function getSortable(item: Template | Product) {
      const definition = definitionsById[item.definition]

      if (!definition) {
        throw new Error('Definition ' + item.definition + ' not found for item ' + item._id)
      }

      const category = categoriesById[definition.category]

      const key: (string | number)[] = []

      if (locations) {
        const loc = locationsById[item.location]
        key.push(loc.names[language])
      }

      key.push(definition.isCustom ? 1 : 0)
      key.push(definition.isCustom ? '' : category.labels[language])
      key.push(definition.name)

      attributeDefinitions.forEach(function(attrDef) {
        if (definition.isCustom && attrDef.textForCustom) {
          const text = definition.attributeCombinations[attrDef._id] as string
          key.push(text)
          return
        }

        const combination = CommonUtils.getCombination(item, definition, attrDef, templateDefinitions)

        if (!combination && attrDef.optional) {
          key.push('')
          return
        }

        key.push(CommonUtils.getAttributeComboSortKey(
          attrDef, combination.values, definition.category, language,
        ))
      })

      return { key, item }
    }

    // Calculate sorting information once per each item.
    // This is faster than recalculating it on each compare.
    const sortables: Sortable[] = items.map(getSortable)

    function compare<T extends string | number>(val1: T, val2: T) {
      if (typeof val1 === 'string' || typeof val2 === 'string') {
        return strnatcmp(val1, val2)
      }
      else {
        return Number(val1) - Number(val2)
      }
    }

    sortables.sort(function(sortable1, sortable2) {
      if (sortable1.key.length !== sortable2.key.length) {
        throw new Error('Sortables do not match')
      }

      for (let i = 0; i < sortable1.key.length; i += 1) {
        const result = compare(sortable1.key[i], sortable2.key[i])

        if (result !== 0) {
          return result
        }
      }

      return 0
    })

    return sortables.map((sortable) => sortable.item)
  },

  getAttributeDetailCompareFunction: function(attributeDefinitions: AttributeDefinition[]) {
    return function(entry1, entry2) {
      let result = 0

      for (let i = 0; result === 0 && i < attributeDefinitions.length; i += 1) {
        const attrId = attributeDefinitions[i]._id
        const sortKey1 = entry1.attributes[attrId].sortKey
        const sortKey2 = entry2.attributes[attrId].sortKey
        result = strnatcmp(sortKey1, sortKey2)
      }

      return result
    }
  },

  getDetailCompareFunction: function(attributeDefinitions: AttributeDefinition[]) {
    return function(entry1, entry2) {
      let result = (entry1.isCustom ? 1 : 0) - (entry2.isCustom ? 1 : 0)

      if (result === 0) {
        result = entry1.category.localeCompare(entry2.category)
      }

      if (result === 0) {
        result = entry1.name.localeCompare(entry2.name)
      }

      if (result === 0) {
        const attrCompFunc = Utils.getAttributeDetailCompareFunction(attributeDefinitions)
        result = attrCompFunc(entry1, entry2)
      }

      return result
    }
  },

  getAttributeFilterOptions: function(
    attribute: AttributeDefinition,
    language: Language,
    comboSources: ComboSource[],
  ): AttributeFilterOption[] {
    const optsByKey: Record<string, AttributeFilterOption> = {}

    attribute.valueGroups.forEach(function(valueGroups) {
      valueGroups.values.forEach(function(value) {
        const label = value.labels[language]

        optsByKey[value._id] = {
          id: value._id,
          value: [value._id],
          label,
          sortKey: value.sortKey || label,
        }
      })
    })

    comboSources.forEach(function(comboSource) {
      const key = comboSource.values.join('|')

      if (!(key in optsByKey)) {
        const label = CommonUtils.getAttributeComboLabel(
          attribute, comboSource.values, comboSource.category, language,
        )

        const sortKey = CommonUtils.getAttributeComboSortKey(
          attribute, comboSource.values, comboSource.category, language,
        )

        optsByKey[key] = {
          id: key,
          value: comboSource.values,
          label,
          sortKey,
        }
      }
    })

    const options = Utils.objectToArray(optsByKey)

    options.sort(function(option1, option2) {
      return strnatcmp(option1.sortKey, option2.sortKey)
    })

    return options
  },

  getCombinationValues: function(
    item,
    attribute: AttributeDefinition,
    productDefinitions,
    templateDefinitions: TemplateDefinition[],
  ) {
    if (attribute.optional && !item.attributes[attribute._id]) {
      return []
    }

    const definition = CommonUtils.findById<any>(productDefinitions, item.definition)

    if (definition.isCustom && attribute.textForCustom) {
      return []
    }

    let combinations: AttributeCombination[] = definition.attributeCombinations[attribute._id]

    // TODO: unduplicate with ensureCombination
    if (definition.template && CommonUtils.attributeContextFilters.templates(attribute)) {
      const templateDefinition = CommonUtils.findById(templateDefinitions, definition.template)

      const combinationIds = combinations

      combinations = templateDefinition.attributeCombinations[attribute._id].filter(
        CommonUtils.getCombinationIdFilter(combinationIds),
      )
    }

    const combinationId = item.attributes[attribute._id]
    const combination = CommonUtils.findByField(combinations, 'id', combinationId)

    return combination.values
  },

  getSortedDefinitionNames: function(definitions: { name: string }[]): string[] {
    // Use a set to filter out duplicates
    const nameSet: Record<string, true> = {}

    definitions.forEach(function(definition) {
      nameSet[definition.name] = true
    })

    const names = Object.keys(nameSet)

    names.sort(function(name1, name2) {
      return name1.localeCompare(name2)
    })

    return names
  },

  // TODO: unduplicate with above?
  getSortedMaterialNames: function(materials: RawMaterial[]): string[] {
    // Use a set to filter out duplicates
    const nameSet: Record<string, true> = {}

    materials.forEach(function(material) {
      nameSet[material.name] = true
    })

    const names = Object.keys(nameSet)

    names.sort(function(name1, name2) {
      return name1.localeCompare(name2)
    })

    return names
  },

  nonCustomDefinitionFilter: function(definition) {
    return !definition.isCustom
  },

  sumTotalForInvoice(
    currentOrder: OrderedMaterial[],
    orderCost: number,
    orderAmount: number,
    additionalExpenses: AdditionalCost,
    currency: number): number {
    const madSummary = Math.floor(currentOrder
      .map((itm) => ((itm.amount * itm.originalCost) * currency))
      .reduce((acc, numm) => acc + numm, 0))
    const summAdditional = Math.round(Object.values(additionalExpenses)
    .reduce((acc, numm, index) => {
      const isShipping = Object.keys(additionalExpenses)[index] === 'shipping'
      if (isShipping) {
        return acc + ((Number(numm) || 0) * currency)
      } else {
        return acc + (Number(numm) || 0)
      }
    }, 0))
    const totalMad = (orderAmount * orderCost) * currency
    const divideSumms = (summAdditional/ madSummary) * totalMad
    const response = Math.trunc((divideSumms * 100) / 100)
    return !isNaN(response) ? response : 0
  },
}
