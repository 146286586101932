import React = require('react')

import { CommonUtils } from '../common/common-utils'
import { Location } from '../common/data-locations'
import { getDataService } from '../common/data-service'
import { Enums } from '../common/enums'
import { EventBus } from '../common/event-bus'
import { BackToIndexLink } from './back-to-index-link'
import { DataEditor, DataEditorField } from './data-editor'
import { EditorUtils } from './editor-utils'
import { MainMenu } from './main-menu'
import { User } from './user'

interface LocationEditorState {
  loaded: boolean,
  objs: Location[],
}

export class LocationEditor extends React.Component<Record<string, never>, LocationEditorState> {
  state = { loaded: false, objs: [] }

  componentDidMount() {
    this.reloadData()
  }

  reloadData = () => {
    return getDataService().Locations.getAll()
    .then((objs) => {
      CommonUtils.sortAsStrings(objs, (loc) => {
        return loc.names[User.getLanguage()].toLowerCase()
      })

      this.setState({ loaded: true, objs }, EventBus.fireFunc('locations-rendered'))
    })
  }

  render() {
    const mainMenu = <MainMenu activeTab="data" />

    if (!this.state.loaded) {
      return (
        <div>
          {mainMenu}
          Loading...
        </div>
      )
    }

    const fields = EditorUtils.getLanguageFields('names')

    const currencyField: DataEditorField = {
      id: 'currency',
      header: 'Default currency',
      type: 'enum',
      enumValues: ['MAD', 'EUR'],
      immutable: true,
      getValue: function(obj) {
        return obj.currency
      },
      validationKey: 'currency',
    }

    const showOrderIdField: DataEditorField = {
      id: 'showOrderId',
      header: 'Order ID column',
      type: 'boolean',
      getValue: function(obj) {
        return obj.showOrderId
      },
      validationKey: 'showOrderId',
    }

    const sellBookField: DataEditorField = {
      id: 'sellBook',
      header: 'Sell book',
      type: 'boolean',
      getValue: function(obj) {
        return obj.sellBook
      },
      validationKey: 'sellBook',
    }

    const deactivatedField: DataEditorField = {
      id: 'deactivated',
      header: 'Deactivated',
      type: 'boolean',
      getValue: function(obj) {
        return obj.deactivated
      },
      validationKey: 'deactivated',
    }

    return (
      <div>
        {mainMenu}
        <BackToIndexLink />
        {[
          { id: 'material', title: 'Raw material locations' },
          { id: 'production', title: 'Production locations' },
          { id: 'storage', title: 'Sales points - storage locations', isSalesPoint: true },
          { id: 'shop', title: 'Sales points - shops', canAdd: true, canDelete: true, isSalesPoint: true },
        ].map((typeConf) => {
          function getUpdates(fieldValues) {
            const names = {}

            Enums.orderedLanguages.forEach(function(lang) {
              names[lang] = fieldValues['lang-' + lang]
            })

            return {
              names,
              showOrderId: fieldValues['showOrderId'],
              sellBook: fieldValues['sellBook'],
              deactivated: fieldValues['deactivated'],
            }
          }

          function getNew(fieldValues) {
            const obj: Record<string, Record<string, boolean>> = getUpdates(fieldValues)
            obj.currency = fieldValues['currency']
            obj.showOrderId = fieldValues['showOrderId']
            obj.sellBook = fieldValues['sellBook']
            obj.deactivated = fieldValues['deactivated']
            return obj
          }

          let locFields = fields

          if (typeConf.isSalesPoint) {
            locFields = locFields.slice()
            locFields.push(currencyField)
            locFields.push(showOrderIdField)
            locFields.push(sellBookField)
            locFields.push(deactivatedField)
          }

          return (
            <div key={typeConf.id}>
              <h4>
                {typeConf.title}
              </h4>
              <DataEditor
                canAdd={Boolean(typeConf.canAdd)}
                canDelete={Boolean(typeConf.canDelete)}
                fields={locFields}
                objs={this.state.objs.filter(function(loc) {
                  return loc.type === typeConf.id
                })}
                actions={EditorUtils.getServiceActions(getDataService().Locations)}
                getNew={getNew}
                getUpdates={getUpdates}
                reloadData={this.reloadData}
              />
            </div>
          )
        })}
      </div>
    )
  }
}
