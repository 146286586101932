import React = require('react')

import { CommonUtils } from '../common/common-utils'
import { Language } from '../common/enums'
import { i18n } from '../common/i18n'
import { LangStr } from '../common/types'
import { CategoryState } from './earning-categories'
import { TableButton } from './table-button'

const { AddButton, SaveButton, DeleteButton } = TableButton

interface UpdateRowProps {
  category: CategoryState,
  idx: number,
  canDelete: boolean,
  onChange: (index: number, labels: LangStr) => void,
  onSave: (index: number) => void,
  onDelete?: (index: number) => void,
}

interface UpdateRowState {
  isSaving: boolean,
  isDeleting: boolean,
}

interface AddRowProps {
  onAdd: (labels: LangStr) => void,
}

interface AddRowState {
  en: string,
  fr: string,
}

interface AccountingCategoriesTableProps {
  categories: CategoryState[],
  canDelete: (category: CategoryState) => boolean,
  onCategoryChange: (index: number, labels: LangStr) => void,
  onCategorySave: (index: number) => void,
  onCategoryDelete: (index: number) => void,
  onCategoryAdd: (labels: LangStr) => void,
}

class UpdateRow extends React.Component<UpdateRowProps, UpdateRowState> {
  state: UpdateRowState = {
    isSaving: false,
    isDeleting: false,
  }

  handleLabelChange = (lang: Language, val: string) => {
    const labels = CommonUtils.clone(this.props.category.labels)
    labels[lang] = val
    this.props.onChange(this.props.idx, labels)
  }

  makeHandleLabelChange = (lang: Language) => {
    return (ev) => this.handleLabelChange(lang, ev.target.value)
  }

  shouldDisableSave = () => {
    const { category } = this.props
    return !category.changed || category.labels.en === '' || category.labels.fr === ''
  }

  handleSaveClicked = async () => {
    this.setState({ isSaving: true })
    await this.props.onSave(this.props.idx)
    this.setState({ isSaving: false })
  }

  handleDeleteClicked = async () => {
    this.setState({ isDeleting: true })
    await this.props.onDelete(this.props.idx)
  }

  renderLabelInput = (lang: Language) => {
    return (
      <input
        className="input-wide"
        value={this.props.category.labels[lang]}
        onChange={this.makeHandleLabelChange(lang)}
      />
    )
  }

  renderSaveBtn = () => {
    if (this.state.isSaving) {
      return <img src="img/loading.gif" />
    } else {
      return <SaveButton disabled={this.shouldDisableSave()} onClick={this.handleSaveClicked} />
    }
  }

  renderDeleteButton = () => {
    if (this.props.canDelete) {
      if (this.state.isDeleting) {
        return <img src="img/loading.gif" />
      } else {
        return <DeleteButton disabled={false} onClick={this.handleDeleteClicked} />
      }
    }
  }

  render() {
    return (
      <tr className="row-entry">
        <td>
          {this.renderLabelInput('en')}
        </td>
        <td>
          {this.renderLabelInput('fr')}
        </td>
        <td>
          {this.renderSaveBtn()}
          {this.renderDeleteButton()}
        </td>
      </tr>
    )
  }
}

class AddRow extends React.Component<AddRowProps, AddRowState> {
  state: AddRowState = {
    en: '',
    fr: '',
  }

  makeHandleLabelChange = (lang) => {
    return (ev) => {
      const newState = {}
      newState[lang] = ev.target.value
      this.setState(newState)
    }
  }

  shouldDisableAdd = () => {
    return this.state.en === '' || this.state.fr === ''
  }

  handleAddClicked = () => {
    const labels = CommonUtils.clone(this.state)
    this.props.onAdd(labels)
    this.setState({ en: '', fr: '' })
  }

  renderLabelInput = (lang: Language) => {
    return (
      <input
        className="input-wide"
        value={this.state[lang]}
        onChange={this.makeHandleLabelChange(lang)}
      />
    )
  }

  renderAddButton = () => {
    return <AddButton disabled={this.shouldDisableAdd()} onClick={this.handleAddClicked} />
  }

  render() {
    return (
      <tr className="row-entry">
        <td>{this.renderLabelInput('en')}</td>
        <td>{this.renderLabelInput('fr')}</td>
        <td>{this.renderAddButton()}</td>
      </tr>
    )
  }
}

export const AccountingCategoriesTable = (props: AccountingCategoriesTableProps) => {
  const renderUpdateRow = (category: CategoryState, idx: number) => (
    <UpdateRow
      key={category._id}
      category={category}
      idx={idx}
      canDelete={props.canDelete(category)}
      onChange={props.onCategoryChange}
      onDelete={props.onCategoryDelete}
      onSave={props.onCategorySave}
    />
  )

  return (
    <table className="table table-bordered table-condensed table-striped data-editor">
      <thead>
        <tr>
          <th>{i18n.t('enum.languages.en')}</th>
          <th>{i18n.t('enum.languages.fr')}</th>
          <th>{i18n.t('action.action')}</th>
        </tr>
      </thead>
      <tbody>
        {props.categories.map(renderUpdateRow)}
        <AddRow onAdd={props.onCategoryAdd} />
      </tbody>
    </table>
  )
}
