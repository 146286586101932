import classNames from 'classnames'
import Immutable = require('immutable')
import { createRef, ComponentType, CSSProperties } from 'react'
import React = require('react')
import toastr from 'toastr/toastr'

import { CommonUtils } from '../common/common-utils'
import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { bindInput } from './bind-utils'
import { Selector } from './selector'
import { User } from './user'
import { Utils } from './utils'
import { ValidationErrors, ValidationUtils } from './validation-utils'

export interface FilterManager<T> {
  getFiltered: (optionalSubset?: T[]) => T[],
  anyFilters: () => boolean,
  getIcon: (fieldName: string, small?: boolean) => JSX.Element,
  getSummary: (mainLabelKey?: string) => JSX.Element,
}

const DROPDOWN_THRESHOLD = 15

function isFiltered(type, values, customFn) {
  if (type === 'predefined') {
    return values.length > 0
  } else if (type === 'predefined-combo') {
    return values.values.length > 0
  } else if (type === 'date-range' || type === 'number-range') {
    return values.from !== '' || values.to !== ''
  } else if (type === 'custom') {
    return customFn(values)
  } else {
    throw new Error('Invalid filter type: ' + type)
  }
}

function getClearFilter(type, customClearFn) {
  if (type === 'predefined') {
    return []
  } else if (type === 'predefined-combo') {
    return { values: [], mode: 'partial' }
  } else if (type === 'date-range' || type === 'number-range') {
    return { from: '', to: '' }
  } else if (type === 'custom') {
    return customClearFn()
  } else {
    throw new Error('Invalid filter type: ' + type)
  }
}

interface FilterPopupProps {
  name: string,
  type: 'predefined' | 'predefined-combo' | 'date-range' | 'number-range' | 'custom',

  // Only for 'predefined' and 'predefined-combo'
  options?: any[],

  values: any | any[],
  getActiveOptions: () => any,
  onApply: (values: any) => void,
  close: (values?: any) => void,

  // Only for custom
  customFormClass?: ComponentType<any>,

  customClearFn?: () => void,
}

interface FilterPopupState {
  values: any,
  tooFarLeft: boolean,
  tooFarRight: boolean,
  validationErrors?: ValidationErrors,
}

class FilterPopup extends React.Component<FilterPopupProps, FilterPopupState> {
  constructor(props) {
    super(props)
    let values = CommonUtils.clone(props.values)
    const { type } = props

    if (type === 'predefined' || type === 'predefined-combo') {
      const useDropdown = props.options.length > DROPDOWN_THRESHOLD

      if (useDropdown) {
        // If empty, add a first empty value to make the UI more intuitive
        if (type === 'predefined' && !values.length) {
          values = [{ id: 1, value: null }]
        }
        else if (type === 'predefined-combo' && !values.values.length) {
          values.values = [{ id: 1, value: null }]
        }
      }
    }

    this.state = {
      values,
      tooFarLeft: false,
      tooFarRight: false,
    }
  }

  popupRef = createRef<HTMLDivElement>()

  _isMounted = false

  componentDidMount() {
    this._isMounted = true

    // Make sure the popup is fully within the client area

    const rect = this.popupRef.current.getBoundingClientRect()

    if (rect.left < 0) {
      this.setState({ tooFarLeft: true })
    }
    else if (rect.right > document.documentElement.offsetWidth) {
      this.setState({ tooFarRight: true })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  setValues = (values) => {
    this.setState({ values })
  }

  generateNewValueId = (values) => {
    // Find max id (include 0 in case of empty list) and increment by 1
    const ids = values.map(function(value) { return value.id })
    return Math.max(...ids.concat(0)) + 1
  }

  renderPredefinedDropdowns = (activeOptions, inactiveOptions) => {
    return (
      <div>
        {this.state.values.map((value, index) => {
          let emptyOption = null

          if (!value.value) {
            // Only show the empty option until a real value has been selected
            emptyOption = <option value={null} className="empty" />
          }

          return (
            <div key={value.id} style={{ marginBottom: '0.25em' }}>
              <select
                className="filter"
                style={{ width: '16em' }}
                value={value.value ?? ''}
                onChange={(evt) => {
                  // TODO: disallow duplicates?
                  const newValues = this.state.values.slice()
                  newValues[index].value = evt.currentTarget.value
                  this.setState({ values: newValues })
                }}
              >
                {emptyOption}
                {activeOptions.map(function(option) {
                  return (
                    <option key={option.value} value={option.value} className="active">
                      {option.label}
                    </option>
                  )
                })}
                {inactiveOptions.map(function(option) {
                  return (
                    <option key={option.value} value={option.value} className="inactive">
                      {option.label}
                    </option>
                  )
                })}
              </select>
              {' '}
              <button
                onClick={() => {
                  const newValues = this.state.values.slice()
                  newValues.splice(index, 1)
                  this.setState({ values: newValues })
                }}
              >
                X
              </button>
            </div>
          )
        })}
        <div style={{ marginLeft: '0.3em' }}>
          <a
            className="lnk-add"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const newValues = this.state.values.slice()
              newValues.push({ id: this.generateNewValueId(this.state.values), value: null })
              this.setState({ values: newValues })
            }}
          >
            {i18n.t('action.add')}
          </a>
        </div>
      </div>
    )
  }

  renderPredefinedCheckbox = (option, isActive) => {
    const index: number = Utils.findIndexByField<any, 'value'>(this.state.values, 'value', option.value)
    const isChecked = index !== -1

    const onChange = (evt) => {
      const newValues = this.state.values.slice()

      if (evt.target.checked) {
        newValues.push({ id: this.generateNewValueId(this.state.values), value: option.value })
      }
      else {
        newValues.splice(index, 1)
      }

      this.setState({ values: newValues })
    }

    const className = classNames('filter-option', isActive ? 'active' : 'inactive')

    return (
      <div key={option.value} className={className}>
        <input type="checkbox" checked={isChecked} onChange={onChange} />
        {' '}
        {option.label}
      </div>
    )
  }

  renderPredefinedCheckboxes = (activeOptions, inactiveOptions) => {
    return (
      <div>
        {activeOptions.map((option) => {
          return this.renderPredefinedCheckbox(option, true)
        })}
        {inactiveOptions.map((option) => {
          return this.renderPredefinedCheckbox(option, false)
        })}
      </div>
    )
  }

  renderPredefined = () => {
    const activeOptions = []
    const inactiveOptions = []

    const set = this.props.getActiveOptions()

    this.props.options.forEach(function(option) {
      if (set.includes(option.value)) {
        activeOptions.push(option)
      }
      else {
        inactiveOptions.push(option)
      }
    })

    const element = (this.props.options.length > DROPDOWN_THRESHOLD ?
      this.renderPredefinedDropdowns(activeOptions, inactiveOptions) :
      this.renderPredefinedCheckboxes(activeOptions, inactiveOptions)
    )

    return (
      <div>
        <div style={{ marginBottom: '0.5em' }}>
          {i18n.t('filters.predefined.desc')}
        </div>
        {element}
      </div>
    )
  }

  renderPredefinedCombo = () => {
    return (
      <div>
        <div style={{ marginBottom: '0.5em' }}>
          {i18n.t('filters.predefined-combo.desc')}
        </div>
        {this.state.values.values.map((value, index) => {
          let emptyOption = null

          if (!value.value) {
            // Only show the empty option until a real value has been selected
            emptyOption = <option value={null} className="empty" />
          }

          const activeOptions = []
          const inactiveOptions = []

          const set = this.props.getActiveOptions()

          this.props.options.forEach((option) => {
            let isActive

            if (this.state.values.mode === 'partial') {
              isActive = set.some(function(activeOption) {
                return activeOption.isSuperset(Immutable.List(option.value))
              })
            }
            else if (this.state.values.mode === 'strict') {
              isActive = set.includes(Immutable.List(option.value))
            }
            else {
              throw new Error('Unexpected mode')
            }

            if (isActive) {
              activeOptions.push(option)
            }
            else {
              inactiveOptions.push(option)
            }
          })

          return (
            <div key={value.id} style={{ marginBottom: '0.25em' }}>
              <select
                className="filter"
                style={{ width: '16em' }}
                value={value.value ?? ''}
                onChange={(evt) => {
                  // TODO: disallow duplicates?
                  const newValues = CommonUtils.clone(this.state.values)
                  newValues.values[index].value = evt.currentTarget.value
                  this.setState({ values: newValues })
                }}
              >
                {emptyOption}
                {activeOptions.map(function(option) {
                  return (
                    <option key={option.id} value={option.id} className="active">
                      {option.label}
                    </option>
                  )
                })}
                {inactiveOptions.map(function(option) {
                  return (
                    <option key={option.id} value={option.id} className="inactive">
                      {option.label}
                    </option>
                  )
                })}
              </select>
              {' '}
              <button
                onClick={() => {
                  const newValues = CommonUtils.clone(this.state.values)
                  newValues.values.splice(index, 1)
                  this.setState({ values: newValues })
                }}
              >
                X
              </button>
            </div>
          )
        })}
        <div style={{ marginLeft: '0.3em' }}>
          <a
            className="lnk-add"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const newValues = CommonUtils.clone(this.state.values)

              newValues.values.push({
                id: this.generateNewValueId(this.state.values.values),
                value: null,
              })

              this.setState({ values: newValues })
            }}
          >
            {i18n.t('action.add')}
          </a>
        </div>
        <div className="text-center" style={{ margin: '1em 0' }}>
          <Selector
            id="mode-selector"
            values={[
              { key: 'partial', label: i18n.t('filters.predefined-combo.mode-button.partial') },
              { key: 'strict', label: i18n.t('filters.predefined-combo.mode-button.strict') },
            ]}
            selectedKey={this.state.values.mode}
            onSelect={(mode) => {
              const newValues = CommonUtils.clone(this.state.values)
              newValues.mode = mode
              this.setState({ values: newValues })
            }}
          />
        </div>
      </div>
    )
  }

  renderDateRange = () => {
    function toLastDayOfMonth(date) {
      const newDate = new Date(date)
      const month = newDate.getUTCMonth()
      newDate.setUTCMonth(month + 1) // next month
      newDate.setUTCDate(0) // last day of the previous month
      return newDate
    }

    function shortDate(date) {
      return i18n.t('short-months.' + (date.getUTCMonth() + 1)) + ' ' + date.getUTCDate()
    }

    function getCurrentMonthShortcut() {
      const from = CommonUtils.getNow()
      from.setUTCDate(1)
      const month = from.getUTCMonth()
      const to = toLastDayOfMonth(from)

      const label = i18n.t('months.' + (month + 1)) + ' ' + from.getUTCFullYear()
      return { key: 'curr-month', label, from, to }
    }

    function getPreviousMonthShortcut() {
      const to = CommonUtils.getNow()
      to.setUTCDate(0) // last day of the previous month

      const month = to.getUTCMonth()

      const from = new Date(to)
      from.setUTCDate(1) // first day of the month

      const label = i18n.t('months.' + (month + 1)) + ' ' + from.getUTCFullYear()
      return { key: 'prev-month', label, from, to }
    }

    function getCurrentHalfMonthShortcut() {
      const from = CommonUtils.getNow()
      let to

      const dayOfMonth = from.getUTCDate()

      if (dayOfMonth > 15) {
        from.setUTCDate(16)
        to = toLastDayOfMonth(from)
      }
      else {
        from.setUTCDate(1)
        to = new Date(from)
        to.setUTCDate(15)
      }

      const toStr = i18n.t('filters.date-range.to').toLowerCase()
      const label = shortDate(from) + ' ' + toStr + ' ' + shortDate(to)
      return { key: 'curr-half-month', label, from, to }
    }

    function getPreviousHalfMonthShortcut() {
      const from = CommonUtils.getNow()
      let to

      const dayOfMonth = from.getUTCDate()

      if (dayOfMonth > 15) {
        from.setUTCDate(1)
        to = new Date(from)
        to.setUTCDate(15)
      }
      else {
        from.setUTCDate(16)
        from.setUTCMonth(from.getUTCMonth() - 1)
        to = toLastDayOfMonth(from)
      }

      const toStr = i18n.t('filters.date-range.to').toLowerCase()
      const label = shortDate(from) + ' ' + toStr + ' ' + shortDate(to)
      return { key: 'prev-half-month', label, from, to }
    }

    function getCurrentYearShortcut() {
      const from = CommonUtils.getNow()
      from.setUTCDate(1)
      from.setUTCMonth(0)

      const to = new Date(from)
      to.setUTCMonth(11)
      to.setUTCDate(31)

      const label = i18n.t('filters.date-range.all-of') + ' ' + from.getUTCFullYear()
      return { key: 'curr-year', label, from, to }
    }

    function getPreviousYearShortcut() {
      const from = CommonUtils.getNow()
      from.setUTCDate(1)
      from.setUTCMonth(0)
      from.setUTCFullYear(from.getUTCFullYear() - 1)

      const to = new Date(from)
      to.setUTCMonth(11)
      to.setUTCDate(31)

      const label = i18n.t('filters.date-range.all-of') + ' ' + from.getUTCFullYear()
      return { key: 'prev-year', label, from, to }
    }

    const shortcuts = [
      getCurrentMonthShortcut(),
      getPreviousMonthShortcut(),
      getCurrentHalfMonthShortcut(),
      getPreviousHalfMonthShortcut(),
      getCurrentYearShortcut(),
      getPreviousYearShortcut(),
    ]

    return (
      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td style={{ width: '50%' }}>
              {i18n.t('filters.date-range.from')}
            </td>
            <td style={{ padding: '0.2em 0' }}>
              {bindInput(this, ['values', 'from'])}
              {ValidationUtils.render(this.state.validationErrors, 'from')}
            </td>
          </tr>
          <tr>
            <td style={{ width: '50%' }}>
              {i18n.t('filters.date-range.to')}
            </td>
            <td style={{ padding: '0.2em 0' }}>
              {bindInput(this, ['values', 'to'])}
              {ValidationUtils.render(this.state.validationErrors, 'to')}
            </td>
          </tr>
          <tr>
            <td style={{ width: '50%', verticalAlign: 'top', padding: '0.2em 0' }}>
              {i18n.t('filters.date-range.shortcuts')}
            </td>
            <td style={{ padding: '0.2em 0' }}>
              {shortcuts.map((shortcut) => {
                const onClick = () => {
                  this.setState({ values: {
                    from: CommonUtils.utcDateYMD(shortcut.from),
                    to: CommonUtils.utcDateYMD(shortcut.to),
                  } })
                }

                return (
                  <div key={shortcut.key}>
                    <a onClick={onClick} style={{ cursor: 'pointer' }}>
                      {shortcut.label}
                    </a>
                  </div>
                )
              })}
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  renderNumberRange = () => {
    return (
      <div>
        <div className="text-center">
          {i18n.t('filters.number-range.desc')}
          {' '}
          {bindInput(this,
            ['values', 'from'],
            { id: 'inp-from', style: { width: '4em' }, validator: Utils.integerValidator },
          )}
          {' '}
          {i18n.t('filters.number-range.and')}
          {' '}
          {bindInput(this,
            ['values', 'to'],
            { id: 'inp-to', style: { width: '4em' }, validator: Utils.integerValidator },
          )}
        </div>
        <div className="text-center" style={{ fontSize: '80%', margin: '1em' }}>
          {i18n.t('filters.number-range.may-leave-empty')}
        </div>
      </div>
    )
  }

  render() {
    let form = null
    let validator = null

    if (this.props.type === 'predefined') {
      form = this.renderPredefined()
    } else if (this.props.type === 'predefined-combo') {
      form = this.renderPredefinedCombo()
    } else if (this.props.type === 'date-range') {
      form = this.renderDateRange()

      validator = () => {
        let anyErrors = false
        const errors = {}

        function checkDate(state, dateName) {
          const date = state.values[dateName]

          const processed = CommonUtils.utcDateYMD(new Date(date + 'T00:00:00Z'))

          if (processed !== date) {
            errors[dateName] = { type: 'date-ymd' }
            anyErrors = true
          }
        }

        checkDate(this.state, 'from')
        checkDate(this.state, 'to')

        return anyErrors ? errors : null
      }
    } else if (this.props.type === 'number-range') {
      form = this.renderNumberRange()
    } else if (this.props.type === 'custom') {
      const CustomFormClass = this.props.customFormClass

      form = (
        <CustomFormClass
          values={this.state.values}
          onChange={this.setValues}
          getActiveOptions={this.props.getActiveOptions}
        />
      )
    } else {
      throw new Error('Invalid filter type: ' + this.props.type)
    }

    let rightOffset

    if (this.state.tooFarLeft) {
      rightOffset = '-19em'
    }
    else if (this.state.tooFarRight) {
      rightOffset = 0
    }
    else {
      rightOffset = '-9.6em'
    }

    return (
      <div
        ref={this.popupRef}
        id={'filter-popup-' + this.props.name}
        style={{
          position: 'absolute',
          width: '20em',
          right: rightOffset,
          top: '1.6em',
          border: '1px solid #aaa',
          backgroundColor: 'white',
          padding: '0.5em 0.7em',
          fontWeight: 'normal',
          boxShadow: '0px 0.15em 1em #808080',

          // Using z-index and position to create a separate stacking context for
          // each popup so their contents wouldn't overlap if multiple ones are open
          zIndex: 10,
        }}
      >
        {form}
        <div className="text-center" style={{ marginTop: '0.5em' }}>
          <button
            className="btn-apply"
            onClick={() => {
              ValidationUtils.clear(this).then(() => {
                let validationErrors = null

                if (validator) {
                  validationErrors = validator()
                }

                if (validationErrors) {
                  this.setState({ validationErrors })
                }
                else {
                  this.props.close(this.state.values)
                }
              })
            }}
          >
            {i18n.t('action.apply')}
          </button>
          {' '}
          <button
            className="btn-clear"
            onClick={() => {
              const values = getClearFilter(this.props.type, this.props.customClearFn)
              this.props.close(values)
            }}
          >
            {i18n.t('action.clear-filter')}
          </button>
          {' '}
          <button
            className="btn-cancel"
            onClick={() => {
              // Must be called without any arguments
              this.props.close()
            }}
          >
            {i18n.t('action.cancel')}
          </button>
        </div>
      </div>
    )
  }
}

// Function for closing previous opened filter panel.
// Only one can be open at a time.
let closePrevious = null

interface FilterIconProps {
  name: string,
  type: 'predefined' | 'predefined-combo' | 'date-range' | 'number-range' | 'custom',

  // Only for 'predefined' and 'predefined-combo'
  options?: object[],

  values: any | any[],
  getActiveOptions: () => void,
  onApply: (values: any) => void,
  small?: boolean,
  customFormClass?: ComponentType<any>,
  customIsFiltered?: () => void,
  customClearFn?: () => void,
}

class FilterIcon extends React.Component<FilterIconProps> {
  state = {
    popupVisible: false,
  }

  componentWillUnmount() {
    if (closePrevious === this.close) {
      closePrevious = null
    }
  }

  close = (callback) => {
    closePrevious = null
    this.setState({ popupVisible: false }, callback)
  }

  render() {
    let popup = null

    if (this.state.popupVisible) {
      popup = (
        <FilterPopup
          name={this.props.name}
          type={this.props.type}
          options={this.props.options}
          values={this.props.values}
          getActiveOptions={this.props.getActiveOptions}
          onApply={this.props.onApply}
          close={(values) => {
            let callback = null

            if (values !== undefined) {
              callback = () => {
                this.props.onApply(values)
              }
            }

            this.close(callback)
          }}
          customFormClass={this.props.customFormClass}
          customClearFn={this.props.customClearFn}
        />
      )
    }

    const filtered = isFiltered(this.props.type, this.props.values, this.props.customIsFiltered)

    const divStyle: CSSProperties = { position: 'relative' }
    const imgStyle = { cursor: 'pointer' }

    if (this.props.small) {
      divStyle['display'] = 'inline-block'
      imgStyle['width'] = '0.6em'
    }
    else {
      divStyle['float'] = 'right'
      imgStyle['marginLeft'] = '0.1em'
    }

    return (
      <div style={divStyle}>
        <img
          id={'filter-icon-' + this.props.name}
          src={filtered ? '/img/filter-active.png' : '/img/filter.png'}
          style={imgStyle}
          title={i18n.t('action.filter')}
          onClick={() => {
            const willBeVisible = !this.state.popupVisible

            if (willBeVisible) {
              if (closePrevious) {
                closePrevious()
              }

              closePrevious = this.close
            }
            else {
              closePrevious = null
            }

            this.setState({ popupVisible: willBeVisible })
          }}
        />
        {popup}
      </div>
    )
  }
}

interface FilterSummaryProps {
  valuesByField?: any,
  filterConf: any,
  mainLabelKey?: string,
  clearAll: () => void,
}

class FilterSummary extends React.Component<FilterSummaryProps> {
  static defaultProps = { mainLabelKey: 'filters', valuesByField: {} }

  renderOr = (index) => {
    const isFirst = index === 0

    if (isFirst) {
      return null
    }
    else {
      return ' ' + i18n.t('common.or') + ' '
    }
  }

  renderPredefined = (conf, values) => {
    const labelByValue = {}

    conf.options.forEach(function(option) {
      labelByValue[option.value] = option.label
    })

    return values.map((filterValue, index) => {
      let label

      if (filterValue.value in labelByValue) {
        label = <b>{labelByValue[filterValue.value]}</b>
      }
      else {
        // TODO warning notification in Fleep?
        label = <b className="text-red">Invalid value</b>
      }

      return (
        <span key={filterValue.value}>
          {this.renderOr(index)}
          {label}
        </span>
      )
    })
  }

  renderPredefinedCombo = (conf, values) => {
    const labelByValue = {}

    conf.options.forEach(function(option) {
      labelByValue[option.id] = option.label
    })

    const elements = values.values.map((filterValue, index) => {
      let label
      if (filterValue.value in labelByValue) {
        label = <b>{labelByValue[filterValue.value]}</b>
      } else {
        label = <b className="text-red">Invalid value</b>
      }
      return (
        <span key={filterValue.value}>
          {this.renderOr(index)}
          {label}
        </span>
      )
    })

    elements.push(' (' + i18n.t('filters.predefined-combo.summary-mode.' + values.mode) + ')')
    return elements
  }

  renderDateRange = (values) => {
    return (
      <span>
        {i18n.t('filters.date-range.from')}
        {' '}
        <b>{values.from}</b>
        {' '}
        {i18n.t('filters.date-range.to').toLowerCase()}
        {' '}
        <b>{values.to}</b>
      </span>
    )
  }

  renderNumberRange = (values) => {
    if (values.from === '') {
      return (
        <span>
          {i18n.t('filters.number-range.at-most')}
          {' '}
          <b>{values.to}</b>
        </span>
      )
    }
    else if (values.to === '') {
      return (
        <span>
          {i18n.t('filters.number-range.at-least')}
          {' '}
          <b>{values.from}</b>
        </span>
      )
    }
    else {
      return (
        <span>
          {i18n.t('filters.number-range.between')}
          {' '}
          <b>{values.from}</b>
          {' '}
          {i18n.t('filters.number-range.and')}
          {' '}
          <b>{values.to}</b>
        </span>
      )
    }
  }

  renderContents = (conf, values) => {
    if (conf.type === 'predefined') {
      return this.renderPredefined(conf, values)
    } else if (conf.type === 'predefined-combo') {
      return this.renderPredefinedCombo(conf, values)
    } else if (conf.type === 'date-range') {
      return this.renderDateRange(values)
    } else if (conf.type === 'number-range') {
      return this.renderNumberRange(values)
    } else if (conf.type === 'custom') {
      return <conf.SummaryClass values={values} />
    } else {
      throw new Error('Invalid filter type: ' + conf.type)
    }
  }

  render() {
    const { valuesByField, filterConf } = this.props
    const border = '1px solid hsl(208, 60%, 60%)'
    const radius = '0.3em'

    const filterElements = Object.keys(valuesByField).map((fieldName) => {
      const values = valuesByField[fieldName]
      const conf = filterConf[fieldName]

      if (!conf) {
        throw new Error('No conf found for ' + fieldName)
      }

      if (!isFiltered(conf.type, values, conf.isFiltered)) {
        return null
      }

      return (
        <span key={fieldName} className="filter" style={{ marginLeft: '0.5em' }}>
          <span
            className="filter-label"
            style={{
              borderTopLeftRadius: radius,
              borderBottomLeftRadius: radius,
              border,
              backgroundColor: 'hsl(208, 85%, 93%)',
              padding: '0.2em 0.3em',
            }}
          >
            {conf.label || i18n.t(conf.labelKey)}
          </span>
          <span
            className="values"
            style={{
              borderTopRightRadius: radius,
              borderBottomRightRadius: radius,
              border,
              padding: '0.2em 0.3em',
              borderLeft: 0,
            }}
          >
            {this.renderContents(conf, values)}
          </span>
        </span>
      )
    })
    .filter(function(filterElement) {
      return filterElement !== null
    })

    if (!filterElements.length) {
      return null
    }

    return (
      <div
        className="filter-summary"
        style={{ marginBottom: '0.6em', fontSize: '85%' }}
      >
        {i18n.t(this.props.mainLabelKey)}
        :
        {filterElements}
        <button
          className="clear-all"
          onClick={this.props.clearAll}
          style={{ marginLeft: '0.4em' }}
        >
          {i18n.t('filters.clear-all')}
        </button>
      </div>
    )
  }
}

function convertValues(valuesByField, filterConf) {
  /**
  NOTE: Currently this function assumes that valuesByField and filterConf
  use the same types of filters for each filtered field present in both.
  E.g if valuesByField has a 'name' field and in filterConf 'name' is
  specified as a 'predefined' type filter then the filter will be treated as
  'predefined'. Although this would cause problems if the same field can
  have different filter types, in practice this is currently never the case.
  Currently this function is only used for converting between inventory
  production and sales points filters. If this changes, the code may have to
  be modified.
  */
  if (!valuesByField) {
    return
  }

  let filtersDropped = false
  Object.keys(valuesByField).forEach(function(fieldName) {
    if (!(fieldName in filterConf)) {
      delete valuesByField[fieldName]
      filtersDropped = true
      return
    }

    const conf = filterConf[fieldName]
    let values
    const lookup = {}
    if (conf.type === 'predefined') {
      values = valuesByField[fieldName]
      conf.options.forEach(function(option) {
        lookup[option.value] = true
      })
      valuesByField[fieldName] = values.filter(function(filterValue) {
        const valInLookup = filterValue.value in lookup
        filtersDropped = filtersDropped || !valInLookup
        return valInLookup
      })
    } else if (conf.type === 'predefined-combo') {
      values = valuesByField[fieldName].values
      conf.options.forEach(function(option) {
        lookup[option.id] = true
      })
      valuesByField[fieldName].values = values.filter(function(filterValue) {
        const valInLookup = filterValue.value in lookup
        filtersDropped = filtersDropped || !valInLookup
        return valInLookup
      })
    }
  })
  if (filtersDropped) {
    toastr.warning(i18n.t('filters.dropped'))
  }
}

function createManager<T>(filterSetKey, filterConf, objs: T[]) {
  const valuesByField = User.getFilter(filterSetKey)
  convertValues(valuesByField, filterConf)

  function getValues(fieldName, type, customClearFn) {
    if (valuesByField && fieldName in valuesByField) {
      return valuesByField[fieldName]
    } else {
      return getClearFilter(type, customClearFn)
    }
  }

  function getField(conf, obj, fieldName) {
    return conf.getField ? conf.getField(obj) : obj[fieldName]
  }

  // Converts value to a format that can be added to an immutable set
  // without creating duplicate entries (as would happen with regular arrays).
  function getUnique(value) {
    const valueType = typeof value
    if (valueType === 'string' || valueType === 'number') {
      return value
    } else if (Array.isArray(value)) {
      return Immutable.List(value)
    } else {
      throw new Error('Unhandled value type. Value: ' + value + ', type: ' + valueType)
    }
  }

  const filter = function(obj) {
    return Object.keys(valuesByField || {}).every(function(fieldName) {
      if (!(fieldName in filterConf)) {
        return true
      }

      const conf = filterConf[fieldName]
      const values = getValues(fieldName, conf.type, conf.clearFn)

      if (!isFiltered(conf.type, values, conf.isFiltered)) {
        return true
      }

      const fieldValue = getField(conf, obj, fieldName)

      if (conf.type === 'predefined') {
        return values.some(function(filterValue) {
          return filterValue.value === fieldValue
        })
      } else if (conf.type === 'predefined-combo') {
        // ex:
        // filterValue.value: 'blanc|noir'
        // fullValue.value: ['blanc', 'noir']
        // fieldValue: ['blanc', 'noir', 'carreaux']

        return values.values.some(function(filterValue) {
          if (filterValue.value === null) {
            return false
          }

          const fullValue = CommonUtils.findByField<any, 'id'>(conf.options, 'id', filterValue.value)

          if (values.mode === 'strict' && fullValue.value.length !== fieldValue.length) {
            return false
          }

          return fullValue.value.every(function(value) {
            return CommonUtils.arrayContains(fieldValue, value)
          })
        })
      } else if (conf.type === 'date-range') {
        const userTime = CommonUtils.toUserTime(User.getUser().country, new Date(fieldValue))
        const dateYMD = CommonUtils.utcDateYMD(userTime)

        return (
          (values.from === '' || dateYMD >= values.from) &&
          (values.to === '' || dateYMD <= values.to)
        )
      } else if (conf.type === 'number-range') {
        return (
          (values.from === '' || Number(fieldValue) >= Number(values.from)) &&
          (values.to === '' || Number(fieldValue) <= Number(values.to))
        )
      } else if (conf.type === 'custom') {
        return conf.filterFn(obj, values)
      } else {
        throw new Error('Invalid filter type: ' + conf.type)
      }
    })
  }

  const manager: FilterManager<T> = {
    getFiltered: function(optionalSubset?) {
      return (optionalSubset || objs).filter(filter)
    },

    anyFilters: function() {
      return Object.keys(valuesByField || {}).some(function(fieldName) {
        const conf = filterConf[fieldName]
        const values = getValues(fieldName, conf.type, conf.clearFn)
        return isFiltered(conf.type, values, conf.isFiltered)
      })
    },

    getIcon: function(fieldName, small?) {
      const conf = filterConf[fieldName]

      if (!conf) {
        throw new Error('No filter conf for field ' + fieldName)
      }

      const values = getValues(fieldName, conf.type, conf.clearFn)

      function getActiveOptions() {
        return Immutable.Set().withMutations(function(mutableSet) {
          manager.getFiltered().forEach(function(obj) {
            const fieldValue = getField(conf, obj, fieldName)
            mutableSet.add(getUnique(fieldValue))
          })
        })
      }

      const filterIconProps: any = {
        name: fieldName,
        type: conf.type,
        options: conf.options,
        values,
        small,
        getActiveOptions,
        onApply: function(newValues) {
          User.setFilter(filterSetKey, fieldName, newValues)
        },
      }
      if (conf.type === 'custom') {
        filterIconProps.customFormClass = conf.FormClass
        filterIconProps.customIsFiltered = conf.isFiltered
        filterIconProps.customClearFn = conf.clearFn
      }
      return <FilterIcon {...filterIconProps} />
    },

    getSummary: function(mainLabelKey?) {
      return (
        <FilterSummary
          valuesByField={valuesByField}
          filterConf={filterConf}
          mainLabelKey={mainLabelKey}
          clearAll={function() {
            User.clearFilter(filterSetKey)
          }}
        />
      )
    },
  }

  return manager
}

let eventCallbacks = Immutable.Map<string, () => void>()

export const Filter = {
  createManager,

  registerEvent: function(component) {
    const callback = function() {
      if (component._isMounted) {
        component.forceUpdate()
      }
    }

    EventBus.on('filters-updated', callback)
    eventCallbacks = eventCallbacks.set(component, callback)
  },

  // Convenience method to avoid having to require EventBus and to
  // maintain semantic symmetry with registerEvent
  unregisterEvent: function(component) {
    const callback = eventCallbacks.get(component)
    eventCallbacks = eventCallbacks.delete(component)
    EventBus.off('filters-updated', callback)
  },
}
