/**
 * In order to speed up the rendering of the invoices table and make it more responsive, the
 * filtered invoices are processed as sets of batches. A batch consists of up to 100 invoices and
 * a set contains up to 10 batches. Once a batch is processed the corresponding rows will appear
 * in the sales report table and the next batch is immediately passed in for processing. This causes
 * the table to fill with rows incrementally.
 *
 * If the number of filtered invoices exceeds the size of a set
 * (10 batches x 100 invoices = 1000 invoices), loading is stopped and a button, that allows the
 * user to load the next set, appears in the UI. The portion of invoices loaded and the number left
 * to load, are reflected in the UI in the form of a progress bar.
 *
 * Before all invoices are loaded, the totals will not be displayed at the bottom of the table as
 * they usually are, because they would not accurately represent the totals for the specified set of
 * filters.
 */

import classnames from 'classnames'
import { Attributes, HTMLAttributes } from 'react'
import React = require('react')

import { Access } from '../common/access'
import { CommonUtils } from '../common/common-utils'
import { AttributeDefinition, Username } from '../common/data-attribute-defs'
import { Invoice } from '../common/data-invoices'
import { Location } from '../common/data-locations'
import { Category, SimpleAttr } from '../common/data-misc'
import { ProductDefinition } from '../common/data-product-defs'
import { Product } from '../common/data-products'
import { getDataService } from '../common/data-service'
import { TemplateDefinition } from '../common/data-template-defs'
import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { InvoiceSettings, InvoiceUtils } from '../common/invoice-utils'
import { DisabledFilterIcon } from './disabled-filter-icon'
import { EmptyObject } from './empty-object'
import { Filter } from './filter'
import { FloatingHeader } from './floating-header'
import { InvoiceSearch } from './invoice-search'
import { InvoiceUiUtils, Rates } from './invoice-ui-utils'
import { Row } from './invoice-view'
import { MainMenu } from './main-menu'
import { ProgressBar } from './progress-bar'
import { ReportUtils } from './report-utils'
import { ReportsMenu } from './reports-menu'
import { User } from './user'
import { Utils } from './utils'

interface ActualPayment {
  EUR: number,
  'MAD-by-card': number,
  'MAD-cheque': number,
  'MAD-in-cash': number,
  'MAD-transfer': number,
  USD: number,
}

interface TotalTypes {
  amount: number,
  guideCommissions: number,
  droitsDeTimbre: number,
  cardCommissions: number,
  vat: number,
  actualPayments: Partial<ActualPayment>,
  byLocationCurrency: {
    EUR: number,
    MAD: number,
  },
}

interface FilterManager {
  anyFilters: (...any) => any,
  getFiltered: (...any) => any,
  getIcon: (...any) => any,
  getSummary: (...any) => any,
}

interface RowData {
  amount: number,
  attributes: AttributeDefinition,
  category: string,
  invoice: Invoice,
  name: string,
  productId: string,
  total: number,
  unitPrice: number,
  }

interface InvoiceData {
  id: string,
  currency: string,
  location: string,
  droitsDeTimbreAmount: number,
  guideCommissionAmount: number,
  marraCashCardAmount: number,
  cardCommission: number,
  actualPayments: ActualPayment,
  shipping: string,
  username: string,
  userTime: Date,
  totals: TotalTypes,
  corrected?: boolean,
  rows: RowData[],
}

interface ListState {
  loaded: boolean,
  printView: boolean,
  expanded: Record<number, boolean>,
  invoices?: Invoice[],
  products?: Product[],
  productsIndex?: Immutable.Map<string, Product>,
  locations?: Location[],
  locationsIndex?: Immutable.Map<string, Location>,
  categories?: SimpleAttr[],
  categoriesIndex?: Immutable.Map<string, Category>,
  attributeDefinitions?: AttributeDefinition[],
  productDefinitions?: ProductDefinition[],
  productDefinitionsIndex?: Immutable.Map<string, ProductDefinition>,
  templateDefinitions?: TemplateDefinition[],
  usernames?: Username[],
  usernamesIndex?: Immutable.Map<string, Username>,
  rates?: Rates,
  invoiceSettings?: InvoiceSettings,
  invoiceFilterManager?: FilterManager,
  entryFilterManager?: FilterManager,
  filteredInvoices?: any,
  invoicesData?: InvoiceData[],
  rowsData?: Row[],
  totals?: TotalTypes,
  idxInvoice?: number,
  idxBatch?: number,
  idxBatchSet?: number,
  isUpdating?: boolean,
}

const batchSize = 100
const numBatches = 10
const invoiceFilterKey = 'sales-report-invoice'
const entryFilterKey = 'sales-report-entry'

class List extends React.Component<EmptyObject, ListState> {
  state: ListState = {
    loaded: false,
    printView: false,
    expanded: {},
  }

  _isMounted = false

  componentDidMount() {
    this._isMounted = true
    this.load()
    EventBus.on('filters-updated', this.startInvoicesUpdate)
  }

  componentWillUnmount() {
    this._isMounted = false
    EventBus.off('filters-updated', this.startInvoicesUpdate)
  }

  load = () => {
    const DataService = getDataService()

    Promise.all([
      DataService.Invoices.getAll(),
      DataService.Products.getAll(),
      DataService.Locations.getByType('sales-points'),
      DataService.Categories.getAll(),
      DataService.AttributeDefinitions.getAll(),
      DataService.ProductDefinitions.getAll(),
      DataService.TemplateDefinitions.getAll(),
      DataService.Users.getUsernames(),
      InvoiceUiUtils.getExchangeRates(),
      InvoiceUiUtils.getSettings(),
    ])
    .then(([
      invoices,
      products,
      locations,
      categories,
      attributeDefinitions,
      productDefinitions,
      templateDefinitions,
      usernames,
      rates,
      invoiceSettings,
    ]) => {
      if (!this._isMounted) {
        return
      }

      const filteredAttributeDefinitions = attributeDefinitions.filter(
        CommonUtils.attributeContextFilters.products,
      )

      const filterManagers = this.buildFilterManagers(
        locations,
        usernames,
        invoices,
        products,
        productDefinitions,
        attributeDefinitions,
        templateDefinitions,
        categories,
      )

      const categoriesIndex = CommonUtils.indexById<string, Category>(categories)
      const productsIndex = CommonUtils.indexById<string, Product>(products)
      const locationsIndex = CommonUtils.indexById<string, Location>(locations)
      const productDefinitionsIndex = CommonUtils.indexById<string, ProductDefinition>(productDefinitions)
      const usernamesIndex = CommonUtils.indexById<string, Username>(usernames)

      this.setState(
        {
          loaded: true,
          invoices,
          products,
          productsIndex,
          locations,
          locationsIndex,
          categories,
          categoriesIndex,
          attributeDefinitions: filteredAttributeDefinitions,
          productDefinitions,
          productDefinitionsIndex,
          templateDefinitions,
          usernames,
          usernamesIndex,
          rates,
          invoiceSettings,

          invoiceFilterManager: filterManagers.invoiceFilterManager,
          entryFilterManager: filterManagers.entryFilterManager,
          filteredInvoices: filterManagers.filteredInvoices,
          invoicesData: [],
          rowsData: [],
          totals: this.getInitialTotals(),
          idxInvoice: 0,
          idxBatch: 0,
          idxBatchSet: 0,
          isUpdating: false,
        }, () => {
          EventBus.fire('sales-report-rendered')
          this.startInvoicesUpdate()
        },
      )
    })
  }

  print = () => {
    this.setState({ printView: true }, Utils.print)
  }

  leavePrintMode = () => {
    this.setState({ printView: false })
  }

  getColumnConf = () => {
    const language = User.getLanguage()
    const columnConf = []

    const includeInExcel = function(view) {
      return view === 'excel'
    }

    columnConf.push({
      id: 'id',
      header: i18n.t('invoice.invoice'),
      includeIf: includeInExcel,
      excelWidth: 10,
      getExcelValue: function(rowData) {
        return rowData.invoice.id
      },
    })

    columnConf.push({
      id: 'time',
      header: i18n.t('reports.date-time'),
      includeIf: includeInExcel,
      excelWidth: 16,
      getExcelValue: function(rowData) {
        return ReportUtils.toExcelTime(rowData.invoice.userTime)
      },
      getExcelMetadata: function(_rowData, context) {
        return { style: context.dateTimeStyle.id }
      },
    })

    columnConf.push({
      id: 'location',
      header: i18n.t('common.location'),
      includeIf: includeInExcel,
      excelWidth: 25,
      getCellContents: function(rowData) {
        return rowData.invoice.location
      },
    })

    columnConf.push({
      id: 'user',
      header: i18n.t('common.user'),
      includeIf: includeInExcel,
      excelWidth: 15,
      getCellContents: function(rowData) {
        return rowData.invoice.username
      },
    })

    columnConf.push({
      id: 'shipping',
      header: i18n.t('invoice.shipping-fee'),
      includeIf: includeInExcel,
      excelWidth: 15,
      getCellContents: function(rowData) {
        return rowData.invoice.shipping || ''
      },
    })

    columnConf.push({
      id: 'amount',
      header: i18n.t('common.amount'),
      excelWidth: 10,
      getCellContents: function(rowData) {
        return rowData.amount
      },
    })

    columnConf.push({
      id: 'name',
      header: i18n.t('inventory.product'),
      filterFieldName: 'name',
      excelWidth: 25,
      getCellContents: function(rowData) {
        return rowData.name
      },
    })

    columnConf.push({
      id: 'category',
      header: i18n.t('common.category'),
      excelWidth: 22,
      filterFieldName: 'category',
      getCellContents: function(rowData) {
        return rowData.category
      },
    })

    this.state.attributeDefinitions.forEach(function(attr) {
      columnConf.push({
        id: 'attr-' + attr._id,
        header: attr.names[language],
        filterFieldName: 'attr-' + attr._id,
        excelWidth: 20,
        getCellContents: function(rowData) {
          return rowData.attributes[attr._id].label
        },
      })
    })

    columnConf.push({
      id: 'unit-price',
      header: i18n.t('common.unit-price'),
      excelWidth: 10,
      getCellProperties: function() {
        return { className: 'text-right' }
      },
      getCellContents: function(rowData) {
        return CommonUtils.formatDecimal(rowData.unitPrice) + ' ' + rowData.invoice.currency
      },
      getExcelValue: function(rowData) {
        return rowData.unitPrice
      },
    })

    columnConf.push({
      id: 'total',
      header: i18n.t('common.total'),
      excelWidth: 10,
      getCellProperties: function() {
        return { className: 'text-right' }
      },
      getCellContents: function(rowData) {
        return CommonUtils.formatDecimal(rowData.total) + ' ' + rowData.invoice.currency
      },
      getExcelValue: function(_rowData, context) {
        return (
          context.columnLetters['unit-price'] + context.rowIndex + '*' +
          context.columnLetters['amount'] + context.rowIndex
        )
      },
      getExcelMetadata: function(_rowData, context) {
        return { type: 'formula', style: context.bodyStyle.id }
      },
    })

    columnConf.push({
      id: 'currency',
      header: i18n.t('common.currency'),
      includeIf: includeInExcel,
      excelWidth: 10,
      getCellContents: function(rowData) {
        return rowData.invoice.currency
      },
    })

    return columnConf
  }

  downloadExcelFile = (rowsData, columnConf) => {
    const userTime = CommonUtils.toUserTime(User.getUser().country, CommonUtils.getNow())
    const fileName = 'sales-' + CommonUtils.utcDateYMD(userTime) + '.xlsx'

    const workbook = ReportUtils.createExcelReport(rowsData, columnConf, {})
    ReportUtils.downloadExcelFile(fileName, workbook)
  }

  // Begin former SalesReportUpdateMixin

  calculateActualPaymentsTotalInMad = (actualPayments) => {
    const total = InvoiceUtils.getActualPaymentOptions(null).reduce((sum, option) => {
      const key = InvoiceUtils.getActualPaymentKey(option)
      const amount = actualPayments[key] || 0

      return sum + amount * this.state.rates[option.currency]
    }, 0)

    return total
  }

  calculateVat = (invoice) => {
    const total = this.calculateActualPaymentsTotalInMad(invoice.actualPayments)
    const breakdown = InvoiceUtils.calculateTotals(
      this.state.invoiceSettings, total, invoice.export,
    )

    return breakdown.vat
  }

  getCategoryName = (productDefinition) => {
    if (productDefinition.isCustom) {
      return i18n.t('common.custom')
    }

    const language = User.getLanguage()
    const category = this.state.categoriesIndex.get(productDefinition.category)

    return category.labels[language]
  }

  entryToRow = (entry, invoiceData) => {
    const language = User.getLanguage()

    const product = this.state.productsIndex.get(entry.product)
    const productDefinition = this.state.productDefinitionsIndex.get(product.definition)

    const attributes = CommonUtils.getAttributeDetails(
      product, this.state.attributeDefinitions, productDefinition,
      this.state.templateDefinitions, language,
    )

    const rowData = {
      invoice: invoiceData,
      productId: entry.product,
      amount: entry.amount,
      name: productDefinition.name,
      category: this.getCategoryName(productDefinition),
      attributes,
      unitPrice: entry.price,
      total: entry.amount * entry.price,
    }

    return rowData
  }

  updateInvoicesData = () => {
    const { filteredInvoices, entryFilterManager } = this.state
    const language = User.getLanguage()

    const invoicesData = []
    const rowsData = []
    const totals = CommonUtils.clone(this.state.totals)

    const batchEnd = Math.min(filteredInvoices.length, this.state.idxInvoice + batchSize)

    for (let idx = this.state.idxInvoice; idx < batchEnd; idx += 1) {
      const invoice = filteredInvoices[idx]

      const filteredEntries = entryFilterManager.getFiltered(invoice.entries)

      if (!filteredEntries.length && !invoice.book) {
        continue
      }

      const userTime = CommonUtils.toUserTime(User.getUser().country, new Date(invoice.time))
      const loc = this.state.locationsIndex.get(invoice.location)

      const { invoiceSettings } = this.state
      const invoiceValues = InvoiceUtils.getValues(invoice, loc.currency)
      const invoiceTotal = InvoiceUtils.getTotal(invoiceSettings, invoiceValues, false)

      let marraCashCardAmount = 0

      if (invoice.marraCashCard) {
        marraCashCardAmount = InvoiceUtils.getMarraCashCardDiscount(
          invoiceSettings, invoiceValues, false,
        )
      }

      let guideCommissionAmount = 0

      if (invoice.guideCommission) {
        const shipping = invoice.shipping || 0
        const invoiceTotalWithoutShipping = invoiceTotal - shipping
        guideCommissionAmount = InvoiceUtils.getGuideCommission(
          invoiceSettings, invoiceTotalWithoutShipping,
        )
        totals.guideCommissions += guideCommissionAmount
      }

      const cardCommission = InvoiceUtils.getCardCommission(
        invoiceSettings, invoice.actualPayments,
      )

      totals.cardCommissions += cardCommission

      Object.keys(invoice.actualPayments).forEach(function(key) {
        totals.actualPayments[key] += Number(invoice.actualPayments[key])
      })

      let droitsDeTimbreAmount = 0

      if (invoice.droitsDeTimbre) {
        droitsDeTimbreAmount = InvoiceUtils.getDroitsDeTimbre(
          invoiceSettings, this.state.rates, loc.currency, invoice.actualPayments,
        )

        totals.droitsDeTimbre += droitsDeTimbreAmount
      }

      const invoiceTotals = InvoiceUtils.calculateTotals(
        invoiceSettings, invoiceTotal, invoice.export,
      )

      totals.vat += this.calculateVat(invoice)

      const invoiceData: any = {
        id: invoice._id,
        userTime,
        location: loc.names[language],
        username: this.state.usernamesIndex.get(invoice.user).username,
        currency: loc.currency,
        book: invoice.book,
        shipping: invoice.shipping,
        marraCashCardAmount,
        guideCommissionAmount,
        cardCommission,
        actualPayments: invoice.actualPayments,
        droitsDeTimbreAmount,
        totals: invoiceTotals,
        corrected: invoice.corrected,
      }

      invoiceData.rows = filteredEntries.map((entry) => this.entryToRow(entry, invoiceData))

      invoiceData.rows.forEach(function(rowData) {
        rowsData.push(rowData)
        totals.byLocationCurrency[invoiceData.currency] += rowData.total
        totals.amount += rowData.amount
      })

      invoicesData.push(invoiceData)
    }

    const idxBatch = this.state.idxBatch + 1

    this.setState({
      invoicesData: this.state.invoicesData.concat(invoicesData),
      rowsData: this.state.rowsData.concat(rowsData),
      totals,
      idxInvoice: batchEnd,
      idxBatch,
    }, () => {
      const batchSetEnd = (this.state.idxBatchSet + 1) * numBatches
      if (idxBatch < batchSetEnd && batchEnd < filteredInvoices.length) {
        setTimeout(() => this.updateInvoicesData())
      }
      else {
        this.setState({ isUpdating: false })
      }
    })
  }

  getInvoiceFilterConf = (paramLocations, usernames) => {
    const language = User.getLanguage()
    const locations = paramLocations.slice()

    locations.sort(function(loc1, loc2) {
      return loc1.names[language].localeCompare(loc2.names[language])
    })

    // TODO: sort users

    return {
      time: {
        type: 'date-range',
        labelKey: 'common.date',
      },
      location: {
        type: 'predefined',
        labelKey: 'common.location',
        options: locations.map(function(loc) {
          return {
            value: loc._id,
            label: loc.names[language],
          }
        }),
      },
      user: {
        type: 'predefined',
        labelKey: 'common.user',
        options: usernames.map(function(userFields) {
          return {
            value: userFields._id,
            label: userFields.username,
          }
        }),
      },
    }
  }

  buildInvoiceFilterManager = (locations, usernames, invoices) => {
    const invoiceFilterConf = this.getInvoiceFilterConf(locations, usernames)
    return Filter.createManager(invoiceFilterKey, invoiceFilterConf, invoices)
  }

  getEntryFilterConf = (
    products,
    productDefinitions,
    attributeDefinitions,
    templateDefinitions,
    categories,
  ) => {
    const language = User.getLanguage()
    const names = Utils.getSortedDefinitionNames(productDefinitions)

    const filterConf = {
      name: {
        type: 'predefined',
        labelKey: 'common.name',
        options: names.map(function(prodName) {
          return { value: prodName, label: prodName }
        }),
        getField: function(entry) {
          const product: Product = CommonUtils.findById(products, entry.product)
          const prodDef: ProductDefinition = CommonUtils.findById(productDefinitions, product.definition)
          return prodDef.name
        },
      },
      category: Utils.getCategoryFilterConf(
        categories,
        language,
        true,
        function(entry) {
          const product: Product = CommonUtils.findById(products, entry.product)
          return CommonUtils.findById(productDefinitions, product.definition)
        },
      ),
    }

    attributeDefinitions.forEach(function(attribute) {
      const comboSources = []

      productDefinitions.forEach(function(definition) {
        if (definition.isCustom && attribute.textForCustom) {
          // Omit custom product freetext attributes from filters
        }
        else if (attribute._id in definition.attributeCombinations || !attribute.optional) {
          definition.attributeCombinations[attribute._id].forEach(function(comboParam) {
            const combination = Utils.ensureCombination(
              comboParam, attribute, definition, templateDefinitions,
            )

            const comboSource = { values: combination.values, category: definition.category }
            comboSources.push(comboSource)
          })
        }
      })

      filterConf['attr-' + attribute._id] = {
        type: 'predefined-combo',
        label: attribute.names[language],
        options: Utils.getAttributeFilterOptions(attribute, language, comboSources),
        getField: function(entry) {
          const item = CommonUtils.findById(products, entry.product)

          return Utils.getCombinationValues(
            item, attribute, productDefinitions, templateDefinitions,
          )
        },
      }
    })

    return filterConf
  }

  buildEntryFilterManager = (
    filteredInvoices,
    products,
    productDefinitions,
    attributeDefinitions,
    templateDefinitions,
    categories,
  ) => {
    let allEntries = []
    filteredInvoices.forEach(function(invoice) {
      allEntries = allEntries.concat(invoice.entries)
    })
    const entryFilterConf = this.getEntryFilterConf(
      products, productDefinitions, attributeDefinitions, templateDefinitions, categories,
    )
    return Filter.createManager(entryFilterKey, entryFilterConf, allEntries)
  }

  buildFilterManagers = (
    locations,
    usernames,
    invoices,
    products,
    productDefinitions,
    attributeDefinitions,
    templateDefinitions,
    categories,
  ) => {
    const invoiceFilterManager = this.buildInvoiceFilterManager(locations, usernames, invoices)
    const filteredInvoices = invoiceFilterManager.getFiltered()
    const entryFilterManager = this.buildEntryFilterManager(
      filteredInvoices, products, productDefinitions, attributeDefinitions, templateDefinitions,
      categories,
    )

    return {
      invoiceFilterManager,
      entryFilterManager,
      filteredInvoices,
    }
  }

  getInitialTotals = () => {
    return {
      actualPayments: InvoiceUtils.getActualPaymentMap(0, null),
      guideCommissions: 0,
      cardCommissions: 0,
      droitsDeTimbre: 0,
      byLocationCurrency: { MAD: 0, EUR: 0 },
      amount: 0,
      vat: 0,
    }
  }

  startInvoicesUpdate = () => {
    if (this.state.isUpdating) {
      return
    }

    const filterManagers = this.buildFilterManagers(
      this.state.locations, this.state.usernames, this.state.invoices, this.state.products,
      this.state.productDefinitions, this.state.attributeDefinitions,
      this.state.templateDefinitions, this.state.categories,
    )

    this.setState({
      invoiceFilterManager: filterManagers.invoiceFilterManager,
      entryFilterManager: filterManagers.entryFilterManager,
      filteredInvoices: filterManagers.filteredInvoices,
      invoicesData: [],
      rowsData: [],
      totals: this.getInitialTotals(),
      idxInvoice: 0,
      idxBatch: 0,
      idxBatchSet: 0,
      isUpdating: true,
    }, this.updateInvoicesData)
  }

  loadNextBatches = () => {
    if (this.state.isUpdating) {
      return
    }

    this.setState({
      idxBatchSet: this.state.idxBatchSet + 1,
    }, this.updateInvoicesData)
  }

  areInvoicesLoaded = () => {
    return this.state.idxInvoice >= this.state.filteredInvoices.length
  }

  // End former SalesReportUpdateMixin

  renderTotalsBreakdownTd = (translationKey, spanId, val) => {
    return (
      <td colSpan={2} className="text-center">
        <span style={{ margin: '0 0.7em', fontWeight: 'bold' }}>
          {i18n.t(translationKey)}
          :
        </span>
        <span id={spanId} style={{ margin: '0 0.7em' }}>
          {CommonUtils.formatDecimal(val)}
          {' MAD'}
        </span>
      </td>
    )
  }

  renderTotalsBreakdown = (total, vat) => {
    const subtotal = total - vat
    return (
      <tr>
        {this.renderTotalsBreakdownTd('reports.total-subtotal', 'breakdown-subtotal', subtotal)}
        {this.renderTotalsBreakdownTd('reports.total-vat', 'breakdown-vat', vat)}
        {this.renderTotalsBreakdownTd('reports.total-total', 'breakdown-total', total)}
      </tr>
    )
  }

  renderTotals = (anyEntryFilters) => {
    const { totals } = this.state
    if (anyEntryFilters) {
      let totalMAD = null, totalEUR = null

      if (totals.byLocationCurrency['MAD']) {
        totalMAD = (
          <div>
            {CommonUtils.formatDecimal(totals.byLocationCurrency['MAD'])}
            {' MAD'}
          </div>
        )
      }

      if (totals.byLocationCurrency['EUR']) {
        totalEUR = (
          <div>
            {CommonUtils.formatDecimal(totals.byLocationCurrency['EUR'])}
            {' EUR'}
          </div>
        )
      }

      return (
        <div className="text-right">
          {totalMAD}
          {totalEUR}
        </div>
      )
    }
    else {
      const totalCashMAD = totals.actualPayments['MAD-in-cash'] - totals.guideCommissions
      const totalCardMAD = totals.actualPayments['MAD-by-card'] - totals.cardCommissions
      const totalChequeMAD = totals.actualPayments['MAD-cheque']
      const totalTransferMAD = totals.actualPayments['MAD-transfer']

      // Round to avoid +/- 0.01 differences
      const totalAllMAD = (
        CommonUtils.round(totalCashMAD) +
        CommonUtils.round(totalCardMAD) +
        CommonUtils.round(totalChequeMAD) +
        CommonUtils.round(totalTransferMAD)
      )

      const eurAsMad = this.state.rates['EUR'] * totals.actualPayments['EUR']
      const usdAsMad = this.state.rates['USD'] * totals.actualPayments['USD']
      const total = totalAllMAD + eurAsMad + usdAsMad

      return (
        <table id="sales-totals">
          <tbody>
            <tr>
              <td style={{ fontWeight: 'bold' }}>
                {i18n.t('reports.cash-from-sales')}
                :
              </td>
              <td className="text-right">
                {CommonUtils.formatDecimal(totals.actualPayments['MAD-in-cash'])}
                {' MAD'}
              </td>
              <td className="text-right">
                {CommonUtils.formatDecimal(totals.actualPayments['EUR'])}
                {' EUR'}
              </td>
              <td className="text-right">
                {CommonUtils.formatDecimal(totals.actualPayments['USD'])}
                {' USD'}
              </td>
              <td style={{ fontWeight: 'bold' }}>
                {i18n.t('reports.card-payments-from-sales')}
                :
              </td>
              <td className="text-right">
                {CommonUtils.formatDecimal(totals.actualPayments['MAD-by-card'])}
                {' MAD'}
              </td>
            </tr>
            <tr>
              <td style={{ fontWeight: 'bold' }}>
                {i18n.t('reports.cash-to-guides')}
                :
              </td>
              <td className="text-right">
                {CommonUtils.formatDecimal(totals.guideCommissions)}
                {' MAD'}
              </td>
              <td className="text-right">
                0.00 EUR
              </td>
              <td className="text-right">
                0.00 USD
              </td>
              <td style={{ fontWeight: 'bold' }}>
                {i18n.t('reports.card-commissions')}
                :
              </td>
              <td className="text-right">
                {CommonUtils.formatDecimal(totals.cardCommissions)}
                {' MAD'}
              </td>
            </tr>
            <tr>
              <td style={{ fontWeight: 'bold' }}>
                {i18n.t('reports.cash-inflow')}
                :
              </td>
              <td className="text-right">
                {CommonUtils.formatDecimal(totalCashMAD)}
                {' MAD'}
              </td>
              <td className="text-right">
                {CommonUtils.formatDecimal(totals.actualPayments['EUR'])}
                {' EUR'}
              </td>
              <td className="text-right">
                {CommonUtils.formatDecimal(totals.actualPayments['USD'])}
                {' USD'}
              </td>
              <td style={{ fontWeight: 'bold' }}>
                {i18n.t('reports.card-payment-inflow')}
                :
              </td>
              <td className="text-right">
                {CommonUtils.formatDecimal(totalCardMAD)}
                {' MAD'}
              </td>
            </tr>
            <tr>
              <td colSpan={6} className="text-center">
                <div style={{ display: 'inline-block' }}>
                  <span style={{ margin: '0 0.7em', fontWeight: 'bold' }}>
                    {i18n.t('reports.total-by-cheque')}
                    :
                  </span>
                  <span className="lbl-total-by-cheque" style={{ margin: '0 0.7em' }}>
                    {CommonUtils.formatDecimal(totalChequeMAD)}
                    {' MAD'}
                  </span>
                </div>
                <div style={{ display: 'inline-block' }}>
                  <span style={{ margin: '0 0.7em', fontWeight: 'bold' }}>
                    {i18n.t('reports.total-by-transfer')}
                    :
                  </span>
                  <span className="lbl-total-by-transfer" style={{ margin: '0 0.7em' }}>
                    {CommonUtils.formatDecimal(totalTransferMAD)}
                    {' MAD'}
                  </span>
                </div>
                <div style={{ display: 'inline-block' }}>
                  <span style={{ margin: '0 0.7em', fontWeight: 'bold' }}>
                    {i18n.t('invoice.droits-de-timbre')}
                    :
                  </span>
                  <span className="lbl-total-droits-de-timbre" style={{ margin: '0 0.7em' }}>
                    {CommonUtils.formatDecimal(totals.droitsDeTimbre)}
                    {' MAD'}
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={6} className="text-center">
                <span style={{ margin: '0 0.7em', fontWeight: 'bold' }}>
                  {i18n.t('reports.total-inflow')}
                  :
                </span>
                <span className="lbl-total-inflow" style={{ margin: '0 0.7em' }}>
                  {CommonUtils.formatDecimal(totalAllMAD)}
                  {' MAD'}
                </span>
                <span className="lbl-total-inflow" style={{ margin: '0 0.7em' }}>
                  {CommonUtils.formatDecimal(totals.actualPayments['EUR'])}
                  {' EUR'}
                </span>
                <span className="lbl-total-inflow" style={{ margin: '0 0.7em' }}>
                  {CommonUtils.formatDecimal(totals.actualPayments['USD'])}
                  {' USD'}
                </span>
              </td>
            </tr>
            {this.renderTotalsBreakdown(total, totals.vat)}
          </tbody>
        </table>
      )
    }
  }

  renderMenus = () => {
    if (!this.state.printView) {
      return [
        <MainMenu key="main" activeTab="reports" />,
        <ReportsMenu key="reports" activeTab="sales" />,
      ]
    }
  }

  renderInvoiceLink = (invoiceId) => {
    const text = i18n.t('invoice.invoice') + ' #' + invoiceId

    if (this.state.printView) {
      return <b>{text}</b>
    }

    return (
      <a
        className="lnk-invoice"
        href={'#/invoice/view/' + invoiceId}
        style={{ fontWeight: 'bold' }}
      >
        {text}
      </a>
    )
  }

  renderSearch = () => {
    if (!this.state.loaded) {
      return null
    }

    return <InvoiceSearch invoices={this.state.invoices} />
  }

  renderButtons = (columnConf) => {
    if (this.state.printView) {
      return (
        <button
          id="btn-leave-print"
          className="no-print"
          onClick={this.leavePrintMode}
          style={{ marginBottom: '0.5em' }}
        >
          {i18n.t('common.leave-print-mode')}
        </button>
      )
    }

    const onClickExcel = () => {
      this.downloadExcelFile(this.state.rowsData, columnConf)
    }

    return (
      <div style={{ margin: '0.5em 0' }}>
        <button id="btn-excel" onClick={onClickExcel}>
          {i18n.t('reports.download-excel-file')}
        </button>
        {' '}
        <button id="btn-print" onClick={this.print}>
          {i18n.t('common.print')}
        </button>
      </div>
    )
  }

  renderLoadNextButton = () => {
    if (this.state.isUpdating || this.areInvoicesLoaded()) {
      return null
    }

    return (
      <button onClick={this.loadNextBatches}>
        {i18n.t('reports.load-next-invoices')}
      </button>
    )
  }

  renderUpdateProgress = () => {
    const numFilteredInvoices = this.state.filteredInvoices.length
    const { idxInvoice } = this.state
    const percentComplete = numFilteredInvoices === 0 ?
      100 : idxInvoice / numFilteredInvoices * 100
    const strInvoicesLoaded = this.state.idxInvoice + ' / ' + this.state.filteredInvoices.length +
      ' ' + i18n.t('reports.invoices-loaded')

    return (
      <div>
        <ProgressBar percentComplete={percentComplete} />
        <div style={{ marginBottom: '5px' }}>
          {strInvoicesLoaded}
          {' '}
          {this.renderLoadNextButton()}
        </div>
      </div>
    )
  }

  getArchivableYears = () => {
    const currentYear = CommonUtils.getCurrentYear()
    const mapYears = {}

    this.state.invoices.forEach(function(invoice) {
      const date = new Date(invoice.time)
      const year = date.getUTCFullYear().toString()

      if (year !== currentYear) {
        mapYears[year] = true
      }
    })

    return Object.keys(mapYears)
  }

  renderArchiveButton = (year) => {
    return (
      <button
        key={'btn-archive-year-' + year}
        className="btn btn-default"
        onClick={() => {
          return getDataService().Invoices.archiveYear(year).then(() => {
            this.load()
            return null
          })
        }}
      >
        {year}
      </button>
    )
  }

  renderArchiveButtons = () => {
    const user = User.getUser()

    if (!Access.archiveInvoices(user)) {
      return null
    }

    const years = this.getArchivableYears()

    if (!years.length) {
      return null
    }

    return (
      <div className="panel panel-default padded">
        {i18n.t('invoice.archive-by-year')}
        {': '}
        <div className="btn-group ">
          {years.map(this.renderArchiveButton)}
        </div>
      </div>
    )
  }

  renderInvoiceFilter = (filterKey, translationKey) => {
    const icon = (
      this.state.isUpdating ?
      <DisabledFilterIcon small={true} /> :
      this.state.invoiceFilterManager.getIcon(filterKey, true)
    )

    return (
      <span className="span-filter">
        {icon}
        {' '}
        {i18n.t(translationKey)}
      </span>
    )
  }

  renderInvoiceFilters = () => {
    return (
      <div className="invoice-filters">
        {this.renderInvoiceFilter('time', 'common.date')}
        {this.renderInvoiceFilter('location', 'common.location')}
        {this.renderInvoiceFilter('user', 'common.user')}
      </div>
    )
  }

  renderPrintHeader = () => {
    if (this.state.printView) {
      return (
        <h3 style={{ fontWeight: 'bold' }}>
          {i18n.t('menu.main.reports')}
          {' - '}
          {i18n.t('menu.reports.sales')}
        </h3>
      )
    }
  }

  renderInvoiceCell = (invoiceData) => {
    const { invoiceFilterManager } = this.state
    const expanded = this.state.expanded[invoiceData.id] || this.state.printView
    let expandCollapseLink = null

    let bookRow = null
    let shippingFeeRow = null
    let marraCashCardRow = null
    let guideCommissionRow = null
    let droitsRow = null
    let cardCommissionRow = null

    if (expanded) {
      if (!this.state.printView) {
        expandCollapseLink = (
          <a
            className="lnk-show"
            onClick={() => {
              const newExpanded = CommonUtils.clone(this.state.expanded)
              newExpanded[invoiceData.id] = false
              this.setState({ expanded: newExpanded })
            }}
            style={{ cursor: 'pointer', fontSize: '85%' }}
          >
            {i18n.t('action.show-less')}
          </a>
        )
      }

      if (invoiceData.book) {
        const { book } = invoiceData

        bookRow = (
          <div className="invoice-detail">
            <b>
              {i18n.t('invoice.book')}
              :
            </b>
            {' '}
            <span className="lbl-book">
              {book.quantity}
              {' x '}
              {CommonUtils.formatDecimal(book.price)}
              {' '}
              {invoiceData.currency}
            </span>
          </div>
        )
      }

      if (invoiceData.shipping) {
        shippingFeeRow = (
          <div className="invoice-detail">
            <b>
              {i18n.t('invoice.shipping-fee')}
              :
            </b>
            {' '}
            <span className="lbl-shipping-fee">
              {CommonUtils.formatDecimal(invoiceData.shipping)}
              {' '}
              {invoiceData.currency}
            </span>
          </div>
        )
      }

      if (invoiceData.marraCashCardAmount) {
        marraCashCardRow = (
          <div className="invoice-detail">
            <b>MarraCashCard:</b>
            {' '}
            <span className="lbl-marra-cash-card">
              {CommonUtils.formatDecimal(invoiceData.marraCashCardAmount)}
              {' '}
              {invoiceData.currency}
            </span>
          </div>
        )
      }

      if (invoiceData.guideCommissionAmount) {
        guideCommissionRow = (
          <div className="invoice-detail">
            <b>
              {i18n.t('invoice.guide-commission')}
              :
            </b>
            {' '}
            <span className="lbl-guide-commission">
              {CommonUtils.formatDecimal(invoiceData.guideCommissionAmount)}
              {' MAD'}
            </span>
          </div>
        )
      }

      if (invoiceData.droitsDeTimbreAmount) {
        droitsRow = (
          <div className="invoice-detail">
            <b>
              {i18n.t('invoice.droits-de-timbre')}
              :
            </b>
            {' '}
            <span className="lbl-droits-de-timbre">
              {CommonUtils.formatDecimal(invoiceData.droitsDeTimbreAmount)}
              {' MAD'}
            </span>
          </div>
        )
      }

      if (invoiceData.cardCommission) {
        cardCommissionRow = (
          <div className="invoice-detail">
            <b>
              {i18n.t('invoice.card-commission')}
              :
            </b>
            {' '}
            <span className="lbl-card-commission">
              {CommonUtils.formatDecimal(invoiceData.cardCommission)}
              {' MAD'}
            </span>
          </div>
        )
      }
    }
    else {
      expandCollapseLink = (
        <a
          className="lnk-show"
          onClick={() => {
            const newExpanded = CommonUtils.clone(this.state.expanded)
            newExpanded[invoiceData.id] = true
            this.setState({ expanded: newExpanded })
          }}
          style={{ cursor: 'pointer', fontSize: '85%' }}
        >
          {i18n.t('action.show-more')}
        </a>
      )
    }

    let correctedNote = null

    if (invoiceData.corrected) {
      correctedNote = (
        <div className="lbl-corrected" style={{ fontSize: '70%' }}>
          {i18n.t('invoice.corrected').toUpperCase()}
        </div>
      )
    }

    const timeFilterIcon = (
      this.state.isUpdating ?
      <DisabledFilterIcon small={true} /> :
      invoiceFilterManager.getIcon('time', true)
    )

    const locationFilterIcon = (
      this.state.isUpdating ?
      <DisabledFilterIcon small={true} /> :
      invoiceFilterManager.getIcon('location', true)
    )

    const userFilterIcon = (
      this.state.isUpdating ?
      <DisabledFilterIcon small={true} /> :
      invoiceFilterManager.getIcon('user', true)
    )

    return (
      <td rowSpan={invoiceData.rows.length + 1} className="invoice-details">
        {this.renderInvoiceLink(invoiceData.id)}
        {correctedNote}
        <div>
          <div>
            {!this.state.printView && timeFilterIcon}
            {' '}
            <span style={{ fontSize: '85%' }}>
              <b>
                {i18n.t('common.date')}
                :
              </b>
              {' '}
              <span className="lbl-date">
                {CommonUtils.utcDateTime(invoiceData.userTime)}
              </span>
            </span>
          </div>
          <div>
            {!this.state.printView && locationFilterIcon}
            {' '}
            <span style={{ fontSize: '85%' }}>
              <b>
                {i18n.t('common.location')}
                :
              </b>
              {' '}
              <span className="lbl-location">
                {invoiceData.location}
              </span>
            </span>
          </div>
          {expanded && (
            <>
              {bookRow}
              {shippingFeeRow}
              <div className="invoice-detail">
                <b>
                  {i18n.t('invoice.subtotal')}
                  :
                </b>
                {' '}
                <span className="lbl-subtotal">
                  {CommonUtils.formatDecimal(invoiceData.totals.subtotal)}
                  {' '}
                  {invoiceData.currency}
                </span>
              </div>
              <div className="invoice-detail">
                <b>
                  {i18n.t('invoice.vat')}
                  :
                </b>
                {' '}
                <span className="lbl-vat">
                  {CommonUtils.formatDecimal(invoiceData.totals.vat)}
                  {' '}
                  {invoiceData.currency}
                </span>
              </div>
            </>
          )}
          <div className="invoice-detail">
            <b>
              {i18n.t('common.total')}
              :
            </b>
            {' '}
            <span className="lbl-total">
              {CommonUtils.formatDecimal(invoiceData.totals.total)}
              {' '}
              {invoiceData.currency}
            </span>
          </div>
          {expanded && (
            <>
              {marraCashCardRow}
              {guideCommissionRow}
              <div className="invoice-detail">
                <b>
                  {i18n.t('invoice.actual-payments')}
                  :
                </b>
                {InvoiceUtils.getSortedActualPayments(
                  invoiceData.actualPayments, this.state.rates, invoiceData.currency, false,
                )
                .map(function(payment) {
                  return (
                    <div
                      key={payment.key}
                      className="lbl-actual-payment"
                      style={{ marginLeft: '1em' }}
                    >
                      {payment.str}
                    </div>
                  )
                })}
              </div>
              {droitsRow}
              {cardCommissionRow}
              <div>
                {this.state.printView ? null : userFilterIcon}
                {' '}
                <span style={{ fontSize: '85%' }}>
                  <b>
                    {i18n.t('common.user')}
                    :
                  </b>
                  {' '}
                  <span className="lbl-user">
                    {invoiceData.username}
                  </span>
                </span>
              </div>
            </>
          )}
          {expandCollapseLink}
        </div>
      </td>
    )
  }

  renderInvoiceRows = (tableColumns) => {
    const { invoicesData } = this.state
    const invoiceRows = []

    invoicesData.forEach((invoiceData) => {
      const invoiceCell = this.renderInvoiceCell(invoiceData)

      invoiceData.rows.forEach(function(rowData, index) {
        const classes = classnames({
          'row-invoice-first': index === 0,
          'row-dark': index % 2 === 1,
          'corrected': invoiceData.corrected,
        })

        invoiceRows.push(
          <tr key={invoiceData.id + '-' + rowData.productId} className={classes}>
            {index === 0 && invoiceCell}
            {tableColumns.map(function(column) {
              let properties: Attributes & HTMLAttributes<HTMLTableCellElement> = {}

              if (column.getCellProperties) {
                properties = column.getCellProperties()
              }

              return (
                <td key={column.id} {...properties}>
                  {column.getCellContents(rowData)}
                </td>
              )
            })}
          </tr>,
        )
      })

      const emptyCols = []

      for (let i = 0; i < tableColumns.length; i += 1) {
        emptyCols.push(<td key={i} style={{ height: '100%' }} />)
      }

      invoiceRows.push(
        // Invoice cell on empty row if no entries
        <tr
          key={'empty-' + invoiceData.id}
          className={classnames({ 'corrected': invoiceData.corrected })}
        >
          {!invoiceData.rows.length && invoiceCell}
          {emptyCols}
        </tr>,
      )
    })

    return invoiceRows
  }

  renderTotalsRow = (anyEntryFilters, entryColCount, totalColCount) => {
    if (this.areInvoicesLoaded()) {
      const totalsElement = this.renderTotals(anyEntryFilters)

      return (
        <tr className="row-totals">
          <td style={{ fontWeight: 'bold', verticalAlign: 'middle' }}>
            {i18n.t('reports.totals')}
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            {this.state.totals.amount}
          </td>
          <td colSpan={entryColCount - 1}>
            {totalsElement}
          </td>
        </tr>
      )
    }

    return (
      <tr>
        <td colSpan={totalColCount}>
          {this.renderUpdateProgress()}
        </td>
      </tr>
    )
  }

  renderTable = (invoiceRows, tableColumns) => {
    const { invoiceFilterManager, entryFilterManager } = this.state

    const headerRow = (
      <tr>
        <th>{i18n.t('invoice.invoice')}</th>
        {tableColumns.map((column) => {
          let filter = null

          if (column.filterFieldName && !this.state.printView) {
            filter = this.state.isUpdating ?
              <DisabledFilterIcon small={false} /> :
              entryFilterManager.getIcon(column.filterFieldName)
          }

          return (
            <th key={column.id}>
              {column.header}
              {filter}
            </th>
          )
        })}
      </tr>
    )

    let totalsRow = null
    const entryColCount = tableColumns.length
    const totalColCount = entryColCount + 1
    const anyEntryFilters = entryFilterManager.anyFilters()

    if (invoiceRows.length) {
      totalsRow = this.renderTotalsRow(anyEntryFilters, entryColCount, totalColCount)
    }
    else {
      const anyInvoiceFilters = invoiceFilterManager.anyFilters()
      const anyFilters = anyEntryFilters || anyInvoiceFilters

      invoiceRows.push(
        <tr key="no-invoices">
          <td colSpan={totalColCount}>
            <div>
              {anyFilters ? i18n.t('reports.no-filtered-invoices') : i18n.t('reports.no-invoices')}
            </div>
          </td>
        </tr>,
      )
    }

    return (
      <table id="tbl-rep-sales" className="table table-bordered table-condensed">
        {this.state.printView && <thead>{headerRow}</thead>}
        {!this.state.printView && <FloatingHeader headerRow={headerRow} />}
        <tbody>
          {invoiceRows}
          {totalsRow}
        </tbody>
      </table>
    )
  }

  render() {
    if (!this.state.loaded) {
      return (
        <div>
          {this.renderMenus()}
          {i18n.t('common.loading')}
        </div>
      )
    }

    const columnConf = this.getColumnConf()

    const tableColumns = columnConf.filter(function(column) {
      if (column.includeIf) {
        return column.includeIf('table')
      }
      else {
        return true
      }
    })

    const invoiceRows = this.renderInvoiceRows(tableColumns)

    return (
      <div>
        {this.renderMenus()}
        {this.renderSearch()}
        {this.renderButtons(columnConf)}
        {this.renderPrintHeader()}
        {this.renderUpdateProgress()}
        {this.renderArchiveButtons()}
        {this.state.invoiceFilterManager.getSummary('reports.invoice-filters')}
        {this.state.entryFilterManager.getSummary('reports.entry-filters')}
        {this.renderInvoiceFilters()}
        {this.renderTable(invoiceRows, tableColumns)}
      </div>
    )
  }
}

export const SalesReport = {
  List,
}
