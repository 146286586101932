import React = require('react')

interface LoadingIconProps {
  size?: number,
}

export class LoadingIcon extends React.Component<LoadingIconProps> {
  static defaultProps = { size: 20 }

  iconRef = React.createRef<SVGSVGElement>()

  componentDidMount() {
    const icon = this.iconRef.current

    if (icon.animate) { // Not supported in IE11, for example
      icon.animate(
        [{ transform: 'rotate(0deg)' }, { transform: 'rotate(360deg)' }],
        { duration: 800, iterations: Infinity },
      )
    }
  }

  render() {
    // The gradient does not actually follow the curve.
    // We use two half circles with linear gradient fills that give a close enough result,
    // especially when the icon is small.

    return (
      <svg
        ref={this.iconRef}
        width={this.props.size}
        height={this.props.size}
        viewBox="0 0 120 120"
      >
        <defs>
          <linearGradient id="gradient1">
            <stop offset="0%" stopOpacity={0.5} />
            <stop offset="100%" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="gradient2">
            <stop offset="0%" stopOpacity={0.5} />
            <stop offset="100%" stopOpacity={1} />
          </linearGradient>
        </defs>
        <path
          d="M 10 60 A 50 50 0 1 0 110 60"
          strokeWidth={15}
          stroke="url(#gradient1)"
          fill="none"
        />
        <path
          d="M 110 60 A 50 50 0 1 0 10 60"
          strokeWidth={15}
          stroke="url(#gradient2)"
          fill="none"
        />
      </svg>
    )
  }
}
