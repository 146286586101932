// TODO: disable delete buttons if in use?

import React = require('react')

import { Access } from '../common/access'
import { CommonUtils } from '../common/common-utils'
import { SimpleAttr } from '../common/data-misc'
import { getDataService } from '../common/data-service'
import { Enums } from '../common/enums'
import { EventBus } from '../common/event-bus'
import { i18n } from '../common/i18n'
import { BackToIndexLink } from './back-to-index-link'
import { DataEditor } from './data-editor'
import { EditorUtils } from './editor-utils'
import { MainMenu } from './main-menu'
import { User } from './user'

function simpleGetNewOrUpdates(fieldValues) {
  const labels = {}

  Enums.orderedLanguages.forEach(function(lang) {
    labels[lang] = fieldValues['lang-' + lang]
  })

  return { labels }
}

interface ProductMaterialState {
  loaded: boolean,
  objs: SimpleAttr[],
}

class ProductCategories extends React.Component<Record<string, never>, ProductMaterialState> {
  state = { loaded: false, objs: [] }

  componentDidMount() {
    this.reloadData()
  }

  reloadData = () => {
    return getDataService().Categories.getAll()
    .then((objs) => {
      CommonUtils.sortAsStrings(objs, function(category) {
        return category.labels[User.getLanguage()].toLowerCase()
      })

      this.setState({ loaded: true, objs }, EventBus.fireFunc('categories-rendered'))
    })
  }

  render() {
    const mainMenu = <MainMenu activeTab="data" />

    if (!this.state.loaded) {
      return (
        <div>
          {mainMenu}
          Loading...
        </div>
      )
    }

    const fields = EditorUtils.getLanguageFields('labels')

    return (
      <div>
        {mainMenu}
        <BackToIndexLink />
        <DataEditor
          canAdd={true}
          canDelete={true}
          fields={fields}
          objs={this.state.objs}
          actions={EditorUtils.getServiceActions(getDataService().Categories)}
          getNew={simpleGetNewOrUpdates}
          getUpdates={simpleGetNewOrUpdates}
          reloadData={this.reloadData}
        />
      </div>
    )
  }
}

class MaterialCategories extends React.Component<Record<string, never>, ProductMaterialState> {
  state = { loaded: false, objs: [] }

  componentDidMount() {
    this.reloadData()
  }

  reloadData = () => {
    return getDataService().MaterialCategories.getAll()
    .then((objs) => {
      CommonUtils.sortAsStrings(objs, function(category) {
        return category.labels[User.getLanguage()].toLowerCase()
      })

      this.setState({ loaded: true, objs }, EventBus.fireFunc('categories-rendered'))
    })
  }

  render() {
    const mainMenu = <MainMenu activeTab="data" />

    if (!this.state.loaded) {
      return (
        <div>
          {mainMenu}
          Loading...
        </div>
      )
    }

    const fields = EditorUtils.getLanguageFields('labels')

    return (
      <div>
        {mainMenu}
        <BackToIndexLink />
        <DataEditor
          canAdd={true}
          canDelete={true}
          fields={fields}
          objs={this.state.objs}
          actions={EditorUtils.getServiceActions(getDataService().MaterialCategories)}
          getNew={simpleGetNewOrUpdates}
          getUpdates={simpleGetNewOrUpdates}
          reloadData={this.reloadData}
        />
      </div>
    )
  }
}

class MaterialProducers extends React.Component<Record<string, never>, ProductMaterialState> {
  state = { loaded: false, objs: [] }

  componentDidMount() {
    this.reloadData()
  }

  reloadData = () => {
    return getDataService().MaterialProducers.getAll()
    .then((objs) => {
      CommonUtils.sortAsStrings(objs, function (producer) {
        return producer.labels[User.getLanguage()].toLowerCase()
      })

      this.setState({ loaded: true, objs }, EventBus.fireFunc('producers-rendered'))
    })
  }

  render() {
    const mainMenu = <MainMenu activeTab="data" />

    if (!this.state.loaded) {
      return (
        <div>
          {mainMenu}
          Loading...
        </div>
      )
    }

    const fields = EditorUtils.getLanguageFields('labels')

    return (
      <div>
        {mainMenu}
        <BackToIndexLink />
        <DataEditor
          canAdd={true}
          canDelete={true}
          fields={fields}
          objs={this.state.objs}
          actions={EditorUtils.getServiceActions(getDataService().MaterialProducers)}
          getNew={simpleGetNewOrUpdates}
          getUpdates={simpleGetNewOrUpdates}
          reloadData={this.reloadData}
        />
      </div>
    )
  }
}

class MaterialWidths extends React.Component<Record<string, never>, ProductMaterialState> {
  state = { loaded: false, objs: [] }

  componentDidMount() {
    this.reloadData()
  }

  reloadData = () => {
    return getDataService().MaterialWidths.getAll()
    .then((objs) => {
      CommonUtils.sortAsStrings(objs, function(width) {
        return width.labels[User.getLanguage()].toLowerCase()
      })

      this.setState({ loaded: true, objs }, EventBus.fireFunc('widths-rendered'))
    })
  }

  render() {
    const mainMenu = <MainMenu activeTab="data" />

    if (!this.state.loaded) {
      return (
        <div>
          {mainMenu}
          Loading...
        </div>
      )
    }

    const fields = EditorUtils.getLanguageFields('labels')

    return (
      <div>
        {mainMenu}
        <BackToIndexLink />
        <DataEditor
          canAdd={true}
          canDelete={true}
          fields={fields}
          objs={this.state.objs}
          actions={EditorUtils.getServiceActions(getDataService().MaterialWidths)}
          getNew={simpleGetNewOrUpdates}
          getUpdates={simpleGetNewOrUpdates}
          reloadData={this.reloadData}
        />
      </div>
    )
  }
}

class Index extends React.Component {
  componentDidMount() {
    EventBus.fire('data-management-index-rendered')
  }

  render() {
    const user = User.getUser()
    const canManageAll = Access.manageData(user)
    const canManageAttributes = Access.manageAttributes(user)

    const sections = []

    if (canManageAll) {
      sections.push({ route: 'categories', label: 'Edit product categories' })
    }

    if (canManageAttributes) {
      sections.push({ route: 'attributes', label: i18n.t('menu.data.attributes') })
    }

    if (canManageAll) {
      sections.push({ route: 'locations', label: 'Edit locations' })
      sections.push({ route: 'material-categories', label: 'Edit raw material categories' })
      sections.push({ route: 'material-producers', label: 'Edit raw material producers' })
      sections.push({ route: 'material-widths', label: 'Edit raw material widths' })
      sections.push({ route: 'user-countries', label: 'Edit user countries' })
      sections.push({ route: 'settings', label: 'Edit settings' })
    }

    return (
      <div>
        <MainMenu activeTab="data" />
        {sections.map(function(section) {
          return (
            <p key={section.route}>
              <a href={'#/data/' + section.route} className="data-section-link">
                {section.label}
              </a>
            </p>
          )
        })}
      </div>
    )
  }
}

export const DataManagement = {
  Index,
  ProductCategories,
  MaterialCategories,
  MaterialProducers,
  MaterialWidths,
}
