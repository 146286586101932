import React, { useRef } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { MaterialProducer } from '../common/data-misc';
import { RawMaterial } from '../common/data-raw-materials';
import { i18n } from '../common/i18n';
import { getDefaultCancelButton, Modal } from './modal';
import { useOpenModalEvent } from './use-open-modal-event';
import { Selector } from './selector'
import { User } from './user';
import { ValidationUtils } from './validation-utils';
import { bindInput } from './bind-utils';
import { EventBus } from '../common/event-bus';
import { DateRangePickerModal } from './date-range-modal';
import { getDataService } from '../common/data-service';
import { MaterialYearlyReport } from '../common/data-material-yearly-report';
interface FormProps {
  producers: MaterialProducer[];
  materials: RawMaterial[];
}

interface CartMaterial {
  material?: string;
  amount: number;
  in: number;
  out: number;
}

interface FormState {
  selectedProducer: MaterialProducer;
  filteredMaterials: RawMaterial[];
  cart: CartMaterial[];
  startDate: Date;
  endDate: Date;
  materialReport: MaterialYearlyReport[]
}

class Form extends React.Component<FormProps, FormState> {

  constructor(props: FormProps) {
    super(props);

    this.state = {
      selectedProducer: null,
      filteredMaterials: [],
      cart: [],
      startDate: moment().subtract(1, 'year').startOf('year').startOf('month').startOf('day').toDate(),
      endDate: moment().subtract(1, 'year').endOf('year').endOf('month').endOf('day').toDate(),
      materialReport: []
    }
  }

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true
  }

  getProducerSelector() {

    const user = User.getUser();

    return (
      <select
        onChange={(event) => this.onProducerSelect(event.target.value)}
      >
        <option key="" value={null}>-</option>
        {this.props.producers.map(producer => {
          return (
            <option key={producer._id} value={producer._id}>
              {producer.labels[user.language]}
            </option>
          )
        })}
      </select>
    )

  }

  onProducerSelect(producerId: string) {

    const selectedProducer = this.props.producers.find(p => p._id === producerId)
    const filteredMaterials = _.sortBy(this.props.materials.filter((m) => m.producer === producerId), 'name')

    this.setState({ selectedProducer, filteredMaterials, cart: [{ material: null, amount: 0, in: 0, out: 0 }] });

    this.updateFilledAmount(null);

  }

  setMaterialAmountFromReport(materialId: string) {

    const report = this.state.materialReport.find(r => r.material === materialId);
    const cartItem = this.state.cart.find(c => c.material === materialId);

    if (report && cartItem) {

      cartItem.amount = report.amountConsumed;
      cartItem.in = report.amountAdded;
      cartItem.out = report.amountConsumed;

    }

    else {

      cartItem.in = 0;
      cartItem.out = 0;

    }

    this.setState({ cart: this.state.cart });

  }

  async updateFilledAmount(range: { startDate: Date, endDate: Date }) {

    const dataService = getDataService();

    const newRange = range || { startDate: this.state.startDate, endDate: this.state.endDate };

    let materialReport: MaterialYearlyReport[] = []

    if (this.state.filteredMaterials.length) {

      materialReport = await dataService.MaterialYearlyReports.getAll({ material: this.state.filteredMaterials.map(m => m._id), timeFrom: newRange.startDate.toISOString(), timeTo: newRange.endDate.toISOString() });

      this.setState({ materialReport, ...newRange });

      for (let cartItem of this.state.cart) {

        if (cartItem.material) this.setMaterialAmountFromReport(cartItem.material);

      }

    }

  }

  getPeriodSelector() {

    return (
      <div>
        <button
          onClick={() => EventBus.fire('open-modal', { modalId: 'date-range-picker' })}
        >
          {moment(this.state.startDate).format('L') + ' - ' + moment(this.state.endDate).format('L')}
        </button>
        <DateRangePickerModal
          modalId='date-range-picker'
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onDateChange={(range) => this.updateFilledAmount(range)}
        ></DateRangePickerModal>
      </div >
    )

  }

  row(fieldName, field, validationField?) {
    return (
      <div className="row" style={{ margin: '0.5em 0' }}>
        <div className="col-xs-5" style={{ fontWeight: 'bold', paddingLeft: 0 }}>
          {fieldName}
        </div>
        <div className="col-xs-7">
          {field}
          {ValidationUtils.render(null, validationField)}
        </div>
      </div>
    )
  }

  addEmptyItemToCart() {

    const cart = this.state.cart;

    cart.push({ material: null, amount: 0, in: 0, out: 0 });

    this.setState({ cart });

  }

  updateCartItem(index: number, update: Partial<CartMaterial>) {

    const item = this.state.cart[index];

    _.assign(item, update);

    this.setState({ cart: this.state.cart });

    if (update.material) this.setMaterialAmountFromReport(item.material);

  }

  renderMaterialSelection() {

    if (!this.state.selectedProducer) {
      return (<div></div>)
    }

    if (!this.state.filteredMaterials.length) {
      return (<span>{i18n.t('material-orders.no-materials')}</span>)
    }

    return (
      <div>
        {this.state.cart.map((cartItem, index) => {
          return (
            <div key={index}>
              <div>{
                this.row(
                  i18n.t('action.material-in-out'),
                  (
                    <div>
                      <b>{i18n.t('common.in')}:</b> {cartItem.in}
                      <span>   </span>
                      <b>{i18n.t('common.out')}:</b> {cartItem.out}
                    </div>

                  )
                )
              }</div>
              <div>{
                this.row(
                  i18n.t('raw-materials.material') + ' #' + (index + 1),
                  <select onChange={(event) => this.updateCartItem(index, { material: event.target.value })}>
                    <option key="-" value="">-</option>
                    {this.state.filteredMaterials
                      .map(material => {
                        return (
                          <option key={material._id} value={material._id}>{material.name}</option>
                        )
                      })}
                  </select>
                )
              }
              </div>
              <div>{
                this.row(
                  i18n.t('common.amount') + ' #' + (index + 1),
                  <div>
                    <input
                      type='number'
                      value={cartItem.amount}
                      onChange={(event) => this.updateCartItem(index, { amount: parseInt(event.target.value, 10) })}
                    ></input>
                    <span>  {cartItem.material ? this.state.filteredMaterials.find(m => m._id === cartItem.material).amount.unit : ''}</span>
                  </div>
                )
              }
              </div>
              <hr className="solid"></hr>
            </div>
          )
        })}
        <button
          onClick={() => this.addEmptyItemToCart()}
          disabled={this.state.filteredMaterials.length === this.state.cart.length}
        >{i18n.t('material-orders.add-material')}</button>
      </div>
    )

  }

  onSave() {

    const cart = this.state.cart.filter(c => c.material);

    const dataService = getDataService();

    return dataService.MaterialOrders.create({
      materials: cart.map(c => {
        return {
          material: c.material,
          amount: c.amount
        }
      })
    });

  }

  render() {

    return (
      <div>
        <div id="form-add-material-order">

          {
            this.row(
              i18n.t('material-orders.producer'),
              this.getProducerSelector()
            )
          }
          {
            this.row(
              i18n.t('common.period'),
              this.getPeriodSelector()
            )
          }
          <hr className="solid"></hr>
          {
            this.renderMaterialSelection()
          }
        </div>
      </div>
    )

  }

}

interface AddMaterialOrderProps {
  modalId: string;
  afterSave: () => void;
  producers: MaterialProducer[];
  materials: RawMaterial[]
};

export const AddMaterialOrderModal = (props: AddMaterialOrderProps) => {

  const [isVisible, close] = useOpenModalEvent(props.modalId)
  const formRef = useRef(null)

  if (!isVisible) {
    return <div />
  }

  return (
    <div>
      <Modal
        title={i18n.t('menu.main.material-orders')}
        closeModal={close}
        dialogClassName="add-material-order"
        buttons={[
          {
            id: 'btn-save',
            title: i18n.t('action.save'),
            onClick: () => {
              return formRef.current.onSave()
                .then(props.afterSave)
                .then(close)
            },
          },
          getDefaultCancelButton(),
        ]}
      >
        <Form
          ref={formRef}
          producers={props.producers}
          materials={props.materials.filter(m => !m.timeDeleted)}
        />
      </Modal>
    </div>
  )
}