import { Fragment, useRef } from 'react'
import React = require('react')

import { CommonUtils } from '../common/common-utils'
import { AttributeDefinition } from '../common/data-attribute-defs'
import { getDataService } from '../common/data-service'
import { TemplateDefinition } from '../common/data-template-defs'
import { Template } from '../common/data-templates'
import { i18n } from '../common/i18n'
import { AttributeCombinationSelector } from './attribute-combination-selector'
import { bindInput } from './bind-utils'
import { getDefaultCancelButton, Modal, ModalButton } from './modal'
import { Selector } from './selector'
import { useOpenModalEvent } from './use-open-modal-event'
import { User } from './user'
import { Utils } from './utils'
import { ValidationUtils } from './validation-utils'

interface TransformFormProps {
  templateDefinition: TemplateDefinition,
  attributeDefinitions: AttributeDefinition[],
  template: Template,
}

interface TransformModalProps {
  modalId: string,
  templateDefinition: TemplateDefinition,

  // Must be filtered by attributeContextFilters.templateProductDiff
  attributeDefinitions: AttributeDefinition[],

  template: Template,
  afterSave: () => void,
}

class TransformForm extends React.Component<TransformFormProps> {
  state = {
    loaded: false,
    productDefinitions: [],
    productDefinition: null,
    attributes: {},
    amountToTransform: '',
    storageLocationName: null,
    orderId: '',
    validationErrors: undefined,
  }

  _isMounted = false

  componentDidMount() {
    this._isMounted = true
    const DataService = getDataService()

    Promise.all([
      DataService.ProductDefinitions.getByTemplate(this.props.templateDefinition._id),
      DataService.Locations.getByType('storage'),
    ])
    .then(([productDefinitions, storageLocations]) => {
      if (!this._isMounted) {
        return
      }

      let selectedDefinition = null
      let attributes = {}

      const { template } = this.props

      const filteredProductDefinitions = productDefinitions.filter(function(productDefinition) {
        if (productDefinition.discontinued) {
          return false
        }

        return Object.keys(template.attributes).every(function(attrId) {
          const expectedCombinationId = template.attributes[attrId]
          const actualCombinationIds = productDefinition.attributeCombinations[attrId] as number[]
          return CommonUtils.arrayContains(actualCombinationIds, expectedCombinationId)
        })
      })

      // Auto-select if there is only one option
      if (filteredProductDefinitions.length === 1) {
        selectedDefinition = filteredProductDefinitions[0]
        attributes = this.getDefaultAttributeSelection(selectedDefinition)
      }

      let storageLocationName = null

      if (storageLocations.length === 1) {
        storageLocationName = storageLocations[0].names[User.getLanguage()]
      }
      else {
        throw new Error('Expected exactly one storage location')
      }

      // TODO: If no defs, disable save button?

      this.setState({
        loaded: true,
        productDefinitions: filteredProductDefinitions,
        productDefinition: selectedDefinition,
        attributes,
        storageLocationName,
      })
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getDefaultAttributeSelection = (productDefinition) => {
    const attributes = {}

    // Auto-select attribute combinations where there is only one option
    this.props.attributeDefinitions.forEach(function(attribute) {
      const combinations = productDefinition.attributeCombinations[attribute._id]

      if (!combinations && attribute.optional) {
        return
      }

      if (combinations.length === 1) {
        const [combo] = combinations
        attributes[attribute._id] = typeof combo === 'number' ? combo : combo.id
      }
    })

    return attributes
  }

  onSave = () => { // Public method
    return ValidationUtils.clear(this)
    .then(() => {
      return getDataService().Templates.transform(
        this.props.template._id,
        this.state.productDefinition?._id,
        this.state.attributes,
        this.state.amountToTransform,
        this.state.orderId,
      )
    })
    .catch((error) => ValidationUtils.check(this, error))
  }

  calculateAmountRemaining = () => {
    let amountToTransform = parseInt(this.state.amountToTransform)
    if (isNaN(amountToTransform)) {
      amountToTransform = 0
    }
    const amountRemaining = this.props.template.amount - amountToTransform

    return amountRemaining
  }

  renderAmountThresholdWarning = () => {
    const { template } = this.props
    if (template.amountThreshold) {
      const amountRemaining = this.calculateAmountRemaining()
      if (amountRemaining < template.amountThreshold) {
        return (
          <div className="row">
            <div className="col-xs-5" />
            <div className="col-xs-7">
              <p id="below-threshold-warning" className="text-red">
                {i18n.t('templates.amount-below-threshold')}
              </p>
            </div>
          </div>
        )
      }
    }
  }

  render() {
    if (!this.state.loaded) {
      return <div>{i18n.t('common.loading')}</div>
    }

    if (this.state.productDefinitions.length === 0) {
      // TODO hide save button
      return (
        <div id="form-transform">
          {i18n.t('template.no-products-for-transform')}
        </div>
      )
    }

    const row = function(key, fieldName, field) {
      return (
        <div key={key} className="row row-inventory">
          <div className="col-xs-5 col-left">
            {fieldName}
          </div>
          <div className="col-xs-7">
            {field}
          </div>
        </div>
      )
    }

    const selectedDefinitionId = (this.state.productDefinition ?
      this.state.productDefinition._id : null
    )

    return (
      <div id="form-transform">
        {row(undefined, i18n.t('prod-def.template'), this.props.templateDefinition.name)}
        {row(
          undefined,
          i18n.t('templates.current-amount'),
          <span id="lbl-current-amount">
            {this.props.template.amount}
          </span>,
        )}
        {row(
          undefined,
          i18n.t('templates.transform-into'),
          <Selector
            id="product-definition-selector"
            values={this.state.productDefinitions.map(function(productDefinition) {
              // TODO: sort by name?
              return { key: productDefinition._id, label: productDefinition.name }
            })}
            selectedKey={selectedDefinitionId}
            onSelect={(productDefinitionId) => {
              const productDefinition = CommonUtils.findById(
                this.state.productDefinitions, productDefinitionId,
              )

              const attributes = this.getDefaultAttributeSelection(productDefinition)

              this.setState({ productDefinition, attributes })
            }}
          />,
        )}
        {ValidationUtils.render(this.state.validationErrors, 'productDefinition')}
        {this.props.attributeDefinitions.map((attribute) => {
          let selector
          const definition = this.state.productDefinition

          if (
            definition &&
            (attribute._id in definition.attributeCombinations || !attribute.optional)
          ) {
            selector = (
              <AttributeCombinationSelector
                attribute={attribute}
                definition={definition}
                templateDefinitions={[this.props.templateDefinition]}
                selectedComboId={this.state.attributes[attribute._id]}
                onSelect={(combinationId) => {
                  const newAttributes = CommonUtils.clone(this.state.attributes)
                  newAttributes[attribute._id] = combinationId
                  this.setState({ attributes: newAttributes })
                }}
              />
            )
          }
          else {
            selector = '-'
          }

          return (
            <Fragment key={attribute._id}>
              {row(undefined, attribute.names[User.getLanguage()], selector)}
              {ValidationUtils.render(this.state.validationErrors, 'attributes.' + attribute._id)}
            </Fragment>
          )
        })}
        {row(
          undefined,
          i18n.t('common.amount'),
          bindInput(this, ['amountToTransform'], {
            id: 'inp-amount-to-transform',
            style: { width: '4em' },
            validator: Utils.nonNegativeIntegerValidator,
          }),
        )}
        {ValidationUtils.render(this.state.validationErrors, 'amountToTransform')}
        {this.renderAmountThresholdWarning()}
        {row(
          undefined,
          i18n.t('common.location'),
          this.state.storageLocationName,
        )}
        {row(
          undefined,
          i18n.t('inventory.order-id'),
          bindInput(this, ['orderId'], { id: 'inp-order-id' }),
        )}
      </div>
    )
  }
}

export const TransformModal = (props: TransformModalProps) => {
  const [isVisible, close] = useOpenModalEvent(props.modalId)
  const formRef = useRef(null)

  if (!isVisible) {
    return <div />
  }

  const buttons: ModalButton[] = [
    {
      id: 'btn-save',
      title: i18n.t('action.save'),
      onClick: async () => {
        await formRef.current.onSave()
        close()
        await props.afterSave()
      },
    },
    getDefaultCancelButton(),
  ]

  return (
    <div>
      <Modal
        title={i18n.t('templates.transform')}
        closeModal={close}
        dialogClassName="transform"
        buttons={buttons}
      >
        <TransformForm
          ref={formRef}
          templateDefinition={props.templateDefinition}
          attributeDefinitions={props.attributeDefinitions}
          template={props.template}
        />
      </Modal>
    </div>
  )
}
