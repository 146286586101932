import { KeyboardEvent, ReactNode } from 'react'
import React = require('react')

import { strnatcmp } from '../client-lib'
import { CommonUtils } from '../common/common-utils'
import { AttributeDefinition, AttributeDefinitionValue } from '../common/data-attribute-defs'
import { UiUtils } from './ui-utils'
import { User } from './user'

// TODO: a way to see the full unfiltered list in dropdown?

interface AttributeCombinationProps {
  attribute: AttributeDefinition,
  values: string[],
  onChange: (values: string[]) => void,
  categoryId?: string,
  width?: number, // Unit: em
}

interface State {
  newValue: string,
  showDropdown: boolean,
  selectedIndex: number,
  highlightedForDelete: boolean,
}

export class AttributeCombination extends React.Component<AttributeCombinationProps, State> {
  static defaultProps = {
    width: 20,
  }

  inputRef = React.createRef<HTMLInputElement>()

  state: State = {
    newValue: '',
    showDropdown: false,
    selectedIndex: 0,
    highlightedForDelete: false,
  }

  focusInput = () => {
    this.inputRef.current.focus()
  }

  getMatches = () => {
    const matches: AttributeDefinitionValue[] = []
    const language = User.getLanguage()

    // TODO: results by group?
    this.props.attribute.valueGroups.forEach((group, groupIndex) => {
      for (const value of group.values) {
        // TODO: don't edit prop value from here, either clone it or edit in parent
        value.groupIndex = groupIndex

        const label = value.labels[language]

        if (CommonUtils.contains(label, this.state.newValue)) {
          if (this.props.values.indexOf(value._id) === -1) {
            if (!group.category || group.category === this.props.categoryId) {
              matches.push(value)
            }
          }
        }
      }
    })

    matches.sort((value1, value2) => {
      let result = value1.groupIndex - value2.groupIndex

      if (result === 0) {
        const sortKey1 = value1.sortKey || value1.labels[language]
        const sortKey2 = value2.sortKey || value2.labels[language]
        result = strnatcmp(sortKey1, sortKey2)
      }

      return result
    })

    return matches
  }

  moveSelection = (diff: number) => {
    // diff must be either -1 or 1
    const maxIndex = this.getMatches().length - 1
    const newIndex = Math.max(0, Math.min(maxIndex, this.state.selectedIndex + diff))
    this.setState({ selectedIndex: newIndex })
  }

  updateValue = (value: string, newValueIds?: string[]) => {
    const stateChanges: State = {
      newValue: value,
      selectedIndex: 0,
      showDropdown: Boolean(value),
      highlightedForDelete: false,
    }

    let callback = null

    if (newValueIds !== undefined) {
      callback = () => {
        this.props.onChange(newValueIds)
      }
    }

    this.setState(stateChanges, callback)
  }

  handleKey = (evt: KeyboardEvent<HTMLInputElement>) => {
    const { keyCode } = evt // TODO: use evt.key

    const valueIds = this.props.values

    if (keyCode === 8) { // Backspace
      if (this.state.newValue === '') {
        const count = valueIds.length

        if (count > 0) {
          if (!this.state.highlightedForDelete) {
            this.setState({ highlightedForDelete: true })
          }
          else {
            this.setState({ highlightedForDelete: false }, () => {
              this.props.onChange(valueIds.slice(0, count - 1))
            })
          }
        }
      }
    }
    else if (keyCode === 9 || keyCode === 13) { // Tab or Enter
      if (this.state.showDropdown) {
        const value = this.getMatches()[this.state.selectedIndex]

        if (value) {
          this.updateValue('', valueIds.concat(value._id))
        }

        evt.preventDefault()
      }
    }
    else if (keyCode === 27) { // Escape
      this.updateValue('')
    }
    else if (keyCode === 38) { // Up
      this.moveSelection(-1)
    }
    else if (keyCode === 40) { // Down
      this.moveSelection(+1)
    }
  }

  getDropdown = () => {
    if (!this.state.showDropdown) {
      return null
    }

    const matches = this.getMatches()

    if (matches.length < 1) {
      return null
    }

    const matchesByGroup: AttributeDefinitionValue[][] = []

    for (const value of matches) {
      const { groupIndex } = value

      if (!(groupIndex in matchesByGroup)) {
        matchesByGroup[groupIndex] = []
      }

      matchesByGroup[groupIndex].push(value)
    }

    const dropdownElements: ReactNode[] = []

    let index = -1
    let firstGroup = true

    matchesByGroup.forEach((matchesOfGroup, groupIndex) => {
      if (!matchesOfGroup) {
        return
      }

      const group = this.props.attribute.valueGroups[groupIndex]

      if (group.names) {
        dropdownElements.push(
          <div
            key={`group-${groupIndex}`}
            style={{
              fontSize: '80%',
              color: '#808080',
              backgroundColor: '#f8f8f8',
              padding: '0.15em 0.8em',
              borderBottom: '1px solid #ddd',
              borderTop: firstGroup ? null : '1px solid #ddd',
            }}
          >
            {group.names[User.getLanguage()]}
          </div>,
        )
      }

      firstGroup = false

      for (const value of matchesOfGroup) {
        index += 1

        // Copy to local variable so we can use this value in callbacks
        const localIndex = index

        const bgColor = index === this.state.selectedIndex ? 'rgb(48, 108, 192)' : 'transparent'
        const textColor = index === this.state.selectedIndex ? 'white' : 'black'

        const label = CommonUtils.getAttributeLabel(
          this.props.attribute,
          value._id,
          this.props.categoryId,
          User.getLanguage(),
        )

        dropdownElements.push(
          <div
            key={index}
            className="suggestion"
            style={{
              backgroundColor: bgColor,
              color: textColor,
              padding: '0.15em 0.3em',
              cursor: 'pointer',
            }}
            onClick={() => {
              this.updateValue('', this.props.values.concat(value._id))
            }}
            onMouseOver={() => {
              if (this.state.selectedIndex !== localIndex) {
                this.setState({ selectedIndex: localIndex })
              }
            }}
          >
            {UiUtils.highlight(label, this.state.newValue)}
          </div>,
        )
      }
    })

    return (
      <div
        style={{
          position: 'absolute',
          top: '1.6em',
          width: '15em',
          backgroundColor: 'white',
          border: '1px solid #ccc',
          boxShadow: '0 0.3em 0.6em rgba(0, 0, 0, 0.2)',
          zIndex: 100,
        }}
      >
        {dropdownElements}
      </div>
    )
  }

  getValueElement = (value: string, index: number) => {
    let backgroundColor = '#d4e3fc'
    let deleteColor = 'rgb(64, 108, 144)'

    if (
      this.state.highlightedForDelete &&
      index === this.props.values.length - 1
    ) {
      backgroundColor = '#f3c8c8'
      deleteColor = 'rgb(144, 64, 64)'
    }

    return (
      <span
        key={index}
        style={{
          backgroundColor,
          marginRight: '0.3em',
          padding: '0 0.3em',
          lineHeight: '1.4em',
          borderRadius: '0.35em',
          display: 'inline-block',
        }}
      >
        <span className="value-label">
          {CommonUtils.getAttributeLabel(
            this.props.attribute,
            value,
            this.props.categoryId,
            User.getLanguage(),
          )}
        </span>
        <span
          style={{
            cursor: 'pointer',
            marginLeft: 3,
            fontSize: '1.4em',
            fontWeight: 'bold',
            verticalAlign: 'middle',
            color: deleteColor,
          }}
          onClick={() => {
            const newValues = this.props.values.slice()
            newValues.splice(index, 1)
            this.props.onChange(newValues)
          }}
        >
          {'\u00d7'}
        </span>
      </span>
    )
  }

  getInputElement = () => {
    const dropdown = this.getDropdown()
    const inputWidth = Math.max(4, Math.min(this.props.width - 2, this.state.newValue.length))

    return (
      <span style={{ position: 'relative' }}>
        <input
          ref={this.inputRef}
          className="inp-attr-value"
          style={{
            border: 0,
            outline: 0,
            width: inputWidth + 'em',
          }}
          onKeyDown={this.handleKey}
          onChange={(evt) => {
            this.updateValue(evt.currentTarget.value)
          }}
          value={this.state.newValue}
        />
        {dropdown}
      </span>
    )
  }

  render() {
    return (
      <div
        style={{
          border: '1px solid #ccc',
          padding: '0.2em',
          width: this.props.width + 'em',
          display: 'inline-block',
          whiteSpace: 'normal',
        }}
        // The input does not usually cover the entire empty area of the div.
        onClick={this.focusInput}
      >
        {this.props.values.map(this.getValueElement)}
        {this.getInputElement()}
      </div>
    )
  }
}
